import styled from 'styled-components';
import { Button } from 'common/common-components.styled';

export const StyledLeaseEndCardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;

  :not(:last-child) {
    border-bottom: 1px solid #E0E3E5;
  }
`;

export const StyledLeasingEndCard = styled.div`
  display: flex;  

  @media screen and (min-width: 993px) and (max-width: 1600px) {
    flex-direction: column;
    align-items: center;
  }
`;

export const StyledImageWrapper = styled.div`
  display: flex;
  margin-right: 20px;
  order: 0;

  @media screen and (min-width: 993px) and (max-width: 1600px) {
    margin-bottom: 25px;
    margin-right: 0;
  }
`;

export const StyledImageBackground = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.extraLightGold};
  height: 50px;
  width: 50px;
`;

export const StyledButtonsContainer = styled.div`
  display: flex;

  @media screen and (min-width: 993px) and (max-width: 1600px) {
    flex-direction: column;
    width: 100%;
  }
`;

export const StyledButton = styled(Button)`
  &:first-child {
    margin-right: 10px;
  }

  @media screen and (min-width: 993px) and (max-width: 1600px) {
    &:first-child {
      margin-right: 0;
      margin-bottom: 8px;
    }
  }
`;

interface StatusTextWrapperProps {
  isStatusHidden?: boolean;
}

export const StyledStatusTextWrapper = styled.div<StatusTextWrapperProps>`
  display: ${({ isStatusHidden }) => (isStatusHidden ? 'none' : 'flex')};
  order: 3;
  white-space: nowrap;
  margin-left: 10px;

  @media screen and (min-width: 993px) and (max-width: 1600px) {
    display: flex;
    order: 1;
    margin-bottom: 25px;
  }
`;