import React, { Fragment, useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import classes from './breadcrumbs-panel-links.module.scss';
import { parseBreadcrumbs } from '../breadcrumbs-panel-dashboard/breadcrumbs-panel-dashboard';
import PropTypes from 'prop-types';
import { withPageContainer } from '../../hoc/withPageContainer';
import { useSelector } from 'react-redux';
import { PAGES, PrefixForId } from '../../common/enums';
import { removeSpaces } from '../../utils/remove-spaces';
import capitalize from 'lodash/capitalize';
import { useUnsavedChangesModal } from 'hooks/modals/unsaved-changes.modal/unsaved-changes.modal';
import { useHistory } from 'react-router-dom';

const BreadcrumbsPanelLinks = props => {
  const location = useLocation();
  const [breadcrumbs, setBreadcrumbs] = useState([]);
  const customUrlTree = useSelector(state => state['app'].customUrlTree);
  const isShowBreadcrumbs = location.pathname !== PAGES.DASHBOARD && breadcrumbs.length > 1;
  const { openUnsavedChangesModal } = useUnsavedChangesModal();
  const history = useHistory();

  useEffect(() => {
    const breadcrumbs = parseBreadcrumbs(location.pathname, customUrlTree);
    setBreadcrumbs(breadcrumbs);
  }, [location, setBreadcrumbs, customUrlTree]);

  const handleRedirect = url => {
    openUnsavedChangesModal(() => {
      history.push(url);
    });
  };

  const createLink = item => {
    const title = item.breadcrumbTitle;
    const url = item.url;
    switch (item.component) {
      case 'a':
        return (
          <a
            id={PrefixForId.Breadcrumbs + PrefixForId.Link + removeSpaces(capitalize(title))}
            onClick={() => handleRedirect(url)}
          >
            {title}
          </a>
        );
      case 'Link':
      default:
        return (
          <div
            id={PrefixForId.Breadcrumbs + PrefixForId.Link + removeSpaces(capitalize(title))}
            onClick={() => handleRedirect(url)}
          >
            {title}
          </div>
        );
    }
  };

  const renderLink = (item, index, arr) => {
    const isLastItem = index === arr.length - 1;
    return (
      <Fragment key={`${item.url}${index}`}>
        {createLink(item)}
        {!isLastItem && item.breadcrumbTitle && (
          <img src='/assets/images/breadcrumbs_separator.svg' alt='breadcrumbs_separator' />
        )}
      </Fragment>
    );
  };

  return (
    <div className={classes.links_container}>
      <div className={props.containerClassName}>
        {isShowBreadcrumbs && (
          <div id={PrefixForId.Breadcrumbs + PrefixForId.Container} className={classes.links}>
            {breadcrumbs.map(renderLink)}
          </div>
        )}
      </div>
    </div>
  );
};

BreadcrumbsPanelLinks.propTypes = {
  containerClassName: PropTypes.string
};

export default withPageContainer(BreadcrumbsPanelLinks);
