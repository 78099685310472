import { useState } from 'react';
import { SESSION_FILTER_KEY } from '../constants';
import { PAGES } from 'common/enums';

export const useSessionFilter = (
  page: PAGES | string,
  defaultFilterForServer: ServerAndClientFilter = {},
  defaultFilterForClient: ServerAndClientFilter = {}
) => {
  const getSessionFilters = () => {
    const sessionFilters = sessionStorage.getItem(SESSION_FILTER_KEY);
    return sessionFilters ? JSON.parse(sessionFilters) : {};
  };

  const [pagesSessionFilters, setPagesSessionFilters] = useState<Record<PAGES | string, SessionFilter>>(
    getSessionFilters()
  );
  const reqCount = getSessionFilters()[page]?.reqCount | 0;

  const setSessionStorage = (
    filterForServer: ServerAndClientFilter = {},
    filterForClient: ServerAndClientFilter = {},
    bigActiveFilter?: BigActiveFilterType
  ) => {
    sessionStorage.setItem(
      SESSION_FILTER_KEY,
      JSON.stringify({
        ...pagesSessionFilters,
        [page]: {
          reqCount: reqCount + 1,
          filterForServer: {
            ...pagesSessionFilters[page]?.filterForServer,
            ...filterForServer
          },
          filterForClient: {
            ...pagesSessionFilters[page]?.filterForClient,
            ...filterForClient
          },
          bigActiveFilter
        }
      })
    );

    setPagesSessionFilters(getSessionFilters());
  };

  if (!reqCount) {
    setSessionStorage(defaultFilterForServer, defaultFilterForClient);
  }

  return { sessionFilter: pagesSessionFilters[page], setSessionFilter: setSessionStorage };
};

export type ServerAndClientFilter = {
  [key: string]: any;
};

export type BigActiveFilterType = number | string | null;

export interface SessionFilter {
  filterForServer: ServerAndClientFilter;
  filterForClient: ServerAndClientFilter;
  reqCount: number;
  bigActiveFilter?: BigActiveFilterType;
}

export type AutocompleteFilterFromState = Omit<SessionFilter, 'reqCount'>;

export type SetSessionFilter = (
  filterForServer: ServerAndClientFilter,
  filterForClient: ServerAndClientFilter,
  bigActiveFilter?: BigActiveFilterType
) => void;

export interface PageFilterHook {
  setSessionFilter: SetSessionFilter;
  sessionFilter: SessionFilter;
}
