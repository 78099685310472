import React, { cloneElement, isValidElement, useCallback, useRef, useState } from 'react';
import classes from './more.module.scss';
import PropTypes from 'prop-types';
import { useOutsideClick } from '../../hooks';
import Spinner from 'components/spinner';
import classNames from 'classnames';

export const MoreMenu = ({ onClick, ...prev }) => (
  <button className={classes.more_button} onClick={onClick} {...prev}>
    <span className={classes.more_dot} />
    <span className={classes.more_dot} />
    <span className={classes.more_dot} />
  </button>
);

MoreMenu.propTypes = {
  onClick: PropTypes.func.isRequired
};

export const MoreMenuItem = ({
  onClick,
  label,
  onOutsideClick,
  img,
  minWidth = '190px',
  isLeftPositionImg,
  disable = false,
  id
}) => {
  const clickHandler = event => {
    event.preventDefault();
    event.stopPropagation();

    if (!disable) {
      onOutsideClick && onOutsideClick();

      if (typeof onClick === 'function') {
        onClick();
      }
    }
  };

  return (
    <button
      style={{ minWidth, justifyContent: isLeftPositionImg ? 'flex-start' : 'space-between' }}
      className={classNames(classes.delete__button, { 'button-mobexo__disable': disable })}
      onClick={clickHandler}
      id={id}
    >
      {isLeftPositionImg && img && img}

      {isLeftPositionImg && !img && img !== false ? (
        <img className={classes.delete__button_img} src='/assets/images/gears.svg' />
      ) : null}

      <span className={classes.delete__button_text}>{label}</span>
      {!isLeftPositionImg && img && img}

      {!isLeftPositionImg && !img && img !== false ? (
        <img className={classes.delete__button_img} src='/assets/images/gears.svg' />
      ) : null}
    </button>
  );
};

MoreMenuItem.propTypes = {
  onClick: PropTypes.func.isRequired,
  minWidth: PropTypes.string,
  label: PropTypes.string,
  isLeftPositionImg: PropTypes.bool,
  disable: PropTypes.bool,
  img: PropTypes.oneOfType([PropTypes.bool, PropTypes.any]),
  onOutsideClick: PropTypes.func,
  id: PropTypes.string
};

export const MoreMenuItemContainer = ({ children, onOutsideClick, top = '0', right = '0' }) => {
  const containerRef = useRef(null);
  useOutsideClick(containerRef, onOutsideClick);

  const getChildrenWithProps = () => {
    const modifyChildProps = (child, i) => {
      if (isValidElement(child)) {
        return cloneElement(child, { onOutsideClick, key: i });
      }
    };
    let result;
    if (Array.isArray(children)) {
      result = children.map(modifyChildProps);
    } else {
      result = modifyChildProps(children);
    }
    return result;
  };

  return (
    <div style={{ top, right }} ref={containerRef} className={classes.more_menu_items}>
      {getChildrenWithProps()}
    </div>
  );
};

MoreMenuItemContainer.propTypes = {
  children: PropTypes.any,
  onOutsideClick: PropTypes.func.isRequired,
  top: PropTypes.string,
  right: PropTypes.string
};

const More = ({ className, children, spinnerStyle, isLoading, onClick }) => {
  const containerRef = useRef(null);
  const [open, setOpen] = useState(false);

  const moreButtonClickHandler = event => {
    event.preventDefault();
    event.stopPropagation();
    setOpen(true);

    if (typeof onClick === 'function') {
      onClick();
    }
  };

  const closeHandler = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  const spinnerContainerStyle = {
    height: spinnerStyle?.containerHeigth || 'auto',
    width: spinnerStyle?.containerWidth || 'auto'
  };

  return (
    <>
      <div ref={containerRef} className={className ? `${className} ${classes.more}` : classes.more}>
        {spinnerStyle && isLoading && open ? (
          <div className={classes.more_menu_items} style={spinnerContainerStyle}>
            <Spinner style={spinnerStyle} />
          </div>
        ) : (
          <>
            {open && <MoreMenuItemContainer onOutsideClick={closeHandler}>{children}</MoreMenuItemContainer>}
            <MoreMenu onClick={moreButtonClickHandler} />
          </>
        )}
      </div>
    </>
  );
};

More.propTypes = {
  className: PropTypes.string,
  deactivate: PropTypes.func,
  spinnerStyle: PropTypes.object,
  isLoading: PropTypes.bool,
  onClick: PropTypes.func,
  children: PropTypes.any
};

export default More;
