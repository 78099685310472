export const vehicleDetailsFields = [
  [
    {
      name: 'Kraftstoff',
      value: '',
      expand: false,
      disabled: true,
      fieldName: 'uds_enginetypecode',
      icon: '/assets/images/kraftstoff_vehicle_details_icon.svg',
      tooltipText: 'Kraftstoff'
    },
    {
      name: 'Leistung',
      value: '',
      expand: false,
      disabled: true,
      fieldName: 'uds_leistungkw',
      icon: '/assets/images/leistung_vehicle_details_icon.svg',
      tooltipText: 'Leistung'
    }
  ],
  [
    {
      name: 'Hubraum',
      value: '',
      expand: false,
      disabled: true,
      fieldName: 'uds_hubraum',
      icon: '/assets/images/hubraum_vehicle_details_icon.svg',
      tooltipText: 'Hubraum'
    },
    {
      name: 'Fahrzeugaufbau',
      value: '',
      expand: false,
      disabled: true,
      fieldName: 'uds_cartypecode',
      icon: '/assets/images/existing_vehicle_hersteller.svg',
      tooltipText: 'Fahrzeugaufbau'
    }
  ],
  [
    {
      name: 'Getriebe',
      value: '',
      expand: false,
      disabled: true,
      fieldName: 'uds_getriebe',
      icon: '/assets/images/getriebe_vehicle_details_icon.svg',
      tooltipText: 'Getriebe'
    },
    {
      name: 'Farbe',
      value: '',
      expand: false,
      disabled: true,
      fieldName: 'uds_color',
      icon: '/assets/images/farbe_vehicle_details_icon.svg',
      tooltipText: 'Farbe'
    }
  ],
  [
    {
      name: 'Listenpreis (brutto)',
      value: '',
      expand: false,
      disabled: true,
      fieldName: 'uds_listenpreis',
      icon: '/assets/images/existing_vehicle_brutto.svg',
      tooltipText: 'Listenpreis (brutto)'
    },
    {
      name: 'Innenfarbe',
      value: '',
      expand: false,
      disabled: true,
      fieldName: 'uds_interiorcolor',
      icon: '/assets/images/innenfarbe_vehicle_details_icon.svg',
      tooltipText: 'Innenfarbe'
    }
  ]
];
