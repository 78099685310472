export const getLeaseEndStatus = (phase?: number): boolean => {
  const validPhases = [3, 4, 6, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, -1];
  return phase !== undefined && !validPhases.includes(phase);
};

export const getLeaseEndHiddenStatus = (phase?: number): boolean => {
  const validPhases = [8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, -1];
  return phase !== undefined && validPhases.includes(phase);
};

export const getLeaseEndTooltip = (phase?: number) => {
  const phaseMapping: Record<number, string> = {
    3: 'Der Antrag wurde erfolgreich eingereicht und Sie werden informiert, sobald eine Entscheidung getroffen wurde.',
    4: 'Der Antrag wurde erfolgreich eingereicht und Sie werden informiert, sobald eine Entscheidung getroffen wurde.',
    6: 'Die Leasingvertragsverlängerung würde vom Vertragspartner abgelehnt.'
  };

  if (phase === undefined) {
    return '';
  }

  return phaseMapping[phase];
};

export const getLeaseEndHandleClick = (
  phase?: number,
  openLeasingContractModal?: () => void,
  openOfferConfirmationModal?: () => void
) => {
  const OPEN_LEASING_CONTRACT_MODAL_IN_PHASE = [0, 1, 2, 5];

  if (phase === undefined) {
    return;
  }

  if (OPEN_LEASING_CONTRACT_MODAL_IN_PHASE.includes(phase)) {
    return openLeasingContractModal;
  }

  if (phase === 7) {
    return openOfferConfirmationModal;
  }
};
