import { AxiosRequestConfig } from 'axios';
import { ApproveOrderData, DownloadExternalSigningFotoParams } from './vehicle.types';

class VehicleRequestConfig {
  getExternalSigningData = (id: string): AxiosRequestConfig => ({
    method: 'POST',
    url: 'vehicle/getExternalSigningData',
    data: { id }
  });

  downloadExternalSigningFoto = (data: DownloadExternalSigningFotoParams): AxiosRequestConfig => ({
    method: 'POST',
    url: 'vehicle/downloadExternalSigningFoto',
    responseType: 'blob',
    data
  });

  setExternalConfigurationStatus = (data: ApproveOrderData): AxiosRequestConfig => ({
    method: 'POST',
    url: 'vehicle/setExternalConfigurationStatus',
    data
  });

  requestNewLink = (id: string): AxiosRequestConfig => ({
    method: 'POST',
    url: 'vehicle/restartExternalConfigurationApprove',
    data: { id }
  });

  getApprovalStatus = (fahrzeugangeboteId: string): AxiosRequestConfig => ({
    method: 'POST',
    url: 'vehicle/getApprovalStatus',
    data: { fahrzeugangeboteId }
  });
}

export const vehicleRequestConfig = new VehicleRequestConfig();
