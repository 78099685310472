import React from 'react';
import PropTypes from 'prop-types';

export const UserCardCrossedOutIcon = ({ width = '20', height = '21', color = '#102F3F', ...props }) => (
  <svg width='20' height='21' viewBox='0 0 20 21' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M15.0073 4.16406H3.42871C2.32414 4.16406 1.42871 5.05949 1.42871 6.16406V14.3069C1.42871 15.2385 2.06561 16.0213 2.92772 16.2437L5.72161 13.4498H4.16681C3.98823 13.4498 3.86919 13.3902 3.75014 13.2712C3.63109 13.1521 3.57157 13.0331 3.57157 12.8545C3.57157 11.1879 4.88109 9.87834 6.54775 9.87834C7.29434 9.87834 7.9993 10.1697 8.52345 10.6479L10.3645 8.8069C10.2719 8.71615 10.2144 8.58967 10.2144 8.44978C10.2144 8.17363 10.4383 7.94978 10.7144 7.94978H11.2216L15.0073 4.16406ZM11.6358 8.94978H13.5716C13.8477 8.94978 14.0716 8.72592 14.0716 8.44978C14.0716 8.17363 13.8477 7.94978 13.5716 7.94978H12.6358L16.4215 4.16406H16.5716C17.6761 4.16406 18.5716 5.05949 18.5716 6.16406V14.3069C18.5716 15.4115 17.6761 16.3069 16.5716 16.3069H4.27868L7.13583 13.4498H8.33347H8.9287C9.28585 13.4498 9.52394 13.2117 9.52394 12.8545C9.52394 12.3483 9.37442 11.8719 9.13898 11.4466L11.6358 8.94978ZM6.54773 9.87834C5.53583 9.87834 4.76202 9.10454 4.76202 8.09263C4.76202 7.08073 5.53583 6.30692 6.54773 6.30692C7.55963 6.30692 8.33344 7.08073 8.33344 8.09263C8.33344 9.10454 7.55963 9.87834 6.54773 9.87834ZM10.7144 12.2355C10.4383 12.2355 10.2144 12.4593 10.2144 12.7355C10.2144 13.0116 10.4383 13.2355 10.7144 13.2355H16.4287C16.7049 13.2355 16.9287 13.0116 16.9287 12.7355C16.9287 12.4593 16.7049 12.2355 16.4287 12.2355H10.7144ZM10.7144 10.0926C10.4383 10.0926 10.2144 10.3165 10.2144 10.5926C10.2144 10.8688 10.4383 11.0926 10.7144 11.0926H16.4287C16.7049 11.0926 16.9287 10.8688 16.9287 10.5926C16.9287 10.3165 16.7049 10.0926 16.4287 10.0926H10.7144Z'
      fill='#102F3F'
    />
    <path d='M3.57178 17.0226L17.1432 3.45117' stroke='#102F3F' strokeLinecap='square' strokeLinejoin='round' />
  </svg>
);

UserCardCrossedOutIcon.propTypes = {
  color: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
};
