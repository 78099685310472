import { AxiosRequestConfig } from 'axios';
import { useFetch } from './use-fetch/use-fetch';
import { b64toBlob } from 'components/download-expansion-component/download-expansion-component';
import { snackbarMessage } from 'utils/snackbar-messages';

export type FileDownloadProps = {
  requestConfig?: AxiosRequestConfig;
  encodeConfig: { path: string[]; encodeWay: 'newBlob' | 'b64Data'; contentType?: string; sliceSize?: number };
  fileName: string;
  isSuccessSnackbar?: boolean;
  isErrorSnackbar?: boolean;
  isWarningSnackbar?: boolean;
  errorSnackbarMessage?: string;
  warningSnackbarMessage?: string;
  successSnackbarMessage?: string;
};

export const useFileDownload = ({
  requestConfig: requestConfigProp,
  encodeConfig,
  fileName,
  isSuccessSnackbar = true,
  isErrorSnackbar,
  isWarningSnackbar,
  errorSnackbarMessage = snackbarMessage.downloadDocuments.error,
  warningSnackbarMessage,
  successSnackbarMessage = snackbarMessage.downloadDocuments.success
}: FileDownloadProps) => {
  const { fetch } = useFetch({
    loadingKey: 'fileDownload',
    isShowSuccessSnackbar: isSuccessSnackbar,
    isShowErrorSnackbar: isErrorSnackbar,
    isShowWarningSnackbar: isWarningSnackbar,
    errorMessage: errorSnackbarMessage,
    warningMessage: warningSnackbarMessage,
    successMessage: successSnackbarMessage
  });

  const downloadFile = async ({ requestConfig }: Partial<FileDownloadProps> = {}) => {
    await fetch({
      requestConfig: requestConfigProp || requestConfig,
      callback: (response: any) => {
        const responseLayer = encodeConfig.path.map(layer => {
          if (response.data) {
            const resp = response.data[layer];
            if (typeof resp === 'string') {
              return resp;
            } else if (Array.isArray(resp)) {
              return resp[0];
            }

            return resp[Object.keys(resp)[0]];
          }
        });
        const blob =
          encodeConfig.encodeWay === 'b64Data'
            ? b64toBlob(responseLayer, encodeConfig.contentType)
            : new Blob([response.data], { type: encodeConfig.contentType });
        const a = document.createElement('a');
        a.href = URL.createObjectURL(blob);
        a.download = fileName;
        a.click();
      }
    });
  };

  return downloadFile;
};
