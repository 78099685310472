import { CrmDataTypes, PAGES } from 'common/enums';
import { FormFieldOption, UserRoles } from 'common/interfaces';

export const defaultCarImageUrl = '/assets/images/car_default.png';

export const Transmission = {
  '100000000': 'Manuell',
  '100000001': 'Automatik'
};

export const defaultGuid = '00000000-0000-0000-0000-000000000000';
export const defaultPicklistValue = '-1';

export const EmissionImages = {
  100000000: 'envkv-a_plus.png',
  100000001: 'envkv-a.png',
  100000002: 'envkv-b.png',
  100000003: 'envkv-c.png',
  100000004: 'envkv-d.png',
  100000005: 'envkv-e.png',
  100000006: 'envkv-f.png',
  100000007: 'envkv-g.png',
  100000008: 'envkv-a.png',
  100000009: 'envkv-a_plus.png',
  100000010: 'envkv-a_plus.png',
  100000011: 'envkv-a_plus.png',
  100000012: 'envkv-a_plus.png',
  100000013: 'envkv-a_plus.png',
  100000014: 'envkv-b.png',
  100000015: 'envkv-c.png',
  100000016: 'envkv-d.png',
  100000017: 'envkv-e.png',
  100000019: 'envkv-g.png'
};

export const CAR_TYPE_CODES = {
  Kleinwagen: '100000000',
  Kompaktklasse: '100000001',
  Mittelklasse: '100000002',
  Gehobene_Mittelklasse: '100000003',
  Obere_Mittelklasse: '100000004',
  Luxusklasse: '100000005',
  Bus: '100000006',
  Van: '100000007',
  Kleintransporter: '100000008',
  Transporter: '100000009'
};

export const uds_enginetypecode = {
  benzin: 100000000,
  diesel: 100000001,
  hybrid_benzin: 100000002,
  hybrid_diesel: 100000003,
  elektro: 100000004,
  wasserstoff: 100000008,
  erdgas: 100000005
};

export const ENGINE_TYPE_LABELS = {
  100000000: 'Benzin',
  100000001: 'Diesel',
  100000002: 'Hybrid',
  100000003: 'Hybrid/Diesel',
  100000004: 'Elektro',
  100000008: 'Wasserstoff',
  100000005: 'Erdgas (CNG)',
  100000006: 'Flüssiggas (LNG)',
  100000009: 'Andere'
};

export const UDS_AUFGABENTYP_FUHRPARK = {
  uvv: 100000000,
  hu: 100000001,
  allgemein: 100000002,
  checklist: 752560000,
  reparaturfreigabe: 100000003,
  replacement: 100000004,
  return: 100000005,
  leaseExtension: 100000006,
  appraise: 100000007,
  inspectionDates: 100000008
};

export const STATECODE = {
  open: 0,
  completed: 1,
  canceled: 2,
  postponed: 100000002
};

export const TASK_STATUS_CODES = {
  open: 752560000,
  completed: 752560002,
  canceled: 752560003,
  postponed: 100000002,
  processing: 752560001
};

export const STATECODE_NAMES = {
  open: 'Offen',
  completed: 'Erledigt',
  canceled: 'Storniert',
  postponed: 'Verschoben',
  processing: 'In Bearbeitung'
};

export const drivingLicenseType = {
  plastic: 100000000,
  gray: 100000001,
  pink: 100000002
};

export const LEASING_OFFER_STATUSES = {
  validOfferMissing: 100000000,
  priceRequested: 100000001,
  offerReceived: 100000002,
  priceReleased: 100000003,
  offerDeclined: 100000004
};

export const VEHICLE_OFFER_STATUSES = {
  validOfferMissing: 100000000,
  priceRequested: 100000001,
  offerReceived: 100000002,
  priceReleased: 100000003,
  offerDeclined: 100000004
};

export const VEHICLE_OFFER_ASSIGNMENT = {
  poolCar: { value: 100000000, label: 'Poolfahrzeug' },
  free: { value: 100000001, label: 'Frei' },
  employeeAssigned: { value: 100000002, label: 'Mitarbeiter zugeordnetes Fahrzeug' }
};

export const BASKET_TYPES = {
  empty: { value: -1, label: '---' },
  company: { value: 100000000, label: 'Firmen-Warenkorb' },
  personal: { value: 100000001, label: 'Persönlicher Warenkorb' },
  poolcar: { value: 100000002, label: 'Poolcar-Warenkorb' }
};

export const REFERENCE_MILEAGE_RANGE_OPTIONS = {
  empty: { value: -1, label: '---', labelNumber: null },
  uds_lease_annual_milleage_k_10: { value: 100000000, label: '10.000', labelNumber: 10000 },
  uds_lease_annual_milleage_k_15: { value: 100000001, label: '15.000', labelNumber: 15000 },
  uds_lease_annual_milleage_k_20: { value: 100000002, label: '20.000', labelNumber: 20000 },
  uds_lease_annual_milleage_k_25: { value: 100000003, label: '25.000', labelNumber: 25000 },
  uds_lease_annual_milleage_k_30: { value: 100000004, label: '30.000', labelNumber: 30000 },
  uds_lease_annual_milleage_k_35: { value: 100000005, label: '35.000', labelNumber: 35000 },
  uds_lease_annual_milleage_k_40: { value: 100000006, label: '40.000', labelNumber: 40000 },
  uds_lease_annual_milleage_k_45: { value: 100000007, label: '45.000', labelNumber: 45000 },
  uds_lease_annual_milleage_k_50: { value: 100000008, label: '50.000', labelNumber: 50000 },
  uds_lease_annual_milleage_k_55: { value: 100000009, label: '55.000', labelNumber: 55000 },
  uds_lease_annual_milleage_k_60: { value: 100000010, label: '60.000', labelNumber: 60000 },
  uds_lease_annual_milleage_k_65: { value: 100000011, label: '65.000', labelNumber: 65000 },
  uds_lease_annual_milleage_k_70: { value: 100000012, label: '70.000', labelNumber: 70000 },
  uds_lease_annual_milleage_k_75: { value: 100000013, label: '75.000', labelNumber: 75000 },
  uds_lease_annual_milleage_k_80: { value: 100000014, label: '80.000', labelNumber: 80000 }
};

export const EMAIL_IF_DRIVER_LICENSE_CHANGED_OPTIONS = {
  empty: { value: -1, label: '---' },
  active: { value: 100000000, label: 'Aktiv' },
  inactive: { value: 100000001, label: 'Inaktiv' }
};

// uds_testcompanycode
export const IS_TEST_COMPANY = {
  empty: { value: -1, label: '---' },
  no: { value: 752560000, label: 'Nein' },
  yes: { value: 752560001, label: 'Ja' }
};

export const REFERENCE_DISTANCE_OPTIONS = {
  empty: { value: -1, label: '---', labelNumber: null },
  uds_lease_length_month_12: { value: 100000000, label: '12 Monate', labelNumber: 12 },
  uds_lease_length_month_18: { value: 100000001, label: '18 Monate', labelNumber: 18 },
  uds_lease_length_month_24: { value: 100000002, label: '24 Monate', labelNumber: 24 },
  uds_lease_length_month_30: { value: 100000003, label: '30 Monate', labelNumber: 30 },
  uds_lease_length_month_36: { value: 100000004, label: '36 Monate', labelNumber: 36 },
  uds_lease_length_month_42: { value: 100000005, label: '42 Monate', labelNumber: 42 },
  uds_lease_length_month_48: { value: 100000006, label: '48 Monate', labelNumber: 48 },
  uds_lease_length_month_54: { value: 100000007, label: '54 Monate', labelNumber: 54 },
  uds_lease_length_month_60: { value: 100000008, label: '60 Monate', labelNumber: 60 }
};

export const crmDataTypes = {
  Boolean: 0,
  Customer: 1,
  DateTime: 2,
  Decimal: 3,
  Double: 4,
  Integer: 5,
  Lookup: 6,
  Memo: 7,
  Money: 8,
  Owner: 9,
  Picklist: 11,
  State: 12,
  Status: 13,
  String: 14,
  Uniqueidentifier: 15,
  BigInt: 18,
  PicklistMultiple: 100
};

export const kraftstoff = [
  { id: '1', label: 'Benzin' },
  { id: '2', label: 'Diesel' },
  { id: '100000003', label: 'Hybrid' },
  { id: '100000007', label: 'Hybrid/Diesel' },
  { id: '100000001', label: 'Elektro' },
  { id: '100000009', label: 'Wasserstoff' },
  { id: '3', label: 'Erdgas (CNG)' },
  { id: '100000010', label: 'Flüssiggas (LNG)' },
  { id: '100000000', label: 'Kein Motor' },
  { id: '100000011', label: 'Andere' },
  { id: '100000004', label: 'Benzin/Erdgas' },
  { id: '100000002', label: 'unbekannt' },
  { id: '100000006', label: 'Plugin-Hybrid' }
];

export const CO2Effizienz = [
  { id: '100000000', label: 'CO2-Effizienz a_plus' },
  { id: '100000001', label: 'CO2-Effizienz a' },
  { id: '100000002', label: 'CO2-Effizienz b' },
  { id: '100000003', label: 'CO2-Effizienz c' },
  { id: '100000004', label: 'CO2-Effizienz d' },
  { id: '100000005', label: 'CO2-Effizienz e' },
  { id: '100000006', label: 'CO2-Effizienz f' },
  { id: '100000007', label: 'CO2-Effizienz g' },
  { id: '100000008', label: 'A' },
  { id: '100000009', label: 'A_PLUS' },
  { id: '100000010', label: 'A+' },
  { id: '100000011', label: 'A+++' },
  { id: '100000012', label: 'AA' },
  { id: '100000013', label: 'Aplus' },
  { id: '100000014', label: 'B' },
  { id: '100000015', label: 'C' },
  { id: '100000016', label: 'D' },
  { id: '100000017', label: 'E' },
  { id: '100000019', label: 'G' },
  { id: '100000018', label: 'N/A' }
];

export const kauf = [
  // {
  //   id: '100000006',
  //   label: 'Tankkarte'
  // },
  {
    id: '100000005',
    label: 'Miete'
  },
  {
    id: '100000004',
    label: 'Finanzierung'
  },
  {
    id: '100000000',
    label: 'Eigentum'
  },
  {
    id: '100000007',
    label: 'Sale & Lease back'
  },
  {
    id: '100000001',
    label: 'Leasing'
  },
  {
    id: '100000002',
    label: 'Langzeitmiete'
  }
  // {
  //   id: '100000003',
  //   label: 'AMS'
  // }
];

export const statusCarcode = [
  {
    id: '100000000',
    label: 'Bestellt'
  },
  {
    id: '100000001',
    label: 'In Betrieb'
  },
  {
    id: '100000002',
    label: 'Ausgesteuert'
  }
];

export const leasingDuration = [
  {
    id: '-1',
    label: '---'
  },
  {
    id: '12',
    label: '12'
  },
  {
    id: '24',
    label: '24'
  },
  {
    id: '36',
    label: '36'
  },
  {
    id: '48',
    label: '48'
  },
  {
    id: '60',
    label: '60'
  }
];

export const emissioncategorycode = [
  { id: '100000000', label: 'Euro4' },
  { id: '100000001', label: 'Euro5' },
  { id: '100000002', label: 'Euro6' },
  { id: '100000003', label: 'Euro6b' },
  { id: '100000004', label: 'Euro6c' },
  { id: '100000005', label: 'Euro6d' },
  { id: '100000006', label: 'Euro6d-TEMP' }
];

export const zuordnung = [
  { id: '100000000', label: 'Poolfahrzeug' },
  { id: '100000001', label: 'Frei' },
  { id: '100000002', label: 'Mitarbeiter zugeordnetes Fahrzeug' }
];

export const threeStatesOptionSet = [
  { id: '-1', label: '---' },
  { id: '100000000', label: 'Ja' },
  { id: '100000001', label: 'Nein' }
];

export const HUInterval = [
  { id: '-1', label: '---' },
  { id: '100000000', label: '12' },
  { id: '100000001', label: '24' },
  { id: '100000002', label: '36' }
];

export const carCategories = [
  { id: '-1', label: '---' },
  { id: '100000007', label: 'NFZ' },
  { id: '100000000', label: 'PKW' },
  { id: '100000003', label: 'LKW' },
  { id: '100000004', label: 'Arbeitsmaschine' },
  { id: '100000005', label: 'Anhänger' },
  { id: '100000006', label: 'Mofa' },
  { id: '100000001', label: 'Lieferwagen' },
  { id: '100000002', label: 'SONDER' }
];

export const insuranceTypes = [
  { value: '100000000', label: 'Teilkasko' },
  { value: '100000001', label: 'Vollkasko' },
  { value: '100000006', label: 'Haftpflicht' },
  { value: '100000005', label: 'Haftpflicht+Vollkasko+GAP' }
];

export const silverDatVehicleTypes = {
  personalCar: { id: '1', label: 'PKW, SUV, Kleintransporter' },
  transporter: { id: '2', label: 'Transporter' },
  truck: { id: '4', label: 'Lastkraftwagen' },
  trailer: { id: '6', label: 'Anhänger' },
  bicycle: { id: '7', label: 'Fahrrad' },
  tool: { id: '8', label: 'Werkzeug' },
  forklift: { id: '9', label: 'Gabelstapler' }
};

export const silverDatVehicleTypesOptions = [{ id: '-1', label: '---' }, ...Object.values(silverDatVehicleTypes)];

export const installationAvailableOptions = [
  { id: '-1', label: '---' },
  { id: '752560000', label: 'Ja' },
  { id: '752560001', label: 'Nein' }
];

export const installationOwnerOptions = [
  { id: '-1', label: '---' },
  { id: '752560000', label: 'Kunde' },
  { id: '752560001', label: 'Leasing' }
];

export const VEHICLE_OFFER_EVENT_DESCRIPTIONS = {
  configurationCreated: { id: '790690e0-e572-ec11-8943-000d3ade37a8', label: 'Fahrzeugkonfiguration vornehmen' },
  configurationUploaded: { id: '45d2fafe-e572-ec11-8943-000d3ade37a8', label: 'Fahrzeugkonfiguration hochladen' },
  offerRequested: { id: '24f1c10b-e672-ec11-8943-000d3ade37a8', label: 'Angebot anfordern' },
  priceRequested: { id: 'b0929b1e-e672-ec11-8943-000d3ade37a8', label: 'Barpreis anfragen' },
  budgetCheckPositive: { id: '8c582b74-e672-ec11-8943-000d3ade37a8', label: 'Budgetprüfung positiv' },
  vehicleRequested: { id: '5f52cc80-e672-ec11-8943-000d3ade37a8', label: 'Fahrzeug anfordern' },
  purchaseTriggered: { id: '07c4e6a0-e672-ec11-8943-000d3ade37a8', label: 'Bestellanforderung ausgelöst' },
  approvedByFleetManager: {
    id: '056733ae-e672-ec11-8943-000d3ade37a8',
    label: 'Bestellanforderung vom Fuhrparkleiter freigegeben'
  },
  firstApproverRequested: {
    id: 'b42041b4-e672-ec11-8943-000d3ade37a8',
    label: 'Fahrzeugbestellung Erstfreigabe anfordern'
  },
  secondApproverRequested: {
    id: 'f5c65aba-e672-ec11-8943-000d3ade37a8',
    label: 'Fahrzeugbestellung Zweitfreigabe anfordern'
  },
  thirdApproverRequested: {
    id: 'd8e440c7-e672-ec11-8943-000d3ade37a8',
    label: 'Fahrzeugbestellung Drittfreigabe anfordern'
  },
  orderApproved: { id: '62b448d9-e672-ec11-8943-000d3ade37a8', label: 'Bestellung vollständig freigegeben' },
  approvalRejected: { id: '9a99d203-8789-ec11-93b0-0022489d3efe', label: 'Fahrzeugkonfiguration archivieren' },
  priceReceived: { id: '15689a2a-e672-ec11-8943-000d3ade37a8', label: 'Barpreis bekommen' },
  globalReferenceVehicleGeneratedViaAPI: {
    id: '6971ad73-b09c-ed11-aad1-000d3adf7830',
    label: 'Globales Referenzfahrzeug mittels Api erzeugen'
  },
  vehicleConfigurationBasedOnGRVCreated: {
    id: 'd34bd18f-b09c-ed11-aad1-000d3adf7830',
    label: 'Fahrzeugkonfiguration auf Basis vom globalen Referenzfahrzeug vornehmen'
  },
  dealershipSelected: {
    id: '5e3e7fab-b09c-ed11-aad1-000d3adf7830',
    label: 'Autohaus auswählen'
  },
  buildAbilityConfirmed: {
    id: '0d31186e-eda6-ed11-aad1-000d3adea2be',
    label: 'Baubarkeitsprüfung bekommen'
  },
  orderVehicleFromDealership: {
    id: 'ed3e6f9e-23b1-ed11-83ff-000d3adccfe1',
    label: 'Fahrzeug beim Autohaus bestellen'
  },
  offerPublishedForUsers: {
    id: '721e2a68-e672-ec11-8943-000d3ade37a8',
    label: 'Angebot für Nutzer veröffentlicht'
  },
  vehicleBasedOnBulkOrderCreated: {
    id: '948f6b0b-0d18-ef11-9f89-000d3ada13c8',
    label: 'SB Fahrzeug angefordert'
  },
  approvalFlowStarted: {
    id: '2d0266e6-0c18-ef11-9f89-000d3ada13c8',
    label: 'Freigabe(n) angefordert'
  }
};

export const OFFER_STATUSES = {
  configurationCreated: 752560000,
  offerRequested: 752560002,
  priceRequested: 752560003,
  requirementApproved: 752560004,
  vehicleRequested: 752560005,
  purchaseTriggered: 752560006,
  approvedByFleetManager: 752560007,
  orderApproved: 752560008,
  approvalRejected: 752560009,
  dealershipSelected: 752560001,
  vehicleConfigurationBasedOnGRVCreated: 752560011,
  globalReferenceVehicleGeneratedViaAPI: 752560010,
  buildAbilityConfirmed: 752560012,
  orderVehicleFromDealership: 752560013,
  orderCompleted: 752560023,
  offerPublishedForUsers: 752560004, // should be changed to 752560028 perhaps
  vehicleBasedOnBulkOrderCreated: 100000002,
  approvalFlowStarted: 100000003
};

export const REQUEST_STATUS = {
  postConfiguration: 100000004, // Nachkonfiguration
  newConfiguration: 100000009 // Neue Konfiguration
};

export const CONFIGURATION_TYPES = {
  personalConfiguration: { value: 100000000, label: 'Mitarbeiter-/Poolfahrzeug' }, // Persönliche Konfiguration
  referenceVehicle: { value: 100000001, label: 'Referenzfahrzeug' }, // Referenzfahrzeug
  globalReferenceVehicle: { value: 100000002, label: 'Massendatenkalkulation' }, // Globales Referenzfahrzeug
  bulkOrder: { value: 100000003, label: 'Sammelbestellung' } // Sammelbestellung
};

/********************** MODALS **********************/
export const MODALS = {
  general_contact_form: 'general_contact_form',
  general_comparison_form: 'general_comparison_form',
  general_configurator: 'general_configurator',
  alert: 'alert',
  insurance_dialog: 'insurance_dialog',
  file_viewer: 'file_viewer',
  checkout: 'checkout'
};

export const MODAL_NAMES = {
  lead_form: 'lead_form',
  comparison_form: 'comparison_form',
  configurator: 'configurator',
  alert: 'alert',
  insurance_dialog: 'insurance_dialog',
  file_viewer: 'file_viewer',
  checkout: 'checkout'
};
/********************** MODALS **********************/

/********************** MODALS **********************/
export const USER_ROLES: UserRoles = {
  fleetManager: { id: 'b7baf7be-51cf-eb11-bacc-00224899ed4b', name: 'Fuhrparkleiter' },
  driver: { id: 'e634ccea-6cce-eb11-bacc-00224899ed4b', name: 'Fahrer' },
  administrator: { id: 'ce061f58-90ca-eb11-bacc-000d3ab79e9d', name: 'Administrator' },
  dealer: { id: 'db1a68dd-51cf-eb11-bacc-00224899ed4b', name: 'Händler' },
  leasingcompany: { id: 'a0ff03e5-51cf-eb11-bacc-00224899ed4b', name: 'Leasingcompany' },
  apiDoc: { id: 'b0f726b1-4a5f-ec11-8f8f-000d3ab86b1b', name: 'Apidoc' },
  attorney: { id: 'd4fc13b2-51cf-eb11-bacc-00224899ed4b', name: 'Anwalt' },
  approver: { id: 'ad891dab-998d-ec11-b400-000d3ab41be3', name: 'Fahrzeugbestellfreigeber' },
  mobexoEmployee: { id: '899cb0c4-0be7-ec11-bb3c-0022489c6bd1', name: 'Mobexo employee' },
  poolManager: { id: 'ed31f7f8-51cf-eb11-bacc-00224899ed4b', name: 'Poolverwalter' },
  insurance: { id: 'a9992fd1-51cf-eb11-bacc-00224899ed4b', name: 'Versicherung' },
  workshop: { id: '30f215cb-51cf-eb11-bacc-00224899ed4b', name: 'Werkstatt' },
  earlyUser: { id: 'f4addd63-f5f5-ec11-bb3d-0022489dfe95', name: 'Early User' }
};

/********************** MODALS **********************/

/********************** OTHER **********************/
export const ONE_MEGABYTE_IN_BYTES = 1048576;

export enum OnboardingEntityNames {
  Location = 'new_standort',
  Driver = 'contact',
  Fleet = 'new_fuhrpark',
  Service = 'uds_leasingvertrge',
  LeasingOffer = 'uds_leasingangebote',
  CostCenter = 'new_kostenstelle'
}

export const locationsFields = {
  entityName: OnboardingEntityNames.Location,
  columns: [
    {
      title: 'Firma',
      crmField: 'new_firma',
      attributeTypeCode: crmDataTypes.Lookup,
      logicalName: 'account',
      required: true
    },
    {
      title: 'Standortkürzel',
      crmField: 'new_name',
      attributeTypeCode: crmDataTypes.String,
      logicalName: null,
      required: true
    },
    {
      title: 'Straße, Hausnummer',
      crmField: 'uds_lieferanschrift_strasse',
      attributeTypeCode: crmDataTypes.String,
      logicalName: null,
      required: true
    },
    {
      title: 'PLZ',
      crmField: 'uds_lieferanschrift_plz',
      attributeTypeCode: crmDataTypes.Integer,
      logicalName: null,
      required: true
    },
    {
      title: 'Ort',
      crmField: 'uds_lieferanschrift_ort',
      attributeTypeCode: crmDataTypes.String,
      logicalName: null,
      required: true
    },
    {
      title: 'Land',
      crmField: 'uds_land',
      attributeTypeCode: crmDataTypes.String,
      logicalName: null,
      required: true
    }
  ]
};

export const driversFields = {
  entityName: OnboardingEntityNames.Driver,
  columns: [
    {
      title: 'Anrede',
      crmField: 'new_herrfrau',
      attributeTypeCode: crmDataTypes.Picklist,
      logicalName: null,
      required: false
    },
    {
      title: 'Vorname',
      crmField: 'firstname',
      attributeTypeCode: crmDataTypes.String,
      logicalName: null,
      required: true
    },
    {
      title: 'Nachname',
      crmField: 'lastname',
      attributeTypeCode: crmDataTypes.String,
      logicalName: null,
      required: true
    },
    {
      title: 'Firma',
      crmField: 'parentcustomerid',
      attributeTypeCode: crmDataTypes.Lookup,
      logicalName: 'account',
      required: true
    },
    {
      title: 'Standort',
      crmField: 'uds_standortid',
      attributeTypeCode: crmDataTypes.Lookup,
      logicalName: 'new_standort',
      required: false
    },
    {
      title: 'E-mail',
      crmField: 'emailaddress1',
      attributeTypeCode: crmDataTypes.String,
      logicalName: null,
      required: true
    },
    {
      title: 'Mobiltelefon',
      crmField: 'mobilephone',
      attributeTypeCode: crmDataTypes.String,
      logicalName: null,
      required: false
    },
    {
      title: 'Webrolle',
      crmField: 'roleInFirma',
      attributeTypeCode: 999,
      noMapping: true,
      logicalName: null,
      required: true
    },
    {
      title: 'Car Policy',
      crmField: 'uds_carpolicyid',
      attributeTypeCode: crmDataTypes.Lookup,
      logicalName: 'uds_carpolicy',
      required: false
    },
    {
      title: 'Mobilitätsbudget',
      crmField: 'uds_carpolicynutzerid',
      attributeTypeCode: crmDataTypes.Lookup,
      logicalName: 'uds_car_policy_nutzer',
      required: false
    },
    {
      title: 'Führerscheinart',
      crmField: 'new_fuehrerscheinart',
      attributeTypeCode: crmDataTypes.Picklist,
      logicalName: null,
      required: false
    },
    {
      title: 'Führerscheinnummer',
      crmField: 'uds_fuhrerscheinnummer',
      attributeTypeCode: crmDataTypes.String,
      logicalName: null,
      required: false
    },
    {
      title: 'Siegelnummer',
      crmField: 'new_siegelnummer',
      attributeTypeCode: crmDataTypes.Integer,
      logicalName: null,
      required: false
    },
    {
      title: 'Führerschein gültig bis',
      crmField: 'uds_fuhrerscheingultigbis',
      attributeTypeCode: crmDataTypes.DateTime,
      logicalName: null,
      required: false
    },
    {
      title: 'Kontrolleur:in',
      crmField: 'uds_kontrolleur',
      attributeTypeCode: crmDataTypes.Boolean,
      logicalName: null,
      required: false
    },
    {
      title: 'Eskalationsmanager:in',
      crmField: 'new_manager_driverscheckeskalation',
      attributeTypeCode: crmDataTypes.Boolean,
      logicalName: null,
      required: false
    },
    {
      title: 'Kontrollinterval in Tagen',
      crmField: 'uds_checkupinterval',
      attributeTypeCode: crmDataTypes.Integer,
      logicalName: null,
      required: false
    },
    {
      title: 'Einladungs-E-Mail senden',
      crmField: 'inviteUser',
      attributeTypeCode: crmDataTypes.Boolean,
      noMapping: true,
      logicalName: null,
      required: false
    }
  ]
};

export const carsFields = {
  entityName: OnboardingEntityNames.Fleet,
  columns: [
    {
      title: 'Firma',
      crmField: 'new_sparteid',
      attributeTypeCode: crmDataTypes.Lookup,
      logicalName: 'account',
      required: true
    },
    {
      title: 'Standort',
      crmField: 'new_standortid',
      attributeTypeCode: crmDataTypes.Lookup,
      logicalName: 'new_standort',
      required: false
    },
    {
      title: 'Status Fahrzeug',
      crmField: 'new_status_carcode',
      attributeTypeCode: crmDataTypes.Picklist,
      logicalName: null,
      required: true
    },
    {
      title: 'Kennzeichen',
      crmField: 'new_name',
      attributeTypeCode: crmDataTypes.String,
      logicalName: null,
      required: true
    },
    {
      title: 'Interne Fahrzeugnummer',
      crmField: 'uds_interne_fahrzeugnummer_kunde',
      attributeTypeCode: crmDataTypes.String,
      logicalName: null,
      required: false
    },
    {
      title: 'Fahrgestellnummer',
      crmField: 'new_fin',
      attributeTypeCode: crmDataTypes.String,
      logicalName: null,
      required: true
    },
    {
      title: 'Erstzulassung',
      crmField: 'new_ezl',
      attributeTypeCode: crmDataTypes.DateTime,
      logicalName: null,
      required: true
    },
    {
      title: 'Fahrer',
      crmField: 'new_fahrerid',
      attributeTypeCode: crmDataTypes.Lookup,
      logicalName: 'contact',
      required: false
    },
    {
      title: 'Zuordnung',
      crmField: 'new_zuordnung',
      attributeTypeCode: crmDataTypes.Picklist,
      logicalName: null,
      required: false
    },
    {
      title: 'Zuordnung Beschreibung',
      crmField: 'new_zuordnung_beschreibung',
      attributeTypeCode: crmDataTypes.String,
      logicalName: null,
      required: false
    },
    {
      title: 'Kostenstellen Verantwortliche',
      crmField: 'new_kstverantw',
      attributeTypeCode: crmDataTypes.Lookup,
      logicalName: 'contact',
      required: false
    },
    {
      title: 'Kostenstelle',
      crmField: 'new_kostenstelleid',
      attributeTypeCode: crmDataTypes.Lookup,
      logicalName: 'new_kostenstelle',
      required: false
    },
    {
      title: 'Bruttolistenpreis',
      crmField: 'uds_fahrzeugpreise',
      attributeTypeCode: crmDataTypes.Money,
      logicalName: null,
      required: false
    },
    {
      title: 'Eigentum / Leasing',
      crmField: 'new_kauf_leasing',
      attributeTypeCode: crmDataTypes.Picklist,
      logicalName: null,
      required: false
    },
    {
      title: 'Hauptauto',
      crmField: 'uds_hauptauto',
      attributeTypeCode: crmDataTypes.Boolean,
      logicalName: null,
      required: false
    },
    {
      title: 'Privatnutzung',
      crmField: 'new_privatnutzung',
      attributeTypeCode: crmDataTypes.Picklist,
      logicalName: null,
      required: false
    }
  ]
};

export const servicesFields = {
  entityName: OnboardingEntityNames.Service,
  columns: [
    {
      title: 'Dienstleistungstyp',
      crmField: 'new_vertragstyp',
      attributeTypeCode: crmDataTypes.Picklist,
      logicalName: null,
      required: true
    },
    {
      title: 'Inkludiert Leasing',
      crmField: 'new_inkludiert_leasing',
      attributeTypeCode: crmDataTypes.Boolean,
      logicalName: null,
      required: true
    },
    {
      title: 'Kennzeichen',
      crmField: 'uds_fuhrparkid',
      attributeTypeCode: crmDataTypes.Lookup,
      logicalName: 'new_fuhrpark',
      required: false
    },
    {
      title: 'Dienstleister',
      crmField: 'uds_leasingbank',
      attributeTypeCode: crmDataTypes.Lookup,
      logicalName: 'account',
      required: false
    },
    {
      title: 'Betrag',
      crmField: 'new_betrag',
      attributeTypeCode: crmDataTypes.Money,
      logicalName: null,
      required: false
    },
    {
      title: 'Rahmenvertrag',
      crmField: 'new_rahmenvertrag',
      attributeTypeCode: crmDataTypes.Lookup,
      logicalName: 'uds_rahmenvertrges',
      required: false
    },
    {
      title: 'Selbstbeteiligung Vollkasko',
      crmField: 'uds_selbstbeteiligung_vollkasko',
      attributeTypeCode: crmDataTypes.Money,
      logicalName: null,
      required: false
    },
    {
      title: 'Selbstbeteiligung Teilkasko',
      crmField: 'uds_selbstbeteiligung_teilkasko',
      attributeTypeCode: crmDataTypes.Money,
      logicalName: null,
      required: false
    },
    {
      title: 'Laufleistung',
      crmField: 'uds_laufleistung',
      attributeTypeCode: crmDataTypes.Integer,
      logicalName: null,
      required: false
    },
    {
      title: 'Laufleistung p. a.',
      crmField: 'uds_laufleistungpa',
      attributeTypeCode: crmDataTypes.Integer,
      logicalName: null,
      required: false
    },
    {
      title: 'Leasingdauer',
      crmField: 'uds_leasingdauer',
      attributeTypeCode: crmDataTypes.Integer,
      logicalName: null,
      required: false
    },
    {
      title: 'Beschreibung Vertragstyp',
      crmField: 'new_beschreibung_vertragstyp',
      attributeTypeCode: crmDataTypes.String,
      logicalName: null,
      required: false
    },
    {
      title: 'Gebucht',
      crmField: 'new_gebucht',
      attributeTypeCode: crmDataTypes.Picklist,
      logicalName: null,
      required: false
    },
    {
      title: 'Gültigkeit von',
      crmField: 'uds_gultigkeitvon',
      attributeTypeCode: crmDataTypes.DateTime,
      logicalName: null,
      required: false
    },
    {
      title: 'Gültigkeit bis',
      crmField: 'uds_gultigkeitbis',
      attributeTypeCode: crmDataTypes.DateTime,
      logicalName: null,
      required: false
    },
    {
      title: 'Vertrags-Nr.',
      crmField: 'uds_vertragnr',
      attributeTypeCode: crmDataTypes.String,
      logicalName: null,
      required: false
    },
    {
      title: 'Zahlungsrhythmus',
      crmField: 'new_zahlungsrythmus',
      attributeTypeCode: crmDataTypes.Picklist,
      logicalName: null,
      required: false
    }
  ]
};

export const costCentersFields = {
  entityName: OnboardingEntityNames.CostCenter,
  columns: [
    {
      title: 'Name',
      crmField: 'new_name',
      attributeTypeCode: crmDataTypes.String,
      logicalName: null,
      required: true
    },
    {
      title: 'Firma',
      crmField: 'new_firmaid',
      attributeTypeCode: crmDataTypes.Lookup,
      logicalName: 'account',
      required: true
    }
  ]
};

export const leasingOffersFields = {
  entityName: OnboardingEntityNames.LeasingOffer,
  columns: [
    {
      title: 'ID',
      crmField: 'uds_name',
      attributeTypeCode: crmDataTypes.String,
      logicalName: null,
      required: true
    },
    // {
    //   title: 'Anfrage-Typ',
    //   crmField: 'uds_name',
    //   attributeTypeCode: crmDataTypes.String,
    //   logicalName: null,
    //   required: true
    // },
    // {
    //   title: 'Erstellt am',
    //   crmField: 'createdon',
    //   attributeTypeCode: crmDataTypes.DateTime,
    //   logicalName: null,
    //   required: true
    // },
    // {
    //   title: 'Leasingbank',
    //   crmField: 'new_leasingbank',
    //   attributeTypeCode: crmDataTypes.Lookup,
    //   logicalName: 'account',
    //   required: true
    // },
    // {
    //   title: 'Kunde',
    //   crmField: 'uds_firmaid',
    //   attributeTypeCode: crmDataTypes.Lookup,
    //   logicalName: 'account',
    //   required: true
    // },
    // {
    //   title: 'jato Code',
    //   crmField: 'uds_jato_code',
    //   attributeTypeCode: crmDataTypes.String,
    //   logicalName: null,
    //   required: true
    // },
    // {
    //   title: 'Hersteller',
    //   crmField: 'uds_marke',
    //   attributeTypeCode: crmDataTypes.String,
    //   logicalName: null,
    //   required: true
    // },
    // {
    //   title: 'Modell',
    //   crmField: 'uds_modell',
    //   attributeTypeCode: crmDataTypes.String,
    //   logicalName: null,
    //   required: true
    // },
    // {
    //   title: 'Fahrzeugtyp',
    //   crmField: 'uds_modellspezifikation',
    //   attributeTypeCode: crmDataTypes.String,
    //   logicalName: null,
    //   required: true
    // },
    // {
    //   title: 'KW',
    //   crmField: 'uds_leistung_kw',
    //   attributeTypeCode: crmDataTypes.Integer,
    //   logicalName: null,
    //   required: true
    // },
    // {
    //   title: 'Hubraum (ccm)',
    //   crmField: 'uds_hubraum',
    //   attributeTypeCode: crmDataTypes.Integer,
    //   logicalName: null,
    //   required: true
    // },
    // {
    //   title: 'Kraftstoff',
    //   crmField: 'uds_enginetypecode',
    //   attributeTypeCode: crmDataTypes.String,
    //   logicalName: null,
    //   required: true
    // },
    // {
    //   title: 'Listenpreis (netto)',
    //   crmField: 'new_listenpreis_netto',
    //   attributeTypeCode: crmDataTypes.Money,
    //   logicalName: null,
    //   required: true
    // },
    // {
    //   title: 'Listenpreis (brutto)',
    //   crmField: 'new_listenpreis_brutto',
    //   attributeTypeCode: crmDataTypes.Money,
    //   logicalName: null,
    //   required: true
    // },
    // {
    //   title: 'Nachlass (%)',
    //   crmField: 'uds_grosskundennachlass_percent_float',
    //   attributeTypeCode: crmDataTypes.Decimal,
    //   logicalName: null,
    //   required: true
    // },
    // {
    //   title: 'Barwert (Netto)',
    //   crmField: 'uds_barpreis_netto',
    //   attributeTypeCode: crmDataTypes.Money,
    //   logicalName: null,
    //   required: true
    // },
    // {
    //   title: 'Barwert (Brutto)',
    //   crmField: 'uds_barpreis_brutto',
    //   attributeTypeCode: crmDataTypes.Money,
    //   logicalName: null,
    //   required: true
    // },
    // {
    //   title: 'Laufzeit (Monate)',
    //   crmField: 'uds_laufzeit',
    //   attributeTypeCode: crmDataTypes.Integer,
    //   logicalName: null,
    //   required: true
    // },
    // {
    //   title: 'Laufleistung gesamt (km)',
    //   crmField: 'uds_gesamtlaufleistung',
    //   attributeTypeCode: crmDataTypes.Integer,
    //   logicalName: null,
    //   required: true
    // },
    {
      title: 'Leasingfaktor (%)',
      crmField: 'uds_leasingfaktor',
      attributeTypeCode: crmDataTypes.Double,
      logicalName: null,
      required: true
    },
    {
      title: 'Finanzrate',
      crmField: 'new_finanzratemobexo',
      attributeTypeCode: crmDataTypes.Money,
      logicalName: null,
      required: true
    },
    {
      title: 'Wartung und Verschleiß',
      crmField: 'new_wartung_und_verschless_imobexo',
      attributeTypeCode: crmDataTypes.Money,
      logicalName: null,
      required: true
    },
    {
      title: 'Versicherung anbieten',
      crmField: 'uds_versicherungcheckbox',
      attributeTypeCode: crmDataTypes.String,
      hideFromMappingAndEditing: true,
      logicalName: null,
      required: false
    },
    {
      title: 'Versicherung',
      crmField: 'new_versicherung_mobexo',
      attributeTypeCode: crmDataTypes.Money,
      logicalName: null,
      required: false
    },
    {
      title: 'Winterreifen anbieten',
      crmField: 'uds_winterreifencheckbox',
      attributeTypeCode: crmDataTypes.String,
      hideFromMappingAndEditing: true,
      logicalName: null,
      required: false
    },
    {
      title: 'Winterreifen',
      crmField: 'new_winterreifen_mobexo',
      attributeTypeCode: crmDataTypes.Money,
      logicalName: null,
      required: false
    },
    {
      title: 'Rädereinlagerung und Wechsel anbieten',
      crmField: 'uds_isradereinlagerungundwech',
      attributeTypeCode: crmDataTypes.String,
      hideFromMappingAndEditing: true,
      logicalName: null,
      required: false
    },
    {
      title: 'Rädereinlagerung und Wechsel',
      crmField: 'uds_radereinlagerungundwechsel',
      attributeTypeCode: crmDataTypes.Money,
      logicalName: null,
      required: false
    },
    {
      title: 'Sommerreifen extra anbieten',
      crmField: 'uds_reifenersatzcheckbox',
      attributeTypeCode: crmDataTypes.String,
      hideFromMappingAndEditing: true,
      logicalName: null,
      required: false
    },
    {
      title: 'Sommerreifen extra',
      crmField: 'new_sommerreifen_mobexo',
      attributeTypeCode: crmDataTypes.Money,
      logicalName: null,
      required: false
    },
    {
      title: 'GEZ anbieten',
      crmField: 'uds_gezcheckbox',
      attributeTypeCode: crmDataTypes.String,
      hideFromMappingAndEditing: true,
      logicalName: null,
      required: false
    },
    {
      title: 'GEZ',
      crmField: 'new_gez_mobexo',
      attributeTypeCode: crmDataTypes.Money,
      logicalName: null,
      required: false
    },
    {
      title: 'KFZ-Steuer anbieten',
      crmField: 'uds_kfzsteuercheckbox',
      attributeTypeCode: crmDataTypes.String,
      hideFromMappingAndEditing: true,
      logicalName: null,
      required: false
    },
    {
      title: 'KFZ-Steuer',
      crmField: 'new_kfz_steuer_mobexo',
      attributeTypeCode: crmDataTypes.Money,
      logicalName: null,
      required: false
    },
    {
      title: 'GAP-Versicherung anbieten',
      crmField: 'uds_isgap_versicherung_anbieten',
      attributeTypeCode: crmDataTypes.String,
      hideFromMappingAndEditing: true,
      logicalName: null,
      required: false
    },
    {
      title: 'GAP-Versicherung',
      crmField: 'uds_gap',
      attributeTypeCode: crmDataTypes.Money,
      logicalName: null,
      required: false
    },
    {
      title: 'Leasing Mehr-KM (cent)',
      crmField: 'uds_mehrkilometer',
      attributeTypeCode: crmDataTypes.Money,
      logicalName: null,
      required: true
    },
    {
      title: 'Leasing berechnungsfreie Mehr-KM',
      crmField: 'uds_toleranzgrenzekm',
      attributeTypeCode: crmDataTypes.Integer,
      logicalName: null,
      required: true
    },
    {
      title: 'Leasing Minder-KM (cent)',
      crmField: 'uds_minderkilometer',
      attributeTypeCode: crmDataTypes.Money,
      logicalName: null,
      required: true
    },
    {
      title: 'Leasing berechnungsfreie Minder-KM',
      crmField: 'uds_berechnungsfreie_minderkilometer_leasing',
      attributeTypeCode: crmDataTypes.Integer,
      logicalName: null,
      required: true
    },
    {
      title: 'Wartung und Verschleiß Mehr-KM (cent)',
      crmField: 'uds_je_mehrkilometer_wv',
      attributeTypeCode: crmDataTypes.Money,
      logicalName: null,
      required: true
    },
    {
      title: 'Wartung und Verschleiß berechnungsfreie Mehr-KM',
      crmField: 'uds_berechnungsfreie_mehrkilometer_wv',
      attributeTypeCode: crmDataTypes.Integer,
      logicalName: null,
      required: true
    },
    {
      title: 'Wartung und Verschleiß Minder-KM (cent)',
      crmField: 'uds_je_minderkilometer_wv',
      attributeTypeCode: crmDataTypes.Money,
      logicalName: null,
      required: true
    },
    {
      title: 'Wartung und Verschleiß berechnungsfreie Minder-KM',
      crmField: 'uds_berechnungsfreie_minderkilometer_wv',
      attributeTypeCode: crmDataTypes.Integer,
      logicalName: null,
      required: true
    },
    {
      title: 'Gültig bis',
      crmField: 'uds_validuntil',
      attributeTypeCode: crmDataTypes.DateTime,
      logicalName: null,
      required: true
    }
  ]
};

export const STEP_NAMES = {
  chooseEntity: 'Choose entity for import',
  uploadExcel: 'Upload excel',
  provideLocationsMapping: 'Provide Locations mapping',
  provideDriversMapping: 'Provide Drivers mapping',
  provideCarsMapping: 'Provide Cars mapping',
  provideServicesMapping: 'Provide Services mapping',
  provideLeasingOffersMapping: 'Provide Leasing Offers mapping',
  provideCostCenterMapping: 'Provide Cost center mapping',
  editingLocationData: 'Editing Location data',
  editingDriverData: 'Editing Driver data',
  editingCarData: 'Editing Car data',
  editingServiceData: 'Editing Services data',
  editingLeasingOffersData: 'Editing Leasing Offers data',
  editingCostCenterData: 'Editing Cost center data'
};

export const ENTITIES = [
  {
    title: 'Standorte',
    name: OnboardingEntityNames.Location,
    fields: locationsFields,
    mappingStep: STEP_NAMES.provideLocationsMapping,
    editStep: STEP_NAMES.editingLocationData,
    visibleFor: [USER_ROLES.fleetManager, USER_ROLES.administrator]
  },
  {
    title: 'Fahrerdaten',
    name: OnboardingEntityNames.Driver,
    fields: driversFields,
    mappingStep: STEP_NAMES.provideDriversMapping,
    editStep: STEP_NAMES.editingDriverData,
    visibleFor: [USER_ROLES.fleetManager, USER_ROLES.administrator]
  },
  {
    title: 'Fahrzeugdaten',
    name: OnboardingEntityNames.Fleet,
    fields: carsFields,
    mappingStep: STEP_NAMES.provideCarsMapping,
    editStep: STEP_NAMES.editingCarData,
    visibleFor: [USER_ROLES.fleetManager, USER_ROLES.administrator]
  },
  {
    title: 'Dienstleistungen',
    name: OnboardingEntityNames.Service,
    fields: servicesFields,
    mappingStep: STEP_NAMES.provideServicesMapping,
    editStep: STEP_NAMES.editingServiceData,
    visibleFor: [USER_ROLES.fleetManager, USER_ROLES.administrator]
  },
  {
    title: 'Leasingdaten',
    name: OnboardingEntityNames.LeasingOffer,
    fields: leasingOffersFields,
    mappingStep: STEP_NAMES.provideLeasingOffersMapping,
    editStep: STEP_NAMES.editingLeasingOffersData,
    visibleFor: [USER_ROLES.leasingcompany]
  },
  {
    title: 'Kostenstelle',
    name: OnboardingEntityNames.CostCenter,
    fields: costCentersFields,
    mappingStep: STEP_NAMES.provideCostCenterMapping,
    editStep: STEP_NAMES.editingCostCenterData,
    visibleFor: [USER_ROLES.fleetManager, USER_ROLES.administrator]
  }
];

export const STEPS = [
  {
    name: STEP_NAMES.chooseEntity,
    completed: false
  }
  // {
  //   name: STEP_NAMES.uploadExcel,
  //   completed: false
  // }
  // {
  //   name: STEP_NAMES.provideLocationsMapping,
  //   completed: false
  // },
  // {
  //   name: STEP_NAMES.provideDriversMapping,
  //   completed: false
  // },
  // {
  //   name: STEP_NAMES.provideCarsMapping,
  //   completed: false
  // },
  // {
  //   name: STEP_NAMES.provideServicesMapping,
  //   completed: false
  // },
  // {
  //   name: STEP_NAMES.editingLocationData,
  //   completed: false
  // },
  // {
  //   name: STEP_NAMES.editingDriverData,
  //   completed: false
  // },
  // {
  //   name: STEP_NAMES.editingCarData,
  //   completed: false
  // },
  // {
  //   name: STEP_NAMES.editingServiceData,
  //   completed: false
  // }
];

export const SESSION_FILTER_KEY = 'filter';

export const isMobileWidth = window.innerWidth <= 550;

export const MILLISECONDS_IN_DAY = 1000 * 60 * 60 * 24;

export const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
export const PORTAL_ENDPOINT = process.env.REACT_APP_PORTAL_ENDPOINT;

export const SurchargeType = {
  100000000: '%',
  100000001: '€'
};

export enum RequestStatuses {
  Success = 'SUCCESS',
  Error = 'ERROR',
  Warning = 'WARNING'
}

export enum DeviceTypes {
  Desktop = 'desktop',
  Tablet = 'tablet',
  Mobile = 'mobile'
}

export const SESSION_SORTING_KEY = 'sorting';

export const NOVOFLEET_CARD_ID = '100000000';
export const ARAL_CARD_ID = '100000001';
export const DKV_CARD_ID = '100000002';

export const REPLACEMENT_VEHICLE_STATUS = {
  notNecessary: 'Bestellung noch nicht notwendig',
  configured: 'Konfiguriert, nicht bestellt',
  ordered: 'Bestellt',
  created: 'In der App angelegt'
};

export const CONTRACT_TYPES: { [name: string]: FormFieldOption } = {
  autohersteller: { value: '100000008', label: 'Autohersteller-Rahmenvertrag' },
  autovermietung: { value: '100000001', label: 'Autovermietung' },
  fuhrparkmanagement: { value: '100000003', label: 'Fuhrparkmanagement' },
  leasingvertrag: { value: '100000000', label: 'Leasingvertrag' },
  mineralolvertrag: { value: '100000005', label: 'Mineralölvertrag' },
  schadenmanagement: { value: '100000002', label: 'Schadenmanagement' },
  versicherung: { value: '100000004', label: 'Versicherung' }
};

export const REPLACEMENT_VEHICLE_PHASES = {
  noPhase: -1,
  created: 1,
  open: 2,
  closedWithoutVehicle: 3,
  processing: 4,
  closedVehicleOrdered: 5,
  closedVehicleDelivered: 6
};

export const REQUEST_REASON_ID = '752560002';

export const FuelCardsStatuses = {
  ANORDER: {
    label: 'Bestellung',
    value: 752560000
  },
  INACTIVE: {
    label: 'Inaktiv',
    value: 752560001
  },
  ACTIVE: {
    label: 'Aktiv',
    value: 752560002
  },
  DEACTIVATION: {
    label: 'Deaktivierung',
    value: 752560003
  },
  MISTAKE: {
    label: 'Fehler',
    value: 752560004
  },
  BLOCKREQUEST: {
    label: 'Sperranfrage',
    value: 752560005
  }
};

export const toLocaleStringSetup = {
  style: 'currency',
  currency: 'EUR'
};

export const toLocaleDateStringSetup: Intl.DateTimeFormatOptions = {
  day: '2-digit',
  month: '2-digit',
  year: 'numeric'
};

export const PRICE_REQUEST_STATUS = {
  open: 100000000,
  closed: 100000001
};

export const stuffId = 'WVtcuski5UOhAki+6Ias0Q==';

export const driversLicenseCheckAddonId = 'aaae374a-ede6-eb11-bacb-0022489a71a2';

export const mobexoPhoneNumber = '+49 234 62 20 94 00';
export const mobexoInfoEmail = 'info@mobexo.de';

export const VEHICLE_RETURN_PHASES = {
  noPhase: -1,
  open: 2,
  filledByClient: 3,
  protocolUploadedByClient: 4,
  carTaxed: 6,
  filledByManagerAndCanceledByEmployee: 9,
  filledByEmployee: 10,
  filledAndCanceledByEmployee: 11,
  protocolUploadedByEmployee: 12,
  returnProtocolWasCanceled: 13,
  mobexoTagThatProtocolIsNotNeeded: 14
};

export const SERVICES = {
  maintenanceAndTear: { id: 'dc42af0d-7d0e-ed11-82e4-000d3addb10f', label: 'Wartung und Verschleiß' },
  insurance: { id: '49de80f1-080f-ed11-82e4-000d3addbbcb', label: 'Versicherung' },
  gapInsurance: { id: '95e5b79f-6211-ed11-b83d-000d3ada36a0', label: 'GAP-Versicherung' },
  vehicleTax: { id: 'a857a051-da13-ed11-b83d-000d3adbd2a7', label: 'KFZ-Steuer' },
  broadcastContributions: { id: '0a5fbf69-da13-ed11-b83d-000d3adbd2a7', label: 'Rundfunkbeiträge' },
  winterTires: { id: '963e6c76-da13-ed11-b83d-000d3adbd2a7', label: 'Winterreifen mit Felge' },
  summerTiresExtra: { id: 'f6b1878a-da13-ed11-b83d-000d3adbd2a7', label: 'Sommerreifen extra' },
  wheelStorage: { id: '29064a97-da13-ed11-b83d-000d3adbd2a7', label: 'Rädereinlagerung und Wechsel' }
};

export const ORDER_STATUS = {
  open: 100000000,
  closed: 100000001,
  noStatus: 100000002,
  processing: 100000003
};

export const InvitedClientsStatuses = {
  Completed: 100000000,
  Canceled: 100000001
};

export const TAXES_COEFFICIENT = 1.19;

export const BOOKING_STATUS = {
  ordered: 100000000,
  notOrdered: 100000001
};

export const SERVICES_CONTRACT_TYPES = {
  finance_rate: 100000000,
  insurance: 100000001
};

export const ROLES_WITH_NO_ACCESS_TO_DASHBOARD_REDIRECT = {
  [USER_ROLES.attorney.id]: PAGES.DAMAGE,
  [USER_ROLES.mobexoEmployee.id]: PAGES.DAMAGE,
  [USER_ROLES.dealer.id]: PAGES.REQUESTS,
  [USER_ROLES.leasingcompany.id]: PAGES.LEASING_REQUESTS,
  [USER_ROLES.insurance.id]: PAGES.SERVICE_PROVIDER_TASKS,
  [USER_ROLES.apiDoc.id]: PAGES.SWAGGER,
  [USER_ROLES.approver.id]: PAGES.APPROVALS,
  [USER_ROLES.poolManager.id]: PAGES.MY_VEHICLES
};

export const FILTER_LABEL = {
  year: { current: 'Aktuelles Jahr', last: 'Letztes Jahr' }
};

export enum DistanceValueType {
  Number = 1,
  Text = 2
}
