import styled from 'styled-components';
import { Flex } from 'common/common-components.styled';
import { ReactNode } from 'react';

export const StyledDeclinedState = styled(Flex)`
  background-color: ${({ theme }) => theme.colors.pink};
  height: 24px;
  border-radius: 12px;
`;

interface StyledOfferStatusProps {
  status: {
    icon: ReactNode;
    active: boolean;
    canceled?: boolean;
  }
}

export const StyledOfferStatus = styled(Flex)<StyledOfferStatusProps>`
  background-color: ${({ status, theme }) =>
    status.canceled
      ? theme.colors.pink
      : status.active
        ? theme.colors.darkBlue
        : theme.colors.extraLightBlue
};
  width: 24px;
  height: 24px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 5px;
`;
