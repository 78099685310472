/* eslint-disable react-hooks/rules-of-hooks */
import React, { MouseEvent, useState } from 'react';
import classes from './my-vehicles-menu.module.scss';
import { useQueryClient } from 'react-query';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { toggleModal } from 'actions/app_action';
import { MODALS, USER_ROLES } from '../../../constants';
import { useOverlay } from 'hooks';
import { useDeactivatingCarModal } from 'hooks/modals/open-deactivating-car.modal';
import { MyVehiclesMenuProps } from './my-vehicles-menu.props';
import { useLeftMenuSetup } from './my-vehicles-menu-setup';
import { VehicleStateCodeLabel, VehicleStatusesLabel } from '../my-vehicles.props';
import { MoreMenu } from 'components/more/more-view';
import classNames from 'classnames';
import { deactivatingCar } from '../utils';
import { PAGES } from '../../../common/enums';
import { MenuTabList } from './components/menu-tab-list';
import { useIsUserHaveRole } from 'hooks/use-is-user-have-role';
import { useIsProductAvailable } from 'hooks/use-is-product-available/use-is-product-available';
import { ProductId } from 'hooks/react-query/product/get-user-product/get-user-product.props';
import { availabilityRollsForProduct } from 'pages/create-damage/utils';
import { DocumentWithNutIcon } from 'components/icons-new/document-with-nut';
import { PlumpRightArrowIcon } from 'components/icons-new/plump-arrow';
import { MyVehicleMenuItem } from './components/my-vehicles-menu-item';
import { Flex } from 'common/common-components.styled';
import { TABLE_MENU_WIDTH } from 'components/table-component/table-component';
import useContractStatus from 'hooks/react-query/use-contract-status';
import { useRightMenuSetup } from './hooks/use-right-menu-setup';

export const MyVehiclesMenu = ({
  carStatus,
  carId = '',
  carNumber = '',
  setStatus,
  leasingReturnEnabled,
  phase,
  openLeasingContractModal,
  openOfferConfirmationModal
}: MyVehiclesMenuProps) => {
  const [active, setActive] = useState(false);
  const [isControlRequested, setIsControlRequested] = useState(false);

  const history = useHistory();
  const dispatch = useDispatch();
  const { isAvailable: showCreateDamage } = useIsProductAvailable(
    ProductId.SelfManagedClaims,
    availabilityRollsForProduct[ProductId.SelfManagedClaims]
  );

  const queryClient = useQueryClient();
  const [showOverlay, hideOverlay] = useOverlay();
  const deactivatingCarModal = useDeactivatingCarModal((reason: string, description: string) =>
    deactivatingCarHandler(carId, reason, description)
  );
  const { isContractAvailable } = useContractStatus();

  const isPoolManager = useIsUserHaveRole(USER_ROLES.poolManager.id);
  const isAdminOrFleetManager = useIsUserHaveRole([USER_ROLES.fleetManager.id, USER_ROLES.administrator.id]);

  const { getMyVehiclesMenuSetupLeft } = useLeftMenuSetup();
  const { getMyVehiclesMenuSetupRight } = useRightMenuSetup(carNumber);

  const menuContainerClassName = classNames(classes.menu_block_container, {
    [classes.menu_block_container_active]: active
  });

  const isCarStatusInActive = carStatus === VehicleStateCodeLabel.Inactive;
  let isDisableRequestDeactivation: boolean = false;

  if (carStatus) {
    isDisableRequestDeactivation = statusesForDisableRequestDeactivation.includes(carStatus) || isControlRequested;
  }

  const closeAlert = () => {
    dispatch(toggleModal(MODALS.alert, null));
  };

  const deactivatingCarHandler = async (id: string, reason: string, description: string) => {
    try {
      showOverlay();
      setIsControlRequested(true);
      closeAlert();

      await deactivatingCar(id, reason, description);
      queryClient.resetQueries([PAGES.MY_VEHICLES]);

      if (setStatus) {
        setStatus(CONTROL_REQUESTED_STATUS);
      }
    } catch (err) {
      console.error(err);
    } finally {
      hideOverlay();
    }
  };

  const openDeactivatingCarModal = () => {
    if (!isDisableRequestDeactivation) {
      setActive(false);
      deactivatingCarModal();
    }
  };

  const closeMenuHandler = (event: MouseEvent<HTMLImageElement, globalThis.MouseEvent>) => {
    event.stopPropagation();
    setActive(false);
  };

  const editVehicleHandler = () => {
    if (!isCarStatusInActive) {
      history.push(`${PAGES.MY_VEHICLES_NEW_VEHICLE}/?id=${carId}`);
    }
  };

  const openModalHandler = (event: MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    setActive(true);
  };

  return (
    <div onBlur={() => setTimeout(() => setActive(false), 100)}>
      <Flex width={TABLE_MENU_WIDTH} justify='flex-end'>
        <MoreMenu onClick={openModalHandler} id={DOTS_ELEMENT_ID} />
      </Flex>

      {carId && carStatus && (
        <div className={classes.menu_relative} onClick={event => event.stopPropagation()}>
          <div className={menuContainerClassName} id={MENU_BLOCK_ID} data-id={carId}>
            <div className={classes.menu_block}>
              <img
                src='/assets/images/close-menu-icon.svg'
                alt='close-menu'
                className='ml-auto cursor-pointer'
                width='25'
                height='25'
                onClick={closeMenuHandler}
              />

              <div className={classes.menu_row}>
                <div className={classes.menu_left}>
                  <ul>
                    <div onClick={editVehicleHandler}>
                      <MyVehicleMenuItem
                        name='Fahrzeug bearbeiten'
                        icon={<DocumentWithNutIcon />}
                        isEnabled={!isCarStatusInActive}
                      />
                    </div>
                  </ul>
                </div>

                <div className={classes.vertical_line} />

                <div className={classes.menu_right}>
                  <ul>
                    <div onClick={openDeactivatingCarModal}>
                      <MyVehicleMenuItem
                        name='Deaktivierung/Aussteuerung anfragen'
                        icon={<PlumpRightArrowIcon />}
                        isEnabled={!isDisableRequestDeactivation}
                      />
                    </div>
                  </ul>
                </div>
              </div>

              <div className={classes.horizontal_line} />

              <div className={classes.menu_row}>
                <MenuTabList
                  className={classes.menu_left}
                  getLink={menuSetup => ({ pathname: menuSetup.href as PAGES, state: { carId, carNumber } })}
                  menuList={getMyVehiclesMenuSetupLeft(
                    carStatus,
                    isPoolManager,
                    isAdminOrFleetManager,
                    carNumber,
                    showCreateDamage,
                    leasingReturnEnabled,
                    phase,
                    openOfferConfirmationModal,
                    openLeasingContractModal
                  )}
                  carStatus={carStatus}
                  carId={carId}
                />

                <div className={classes.vertical_line_big} />

                <MenuTabList
                  className={classes.menu_left}
                  getLink={menuSetup => menuSetup.href as PAGES}
                  menuList={getMyVehiclesMenuSetupRight(isPoolManager, isContractAvailable)}
                  carStatus={carStatus}
                  carId={carId}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const statusesForDisableRequestDeactivation = [
  VehicleStatusesLabel.ControlRequested,
  VehicleStatusesLabel.InControl,
  VehicleStatusesLabel.Controlled,
  VehicleStateCodeLabel.Inactive
];

export const CONTROL_REQUESTED_STATUS = 'Aussteuerung angefordert';

export const MENU_BLOCK_ID = 'menuBlock';
export const DOTS_ELEMENT_ID = 'dotsElement';
