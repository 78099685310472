import { AnyObject, FormFieldOption, User } from 'common/interfaces';
import { getAutohausesCompanies, getCompaniesByTypes, getDriversWithCar } from 'services/company-service';
import { getEntityByFieldQuery } from 'services/crm-entity-service';
import { crmDataTypes, IS_TEST_COMPANY } from './../../../constants';
import { getResponseFormData } from 'utils/get-response-data';
import { getFileDescriptions, getUnusedUserCompanyFuhrparks } from 'services/fuhrpark-service';
import { UserCar } from 'components/benutzereinstellungen/components/user-cars-component/user-cars-component.props';
import { getUserCompanyCarPolicies } from 'services/car-policy-service';
import { ConditionOperator, PAGES } from 'common/enums';
import { getCarsListVR } from 'services/fuhrpark-lease-end-service';
import { getCarDealerships } from 'services/car-dealership-service';
import { documentTypeService } from 'services/document-type-service';
import { damageService } from 'services/damage-service/damage-service';
import { getDriversForBooking, getPersonsForStandorts } from 'services/pool-car-booking-servises';
import { reportsRequestConfig } from 'request-config/reports/reports.request-config';
import { getFuelReportService } from 'services/fuel-report-service';

export const getOptions = async (source: string, user: User, value: string) => {
  let options: FormFieldOption[] = [];
  const sourceMapping: { [key: string]: () => any } = {
    getCompanyDrivers: () => getDriversWithCar(user.companyId, true),
    getContractPartnerCompanies: () =>
      getCompaniesByTypes({
        CustomerTypeCodes: [100000005, 11, 10, 100000000],
        Attributes: ['accountid', 'name', 'uds_testcompanycode']
      }),
    getCarBrands: () =>
      getEntityByFieldQuery({
        entityName: 'uds_carbrand',
        columns: ['uds_carbrandid', 'uds_name'],
        conditions: [
          {
            attributeTypeCode: crmDataTypes.State,
            attributeName: 'statecode',
            conditionOperator: ConditionOperator.Equal,
            value: [{ value: '0' }]
          }
        ],
        order: { predicate: 'uds_name', reverse: false }
      }),
    getCarBrandsForGrv: () =>
      getEntityByFieldQuery({
        entityName: 'uds_carbrand',
        columns: ['uds_carbrandid', 'uds_name'],
        conditions: [
          {
            attributeTypeCode: crmDataTypes.State,
            attributeName: 'statecode',
            conditionOperator: ConditionOperator.Equal,
            value: [{ value: '0' }]
          },
          {
            attributeTypeCode: crmDataTypes.Boolean,
            attributeName: 'uds_available_for_grv',
            conditionOperator: ConditionOperator.Equal,
            value: [1]
          }
        ],
        order: { predicate: 'uds_name', reverse: false }
      }),
    getCompanyContracts: () =>
      getEntityByFieldQuery({
        entityName: 'uds_rahmenvertrges',
        columns: ['uds_vertragsbezeichnung', 'uds_rahmenvertrgesid'],
        conditions: [
          {
            attributeTypeCode: crmDataTypes.Lookup,
            attributeName: 'new_kundeid',
            conditionOperator: ConditionOperator.Equal,
            value: [
              {
                id: user.companyId,
                logicalName: '',
                name: ''
              }
            ]
          },
          {
            attributeTypeCode: crmDataTypes.State,
            attributeName: 'statecode',
            conditionOperator: ConditionOperator.Equal,
            value: [{ value: '0' }]
          }
        ]
      }),
    getUnusedUserCompanyFuhrparks: () => getUnusedUserCompanyFuhrparks(user.id),
    getCarDealerships: () => getAutohausesCompanies(['accountid', 'name']),
    getUserCompanyCarPolicies: () => getUserCompanyCarPolicies(user.id),
    getCarsListVR: () => getCarsListVR(),
    getDienstleistungen: () =>
      getCompaniesByTypes({
        CustomerTypeCodes: [100000000, 100000005, 14, 2, 13, 1, 11, 10],
        Attributes: ['accountid', 'name']
      }),
    getUserCompanyCarDealerships: () => getCarDealerships(user.companyId),
    getDamagePageDocumentTypes: () => documentTypeService.getDocumentTypeByApPage(PAGES.DAMAGE),
    getDamageRecordMark: () => damageService.getDamageRecordFormData({ search: null, formDataKey: 'kennzeichen' }),
    getDamageRecordRepairPartner: () =>
      damageService.getDamageRecordFormData({ search: value, formDataKey: 'reparaturpartner' }),
    getDamageRecordWorkshop: () => damageService.getDamageRecordFormData({ search: value, formDataKey: 'werkstatt' }),
    getDamageRecordOwner: () => damageService.getDamageRecordFormData({ search: value, formDataKey: 'eigentumer' }),
    getDamageRecordInsurer: () => damageService.getDamageRecordFormData({ search: value, formDataKey: 'versicherer' }),
    getDamageRecordInsuranceBroker: () =>
      damageService.getDamageRecordFormData({ search: value, formDataKey: 'versicherungsmarkler' }),
    getDocumentTypes: () => getFileDescriptions(),
    getPersonsForStandorts: () => getPersonsForStandorts(),
    getFuelReportsFiltersName: () => getFuelReportService({ searchValue: value, columnName: 'new_name' }),
    getFuelReportsFiltersDriver: () => getFuelReportService({ searchValue: value, columnName: 'new_fahrerid' }),
    getFuelReportsFiltersLocation: () => getFuelReportService({ searchValue: value, columnName: 'new_standortid' }),
    getFuelReportsFiltersBranch: () => getFuelReportService({ searchValue: value, columnName: 'new_sparteid' }),
    getFuelReportsFiltersManufacturer: () => getFuelReportService({ searchValue: value, columnName: 'new_hersteller' }),
    getFuelReportsFiltersModel: () => getFuelReportService({ searchValue: value, columnName: 'new_model' }),
    getFuelReportsFiltersCostCenterId: () =>
      getFuelReportService({ searchValue: value, columnName: 'new_kostenstelleid' }),
    getFuelReportsFiltersCostCenter2: () =>
      getFuelReportService({ searchValue: value, columnName: 'uds_kostenstelle2' }),
    getFuelReportsFiltersCostCenter3: () =>
      getFuelReportService({ searchValue: value, columnName: 'uds_kostenstelle3' }),
    getDriversForBooking: () => getDriversForBooking()
  };

  const response: any = await sourceMapping[source]();
  let responseArray: AnyObject[] = [];

  switch (source) {
    case 'getCompanyDrivers':
    case 'getDriversForBooking':
      options = response.data.data.map((item: { id: string; logicalName: string; name: string }) => ({
        value: item.id,
        label: item.name
      }));
      break;
    case 'getContractPartnerCompanies':
      interface ContractPartnerCompany {
        accountid: string;
        name: string;
        uds_testcompanycode?: number;
      }
      options = response.data
        .filter(
          (item: ContractPartnerCompany) => item.uds_testcompanycode !== IS_TEST_COMPANY.yes.value || user.isTestUser
        )
        .sort((a: ContractPartnerCompany, b: ContractPartnerCompany) => a.name?.localeCompare(b.name))
        .map((item: ContractPartnerCompany) => ({
          value: item.accountid,
          label: item.name
        }));
      break;
    case 'getCarBrands':
    case 'getCarBrandsForGrv':
      responseArray = getResponseFormData(response.data, [{ name: 'entityes', type: 'array' }]) as AnyObject[];
      options = responseArray.map(item => ({
        value: item.uds_carbrandid,
        label: item.uds_name
      }));
      break;
    case 'getCompanyContracts':
      responseArray = getResponseFormData(response.data, [{ name: 'entityes', type: 'array' }]) as AnyObject[];
      options = responseArray.map(item => ({
        value: item.uds_rahmenvertrgesid,
        label: item.uds_vertragsbezeichnung || '-Leere Vertragsbezeichnung-'
      }));
      break;
    case 'getUnusedUserCompanyFuhrparks':
      options = response.data.map((item: UserCar) => ({
        value: item.new_fuhrparkid,
        label: `${item.new_name}${
          item.new_hersteller || item.new_model ? ` (${item.new_hersteller || ''} ${item.new_model || ''})` : ''
        }`
      }));
      break;
    case 'getCarDealerships':
      responseArray = getResponseFormData(response.data, [{ name: null, type: 'array' }]) as AnyObject[];
      options = responseArray.map(item => ({
        value: item.accountid,
        label: item.name
      }));
      break;
    case 'getUserCompanyCarPolicies':
      options = response.data.items.map((item: { uds_carpolicyid: string; uds_name: string }) => ({
        value: item.uds_carpolicyid,
        label: item.uds_name
      }));
      break;
    case 'getCarsListVR':
      options = response.data.data.fuhrparkList.map((item: AnyObject) => ({
        value: item.id,
        label: item.name,
        showEndAdornment: item.lafPresent
      }));
      break;
    case 'getDienstleistungen':
      options = response.data.map((item: { accountid: string; name: string }) => ({
        value: item.accountid,
        label: item.name
      }));
      break;
    case 'getUserCompanyCarDealerships':
      responseArray = getResponseFormData(response.data, [{ name: null, type: 'array' }], true) as AnyObject[];
      options = responseArray.map(item => ({
        value: item.uds_autohandlerid_id,
        label: item.uds_autohandlerid
      }));
      break;
    case 'getDamagePageDocumentTypes':
      responseArray = response.data.data.documentTypes;
      options = responseArray.map(item => ({
        value: item.id,
        label: item.name
      }));
      break;
    case 'getDamageRecordMark':
    case 'getDamageRecordRepairPartner':
    case 'getDamageRecordOwner':
    case 'getDamageRecordInsurer':
    case 'getDamageRecordInsuranceBroker':
      responseArray = response.data.data;
      options = responseArray.map(item => ({
        value: item.id,
        label: item.name
      }));
      break;
    case 'getDamageRecordWorkshop':
      responseArray = response.data.data;
      options = responseArray.map(item => ({
        value: item.name,
        label: item.name
      }));
      break;
    case 'getDocumentTypes':
      options = response.data.data.sort().map((item: string) => ({
        value: item,
        label: item
      }));
      break;
    case 'getPersonsForStandorts':
      responseArray = getResponseFormData(response.data, [{ name: null, type: 'array' }]) as AnyObject[];
      options = responseArray.map(item => ({
        value: item.contactid,
        label: item.fullname
      }));
      break;
    case 'getFuelReportsFiltersName':
      options = response.data.data.new_name.map((item: string) => ({
        value: item,
        label: item
      }));

      break;

    case 'getFuelReportsFiltersDriver':
      options = response.data.data.new_fahrerid.map((item: AnyObject) => ({
        value: item.id,
        label: item.name
      }));

      break;

    case 'getFuelReportsFiltersLocation':
      options = response.data.data.new_standortid.map((item: AnyObject) => ({
        value: item.id,
        label: item.name
      }));

      break;
    case 'getFuelReportsFiltersBranch':
      options = response.data.data.new_sparteid.map((item: AnyObject) => ({
        value: item.id,
        label: item.name
      }));

      break;

    case 'getFuelReportsFiltersManufacturer':
      options = response.data.data.new_hersteller.map((item: string) => ({
        value: item,
        label: item
      }));

      break;

    case 'getFuelReportsFiltersModel':
      options = response.data.data.new_model.map((item: string) => ({
        value: item,
        label: item
      }));

      break;

    case 'getFuelReportsFiltersCostCenterId':
      options = response.data.data.new_kostenstelleid.map((item: AnyObject) => ({
        value: item.id,
        label: item.name
      }));

      break;

    case 'getFuelReportsFiltersCostCenter2':
      options = response.data.data.uds_kostenstelle2.map((item: string) => ({
        value: item,
        label: item
      }));

      break;

    case 'getFuelReportsFiltersCostCenter3':
      options = response.data.data.uds_kostenstelle3.map((item: string) => ({
        value: item,
        label: item
      }));

      break;
  }

  return options;
};
