import { Box, LinearProgress } from '@mui/material';
import { Flex, Text } from 'common/common-components.styled';
import React, { useState, useEffect } from 'react';
import { QueryProps } from '../report-damage.props';
import { createDamageCase, finishCreateDamageCase, uploadFilesOfDamage } from 'services/report-damage-service';
import { documentTypeId } from '../utils';
import { useDispatch } from 'react-redux';
import { toggleModal } from 'actions/app_action';
import { MODALS, RequestStatuses } from '../../../constants';
import { useOverlay } from 'hooks';
import { AxiosResponse } from 'axios';
import { ResponseModel } from 'common/interfaces';

type FileSectionIndex = 0 | 1 | 2 | 3 | 4;

interface FileUploadingProcess {
  data: QueryProps;
  setCompeteStep: () => void;
}

const FileUploadingProcess: React.FC<FileUploadingProcess> = ({ data, setCompeteStep }) => {
  const dispatch = useDispatch();
  const [showOverlay, hideOverlay] = useOverlay();
  const [createdCaseId, setCreatedCaseId] = useState<string | null>(null);
  const [progress, setProgress] = useState(-1);
  const [actualUploadedFile, setActualUploadedFile] = useState(0);

  const getCreatedCaseId = async () => {
    const requestData = { ...data };
    delete requestData.files;

    const response = await createDamageCase(requestData, false);
    const { schadenAufnahmeId: caseId } = response?.data?.data;

    if (caseId) {
      setCreatedCaseId(caseId);
    } else {
      dispatch(toggleModal(MODALS.alert, null));
      hideOverlay('createCase');

      return;
    }
  };

  const uploadFiles = async (caseId: string) => {
    const files = data.files || [];

    const filesArray = files.map((file, index) => ({
      ...file,
      index
    }));

    for (const { el, fileSection, index } of filesArray) {
      const response: AxiosResponse<ResponseModel> | undefined = await uploadFilesOfDamage(
        [el] as File[],
        caseId,
        documentTypeId[fileSection as FileSectionIndex]
      );

      if (response?.data.level === RequestStatuses.Success) {
        setActualUploadedFile(index + 1);
        setProgress(((index + 1) / filesArray.length) * 100);
      } else {
        dispatch(toggleModal(MODALS.alert, null));
        hideOverlay('createCase');

        return;
      }
    }
  };

  const finishCreatingDamageCase = async (caseId: string) => {
    const response = await finishCreateDamageCase(caseId);

    if (response?.data.level === RequestStatuses.Success) {
      localStorage.removeItem('SMdata');
      setCompeteStep();
    }

    hideOverlay('createCase');
  };

  useEffect(() => {
    getCreatedCaseId();
  }, []);

  useEffect(() => {
    if (createdCaseId) {
      uploadFiles(createdCaseId);
    }
  }, [createdCaseId]);

  useEffect(() => {
    if (progress === 100 && createdCaseId) {
      finishCreatingDamageCase(createdCaseId);
      dispatch(toggleModal(MODALS.alert, null));
    }
  }, [progress, createdCaseId]);

  return (
    <Flex direction={'column'}>
      <Flex direction='column' align='center' justify='center'>
        <img width={235} height={235} src={'/assets/images/download-screen.svg'} alt={'uploading-screen'} />

        <Box sx={{ my: '20px', textAlign: 'center' }}>
          <Text size='20' bold color='darkBlue'>
            Wir speichern Ihre Dateien ab.
          </Text>
          <Text size='20' bold color='darkBlue'>
            Bitte schließen Sie das Fenster nicht.
          </Text>
        </Box>

        <Text size='16' color='grey600' bottom='10'>
          {actualUploadedFile} / {data.files?.length || 0}
        </Text>
      </Flex>

      <Flex justify='center'>
        <Box sx={{ width: '50%' }}>
          <LinearProgress
            sx={{
              height: '20px',
              width: '100%',
              borderRadius: '5px',
              backgroundColor: '#E5F7FF',
              '& .MuiLinearProgress-bar': {
                backgroundColor: '#335566',
                borderRadius: '5px'
              }
            }}
            variant='determinate'
            value={progress}
            classes={{ colorPrimary: '#133', colorSecondary: 'blue' }}
          />
        </Box>
      </Flex>
    </Flex>
  );
};

export default FileUploadingProcess;
