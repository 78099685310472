import React, { cloneElement, CSSProperties, RefObject } from 'react';
import useMaxWidthForEllipsisWithTooltip from 'components/ellipsis-with-tooltip/hooks/use-max-width-for-ellipsis-with-tooltip';
import { ColumnsVariant, ColumnType } from 'components/table-component/table-component.props';
import { Flex } from 'common/common-components.styled';
import { PrefixForId } from 'common/enums';
import { removeSpaces } from 'utils/remove-spaces';
import classNames from 'classnames';
import classes from 'components/table-component/table-component.module.scss';
import EllipsisWithTooltip from 'components/ellipsis-with-tooltip';
import { DEFAULT_COLUMN_MARGIN, HTML_COLUMN_MARGIN } from 'components/table-component/table-component';
import { HeaderCellProps } from './header-cell.props';

const HeaderCell = ({
  column,
  index,
  columnLength,
  sortHandler,
  isSortNeeded,
  sortArrowClassName
}: HeaderCellProps) => {
  const { container, maxWidth } = useMaxWidthForEllipsisWithTooltip();
  const isSort = isSortNeeded(column);

  const isHTMLColumnsVariant = (type: ColumnsVariant) =>
    type === ColumnsVariant.Button || type === ColumnsVariant.Chip || type === ColumnsVariant.Menu;

  const getStyleForHeader = (column: ColumnType, isLastItem: boolean): CSSProperties => ({
    cursor: column.sort ? 'pointer' : 'default',
    width: column.width,
    marginRight: isLastItem ? 0 : isHTMLColumnsVariant(column.type) ? HTML_COLUMN_MARGIN : DEFAULT_COLUMN_MARGIN,
    flexShrink: isHTMLColumnsVariant(column.type) ? 0 : undefined
  });

  return (
    <Flex
      align='center'
      id={PrefixForId.Table + PrefixForId.Column + PrefixForId.Title + removeSpaces(column.name)}
      style={getStyleForHeader(column, index === columnLength - 1)}
      onClick={() => sortHandler(column)}
      ref={container as RefObject<HTMLDivElement>}
      className={classNames(classes.text, column.className)}
    >
      {isSort ? (
        <img className={sortArrowClassName(column)} src='/assets/images/down_arrow.svg' alt='down_arrow' />
      ) : null}

      {column.headerComponent ? (
        cloneElement(column.headerComponent, { column })
      ) : (
        <EllipsisWithTooltip tooltip={column.name} maxWidth={`${isSort && maxWidth ? maxWidth - 5 : maxWidth}px`}>
          <p>{column.name}</p>
        </EllipsisWithTooltip>
      )}
    </Flex>
  );
};

export default HeaderCell;
