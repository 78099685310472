import authService from './auth-service';
import axios, { AxiosResponse } from 'axios';
import { ResponseModel } from 'common/interfaces';
import {
  PrimaryEntityValues,
  ValidateMainEntityResponse
} from 'components/cost-centers-settings/cost-centers-settings.props';

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

export async function validateMainEntity(
  mainEntityCode: PrimaryEntityValues,
  isChildInclude: boolean,
  companyId: string
): Promise<AxiosResponse<ResponseModel<ValidateMainEntityResponse>>> {
  const token = await authService.acquireTokenAsync();
  return axios({
    method: 'POST',
    url: `${API_ENDPOINT}/api/Kostenstelle/ValidateMainEntity`,
    headers: {
      Authorization: `Bearer ${token}`
    },
    data: {
      mainEntityCode,
      isChildInclude,
      companyId
    }
  });
}

export async function changeMainEntity(
  mainEntityCode: PrimaryEntityValues,
  isChildInclude: boolean,
  companyId: string
): Promise<AxiosResponse<ResponseModel<null>>> {
  const token = await authService.acquireTokenAsync();
  return axios({
    method: 'POST',
    url: `${API_ENDPOINT}/api/Kostenstelle/ChangeMainEntity`,
    headers: {
      Authorization: `Bearer ${token}`
    },
    data: {
      mainEntityCode,
      isChildInclude,
      companyId
    }
  });
}
