import React, { lazy } from 'react';
import { lazyLoadingRetryOnFail } from './utils';
const Aufgaben = lazy(() => lazyLoadingRetryOnFail(() => import('components/aufgaben-component')));
const TasksPage = lazy(() => lazyLoadingRetryOnFail(() => import('pages/tasks-page/tasks-page')));
const Barpreisanfragen = lazy(() => lazyLoadingRetryOnFail(() => import('components/barpreisanfragen-component')));
const LeasingOfferListPage = lazy(() =>
  lazyLoadingRetryOnFail(() => import('components/leasing-offer-list/leasing-offer-list'))
);
const DetailsPage = lazy(() => lazyLoadingRetryOnFail(() => import('pages/details/details-view')));
const DealerOrderDetailsPage = lazy(() =>
  lazyLoadingRetryOnFail(() => import('pages/dealer-oder-details-page/order-details-page'))
);
const ConfiguratorPage = lazy(() => lazyLoadingRetryOnFail(() => import('pages/configurator-page')));
const ContactDocumentsPage = lazy(() => lazyLoadingRetryOnFail(() => import('pages/contact-documents-page')));
const DokumentePage = lazy(() => lazyLoadingRetryOnFail(() => import('pages/dokumente')));
const NeueFahrzeugePage = lazy(() =>
  lazyLoadingRetryOnFail(() => import('pages/neue-fahrzeuge-page/neue-fahrzeuge-page'))
);
const ExistingVehiclePage = lazy(() => lazyLoadingRetryOnFail(() => import('pages/existing-vehicle-page')));
const MyVehiclesPage = lazy(() => lazyLoadingRetryOnFail(() => import('pages/my-vehicles/my-vehicles')));
const MyRequestsPage = lazy(() => lazyLoadingRetryOnFail(() => import('pages/my-requests/my-requests')));
const FahrerEinladenPage = lazy(() => lazyLoadingRetryOnFail(() => import('pages/fahrer-einladen-page')));
const BenutzereinstellungenPage = lazy(() => lazyLoadingRetryOnFail(() => import('pages/benutzereinstellungen-page')));
const MeineFahrerPage = lazy(() => lazyLoadingRetryOnFail(() => import('pages/meine-fahrer-page')));
const DrivingLicenseControlPage = lazy(() =>
  lazyLoadingRetryOnFail(() => import('pages/driving-license-control-page'))
);
const FahrzeugkostenPage = lazy(() => lazyLoadingRetryOnFail(() => import('pages/fahrzeugkosten-page')));
const FreigabenPage = lazy(() => lazyLoadingRetryOnFail(() => import('pages/freigaben')));
const ReportsPage = lazy(() => lazyLoadingRetryOnFail(() => import('components/reports')));
const CarPoliciesPage = lazy(() => lazyLoadingRetryOnFail(() => import('pages/car-policies-page')));
const FahrerlevelPage = lazy(() => lazyLoadingRetryOnFail(() => import('pages/fahrerlevel-page')));
const SetupAssistantPage = lazy(() => lazyLoadingRetryOnFail(() => import('pages/einrichtungs-assistent')));
const MonatsrechnungenPage = lazy(() => lazyLoadingRetryOnFail(() => import('pages/monatsrechnungen-page')));
const EingangsrechnungPage = lazy(() =>
  lazyLoadingRetryOnFail(() => import('pages/eingangsrechnung-page/eingangsrechnung-view'))
);
const EingagnsrechnungsOcrPageCreate = lazy(() =>
  lazyLoadingRetryOnFail(() => import('pages/eingagnsrechnungs-ocr-page/eingagnsrechnungs-ocr-page-create'))
);
const EingagnsrechnungsOcrPageEdit = lazy(() =>
  lazyLoadingRetryOnFail(() => import('pages/eingagnsrechnungs-ocr-page/eingagnsrechnungs-ocr-page-edit'))
);
const BestellungenPage = lazy(() => lazyLoadingRetryOnFail(() => import('pages/bestellungen-page')));
const VertragePage = lazy(() => lazyLoadingRetryOnFail(() => import('pages/vertrage-page')));
const DashboardPage = lazy(() => lazyLoadingRetryOnFail(() => import('pages/dashboard')));
const FahrzeugeDerCarPolicyPage = lazy(() =>
  lazyLoadingRetryOnFail(() => import('pages/fahrzeuge-der-car-policy-page/fahrzeuge-der-car-policy-page'))
);
const ReferenceVehiclesPage = lazy(() => lazyLoadingRetryOnFail(() => import('pages/reference-vehicles-page')));
const GlobalReferenceVehiclesPage = lazy(() =>
  lazyLoadingRetryOnFail(() => import('pages/global-reference-vehicles-page'))
);
// const UnternehmenPage = lazy(() => lazyLoadingRetryOnFail(() => import('components/unternehmen')));
const SchadenPage = lazy(() => lazyLoadingRetryOnFail(() => import('components/schaden-component/schaden-component')));
const RepairStatusPage = lazy(() =>
  lazyLoadingRetryOnFail(() => import('pages/repair-status/authorized/repair-status'))
);
const SupportPage = lazy(() => lazyLoadingRetryOnFail(() => import('pages/support-page/support-page')));
const AssignmentsPage = lazy(() => lazyLoadingRetryOnFail(() => import('components/assignments-component')));
const TransfersPage = lazy(() => lazyLoadingRetryOnFail(() => import('components/transfers-component')));
const AssignmentsRecord = lazy(() =>
  lazyLoadingRetryOnFail(() => import('components/assignments-record/assignments-record'))
);
const MietwagenBestellen = lazy(() => lazyLoadingRetryOnFail(() => import('components/mietwagen-bestellen')));
const VehicleReturnPage = lazy(() =>
  lazyLoadingRetryOnFail(() => import('pages/vehicle-return-page/vehicle-return-page'))
);
const SwaggerPage = lazy(() => lazyLoadingRetryOnFail(() => import('pages/swagger-page/swagger-page')));
const PackagesAndPricesPage = lazy(() =>
  lazyLoadingRetryOnFail(() => import('pages/packages-and-prices-page/packages-and-prices-page'))
);
const PackagePage = lazy(() => lazyLoadingRetryOnFail(() => import('pages/package-page/package-page')));
const AddSubcompanyPackage = lazy(() =>
  lazyLoadingRetryOnFail(() => import('pages/packages-and-prices-page/add-subcompaniess/add-subcompany-package'))
);
const NoAccessPage = lazy(() => lazyLoadingRetryOnFail(() => import('pages/no-access-page/no-access-page')));
const OnboardingPage = lazy(() =>
  lazyLoadingRetryOnFail(() => import('components/onboarding-component/onboarding-component'))
);
const FuelCardsPage = lazy(() => lazyLoadingRetryOnFail(() => import('pages/fuel-cards/fuel-cards')));
const FuelCardsDetailsPage = lazy(() =>
  lazyLoadingRetryOnFail(() => import('pages/fuel-cards/fuel-cards-details/fuel-cards-details'))
);
const FuelCardOrderingPage = lazy(() =>
  lazyLoadingRetryOnFail(() => import('pages/fuel-cards/fuel-cards-ordering-page/fuel-card-ordering-page'))
);
const LeasingEndPage = lazy(() => lazyLoadingRetryOnFail(() => import('pages/leasing-end-page/leasing-end-page')));
const DecisionSupport = lazy(() => lazyLoadingRetryOnFail(() => import('pages/decision-support/decision-support')));
const InviteClients = lazy(() => lazyLoadingRetryOnFail(() => import('pages/invite-clients-page/invite-clients-page')));
const InviteClientProcess = lazy(() =>
  lazyLoadingRetryOnFail(() => import('components/invite-clients-process/invite-client-process'))
);
const CreateOfferPage = lazy(() => lazyLoadingRetryOnFail(() => import('pages/create-offer/create-offer-page')));
const CreateOfferProcess = lazy(() =>
  lazyLoadingRetryOnFail(() => import('pages/create-offer/components/create-offer-process'))
);
const TopDeals = lazy(() => lazyLoadingRetryOnFail(() => import('pages/top-deals/top-deals')));
const FuelReceipt = lazy(() => lazyLoadingRetryOnFail(() => import('components/fuel-receipt')));
const NewBooking = lazy(() => lazyLoadingRetryOnFail(() => import('components/pool-car-booking/new-booking')));
const AccidentRepairPage = lazy(() =>
  lazyLoadingRetryOnFail(() => import('components/accident-repair-component/accident-repair-component'))
);
const BillingElectricCar = lazy(() => lazyLoadingRetryOnFail(() => import('components/billing-electric-car')));
const AccidentRepairRecord = lazy(() =>
  lazyLoadingRetryOnFail(() => import('components/accident-repair-record/accident-repair-record'))
);
const LeasingReturnPage = lazy(() =>
  lazyLoadingRetryOnFail(() => import('components/leasing-return-component/leasing-return-component'))
);
const LeasingrukgabeRecord = lazy(() =>
  lazyLoadingRetryOnFail(() => import('components/leasingruckgabe-record/leasingrukgabe-record'))
);
const BookingsPage = lazy(() => lazyLoadingRetryOnFail(() => import('components/pool-car-booking/bookings-page')));
const DamageDetailPage = lazy(() => lazyLoadingRetryOnFail(() => import('./damage-detail-page/damage-detail-page')));
const CreateDamagePage = lazy(() => lazyLoadingRetryOnFail(() => import('./create-damage/create-damage')));
const BookingRentalCar = lazy(() =>
  lazyLoadingRetryOnFail(() => import('components/booking-rental-car/booking-rental-car'))
);
const DocumentmanagerPagePageEdit = lazy(() =>
  lazyLoadingRetryOnFail(() => import('./documentmanager-page/documentmanager-page.edit'))
);
const DocumentmanagerUploadPage = lazy(
  () => import('./documentmanager-page/documentmanager-upload/documentmanager-upload.page')
);
const DocumentManagerPage = lazy(() =>
  lazyLoadingRetryOnFail(() => import('./documentmanager-page/documentmanager-view'))
);
const InvoiceUploadPage = lazy(() =>
  lazyLoadingRetryOnFail(() => import('./eingangsrechnung-page/invoice-upload/invoice-upload'))
);
const FleetSettingsPage = lazy(() => lazyLoadingRetryOnFail(() => import('pages/fleet-settings-page')));
const AccountSettingsPage = lazy(() => lazyLoadingRetryOnFail(() => import('pages/account-settings-page')));
const CompanySettingsPage = lazy(() => lazyLoadingRetryOnFail(() => import('pages/company-settings-page')));
const PersonalEmailTemplatePage = lazy(() =>
  lazyLoadingRetryOnFail(() => import('pages/personal-email-template-page'))
);

import { Route, RoutesAccessRights } from 'common/interfaces';
import { USER_ROLES } from '../constants';
import { PAGES } from 'common/enums';

export const CREATE_DAMAGE_REDIRECT_TO = PAGES.DASHBOARD;

export const getRoutes = (dashboardRedirectTo: () => PAGES, accessRights: RoutesAccessRights) => {
  const routes: Route[] = [
    {
      path: PAGES.TASKS,
      component: <Aufgaben />,
      accessTo: [],
      redirectTo: PAGES.DASHBOARD
    },
    {
      path: PAGES.SERVICE_PROVIDER_TASKS,
      component: <TasksPage />,
      accessTo: [],
      redirectTo: PAGES.DASHBOARD
    },
    {
      path: PAGES.REQUESTS,
      component: <Barpreisanfragen />,
      accessTo: [],
      redirectTo: PAGES.DASHBOARD
    },
    {
      path: PAGES.LEASING_REQUESTS,
      component: <LeasingOfferListPage />,
      accessTo: [],
      redirectTo: PAGES.DASHBOARD
    },
    {
      path: PAGES.REQUESTS_DETAILS,
      component: <DetailsPage />,
      accessTo: [],
      redirectTo: PAGES.DASHBOARD
    },
    {
      path: PAGES.LEASING_REQUESTS_DETAILS,
      component: <DetailsPage />,
      accessTo: [],
      redirectTo: PAGES.DASHBOARD
    },
    {
      path: PAGES.CONFIGURATION_DETAILS,
      component: <DetailsPage />,
      accessTo: [],
      redirectTo: PAGES.DASHBOARD
    },
    {
      path: PAGES.EMPLOYEE_CONFIGURATION_DETAILS,
      component: <DetailsPage />,
      accessTo: [],
      redirectTo: PAGES.DASHBOARD
    },
    {
      path: PAGES.REFERENCE_VEHICLES_DETAILS,
      component: <DetailsPage />,
      accessTo: [],
      redirectTo: PAGES.DASHBOARD
    },
    {
      path: PAGES.ORDER_DETAILS,
      component: <DealerOrderDetailsPage />,
      accessTo: [],
      redirectTo: PAGES.DASHBOARD
    },
    {
      path: PAGES.NEW_CONFIGURATION,
      component: <ConfiguratorPage />,
      accessTo: [],
      redirectTo: PAGES.DASHBOARD
    },
    {
      path: PAGES.DOCUMENTS_CONTRACT,
      component: <ContactDocumentsPage />,
      accessTo: [],
      redirectTo: PAGES.DASHBOARD
    },
    {
      path: PAGES.DOCUMENTS,
      component: <DokumentePage />,
      accessTo: [],
      redirectTo: PAGES.DASHBOARD
    },
    {
      path: PAGES.MY_VEHICLES_NEW_VEHICLE,
      component: <NeueFahrzeugePage />,
      accessTo: [],
      redirectTo: PAGES.DASHBOARD
    },
    {
      path: PAGES.MY_VEHICLES_DETAILS,
      component: <ExistingVehiclePage />,
      accessTo: [],
      redirectTo: PAGES.DASHBOARD
    },
    {
      path: PAGES.MY_VEHICLES,
      component: <MyVehiclesPage />,
      accessTo: [],
      redirectTo: PAGES.DASHBOARD
    },
    {
      path: PAGES.MY_REQUESTS,
      component: <MyRequestsPage />,
      accessTo: [],
      redirectTo: PAGES.DASHBOARD
    },
    {
      path: PAGES.MY_DRIVERS_CREATE,
      component: <FahrerEinladenPage />,
      accessTo: [],
      redirectTo: PAGES.DASHBOARD
    },
    {
      path: PAGES.MY_DRIVERS_DETAILS,
      component: <BenutzereinstellungenPage />,
      accessTo: [],
      redirectTo: PAGES.DASHBOARD
    },
    {
      path: PAGES.MY_DRIVERS,
      component: <MeineFahrerPage />,
      accessTo: [],
      redirectTo: PAGES.DASHBOARD
    },
    {
      path: PAGES.DRIVERS_CHECK,
      component: <DrivingLicenseControlPage />,
      accessTo: [],
      redirectTo: PAGES.DASHBOARD
    },
    {
      path: PAGES.VEHICLE_COSTS,
      component: <FahrzeugkostenPage />,
      accessTo: [],
      redirectTo: PAGES.DASHBOARD
    },
    {
      path: PAGES.VEHICLE_OFFER_DETAILS,
      component: <DetailsPage />,
      accessTo: [],
      redirectTo: PAGES.DASHBOARD
    },
    {
      path: PAGES.DASHBOARD_RELEASES,
      component: <FreigabenPage />,
      accessTo: [],
      redirectTo: PAGES.DASHBOARD
    },
    {
      path: PAGES.FUEL_REPORT,
      component: <ReportsPage />,
      accessTo: [],
      redirectTo: PAGES.DASHBOARD
    },
    {
      path: PAGES.CAR_POLICY,
      component: <CarPoliciesPage />,
      accessTo: [],
      redirectTo: PAGES.DASHBOARD
    },
    {
      path: PAGES.MOBILITY_BUDGET,
      component: <FahrerlevelPage />,
      accessTo: [],
      redirectTo: PAGES.DASHBOARD
    },
    {
      path: PAGES.SETUP_ASSISTANT,
      component: <SetupAssistantPage />,
      accessTo: [],
      redirectTo: PAGES.DASHBOARD
    },
    {
      path: PAGES.MONTHLY_BILLS,
      component: <MonatsrechnungenPage />,
      accessTo: [],
      redirectTo: PAGES.DASHBOARD
    },
    {
      path: PAGES.EINGANGSRECHNUNG,
      component: <EingangsrechnungPage />,
      accessTo: [],
      redirectTo: PAGES.DASHBOARD
    },
    {
      path: PAGES.DOCUMENTMANAGER_EDIT,
      component: <DocumentmanagerPagePageEdit />,
      accessTo: [],
      redirectTo: PAGES.DASHBOARD
    },
    {
      path: PAGES.DOCUMENTMANAGER_UPLOAD,
      component: <DocumentmanagerUploadPage />,
      accessTo: [],
      redirectTo: PAGES.DASHBOARD
    },
    {
      path: PAGES.DOCUMENTMANAGER,
      component: <DocumentManagerPage />,
      accessTo: [],
      redirectTo: PAGES.DASHBOARD
    },
    {
      path: PAGES.EINGANGSRECHNUNG_UPLOAD,
      component: <InvoiceUploadPage />,
      accessTo: [],
      redirectTo: PAGES.DASHBOARD
    },
    {
      path: PAGES.EINGANGSRECHNUNG_CREATE,
      component: <EingagnsrechnungsOcrPageCreate />,
      accessTo: [],
      redirectTo: PAGES.DASHBOARD
    },
    {
      path: PAGES.EINGANGSRECHNUNG_EDIT,
      component: <EingagnsrechnungsOcrPageEdit />,
      accessTo: [],
      redirectTo: PAGES.DASHBOARD
    },
    {
      path: PAGES.ORDERS,
      component: <BestellungenPage />,
      accessTo: [],
      redirectTo: PAGES.DASHBOARD
    },
    {
      path: PAGES.CONTRACTS,
      component: <VertragePage />,
      accessTo: [],
      redirectTo: PAGES.DASHBOARD
    },
    {
      path: PAGES.DASHBOARD,
      component: <DashboardPage />,
      accessTo: [],
      redirectTo: dashboardRedirectTo()
    },
    {
      path: PAGES.ACCOUNT_SETTINGS,
      component: <BenutzereinstellungenPage />,
      accessTo: [],
      redirectTo: PAGES.DASHBOARD
    },
    {
      path: PAGES.NEW_CONFIGURATIONS,
      component: <FahrzeugeDerCarPolicyPage />,
      accessTo: [],
      redirectTo: PAGES.DASHBOARD
    },
    {
      path: PAGES.NEW_REFERENCE_CONFIGURATIONS,
      component: <FahrzeugeDerCarPolicyPage />,
      accessTo: [],
      redirectTo: PAGES.DASHBOARD
    },
    {
      path: PAGES.CONFIGURATIONS,
      component: <FahrzeugeDerCarPolicyPage />,
      accessTo: [],
      redirectTo: PAGES.DASHBOARD
    },
    {
      path: PAGES.EMPLOYEE_CONFIGURATIONS,
      component: <FahrzeugeDerCarPolicyPage />,
      accessTo: [],
      redirectTo: PAGES.DASHBOARD
    },
    {
      path: PAGES.REFERENCE_VEHICLES,
      component: <ReferenceVehiclesPage />,
      accessTo: [],
      redirectTo: PAGES.DASHBOARD
    },
    {
      path: PAGES.GLOBAL_REFERENCE_VEHICLES,
      component: <GlobalReferenceVehiclesPage />,
      accessTo: [],
      redirectTo: PAGES.DASHBOARD
    },
    {
      path: PAGES.DAMAGE_DETAILS,
      component: <DamageDetailPage />,
      accessTo: [],
      redirectTo: PAGES.DASHBOARD
    },
    {
      path: PAGES.DAMAGE,
      component: <SchadenPage />,
      accessTo: [],
      redirectTo: PAGES.DASHBOARD
    },
    {
      path: PAGES.REPAIRED,
      component: <RepairStatusPage />,
      accessTo: [],
      redirectTo: PAGES.DASHBOARD
    },
    {
      path: PAGES.SUPPORT,
      component: <SupportPage />,
      accessTo: [],
      redirectTo: PAGES.DASHBOARD
    },
    {
      path: PAGES.ASSIGNMENTS,
      component: <AssignmentsPage />,
      accessTo: [],
      redirectTo: PAGES.DASHBOARD
    },
    {
      path: PAGES.ASSIGNMENTS_VEHICLE_TRANSFER,
      component: <TransfersPage />,
      accessTo: [],
      redirectTo: PAGES.DASHBOARD
    },
    {
      path: PAGES.ASSIGNMENTS_VEHICLE_TRANSFER_RECORD,
      component: <AssignmentsRecord />,
      accessTo: [],
      redirectTo: PAGES.DASHBOARD
    },
    {
      path: PAGES.ASSIGNMENTS_ORDER_RENTAL_CAR,
      component: <MietwagenBestellen />,
      accessTo: [],
      redirectTo: PAGES.DASHBOARD
    },
    {
      path: PAGES.ASSIGNMENTS_ACCIDENT_REPAIR,
      component: <AccidentRepairPage />,
      accessTo: [],
      redirectTo: PAGES.DASHBOARD
    },
    {
      path: PAGES.ASSIGNMENTS_ACCIDENT_REPAIR_RECORD,
      component: <AccidentRepairRecord />,
      accessTo: [],
      redirectTo: PAGES.DASHBOARD
    },
    {
      path: PAGES.ASSIGNMENTS_LEASE_RETURN,
      component: <LeasingReturnPage />,
      accessTo: [],
      redirectTo: PAGES.DASHBOARD
    },
    {
      path: PAGES.ASSIGNMENTS_LEASE_RETURN_PRO,
      component: <VehicleReturnPage />,
      accessTo: [],
      redirectTo: PAGES.DASHBOARD
    },
    {
      path: PAGES.ASSIGNMENTS_LEASE_RETURN_RECORD,
      component: <LeasingrukgabeRecord />,
      accessTo: [],
      redirectTo: PAGES.DASHBOARD
    },

    {
      path: PAGES.POOL_CALENDAR,
      component: <BookingsPage />,
      accessTo: [],
      redirectTo: PAGES.DASHBOARD
    },
    {
      path: PAGES.POOL_CALENDAR_NEW,
      component: <NewBooking />,
      accessTo: [],
      redirectTo: PAGES.DASHBOARD
    },
    {
      path: PAGES.POOL_CALENDAR_VEHICLE,
      component: <ExistingVehiclePage />,
      accessTo: [],
      redirectTo: PAGES.DASHBOARD
    },

    {
      path: PAGES.MY_POOL_CALENDAR,
      component: <BookingsPage />,
      accessTo: [],
      redirectTo: PAGES.DASHBOARD
    },
    {
      path: PAGES.MY_POOL_CALENDAR_NEW,
      component: <NewBooking />,
      accessTo: [],
      redirectTo: PAGES.DASHBOARD
    },

    {
      path: PAGES.MY_POOL_CALENDAR_VEHICLE,
      component: <ExistingVehiclePage />,
      accessTo: [],
      redirectTo: PAGES.DASHBOARD
    },

    {
      path: PAGES.SWAGGER,
      component: <SwaggerPage />,
      accessTo: [],
      redirectTo: PAGES.DASHBOARD
    },
    // {
    //   path: PAGES.LONG_TERM_RENTAL,
    //   component: <LongTermRentPage />,
    //   accessTo: [],
    //   redirectTo: PAGES.DASHBOARD
    // },
    {
      path: PAGES.PACKAGES,
      component: <PackagesAndPricesPage />,
      accessTo: [],
      redirectTo: PAGES.DASHBOARD
    },
    {
      path: PAGES.PACKAGE_PAGE,
      component: <PackagePage />,
      accessTo: [],
      redirectTo: PAGES.DASHBOARD
    },
    {
      path: PAGES.ADD_COMPANY,
      component: <AddSubcompanyPackage />,
      accessTo: [],
      redirectTo: PAGES.DASHBOARD
    },
    {
      path: PAGES.NO_ACCESS,
      component: <NoAccessPage />,
      accessTo: [],
      redirectTo: PAGES.DASHBOARD
    },
    {
      path: PAGES.IMPORT,
      component: <OnboardingPage />,
      accessTo: [],
      redirectTo: PAGES.DASHBOARD
    },
    // {
    //   path: PAGES.CHAT_TEST,
    //   component: <WeavyChatPage />,
    //   accessTo: [],
    //   redirectTo: PAGES.DASHBOARD
    // },
    {
      path: PAGES.FUEL_CARDS,
      component: <FuelCardsPage />,
      accessTo: [],
      redirectTo: PAGES.DASHBOARD
    },
    {
      path: PAGES.FUEL_CARDS_ORDER,
      component: <FuelCardsDetailsPage />,
      accessTo: [],
      redirectTo: PAGES.DASHBOARD
    },
    {
      path: PAGES.FUEL_CARDS_ORDER_FUEL_CARD,
      component: <FuelCardOrderingPage />,
      accessTo: [],
      redirectTo: PAGES.DASHBOARD
    },
    {
      path: PAGES.LEASE_END,
      component: <LeasingEndPage />,
      accessTo: [],
      redirectTo: PAGES.DASHBOARD
    },
    {
      path: PAGES.ASSIGNMENTS_FUEL_RECEIPT_SUBMIT,
      component: <FuelReceipt />,
      accessTo: [],
      redirectTo: PAGES.DASHBOARD
    },
    {
      path: PAGES.ASSIGNMENTS_ELECTRIC_CAR_CHARGING_COST,
      component: <BillingElectricCar />,
      accessTo: [],
      redirectTo: PAGES.DASHBOARD
    },
    {
      path: PAGES.BILLING_ELECTRIC_CAR_CHARGING_COSTS,
      component: <BillingElectricCar />,
      accessTo: [],
      redirectTo: PAGES.DASHBOARD
    },
    {
      path: PAGES.TANK_RECEIPT_SUBMIT,
      component: <FuelReceipt />,
      accessTo: [],
      redirectTo: PAGES.DASHBOARD
    },
    {
      path: PAGES.DECISION_SUPPORT,
      component: <DecisionSupport />,
      accessTo: [],
      redirectTo: PAGES.DASHBOARD
    },
    {
      path: PAGES.CLIENTS_FLEETMANAGER,
      component: <InviteClients />,
      accessTo: [],
      redirectTo: PAGES.DASHBOARD
    },
    {
      path: PAGES.INVITE_CLIENT_FLEETMANAGER,
      component: <InviteClientProcess />,
      accessTo: [],
      redirectTo: PAGES.DASHBOARD
    },
    {
      path: PAGES.CLIENTS_DEALER,
      component: <InviteClients />,
      accessTo: [],
      redirectTo: PAGES.DASHBOARD
    },
    {
      path: PAGES.INVITE_CLIENT_DEALER,
      component: <InviteClientProcess />,
      accessTo: [],
      redirectTo: PAGES.DASHBOARD
    },
    {
      path: PAGES.CREATE_OFFER,
      component: <CreateOfferPage />,
      accessTo: [],
      redirectTo: PAGES.DASHBOARD
    },
    {
      path: PAGES.CREATE_OFFER_PROCESS,
      component: <CreateOfferProcess />,
      accessTo: [],
      redirectTo: PAGES.DASHBOARD
    },
    {
      path: PAGES.TOP_DEALS,
      component: <TopDeals />,
      accessTo: [],
      redirectTo: PAGES.DASHBOARD
    },
    {
      path: PAGES.BOOKING_RENTAL_CAR,
      component: <BookingRentalCar />,
      accessTo: [],
      redirectTo: PAGES.DASHBOARD
    },
    {
      path: PAGES.CREATE_DAMAGE,
      component: <CreateDamagePage />,
      accessTo: [],
      redirectTo: CREATE_DAMAGE_REDIRECT_TO
    },
    {
      path: PAGES.FLEET_SETTINGS,
      component: <FleetSettingsPage />,
      accessTo: [],
      redirectTo: PAGES.DASHBOARD
    },
    {
      path: PAGES.ACCOUNTING_SETTINGS,
      component: <AccountSettingsPage />,
      accessTo: [],
      redirectTo: PAGES.DASHBOARD
    },
    {
      path: PAGES.COMPANIES,
      component: <CompanySettingsPage />,
      accessTo: [],
      redirectTo: PAGES.DASHBOARD
    },
    {
      path: PAGES.PERSONAL_EMAIL_TEMPLATE,
      component: <PersonalEmailTemplatePage />,
      accessTo: [],
      redirectTo: PAGES.DASHBOARD
    }
  ];

  Object.entries(accessRights).forEach(([key, value]) => {
    const route = routes.find(route => String(route.path) === key);
    if (route) {
      route.accessTo = value.map(role =>
        Object.values(USER_ROLES).find(userRole => userRole.id === role?.uds_b2c_webroleid)
      );
    }
  });

  return routes;
};
