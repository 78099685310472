import React from 'react';
import { Flex, Text } from 'common/common-components.styled';
import Download from 'components/icons/download';
import { UploadTestReportIcon } from 'components/icons/upload-test-report-icon';
import { StyledButtonsContainer, StyledButton } from './leasing-end-generic.card.styled';
import { TASK_STATUS_CODES } from '../../../../constants';

interface VehiclePreparationCardProps {
  status: number;
  onUpload?: () => void;
  onDownload?: () => void;
}

const getTaskDescription = (status: number) => {
  switch (status) {
    case TASK_STATUS_CODES.open:
    case TASK_STATUS_CODES.postponed:
      return 'Prüfe anhand der Checkliste, ob dein Fahrzeug für die Rückgabe bereit ist. ';

    case TASK_STATUS_CODES.processing:
      return 'Wir überprüfen die Checkliste und melden uns bei dir, sobald die Prüfung erledigt ist.';

    case TASK_STATUS_CODES.completed:
      // eslint-disable-next-line
      return 'Wir haben die Checkliste überprüft. Sollten wir Unstimmigkeiten festgestellt haben, melden wir uns bei dir.';
  }
};

export const ChecklistButtons = ({ status, onUpload, onDownload }: VehiclePreparationCardProps) => {
  return (
    <>
      {(status === TASK_STATUS_CODES.open || status === TASK_STATUS_CODES.postponed) && (
        <StyledButtonsContainer>
          <StyledButton secondary onClick={onUpload} right='10'>
            <Flex align='center'>
              <UploadTestReportIcon width='14' height='14' />
              <Text left='3'>Hochladen</Text>
            </Flex>
          </StyledButton>
          <StyledButton onClick={onDownload}>
            <Flex align='center'>
              <Flex right='5'>
                <Download fill='white' />
              </Flex>
              Herunterladen
            </Flex>
          </StyledButton>
        </StyledButtonsContainer>
      )}
    </>
  );
};

const VehiclePreparationCard = ({ status }: VehiclePreparationCardProps) => {
  return (
    <Flex direction='column'>
      <Flex maxWidth='260px'>
        <Text size='12' color='grey500'>
          {getTaskDescription(status)}
        </Text>
      </Flex>
    </Flex>
  );
};

export default VehiclePreparationCard;