import React from 'react';
import ParkschadenIcon from './icons/parkschaden';
import GlasschadenIcon from './icons/glasschaden';
import AuffahrschadenIcon from './icons/auffahrschaden';
import SachbeschVandalismusIcon from './icons/sachbesch-vandalismus';
import VorfahrtVerletztIcon from './icons/vorfahrt-verletzt';
import FahrspurwechselIcon from './icons/fahrspurwechsel';
import WildTierschadenIcon from './icons/wild-tierschaden';
import HagelschadenIcon from './icons/hagelschaden';
import SturmschadenIcon from './icons/sturmschaden';
import EinbruchTeildiebstahlIcon from './icons/einbruch-teildiebstahl';
import MarderschadenIcon from './icons/marderschaden';
import BrandschadenIcon from './icons/brandschaden';
import TotaldiebstahlIcon from './icons/totaldiebstahl';
import SonstigesIcon from './icons/sonstiges';
import ChooseDamage from './collision-damage-component/choose-damage';
import SchadenmeldungIcon from './icons/schadenmeldun';
import KVAIcon from './icons/kva';
import BringserviceIcon from './icons/bringservice';
import ErsatzfahrzeugIcon from './icons/ersatzfahrzeug';
import ReparaturIcon from './icons/reparatur';

export const carNumberValidation = /^([A-Z,Ä,Ö,Ü]{1,3})-([A-Z,Ä,Ö,Ü]{1,2}[ ])(\d{1,4}[H,E]{0,1})$/;

export const damageTypes = [
  {
    name: 'Parkschaden',
    icon: <ParkschadenIcon />
  },
  {
    name: 'Glasschaden',
    icon: <GlasschadenIcon />
  },
  {
    name: 'Auffahrschaden',
    icon: <AuffahrschadenIcon />
  },
  {
    name: 'Sachbesch./ Vandalismus',
    icon: <SachbeschVandalismusIcon />
  },
  {
    name: 'Vorfahrt verletzt',
    icon: <VorfahrtVerletztIcon />
  },
  {
    name: 'Fahrspurwechsel',
    icon: <FahrspurwechselIcon />
  },
  {
    name: 'Wild-/ Tierschaden',
    icon: <WildTierschadenIcon />
  },
  {
    name: 'Hagelschaden',
    icon: <HagelschadenIcon />
  },
  {
    name: 'Sturmschaden',
    icon: <SturmschadenIcon />
  },
  {
    name: 'Einbruch/ Teildiebstahl',
    icon: <EinbruchTeildiebstahlIcon />
  },
  {
    name: 'Marderschaden',
    icon: <MarderschadenIcon />
  },
  {
    name: 'Brandschaden',
    icon: <BrandschadenIcon />
  },
  {
    name: 'Totaldiebstahl',
    icon: <TotaldiebstahlIcon />
  },
  {
    name: 'Sonstiges',
    icon: <SonstigesIcon />
  }
];

export const damage = [
  { name: 'Motorhaube', color: '#C6CACC', value: 2 },
  { name: 'Scheinwerfer vorne rechts', color: '#ABB0B2', value: 1 },
  { name: 'Scheinwerfer vorne links', color: '#ABB0B2', value: 3 },
  { name: 'Windschutzscheibe', color: '#ABB0B2', value: 4 },
  { name: 'Schiebedach/Glasdach', color: '#C6CACC', value: 5 },
  { name: 'Außenspiegel links', color: '#C6CACC', value: 6 },
  { name: 'Seitenfenster vorne links', color: '#ABB0B2', value: 7 },
  { name: 'Seitenfenster hinten links', color: '#ABB0B2', value: 8 },
  { name: 'C-Säule hinten links', color: '#C6CACC', value: 9 },
  { name: 'Kotflügel/Seitenteil hinten links', color: '#E0E3E5', value: 10 },
  { name: 'Rücklicht links', color: '#ABB0B2', value: 11 },
  { name: 'Kennzeichen hinten', color: '#ABB0B2', value: 12 },
  { name: 'Kofferraumklappe/Heckklappe', color: '#E0E3E5', value: 13 },
  { name: 'Heckscheibe', color: '#ABB0B2', value: 14 },
  { name: 'Dach', color: '#E0E3E5', value: 15 },
  { name: 'C-Säule hinten rechts', color: '#C6CACC', value: 16 },
  { name: 'Seitenfenster hinten rechts', color: '#ABB0B2', value: 17 },
  { name: 'Seitenfenster vorne rechts', color: '#ABB0B2', value: 18 },
  { name: 'Tür vorne rechts', color: '#C6CACC', value: 19 },
  { name: 'Außenspiegel rechts', color: '#C6CACC', value: 20 },
  { name: 'Kennzeichen vorne', color: '#ABB0B2', value: 21 },
  { name: 'Stoßfängerverkleidung vorne', color: '#C6CACC', value: 22 },
  { name: 'Reifen vorne links', color: '#ABB0B2', value: 23 },
  { name: 'Felge vorne links', color: '#C6CACC', value: 24 },
  { name: 'Kontflügel/Seitenteil vorne links', color: '#E0E3E5', value: 25 },
  { name: 'Tür vorne links', color: '#C6CACC', value: 26 },
  { name: 'Schweller links', color: '', value: 27 },
  { name: 'Tür hinten links', color: '#C6CACC', value: 28 },
  { name: 'Felge hinten links', color: '#C6CACC', value: 29 },
  { name: 'Reifen hinten links', color: '#ABB0B2', value: 30 },
  { name: 'Stoßfängerverkleidung hinten', color: '#C6CACC', value: 31 },
  { name: 'Rücklicht rechts', color: '#ABB0B2', value: 32 },
  { name: 'Kotflügel/Seitenteil hinten rechts', color: '#E0E3E5', value: 33 },
  { name: 'Reifen hinten rechts', color: '#ABB0B2', value: 34 },
  { name: 'Felge hinten rechts', color: '#C6CACC', value: 35 },
  { name: 'Tür hinten rechts', color: '#C6CACC', value: 36 },
  { name: 'Schweller rechts', color: '', value: 37 },
  { name: 'Kotflügel/Seitenteil vorne rechts', color: '#E0E3E5', value: 38 },
  { name: 'Reifen vorne rechts', color: '#ABB0B2', value: 39 },
  { name: 'Felge vorne rechts', color: '#C6CACC', value: 40 },
  { name: 'Sonstiges', color: '#E0E3E5', value: 100000041 }
];

export const successIcons = [
  {
    name: 'Schadenmeldung',
    description: 'Deine Schadenmeldung wird geprüft und bearbeitet.',
    icon: <SchadenmeldungIcon />
  },
  {
    name: 'Kostenvoranschlag',
    description: 'Anschließend wird ein Kostenvorschlag für die Reparatur erstellt.',
    icon: <KVAIcon />
  },
  {
    name: 'Hol- & Bringservice',
    description: 'Unsere Partnerwerkstatt holt dein Fahrzeug zur Reparatur bei dir ab.',
    icon: <BringserviceIcon />
  },
  {
    name: 'Ersatzfahrzeug',
    description: 'Dank Ersatzfahrzeug bleibst du während der Reparatur mobil.',
    icon: <ErsatzfahrzeugIcon />
  },
  {
    name: 'Reparatur',
    description: 'Unsere Partnerwerkstätten kümmern sich deutschland-weit um die Reparatur.',
    icon: <ReparaturIcon />
  }
];

export const savedObject = [
  'direction',
  'previousStep',
  'step',
  'region',
  'letters',
  'numbers',
  'kennzeichen',
  'fahrerInfo',
  'fuhrparkInfo',
  'fahrerInsurance',
  'new_hersteller',
  'new_model',
  'new_fin',
  'firma',
  'new_ezl',
  'new_ezl_value',
  'newFirmName',
  'newFirmNumber',
  'Schadendatum',
  'Uhrzeit',
  'Land',
  'Stadt',
  'strPLZ',
  'Strasse',
  'uds_classification_schadenortcode',
  'Postleitzahl',
  'Wunschtermin',
  'AlternativerTermin',
  'fahrerDataMobilephone',
  'fahrerDataMobilephoneCode',
  'fahrerDataMobilephone1',
  'fahrerDataMobilephone1Code',
  'anotherPersonFahrerName',
  'anotherPersonFahrerEmail',
  'anotherPersonFahrerEmailRepeat',
  'anotherPersonFahrerTelefonCode',
  'anotherPersonFahrerTelefon',
  'anotherPersonFahrerTelefon2Code',
  'anotherPersonFahrerTelefon2',
  'fahrerDataFullname',
  'fahrerDataEmailaddress1',
  'fahrerDataEmailaddressRepeat',
  'previousStep',
  'new_fuhrparkid',
  'staticDamageList',
  'schadenAlsFahrzeugnutzer',
  'schadenAlsAnderePerson',
  'isContactPerson',
  'isContactPersonName',
  'isContactPersonEmail',
  'isContactPersonPhoneCode',
  'isContactPersonPhone',
  'isContactPersonPhoneSecondCode',
  'isContactPersonPhoneSecond',
  'isContactPersonEmailRepeat'
];

export const driverFields = [
  'whoDriveName',
  'whoDrivePhone',
  'whoDriveEmail',
  'whoDrivePLZ',
  'whoDriveStadt',
  'whoDriveAdress'
];

export const otherFields = [
  'whoDriveNameAndere',
  'whoDrivePhoneAndere',
  'whoDriveEmailAndere',
  'whoDrivePLZAndere',
  'whoDriveStadtAndere',
  'whoDriveAdressAndere'
];

export const convertUTCToLocalDate = date => {
  if (!date) {
    return date;
  }
  date = new Date(date);
  date = new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate());
  return date;
};

export const convertLocalToUTCDate = date => {
  if (!date || !date.isValid()) {
    return null;
  }
  date = new Date(date);
  date = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
  return date;
};

export const documentTypeId = {
  0: '65b5feb2-92f0-e711-8145-e0071b6641e1',
  1: '80b5feb2-92f0-e711-8145-e0071b6641e1',
  2: 'c0b5feb2-92f0-e711-8145-e0071b6641e1',
  3: '404e9de6-fb68-ea11-a811-000d3ab8de1e',
  4: 'cab5feb2-92f0-e711-8145-e0071b6641e1'
};
