import React from 'react';
import PropTypes from 'prop-types';

export const CalculatorIcon = ({ color = '#fff' }) => {
  return (
    <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M16.1446 18H3V2H16.1323L16.1446 18ZM4.24309 16.7692H14.9138V3.23077H4.23077L4.24309 16.7692Z' fill={color} />
      <path d='M16.1446 6.35691H3V2H16.1323L16.1446 6.35691ZM4.24309 5.12615H14.9138V3.23077H4.23077L4.24309 5.12615Z' fill={color} />
      <path d='M7.24645 7.74121H5.38184V9.60583H7.24645V7.74121Z' fill={color} />
      <path d='M10.5072 7.74121H8.64258V9.60583H10.5072V7.74121Z' fill={color} />
      <path d='M13.7689 7.74121H11.9043V9.60583H13.7689V7.74121Z' fill={color} />
      <path d='M7.24645 10.7197H5.38184V12.5843H7.24645V10.7197Z' fill={color} />
      <path d='M10.5072 10.7197H8.64258V12.5843H10.5072V10.7197Z' fill={color} />
      <path d='M13.7689 10.7197H11.9043V12.5843H13.7689V10.7197Z' fill={color} />
      <path d='M7.24645 13.6982H5.38184V15.5629H7.24645V13.6982Z' fill={color} />
      <path d='M10.5072 13.6982H8.64258V15.5629H10.5072V13.6982Z' fill={color} />
      <path d='M13.7689 13.6982H11.9043V15.5629H13.7689V13.6982Z' fill={color} />
    </svg>
  );
};

CalculatorIcon.propTypes = {
  color: PropTypes.string
};
