import { toggleModal } from 'actions/app_action';
import { Button } from 'common/common-components.styled';
import { OrderVehicleReport } from 'components/aufgaben-component/components/order-vehicle-report/order-vehicle-report';
import { MODALS, MODAL_NAMES } from '../../../../constants';
import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useFetch } from 'hooks/use-fetch/use-fetch';
import { fleetLeaseEndRequestConfig } from 'request-config/freet-lease-end/fleet-lease-end.request-config';
import classes from './vehicle-initial-review-buttons.module.scss';
import { VehicleInitialReviewPhases } from 'components/aufgaben-component/task.prop';

type Props = {
  vir_phaseinfo: {
    phaseInfo: {
      person: string;
      phone: string;
      address: string;
    };
    phaseNo: number;
  };
  new_fuhrparkid: string;
  activityid: string;
  setRefreshKey: () => React.Dispatch<React.SetStateAction<number>>;
};

export const VehicleInitialReviewButtons = ({ vir_phaseinfo, new_fuhrparkid, activityid, setRefreshKey }: Props) => {
  const dispatch = useDispatch();
  const { fetch } = useFetch({
    loadingKey: 'setting phase',
    isShowSuccessSnackbar: true,
    successMessage: 'Die Daten wurden erfolgreich übermittelt',
    successCallback: () => setRefreshKey()
  });

  const onChange = async (action: string) => {
    dispatch(toggleModal(MODALS.alert, null));

    await fetch({
      requestConfig: fleetLeaseEndRequestConfig.setVirAction({
        actionName: action,
        fuhrparkId: new_fuhrparkid,
        taskId: activityid,
        address: vir_phaseinfo?.phaseInfo?.address || '',
        name: vir_phaseinfo?.phaseInfo?.person || '',
        phone: vir_phaseinfo?.phaseInfo?.phone || ''
      })
    });
  };

  const openVehicleReviewModal = useCallback(() => {
    dispatch(toggleModal(MODALS.alert, null));

    const alertData = {
      title: 'Kfz-Gutachten beauftragen',
      children: (
        <OrderVehicleReport
          phaseInfo={vir_phaseinfo?.phaseInfo}
          fuhrparkId={new_fuhrparkid}
          actionName='FormFilled'
          taskId={activityid}
          closeAlert={() => dispatch(toggleModal(MODALS.alert, null))}
          callback={setRefreshKey}
        />
      ),
      buttons: [
        {
          type: 'cancel',
          title: 'Abbrechen',
          action: () => dispatch(toggleModal(MODALS.alert, null)),
          hide: true
        }
      ],
      allButtonsHidden: true
    };
    dispatch(toggleModal(MODALS.alert, MODAL_NAMES.alert, alertData));
  }, [activityid, dispatch, new_fuhrparkid, vir_phaseinfo?.phaseInfo]);

  switch (vir_phaseinfo?.phaseNo) {
    case VehicleInitialReviewPhases.Phase0:
    case VehicleInitialReviewPhases.Phase1:
    case VehicleInitialReviewPhases.Phase6:
      return (
        <div className={classes.container}>
          <Button id={'cancelButton'} secondary onClick={() => onChange('Cancel')}>
            Benötige ich nicht
          </Button>
          <Button id={'bookAppraisalButtton'} onClick={openVehicleReviewModal}>
            Gutachten buchen
          </Button>
        </div>
      );
    case VehicleInitialReviewPhases.Phase4:
      return (
        <Button id={'bookAnywayButton'} onClick={() => onChange('Restore')}>
          Trotzdem buchen
        </Button>
      );
    default:
      return null;
  }
};
