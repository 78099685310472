import { UnavailableRange } from 'pages/existing-vehicle-page/components/layout/main-tabs/components/tab-panels/vehicle-history/components/sections/cost-centers-widget/cost-centers-widget.types';
import moment, { Moment } from 'moment';
import { changeCostCenterFormFields } from 'components/cost-centers-settings/form-fields/change-cost-center.form-fields';
import { ChangeCostCenterFieldNames } from 'components/cost-centers-settings/change-cost-center/change-cost-center.types';
import cloneDeep from 'lodash/cloneDeep';

export const getShouldDisableDateFunc = (unavailableRanges: UnavailableRange[]) => {
  return (day: Moment) => {
    return unavailableRanges.some(unavailableRange =>
      day.isBetween(unavailableRange.from, unavailableRange.to, undefined, '[]')
    );
  };
};

export const getNearestUnavailableDates = (unavailableRanges: UnavailableRange[], day: Moment) => {
  const getNearestDate = (arrOfDates: Moment[], day: Moment) => {
    let nearestDate: Moment | undefined = undefined;

    arrOfDates.forEach(date => {
      if (nearestDate) {
        if (Math.abs(date.diff(day, 'days')) < Math.abs(nearestDate.diff(day, 'days'))) {
          nearestDate = date;
        }
      } else {
        nearestDate = date;
      }
    });

    return nearestDate;
  };

  const newUnavailableRanges = cloneDeep(unavailableRanges);

  const startsOfRanges = newUnavailableRanges.map(range => range.from).filter(fromDay => fromDay.isAfter(day));
  const endsOfRanges = newUnavailableRanges.map(range => range.to).filter(toDay => toDay.isBefore(day));

  let inFuture: Moment | undefined = getNearestDate(startsOfRanges, day);
  if (inFuture) {
    inFuture = (inFuture as Moment).endOf('day');
  }
  const inPast: Moment | undefined = getNearestDate(endsOfRanges, day);

  return { inFuture, inPast };
};

export const getChangeCostCenterFields = (isUpdate: boolean, isFirstItem: boolean) => {
  if (isUpdate) {
    const newChangeCostCenterFormFields = cloneDeep(changeCostCenterFormFields);
    const validFromField = newChangeCostCenterFormFields.find(
      field => field.name === ChangeCostCenterFieldNames.ValidFrom
    );
    const validToField = newChangeCostCenterFormFields.find(field => field.name === ChangeCostCenterFieldNames.ValidTo);
    if (validFromField) {
      validFromField.label = 'Kostenstelle gültig ab:';
    }
    if (validToField) {
      validToField.label = 'Kostenstelle gültig bis (Optional):';
      if (!isFirstItem) {
        validToField.label = 'Kostenstelle gültig bis:';
        validToField.validation = {
          required: 'Bitte prüfe die Eingabe.'
        };
      }
    }

    return newChangeCostCenterFormFields;
  }
  return changeCostCenterFormFields;
};
