import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import { ButtonComponentType, ButtonType } from './button.props';
import SaveIcon from 'components/icons/save-icon';
import { Tooltip } from '@mui/material';
import { FileDownloadIcon } from 'components/icons-new/file-download';
import Download from 'components/icons/download';
import IconHover from 'components/icon-hover/icon-hover';
import { useDispatch } from 'react-redux';
import { toggleModal } from 'actions/app_action';
import { MODALS } from '../../constants';

const Button = props => {
  const {
    component,
    className,
    children,
    disabled,
    type = 'main',
    width,
    margin,
    id,
    tooltip,
    iconWidth,
    ...rest
  } = props;
  const combinedClassName = classnames(
    'button-mobexo',
    {
      'button-mobexo__disable': disabled,
      'button-mobexo__main': type === ButtonType.Main,
      'button-mobexo__secondary': type === ButtonType.Secondary
    },
    className
  );

  const dispatch = useDispatch();

  const closeAlert = () => {
    dispatch(toggleModal(MODALS.alert, null));
  };

  switch (component) {
    case ButtonComponentType.Link:
      return (
        <Link id={id} className={combinedClassName} style={{ width: width || 'auto', margin }} {...rest}>
          {children}
        </Link>
      );
    case ButtonComponentType.Button:
      return (
        <button
          id={id}
          className={combinedClassName}
          disabled={disabled}
          style={{ width: width || 'auto', margin }}
          {...rest}
        >
          {children}
        </button>
      );

    case ButtonComponentType.CloseModal:
      return (
        <button
          id={id}
          className='button-mobexo__secondary'
          disabled={disabled}
          style={{ width: width || 'auto', margin: margin || '0 10px 0 0' }}
          {...rest}
          onClick={closeAlert}
        >
          {children}
        </button>
      );

    case ButtonComponentType.SaveButton:
      return (
        <button id={id} className={combinedClassName} disabled={disabled} style={{ width: width || '175px' }} {...rest}>
          <SaveIcon className='mr-1' />
          {children || 'Speichern'}
        </button>
      );

    case ButtonComponentType.DownloadExcel:
      return (
        <button className={combinedClassName} disabled={disabled} style={{ width: width || 'auto' }} {...rest}>
          <Download fill={'#fff'} />
          <div className='mr-15' />
          {children || 'Excel'}
        </button>
      );

    case ButtonComponentType.DownloadExcelIcon:
      return (
        <Tooltip
          title={tooltip ? tooltip : 'Herunterladen Excel-Datei'}
          disableTouchListener={disabled}
          disableHoverListener={disabled}
        >
          <button className='reset-button' disabled={disabled} style={{ width: width || 'auto' }} {...rest}>
            <IconHover>
              <FileDownloadIcon width={iconWidth} height={iconWidth} />
            </IconHover>
          </button>
        </Tooltip>
      );

    default:
      return null;
  }
};

Button.defaultProps = {
  component: 'button'
};

Button.propTypes = {
  component: PropTypes.oneOf(['Link', 'button', 'saveButton', 'downloadExcel', 'downloadExcelIcon']),
  children: PropTypes.any,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  to: PropTypes.string,
  type: PropTypes.oneOf(['main', 'secondary']),
  width: PropTypes.string,
  margin: PropTypes.string,
  id: PropTypes.string,
  tooltip: PropTypes.string,
  iconWidth: PropTypes.number
};

export default Button;
