import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTypedSelector } from 'hooks/use-typed-selector';
import { RequestDataModel, ResponseModel, User } from 'common/interfaces';
import { MenuItem } from '@mui/material';
import { defaultOptions } from 'components/form-hook/form-field/form-field';
import {
  StyledMainRoleDropDownIcon,
  StyledMainRoleSwitcher,
  StyledSpinnerContainer
} from 'components/main-role-switcher/main-role-switcher.styled';
import { useForm } from 'react-hook-form';
import { setUser } from 'actions/app_action';
import { useDispatch } from 'react-redux';
import { crmDataTypes, RequestStatuses } from '../../constants';
import { AxiosResponse } from 'axios';
import { updateCrmEntity } from 'services/crm-entity-service';
import Spinner from 'components/spinner';
import { PAGES } from 'common/enums';
import { MainRoleSwitcherProps } from 'components/main-role-switcher/main-role-switcher.props';
import { queryClient } from 'index';
import { useUnsavedChangesModal } from 'hooks/modals/unsaved-changes.modal/unsaved-changes.modal';
import useUnsavedChangesLink from 'hooks/use-unsaved-changes/use-unsaved-changes-link';

const MainRoleSwitcher = ({ textColor }: MainRoleSwitcherProps) => {
  const user = useTypedSelector<User>(state => state['app'].user);
  const dispatch = useDispatch();
  const history = useHistory();
  const [loading, setLoading] = useState(false);

  const reactHookFormData = useForm();
  const { register, watch, setValue } = reactHookFormData;

  const { openUnsavedChangesModal } = useUnsavedChangesModal();
  const { unsavedChangesHandler } = useUnsavedChangesLink();

  const mainRoleValue: string = watch('mainRole');
  const value: string = mainRoleValue || user.mainRole.id;

  useEffect(() => {
    const updateMainRole = async () => {
      const dataToSend: RequestDataModel = {
        entityName: 'contact',
        id: user.id,
        attributes: {
          uds_main_b2c_webrolleid: {
            attributeTypeCode: crmDataTypes.Lookup,
            attributeValue: {
              id: value,
              logicalName: '',
              name: ''
            }
          }
        }
      };

      setLoading(true);
      let response: AxiosResponse<ResponseModel> | null = null;
      try {
        response = await updateCrmEntity(dataToSend);
      } catch (e) {
        console.log(e);
      } finally {
        setLoading(false);
      }

      if (response?.data?.level === RequestStatuses.Success) {
        dispatch(setUser({ ...user, mainRole: user.roles.find(role => role.id === value) }));
        queryClient.clear();
        localStorage.removeItem('selectedDrivers');
        history.push(PAGES.DASHBOARD);
      }
    };

    if (user.mainRole.id === value) {
      return;
    }

    updateMainRole();
  }, [value]);

  return loading ? (
    <StyledSpinnerContainer justify='center'>
      <Spinner style={spinnerStyle} />
    </StyledSpinnerContainer>
  ) : (
    <StyledMainRoleSwitcher
      {...register('mainRole')}
      value={value}
      IconComponent={StyledMainRoleDropDownIcon}
      id='breadcrumbsRoleSwitcher'
      textcolor={textColor}
      onChange={e =>
        unsavedChangesHandler(() => openUnsavedChangesModal(() => setValue('mainRole', e.target.value, defaultOptions)))
      }
    >
      {user.roles
        ?.sort((a, b) => a.name.localeCompare(b.name))
        .map(option => (
          <MenuItem key={option.id} value={option.id} id={'breadcrumbsRoleSwitcherMenuItem' + option.id}>
            {option.name}
          </MenuItem>
        ))}
    </StyledMainRoleSwitcher>
  );
};

const spinnerStyle = {
  width: '20px',
  zIndex: 99
};

export default MainRoleSwitcher;
