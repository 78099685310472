import React, { ReactElement } from 'react';
import { Flex, Text } from 'common/common-components.styled';
import {
  StyledLeasingEndCard,
  StyledImageWrapper,
  StyledStatusTextWrapper,
  StyledLeaseEndCardWrapper,
  StyledImageBackground
} from './leasing-end-generic.card.styled';

interface LeasingEndCardProps {
  image: ReactElement;
  title: string;
  statusLabel: string;
  statusColor: string;
  children?: ReactElement;
  isHideStatus?: boolean;
  buttons?: ReactElement | null;
}

const LeasingendGenericCard = ({
  image,
  title,
  children,
  isHideStatus,
  buttons,
  statusLabel,
  statusColor
}: LeasingEndCardProps) => {
  return (
    <StyledLeaseEndCardWrapper>
      <StyledLeasingEndCard>
        <StyledImageWrapper>
          <StyledImageBackground>{image}</StyledImageBackground>
        </StyledImageWrapper>
        <Flex direction='column' width='100%' style={{ order: 2 }}>
          <Flex bottom='5' justify='space-between' width='100%'>
            <Text>{title}</Text>
          </Flex>
          {children}
        </Flex>
        <StyledStatusTextWrapper isStatusHidden={isHideStatus}>
          {statusLabel && (
            <Text is={'status'} color={statusColor}>
              {statusLabel}
            </Text>
          )}
        </StyledStatusTextWrapper>
      </StyledLeasingEndCard>
      <Flex justify='flex-end' top='20'>
        {buttons}
      </Flex>
    </StyledLeaseEndCardWrapper>
  );
};

export default LeasingendGenericCard;
