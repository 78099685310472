import React from 'react';
import PropTypes from 'prop-types';
import classes from './equipment-tab.module.scss';

export function StandardEquipmentItem({ value, className }) {
  return (
    <div className={className ? `${classes.standard_equipment} ${className}` : classes.standard_equipment}>
      <div className={classes.standard_equipment_container}>
        <p className={classes.standard_equipment_item}>{value}</p>
      </div>
      <div className={classes.divider} />
    </div>
  );
}

export function OptionalEquipmentItem({ item }) {
  return (
    <div className={`${classes.standard_equipment} ${classes.optional_equipment}`}>
      <div className={classes.standard_equipment_container}>
        <div className={classes.optional_equipment_container}>
          <span>{item.new_herstellercode || '-'}</span>
        </div>
        <div className={classes.optional_equipment_container}>
          <p>{item.new_beschreibung || '-'}</p>
          {/*<span>{item.price*/}
          {/*  ? item.price.toLocaleString('de-DE', { style: 'currency', currency: 'EUR' })*/}
          {/*  : '-'}</span>*/}
        </div>
      </div>
      <div className={classes.divider} />
    </div>
  );
}

StandardEquipmentItem.propTypes = {
  value: PropTypes.string.isRequired,
  className: PropTypes.string
};

OptionalEquipmentItem.propTypes = {
  item: PropTypes.object.isRequired
};
