import { CrmFieldResponse, LookupResponse, PicklistResponse } from 'common/interfaces';

export interface ReleaseDataResponse {
  status: ReleaseStatuses;
  fahrzeugangeboteData?: {
    ausstattungs: {
      id: string;
      attributes: Omit<EquipmentItem, 'id'>;
    }[];
    fahrzeugangebote: {
      id: string;
      attributes: Omit<VehicleOfferData, 'uds_fahrzeugangeboteid' | 'uds_co2_efficiencycode_value'>;
    };
    carPolicies: {
      id: string;
      attributes: Omit<CarPolicyItem, 'id'>;
    }[];
    globalFahrzeugangebote: {
      id: string;
      attributes: GlobalVehicleOfferData;
    };
  };
  fotoData?: PhotoData[];
  stepperData?: StepperData[];
  email?: string;
  driverData?: {
    budget: {
      id: string;
      attributes: {
        uds_budget_eur: CrmFieldResponse<PicklistResponse>;
        uds_name: CrmFieldResponse;
      };
    };
    carPolicy: {
      id: string;
      attributes: {
        uds_name: CrmFieldResponse;
      };
    };
    driver: {
      id: string;
      attributes: {
        fullname: CrmFieldResponse;
      };
    };
  };
}

export interface GlobalVehicleOfferData {
  uds_configurationtypecode: CrmFieldResponse<PicklistResponse>;
}

export interface CarPolicyItem {
  uds_name: CrmFieldResponse;
  id: string;
}

export interface EquipmentItem {
  new_art: CrmFieldResponse<PicklistResponse>;
  new_beschreibung: CrmFieldResponse;
  new_herstellercode: CrmFieldResponse;
  new_name: CrmFieldResponse;
  id: string;
}

export interface PhotoData {
  fileName: string;
  storageFileId: string;
}

export interface StepperData {
  isCurrentUser: boolean;
  isSigned: boolean;
  initials: string;
}

export interface VehicleOfferData {
  uds_fahrzeugangeboteid: string;
  uds_carbrandid: CrmFieldResponse<LookupResponse>;
  uds_carmodelid: CrmFieldResponse<LookupResponse>;
  uds_cartypecode: CrmFieldResponse<PicklistResponse>;
  uds_color: CrmFieldResponse;
  uds_enginetypecode: CrmFieldResponse<PicklistResponse>;
  uds_getriebe: CrmFieldResponse<PicklistResponse>;
  uds_hubraum: CrmFieldResponse<number>;
  uds_leistungkw: CrmFieldResponse<number>;
  uds_listenpreis: CrmFieldResponse<PicklistResponse>;
  uds_listenpreis_netto: CrmFieldResponse<PicklistResponse>;
  uds_barpreis_netto: CrmFieldResponse<PicklistResponse>;
  uds_name: CrmFieldResponse;
  uds_co2_efficiencycode: CrmFieldResponse<PicklistResponse>;
  uds_co2_efficiencycode_value: CrmFieldResponse<number>;
  uds_uds_fuelconsumption_combined: CrmFieldResponse;
  uds_calculated_fuelconsumption_combined: CrmFieldResponse<number>;
  uds_co2_emission_combined: CrmFieldResponse;
}

export type ReleaseData = Partial<
  Omit<ReleaseDataResponse, 'fahrzeugangeboteData'> & {
    vehicleOfferData: VehicleOfferData;
    globalVehicleOfferData: GlobalVehicleOfferData;
    equipment: EquipmentItem[];
    carPolicies: CarPolicyItem[];
  }
>;

export enum ReleaseStatuses {
  Wait,
  Signed,
  Decline,
  Expired
}
