import React from 'react';
import { useQuery } from 'react-query';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { setContactSetup } from '../../../../../actions/app_action';
import { USER_ROLES } from '../../../../../constants';
import { UserCardIcon } from '../../../../icons/user-card';
import { UserCardCrossedOutIcon } from '../../../../icons/user-card-crossed-out';
import { UserIcon } from '../../../../icons/user-icon';
import More, { MoreMenuItem } from '../../../../more';
import { useUnsubscribeDriverLicenseCheckModal } from '../../../../../hooks/modals/driver-check/unsubscribe-driver-license-check.modal';
import { useBookDriverLicenseCheckModal } from '../../../../../hooks/modals/driver-check/book-driver-license-check.modal';
import { PAGES, ReactQueryKeys } from '../../../../../common/enums';
import { useIsUserHaveRole } from '../../../../../hooks/use-is-user-have-role';
import { getIsAccountBooked } from 'services/drivers-check-service';

export const DriverscheckMenu = ({ row }) => {
  const { parentcustomerid, contactid, parentcustomerid_id, emailaddress1, uds_isdrivercheckneeded } = row;

  const dispatch = useDispatch();

  const { data, isFetching } = useQuery([ReactQueryKeys.AccountIsBooked, parentcustomerid_id], () =>
    getIsAccountBooked(parentcustomerid_id)
  );
  const history = useHistory();

  const isAccountBooked = data?.data?.data?.uds_isorg_in_driverscheck;
  const isDriver = useIsUserHaveRole(USER_ROLES.driver.id);
  const isPoolManager = useIsUserHaveRole(USER_ROLES.poolManager.id);

  const { openModal: openUnsubscribeDriverLicenseCheckModal } = useUnsubscribeDriverLicenseCheckModal();
  const { openModal: openBookDriverLicenseCheckModal } = useBookDriverLicenseCheckModal();

  const openSettingPageViaModal = (state = {}) => {
    if (isDriver || isPoolManager) {
      return;
    }

    dispatch(
      setContactSetup({
        id: contactid,
        companyId: parentcustomerid
      })
    );

    history.push({
      pathname: `${PAGES.MY_DRIVERS_DETAILS}/`,
      search: `?id=${contactid}`,
      state: {
        tabName: 'führerschein',
        ...state
      }
    });
  };

  return (
    <div>
      {isDriver || isPoolManager ? null : (
        <More spinnerStyle={spinnerStyle} isLoading={isFetching}>
          {uds_isdrivercheckneeded ? (
            <MoreMenuItem
              img={<UserCardCrossedOutIcon width='20' height='20' />}
              label='Führerscheinkontrolle abbestellen'
              onClick={() =>
                openUnsubscribeDriverLicenseCheckModal(contactid, {
                  callback: openSettingPageViaModal
                })
              }
              isLeftPositionImg
              minWidth='296px'
              disable={!isAccountBooked}
            />
          ) : (
            <MoreMenuItem
              img={<UserCardIcon />}
              label='Führerscheinkontrolle buchen'
              onClick={() =>
                openBookDriverLicenseCheckModal(contactid, !!emailaddress1, { callback: openSettingPageViaModal })
              }
              isLeftPositionImg
              minWidth='296px'
              disable={!isAccountBooked}
            />
          )}
          <MoreMenuItem
            img={<UserIcon />}
            label='Funktion anpassen'
            onClick={() =>
              openSettingPageViaModal({
                formName: 'Funktion',
                formDataNames: ['uds_isdrivercheckneeded', 'uds_kontrolleur', 'new_manager_driverscheckeskalation']
              })
            }
            isLeftPositionImg
            minWidth='296px'
            disable={!isAccountBooked}
          />
        </More>
      )}
    </div>
  );
};

const spinnerStyle = {
  width: '40px',
  position: 'absolute',
  left: '50%',
  top: '50%',
  transform: 'translate(-50%, -50%)',
  containerHeigth: '80px',
  containerWidth: '300px'
};
