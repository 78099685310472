import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState, useCallback, useRef, createContext } from 'react';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { useOverlay, useQuery } from '../../hooks';
import { getFuhrparkById } from '../../services/fuhrpark-service';
import classes from './existing-vehicle.module.scss';
import cloneDeep from 'lodash/cloneDeep';
import { setCustomUrlTree, toggleModal } from '../../actions/app_action';
import useRequest from '../../hooks/use-request';
import { MODALS, MODAL_NAMES, USER_ROLES, silverDatVehicleTypes, RequestStatuses } from '../../constants';
import { getResponseData } from '../../utils/get-response-data';
import authService from '../../services/auth-service';
import { defaultUrlTree } from '../../components/breadcrumbs-panel-dashboard/breadcrumbs-panel-dashboard';
import { ArrowIconRight } from 'components/icons/arrow-icon-right';
import LeasingendGenericCard from './vehicle-items/leasingend-cards/leasingend-generic-card';
import VehiclePreparationCard from './vehicle-items/leasingend-cards/vehicle-preparation-card';
import ChecklistUpload from 'components/aufgaben-component/components/upload-checklist/upload-checklist';
import sharepointService from '../../services/sharepoint-service';
import { format, parseISO } from 'date-fns';
import { closeChecklistTask } from 'components/aufgaben-component/utils';
import { setSnakbar } from 'actions/app_action';
import { useIsUserHaveRole } from '../../hooks/use-is-user-have-role';
import { REPLACEMENT_VEHICLE_PHASES, TASK_STATUS_CODES, STATECODE_NAMES, VEHICLE_RETURN_PHASES } from '../../constants';
import ReplacementVehicleOrderingCard from './vehicle-items/leasingend-cards/replacement-vehicle-ordering-card';
import { WidgetDocumnetContainer } from 'components/widget-documnet/widget-documnet-container';
import { Flex } from 'common/common-components.styled';
import { RVOButtons } from './vehicle-items/leasingend-cards/replacement-vehicle-ordering-card';
import { ChecklistButtons } from './vehicle-items/leasingend-cards/vehicle-preparation-card';
import moment from 'moment';
import { PAGES } from '../../common/enums';
import { VehicleReturnButtons, VehicleReturnCard } from './vehicle-items/leasingend-cards/vehicle-return-card';
import { useTheme } from 'styled-components';
import { LeaseExtentionPhases, VehicleInitialReviewPhases } from 'components/aufgaben-component/task.prop';
import { Text } from 'common/common-components.styled';
import { ButtonsForLeaseEnd } from 'components/aufgaben-component/components/unfolded-task/components/buttons-for-lease-extension/buttons-for-lease-extension';
import { ReturnKeys } from 'components/icons-new/return-keys';
import { Car } from 'components/icons-new/car';
import { CalendarPlanningIcon } from 'components/icons-new/calendar-planning';
import { VehicleInitialReviewButtons } from './vehicle-items/vehicle-initial-review/vehicle-initial-review-buttons';
import { VehicleDetailContext } from './existing-vehicle.context';
import { VehicleDetailTopWidgetLayout } from './components/layout/top-widget-layout/top-widget-layout';
import { VehicleDetailsMainTabs } from './components/layout/main-tabs/main-tabs';
import { useVehicleDetailsOnUnmount } from './hooks/use-un-unmount';
import { VehicleDetailsRepairPartnerWidget } from './components/widgets/repair-partner/repair-partner';
import { CarOverviewSection } from './components/layout/car-overview-section/car-overview-section';
import { useFleetByIdQuery } from 'hooks/react-query/fleet/use-get-by-id/use-get-by-id';
import { VehicleStatusesValue } from 'pages/my-vehicles/my-vehicles.props';
import useWindowSize from 'hooks/use-window-size';

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

export const getAllFiles = async id => {
  const token = await authService.acquireTokenAsync();
  return axios({
    method: 'GET',
    url: `${API_ENDPOINT}/api/fuhrpark/AllFiles?FuhrparkId=${id}`,
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
};

const getChecklistFile = async () => {
  const token = await authService.acquireTokenAsync();
  return axios({
    method: 'GET',
    url: `${API_ENDPOINT}/api/CrmTask/GetChecklistFile`,
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
};

const getStatusFromPhase = phase => {
  switch (phase) {
    case REPLACEMENT_VEHICLE_PHASES.noPhase:
      return null;
    case REPLACEMENT_VEHICLE_PHASES.created:
    case REPLACEMENT_VEHICLE_PHASES.open:
      return {
        label: STATECODE_NAMES.open,
        value: TASK_STATUS_CODES.open,
        color: 'darkGold'
      };
    case REPLACEMENT_VEHICLE_PHASES.closedWithoutVehicle:
    case REPLACEMENT_VEHICLE_PHASES.closedVehicleOrdered:
    case REPLACEMENT_VEHICLE_PHASES.closedVehicleDelivered:
      return {
        label: STATECODE_NAMES.completed,
        value: TASK_STATUS_CODES.completed,
        color: 'green'
      };
    case REPLACEMENT_VEHICLE_PHASES.processing:
      return {
        label: STATECODE_NAMES.processing,
        value: TASK_STATUS_CODES.processing,
        color: 'darkGold'
      };
  }
};

const getStatusColor = status => {
  switch (status) {
    case TASK_STATUS_CODES.completed:
      return 'green';
    default:
      return 'darkGold';
  }
};

const getStatusFromPhaseVehicleReturn = phase => {
  switch (phase) {
    case VEHICLE_RETURN_PHASES.open:
    case VEHICLE_RETURN_PHASES.filledAndCanceledByEmployee:
      return {
        label: STATECODE_NAMES.open,
        value: TASK_STATUS_CODES.open,
        color: 'darkGold'
      };
    case VEHICLE_RETURN_PHASES.filledByClient:
    case VEHICLE_RETURN_PHASES.filledByEmployee:
    case VEHICLE_RETURN_PHASES.returnProtocolWasCanceled:
      return {
        label: STATECODE_NAMES.processing,
        value: TASK_STATUS_CODES.processing,
        color: 'darkGold'
      };
    case VEHICLE_RETURN_PHASES.protocolUploadedByClient:
    case VEHICLE_RETURN_PHASES.protocolUploadedByEmployee:
    case VEHICLE_RETURN_PHASES.mobexoTagThatProtocolIsNotNeeded:
      return {
        label: STATECODE_NAMES.completed,
        value: TASK_STATUS_CODES.completed,
        color: 'green'
      };
    default:
      return null;
  }
};

const VEHICLE_VIEW_FORM_LOADING_KEY = 'vehicleViewFormLoadingKey';

const VehicleViewFormContainer = () => {
  const query = useQuery();
  const [refreshKey, setRefreshKey] = useState(0);
  const { data: vehicle } = useRequest(
    () => getFuhrparkById(query.id),
    [refreshKey],
    false,
    VEHICLE_VIEW_FORM_LOADING_KEY
  );
  const isDriver = useIsUserHaveRole(USER_ROLES.driver.id);

  const vehicleDetailContextValue = { vehicle: vehicle?.data || {}, setRefreshKey };

  return (
    <VehicleDetailContext.Provider value={vehicleDetailContextValue}>
      <VehicleViewForm isDriver={isDriver} id={query.id} vehicle={vehicle || {}} setRefreshKey={setRefreshKey} />
    </VehicleDetailContext.Provider>
  );
};

export const Section = props => {
  return (
    <section id={props.id ? props.id : null} className={classNames('mobexo-panel', classes.container)}>
      <div className='w-100'>{props.children}</div>
    </section>
  );
};

Section.propTypes = {
  children: PropTypes.any,
  id: PropTypes.string
};

const VehicleViewForm = ({ vehicle, setRefreshKey, isDriver }) => {
  const query = useQuery();
  const dispatch = useDispatch();
  const [showOverlay, hideOverlay] = useOverlay(VEHICLE_VIEW_FORM_LOADING_KEY);
  const item = useMemo(() => getResponseData(vehicle, ['fuhrpark', 'task', 'leaseEndInfo', 'serverDate']), [vehicle]);

  const [checklistFileData, setChecklistFileData] = useState({
    fileId: '',
    name: ''
  });
  const reportFormStateRef = useRef();
  useVehicleDetailsOnUnmount();

  const { fleetByIdQuery } = useFleetByIdQuery();
  const { fuhrpark } = fleetByIdQuery;

  const theme = useTheme();
  const { isMobile } = useWindowSize();

  useEffect(() => {
    getChecklistFile().then(response => {
      setChecklistFileData({ fileId: response.data.data[0].fileId, name: response.data.data[0].name });
    });
  }, []);

  const closeAlert = () => {
    dispatch(toggleModal(MODALS.alert, null));
  };

  useEffect(() => {
    if (item) {
      const newUrlTree = cloneDeep(defaultUrlTree);
      newUrlTree.children['meine-fahrzeuge'].children['fahrzeuge'] = {
        breadcrumbTitle: item.new_name?.attributeValue || '',
        title: item.new_name?.attributeValue || '',
        url: `${PAGES.MY_VEHICLES_DETAILS}/?id=${query.id}`
      };

      newUrlTree.children['pool-car-buchungen'].children['fahrzeuge'] = {
        breadcrumbTitle: item.new_name?.attributeValue || '',
        title: item.new_name?.attributeValue || '',
        url: `${PAGES.POOL_CALENDAR}/fahrzeuge/?id=${query.id}`
      };

      newUrlTree.children['meine-pool-car-buchungen'].children['fahrzeuge'] = {
        breadcrumbTitle: item.new_name?.attributeValue || '',
        title: item.new_name?.attributeValue || '',
        url: `${PAGES.MY_POOL_CALENDAR}/fahrzeuge/?id=${query.id}`
      };

      dispatch(setCustomUrlTree(newUrlTree));
    }
  }, [item]);

  const onReportFormComplete = useCallback((files, date) => {
    reportFormStateRef.current = {
      files,
      date
    };
  }, []);

  const submitChecklistReport = useCallback(() => {
    closeAlert();
    showOverlay();
    reportFormStateRef.current &&
      closeChecklistTask(
        item.checklist.activityid.attributeValue,
        Object.values(reportFormStateRef.current.files)
      ).then(response => {
        dispatch(
          setSnakbar({
            isOpen: true,
            message: response.data.isSuccess
              ? 'Die Checkliste wurde erfolgreich versendet'
              : response.data.messages[0].message,
            type: response.data.level.toLowerCase()
          })
        );
        setRefreshKey(oldKey => oldKey + 1);
        hideOverlay();
      });
  }, [closeAlert, dispatch, showOverlay]);

  const openUploadChecklistModal = () => {
    const alertData = {
      title: 'Checkliste für Fahrzeugrückgabe',
      children: <ChecklistUpload onFormComplete={onReportFormComplete} onSubmit={submitChecklistReport} />,
      buttons: [
        {
          type: 'cancel',
          title: 'Abbrechen',
          action: closeAlert,
          hide: true
        }
      ],
      allButtonsHidden: true
    };
    dispatch(toggleModal(MODALS.alert, MODAL_NAMES.alert, alertData));
  };

  const handleDownload = async (id, name) => {
    showOverlay();
    await sharepointService
      .downloadFileById(id)
      .then(({ data }) => {
        const a = document.createElement('a');
        a.href = URL.createObjectURL(new Blob([data]));
        a.download = name;
        a.click();
      })
      .catch(err => console.log(err))
      .finally(hideOverlay);
  };

  const getFormattedDate = useCallback(
    field => {
      if (item && !_.isEmpty(item)) {
        return item[field].attributeValue ? format(parseISO(item[field].attributeValue), 'dd.MM.yyyy') : '-';
      }
    },
    [item]
  );

  const getLeaseEndDescription = useCallback(
    (leaseReturnField, leaseExtendedField) => {
      return (
        <div>
          <p>{`Status und Aufgaben zur Leasingende des Fahrzeugs Leasingende: ${getFormattedDate(
            leaseReturnField
          )}`}</p>
          <p>{`Leasingende stillschweigend verlängert bis: ${getFormattedDate(leaseExtendedField)}`}</p>
        </div>
      );
    },
    [getFormattedDate]
  );

  const getVIRWidgetStatus = phase => {
    switch (phase) {
      case VehicleInitialReviewPhases.Phase0:
      case VehicleInitialReviewPhases.Phase1:
        return 'Offen';
      case VehicleInitialReviewPhases.Phase2:
      case VehicleInitialReviewPhases.Phase3:
      case VehicleInitialReviewPhases.Phase4:
        return 'Erledigt';
      case VehicleInitialReviewPhases.Phase6:
        return 'in Bearbeitung';
      default:
        return;
    }
  };

  const getVIRStatusColor = phase => {
    return [
      VehicleInitialReviewPhases.Phase0,
      VehicleInitialReviewPhases.Phase1,
      VehicleInitialReviewPhases.Phase6
    ].includes(phase)
      ? 'darkGold'
      : 'green';
  };

  const getVIRLabel = phase => {
    const { new_name: name, uds_lease_return_date: leasingreturnDate, vir } = item;
    const formattedDate = moment(leasingreturnDate.attributeValue).format('DD.MM.YYYY');
    const { phaseDate } = vir?.vir_phaseinfo;

    switch (phase) {
      case VehicleInitialReviewPhases.Phase0:
      case VehicleInitialReviewPhases.Phase1:
      case VehicleInitialReviewPhases.Phase6:
        return `Der Leasingvertrag für Fahrzeug ${name?.attributeValue} endet spätestens am ${formattedDate}. Bitte beauftragen Sie ein Kfz-Gutachten.`;
      case VehicleInitialReviewPhases.Phase2:
      case VehicleInitialReviewPhases.Phase3:
        return `Gutachten wurde am ${phaseDate} erfolgreich beauftragt.`;
      case VehicleInitialReviewPhases.Phase4:
        return `Gutachten am ${phaseDate} wurde als unnötig markiert.`;
      default:
        return;
    }
  };

  const gridClassName = classNames(classes.grid, {
    [classes.grid_order_status]: fuhrpark?.new_status_carcode_value === VehicleStatusesValue.Ordered,
    [classes.grid_driver]: !isMobile && isDriver
  });

  return (
    <div className={classes.existing_vehicle}>
      <Flex className={gridClassName} maxWidth={isDriver ? '1250px' : '1400px'}>
        <VehicleDetailTopWidgetLayout />
        <CarOverviewSection />
        <VehicleDetailsRepairPartnerWidget />
        <VehicleDetailsMainTabs />

        <div className={classes.existing_vehicle_wrapper}>
          <Flex direction='column'>
            {item.widgetSetings && item.widgetSetings.showWidget && (
              <WidgetDocumnetContainer
                img={<ArrowIconRight height='20' width='20' />}
                title='Leasingende'
                className={classes.vehicle_container}
                description={getLeaseEndDescription('uds_lease_return_date', 'uds_leaseenddate_extended')}
                type='new'
              >
                {item.widgetSetings.showRvoWidget && (
                  <LeasingendGenericCard
                    image={<Car color={theme.colors.darkGold} />}
                    title='Ersatzbeschaffung'
                    statusLabel={getStatusFromPhase(item?.rvo?.rvo_state)?.label}
                    statusColor={getStatusFromPhase(item?.rvo?.rvo_state)?.color}
                    isHideStatus={item.rvo && item.rvo.rvo_state === REPLACEMENT_VEHICLE_PHASES.processing}
                    buttons={
                      <RVOButtons
                        carId={item.new_fuhrparkid.attributeValue}
                        rvo={item.rvo}
                        driver={item.new_fahrerid}
                        plateNumber={item.new_name.attributeValue}
                        setRefreshKey={setRefreshKey}
                      />
                    }
                  >
                    <ReplacementVehicleOrderingCard
                      rvo_phaseinfo={item.rvo.rvo_phaseinfo}
                      rvo_state={item.rvo.rvo_state}
                    />
                  </LeasingendGenericCard>
                )}
                {item.widgetSetings.showChecklistWidget && (
                  <LeasingendGenericCard
                    image={<Car color={theme.colors.darkGold} />}
                    title='Checkliste für Fahrzeugrückgabe'
                    statusLabel={item.checklist.widgetState.attributeValue.label}
                    statusColor={getStatusColor(item.checklist.widgetState.attributeValue.value)}
                    buttons={
                      <ChecklistButtons
                        status={item.checklist.widgetState.attributeValue.value}
                        onUpload={openUploadChecklistModal}
                        onDownload={() => handleDownload(checklistFileData.fileId, checklistFileData.name)}
                      />
                    }
                  >
                    <VehiclePreparationCard status={item.checklist.widgetState.attributeValue.value} />
                  </LeasingendGenericCard>
                )}
                {item.widgetSetings.showVrWidget && (
                  <LeasingendGenericCard
                    image={<ReturnKeys color={theme.colors.darkGold} />}
                    title='Leasingrückgabe'
                    statusLabel={getStatusFromPhaseVehicleReturn(item.vr.vr_state)?.label}
                    statusColor={getStatusFromPhaseVehicleReturn(item.vr.vr_state)?.color}
                    buttons={
                      <VehicleReturnButtons
                        phase={item.vr.vr_state}
                        vehicleId={item.vr.vr_phaseinfo.fuhrparkId}
                        orderId={item.vr.vr_phaseinfo.lafId}
                        setRefreshKey={setRefreshKey}
                      />
                    }
                  >
                    <VehicleReturnCard phase={item.vr.vr_state} />
                  </LeasingendGenericCard>
                )}
                {item.widgetSetings.showLvaWidget && (
                  <LeasingendGenericCard
                    image={<CalendarPlanningIcon color={theme.colors.darkGold} />}
                    title='Leasingvertragsanpassung'
                    statusLabel={item.lva.lva_phaseinfo.phaseState}
                    statusColor={PHASES_STATUS_OPEN.includes(item.lva?.lva_phaseinfo?.phaseNo) ? 'darkGold' : 'green'}
                    buttons={
                      <ButtonsForLeaseEnd
                        isMobexoEmployee={false}
                        currentPhase={item?.lva?.lva_state}
                        isWidget={true}
                        leaseExtensionId={item.lva?.lva_phaseinfo?.lvaId}
                        plateNumber={{
                          ...item.new_name,
                          attributeValue: {
                            id: item.lva?.lva_phaseinfo?.fuhrparkId,
                            logicalName: 'fuhrpark',
                            name: item.new_name.attributeValue
                          }
                        }}
                        phaseInfo={item.lva?.lva_phaseinfo?.specificPhaseInfo}
                        files={item.lva?.lva_phaseinfo?.fileList?.Verlängerungangebot || []}
                        leaseEndDate={item.uds_lease_return_date.attributeValue}
                        refresh={() => setRefreshKey(oldKey => oldKey + 1)}
                        taskId={item.lva?.lva_phaseinfo?.lvaId}
                      />
                    }
                  >
                    <Text size='12' color='grey500'>
                      {item.lva?.lva_phaseinfo?.phaseDescription}
                    </Text>
                  </LeasingendGenericCard>
                )}

                {item.widgetSetings.showVirWidget && (
                  <LeasingendGenericCard
                    image={<Car color={theme.colors.darkGold} />}
                    title='Kfz-Gutachten beauftragen'
                    statusLabel={getVIRWidgetStatus(item.vir?.vir_phaseinfo.phaseNo)}
                    statusColor={getVIRStatusColor(item.vir?.vir_phaseinfo.phaseNo)}
                    buttons={
                      <VehicleInitialReviewButtons
                        isWidget={item?.widgetSetings?.isWidget}
                        vir_phaseinfo={item?.vir?.vir_phaseinfo}
                        activityid={item.checklist?.activityid?.attributeValue}
                        new_fuhrparkid={item.new_fuhrparkid?.attributeValue}
                        setRefreshKey={() => setRefreshKey(oldKey => oldKey + 1)}
                      />
                    }
                  >
                    <Text size='12' color='grey500'>
                      {getVIRLabel(item.vir?.vir_phaseinfo.phaseNo)}
                    </Text>
                  </LeasingendGenericCard>
                )}
              </WidgetDocumnetContainer>
            )}
          </Flex>
        </div>
      </Flex>
    </div>
  );
};

const ExistingVehiclePage = () => {
  return <VehicleViewFormContainer />;
};

export default ExistingVehiclePage;

const PHASES_STATUS_OPEN = [
  LeaseExtentionPhases.Phase0,
  LeaseExtentionPhases.Phase1,
  LeaseExtentionPhases.Phase2,
  LeaseExtentionPhases.phase7
];

const PLACEHOLDER_IMAGES = {
  [silverDatVehicleTypes.personalCar.id]: '/assets/images/vehicle-placeholders/PKW.svg',
  [silverDatVehicleTypes.truck.id]: '/assets/images/vehicle-placeholders/LKW.svg',
  [silverDatVehicleTypes.trailer.id]: '/assets/images/vehicle-placeholders/Anhanger.svg',
  [silverDatVehicleTypes.bicycle.id]: '/assets/images/vehicle-placeholders/Fahrrad.svg',
  [silverDatVehicleTypes.tool.id]: '/assets/images/vehicle-placeholders/Werkzeug.svg',
  [silverDatVehicleTypes.transporter.id]: '/assets/images/vehicle-placeholders/Transporter.svg'
};

export const FuhrparkGallery = ({ images, files, vehicleType = {} }) => {
  const placeholderImg =
    vehicleType?.value && PLACEHOLDER_IMAGES[vehicleType.value]
      ? PLACEHOLDER_IMAGES[vehicleType.value]
      : PLACEHOLDER_IMAGES[silverDatVehicleTypes.personalCar.id];

  if (!images) {
    return (
      <Spinner
        style={{
          width: '100px',
          position: 'absolute',
          left: '50%',
          top: '50%',
          transform: 'translate(-50%, -50%)'
        }}
      />
    );
  } else if (images.length || files.length) {
    return (
      <Gallery images={images.map(({ original }) => original.split(',')[1])} gallery={files['Foto(s) Fahrzeug']} />
    );
  }

  return <img src={placeholderImg} alt='Vehicle car placeholder' />;
};
