import styled from 'styled-components';
import { Button, Flex } from 'common/common-components.styled';

export const StyledConfirmButton = styled(Button)`
  background-color: ${({ theme }) => theme.colors.emeraldGreen};
  width: 50%;
  max-width: 250px;
`;

export const StyledDeclineButton = styled(Button)`
  background-color: ${({ theme }) => theme.colors.pink};
  width: 50%;
  max-width: 250px;
`;

export const StyledFieldSection = styled.div`
  flex-basis: 50%;

  &:first-child {
    margin-right: 10px;
  }
`;

export const StyledCarPolicy = styled(Flex)`
  background-color: ${({ theme }) => theme.colors.extraLightBlue};
  border-radius: 10px;
  padding: 2px 10px 0;
  margin-right: 10px;
  margin-bottom: 10px;
  font-size: 12px;
`;

export const StyledStepsContainer = styled(Flex)`
  width: 75%;

  @media (min-width: ${({ theme }) => theme.media.tablet}) {
    width: 65%;
  }

  @media (min-width: ${({ theme }) => theme.media.desktop}) {
    width: 50%;
  }
`;

export const StyledStep = styled(Flex)`
  width: 220px;
  @media (max-width: ${({ theme }) => theme.media.desktop}) {
    width: 120px;
  }
  @media (max-width: ${({ theme }) => theme.media.tablet}) {
    width: 90px;
    p {
      font-size: 12px;
    }
  }
`;

export const StyledGalleryContainer = styled(Flex)`
  flex-direction: row;

  @media (max-width: ${({ theme }) => theme.media.tablet}) {
    flex-direction: column;
    & > div {
      width: 100%;
    }
  }
`;
