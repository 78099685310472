import { getLeasinggesellschaften } from 'services/company-service';
import { RequestStatuses } from '../../../constants';
import { getResponseFormData } from 'utils/get-response-data';
import { useQuery } from 'react-query';
import { LeasingCompany } from 'components/global-reference-vehicles/action-switcher/leasing-price-dialog/choose-leasing-company/choose-leasing-company.props';

export const useLeasingCompanies = (companyId?: string) => {
  const modifyResponse = ({ data }: any) => {
    if (data.level === RequestStatuses.Success) {
      return getResponseFormData(data, [{ name: null, type: 'array' }]) as LeasingCompany[];
    }
    return [];
  };

  const { data: leasingCompanies, isFetching: isLeasingCompaniesFetching } = useQuery(
    ['leasing-companies-global-reference', companyId],
    () => getLeasinggesellschaften(companyId),
    {
      select: modifyResponse,
      enabled: !!companyId
    }
  );

  return {
    leasingCompanies,
    isLeasingCompaniesFetching
  };
};
