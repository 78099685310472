import { useState } from 'react';

export const useSessionState = <T>(sessionKey: string, defaultValue: T) => {
  const getSessionState = () => {
    const sessionState = sessionStorage.getItem(sessionKey);
    return sessionState ? (JSON.parse(sessionState) as T) : defaultValue;
  };

  const [state, setState] = useState<T>(getSessionState());

  const setSessionState = (sessionState: T) => {
    sessionStorage.setItem(sessionKey, JSON.stringify(sessionState));
    setState(getSessionState());
  };

  return { state, setSessionState };
};
