import React from 'react';
import { EmissionImages, CO2Effizienz, RequestStatuses } from '../../../constants';
import classes from './environment-details-tab.module.scss';
import { Distance, Flex, Text } from '../../../common/common-components.styled';
import sharepointService from '../../../services/sharepoint-service';
import { useOverlay } from '../../../hooks';
import { setSnakbar } from '../../../actions/app_action';
import { useDispatch } from 'react-redux';
import useWindowSize from '../../../hooks/use-window-size';
import { useLocation } from 'react-router';
import { UNAUTHORIZED_PAGES } from '../../../common/enums';

const EnvironmentValue = ({ item }) => {
  return (
    <>
      <div className={classes.environment_value}>
        <div className={classes.environment_value_container}>
          <span>{item.title}</span>
        </div>
        <div className='mr-1' />
        <div className={classes.environment_value_container}>
          <p>{item.value || '-'}</p>
        </div>
      </div>
      {item.divider && <div className={classes.divider} />}
    </>
  );
};

export default function EnvironmentDetailsTab({ data }) {
  const dispatch = useDispatch();
  const location = useLocation();
  const [showOverlay, hideOverlay] = useOverlay();
  const isShowCO2Diagram = data.uds_co2_efficiencycode_value && data.uds_co2_efficiencycode_value !== 100000018;
  const { windowWidth } = useWindowSize();
  const isMobileWidth = windowWidth <= 600;
  const isExternalReleasePage = location.pathname.includes(UNAUTHORIZED_PAGES.RELEASE);

  const environmentDetailsItems = [
    {
      title: `Kraftstoffverbrauch ${
        data.uds_co2_efficiencycode_value
          ? data.uds_co2_efficiencycode_value === 100000018 // N/A
            ? '(WLTP KOMBINIERT)'
            : '(NEFZ KOMBINIERT)'
          : '(KOMBINIERT)'
      }`,
      value:
        data.uds_uds_fuelconsumption_combined && data.uds_co2_efficiencycode_value
          ? `${data.uds_uds_fuelconsumption_combined} l/100km`
          : null,
      divider: true
    },
    {
      title: 'Kalkulatorischer Verbrauch (INKL. AUFSCHLAG)',
      value: data.uds_calculated_fuelconsumption_combined
        ? `${data.uds_calculated_fuelconsumption_combined} l/100km`
        : null,
      divider: isMobileWidth || isShowCO2Diagram
    },
    {
      title: `CO2-Emission ${
        data.uds_co2_efficiencycode_value
          ? data.uds_co2_efficiencycode_value === 100000018
            ? '(WLTP KOMBINIERT)'
            : '(NEFZ KOMBINIERT)'
          : '(KOMBINIERT)'
      }`,
      value:
        data.uds_co2_emission_combined && data.uds_co2_efficiencycode_value
          ? `${data.uds_co2_emission_combined} g/km`
          : null,
      divider: true
    },
    {
      title: `CO2-Effizienz ${
        data.uds_co2_efficiencycode_value ? (data.uds_co2_efficiencycode_value !== 100000018 ? '(NEFZ)' : '') : ''
      }`,
      value:
        data.uds_co2_efficiencycode_value &&
        CO2Effizienz.find(CO2Item => CO2Item.id === String(data.uds_co2_efficiencycode_value)).label,
      divider: false
    }
  ];

  const downloadInfoFile = async () => {
    const descriptions = ['Verbrauch und Umwelt'];
    showOverlay();
    let response;
    try {
      response = await sharepointService.getFilesByDescriptions({
        entityId: data.uds_fahrzeugangeboteid,
        descriptions
      });
    } catch (e) {
      console.log(e);
    }

    if (response?.data.level === RequestStatuses.Success) {
      if (response.data.data.length) {
        let fileResponse;
        try {
          fileResponse = await sharepointService.downloadFileById(response.data.data[0].id);
        } catch (e) {
          console.log(e);
        }
        if (fileResponse?.data) {
          const file = new Blob([fileResponse.data], { type: 'application/pdf' });
          const fileURL = URL.createObjectURL(file);
          const pdfWindow = window.open();
          if (pdfWindow) {
            pdfWindow.location.href = fileURL;
          }
        }
      } else {
        dispatch(
          setSnakbar({
            isOpen: true,
            type: 'error',
            message: 'Datei nicht gefunden.'
          })
        );
      }
    } else {
      dispatch(
        setSnakbar({
          isOpen: true,
          type: 'error',
          message: 'Beim Laden ist ein Fehler aufgetreten.'
        })
      );
    }
    hideOverlay();
  };

  return (
    <div className={classes.main_container}>
      <div
        className={
          isShowCO2Diagram
            ? classes.environment_container
            : `${classes.environment_container} ${classes.environment_container_no_CO2_diagram}`
        }
      >
        <Flex width='100%' direction='column'>
          {environmentDetailsItems.slice(0, 2).map(detailsItem => (
            <EnvironmentValue key={detailsItem.title} item={detailsItem} />
          ))}
        </Flex>
        <Distance side='20' />
        <Flex width='100%' direction='column'>
          {environmentDetailsItems.slice(2, environmentDetailsItems.length).map(detailsItem => (
            <EnvironmentValue key={detailsItem.title} item={detailsItem} />
          ))}
        </Flex>
      </div>
      {isShowCO2Diagram && (
        <>
          <div className='mr-15' />
          <div className={classes.environment_container}>
            <Text color='grey600' bottom='15'>
              Auf der Grundlage der gemessenen CO2-Emissionen unter Berücksichtigung der Masse des Fahrzeugs emittelt.
            </Text>
            <div className={classes.environment_value_container}>
              <img
                className='img-responsive'
                src={`/assets/images/envkv/${EmissionImages[data.uds_co2_efficiencycode_value]}`}
                alt='CO2-Effizienz b'
              />
            </div>
            {!isExternalReleasePage && (
              <Text onClick={downloadInfoFile} color='blue' underline pointer top='15'>
                Informationen über Kraftstoffverbrauch, CO2-Emissionen und Stromverbrauch i.S.d. Pkw-EnVKV downloaden.
              </Text>
            )}
          </div>
        </>
      )}
    </div>
  );
}
