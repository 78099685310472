import {
  Control,
  FieldValues,
  RegisterOptions,
  UseFormClearErrors,
  UseFormGetValues,
  UseFormHandleSubmit,
  UseFormRegister,
  UseFormReset,
  UseFormResetField,
  UseFormSetError,
  UseFormSetFocus,
  UseFormSetValue,
  UseFormWatch
} from 'react-hook-form';
import { CrmDataTypes, FormFieldTypes, PAGES, SharePointTitle } from 'common/enums';
import { Moment } from 'moment';
import { FunctionComponentElement, MouseEvent, ReactElement, ReactNode } from 'react';
import { DropzoneOptions } from 'react-dropzone';
import { TextProps } from './common-components.styled';
import { ITextAreaField } from 'components/form-hook/form-field/components/text-area/text-area.props';
import { InfoHintProps } from 'components/info-hint/info-hint.props';
import { CalendarPickerView } from '@mui/x-date-pickers';
import { TimeType } from 'components/form-hook/form-field/components/time-field/time.props';
import { PickersDayProps } from '@mui/x-date-pickers/PickersDay';
import { AxiosResponse } from 'axios';

export interface User {
  id: string;
  name: string;
  companyId: string;
  isTestUser: boolean;
  companyName: string;
  roles: UserRole[];
  mainRole: UserRole;
  childCompanies: UserCompany[];
  appPages: RoutesAccessRights;
  avatar?: string;
  driversCheckFunction: {
    isManager: boolean;
    isController: boolean;
  };
}

export interface UserRoles {
  fleetManager: { id: 'b7baf7be-51cf-eb11-bacc-00224899ed4b'; name: 'Fuhrparkleiter' };
  driver: { id: 'e634ccea-6cce-eb11-bacc-00224899ed4b'; name: 'Fahrer' };
  administrator: { id: 'ce061f58-90ca-eb11-bacc-000d3ab79e9d'; name: 'Administrator' };
  dealer: { id: 'db1a68dd-51cf-eb11-bacc-00224899ed4b'; name: 'Händler' };
  leasingcompany: { id: 'a0ff03e5-51cf-eb11-bacc-00224899ed4b'; name: 'Leasingcompany' };
  apiDoc: { id: 'b0f726b1-4a5f-ec11-8f8f-000d3ab86b1b'; name: 'Apidoc' };
  attorney: { id: 'd4fc13b2-51cf-eb11-bacc-00224899ed4b'; name: 'Anwalt' };
  approver: { id: 'ad891dab-998d-ec11-b400-000d3ab41be3'; name: 'Fahrzeugbestellfreigeber' };
  mobexoEmployee: { id: '899cb0c4-0be7-ec11-bb3c-0022489c6bd1'; name: 'Mobexo employee' };
  poolManager: { id: 'ed31f7f8-51cf-eb11-bacc-00224899ed4b'; name: 'Poolverwalter' };
  insurance: { id: 'a9992fd1-51cf-eb11-bacc-00224899ed4b'; name: 'Versicherung' };
  workshop: { id: '30f215cb-51cf-eb11-bacc-00224899ed4b'; name: 'Werkstatt' };
  earlyUser: { id: 'f4addd63-f5f5-ec11-bb3d-0022489dfe95'; name: 'Early User' };
}

export type UserRole = UserRoles[keyof UserRoles];
export type UserRoleId = UserRole['id'];
export type UserRoleName = UserRole['name'];

export interface UserCompany {
  id: string;
  name: string;
}

export interface AnyObject {
  [name: string]: any;
}

export interface StringObject {
  [name: string]: string;
}

export interface BoolObject {
  [name: string]: boolean;
}

export interface LeasingOffer {
  new_mobexoangebotgesamtrate: string;
  active: boolean;
  referenceLeasingOffer: boolean;
  new_leasingbank: string;
  uds_laufzeit: number;
  uds_laufleistungpa: number;
  uds_gesamtkosten_monatlich_vertragsabschluss_value: number;
  new_leasingbank_id: string;
  uds_leasingangeboteid: string;
  uds_bezugid_id: string;
  new_mobexoangebotgesamtrate_value: number;
  uds_isausgewahlt: boolean;
  uds_calc_monthly_costs_incl_fuel_value: number;
  uds_leasingfaktor: number;
  uds_name: string;
}

export interface TableParams {
  pageNumber: number;
  countOnPage: number;
  sortParam: {
    [key: string]: string | number | boolean;
  };
  searchParam: AnyObject;
}

export interface ISort {
  predicate: string;
  reverse: boolean;
}

export interface TableRequest<TSearch = AnyObject> {
  pagination: { start: number; number: number };
  search: Partial<TSearch>;
  sort: Partial<ISort>;
}

export interface ResponseModel<T = AnyObject> {
  data: T;
  level: string;
  isSuccess: boolean;
  messages: AnyObject[];
}

export type ApiResponse<T = AnyObject> = AxiosResponse<ResponseModel<T>>;

export type TransformResponse<T> = {
  [P in keyof T]: T[P] extends { attributeValue: any } ? PickValue<T[P], 'attributeValue'> : never;
};

export interface CrmResponse<T = string> {
  attributeTypeCode: CrmDataTypes;
  attributeValue: T | null;
}

export interface CrmGuidResponse {
  attributeTypeCode: CrmDataTypes.UniqueIdentifier;
  attributeValue: string;
}

export interface LookupResponse<TLogicalName = string, TName = string> {
  id: string;
  logicalName: TLogicalName;
  name: TName;
}

export interface CrmLookupResponse<TLogicalName = string, TName = string>
  extends CrmResponse<LookupResponse<TLogicalName, TName>> {
  attributeTypeCode: CrmDataTypes.Lookup;
}

export interface CrmCustomerResponse<TLogicalName = string, TName = string>
  extends CrmResponse<LookupResponse<TLogicalName, TName>> {
  attributeTypeCode: CrmDataTypes.Customer;
}

export interface PicklistResponse<TLabel = string, TValue = number> {
  label: TLabel;
  value: TValue;
}

export interface CrmPicklistResponse<TLabel = string, TValue = number>
  extends CrmResponse<PicklistResponse<TLabel, TValue>> {
  attributeTypeCode: CrmDataTypes.Picklist;
}

export interface CrmMoneyResponse<TLabel = string, TValue = number>
  extends CrmResponse<PicklistResponse<TLabel, TValue>> {
  attributeTypeCode: CrmDataTypes.Money;
}

export interface CrmPicklistMultipleResponse<TLabel = string, TValue = number>
  extends CrmResponse<PicklistResponse<TLabel, TValue>[]> {
  attributeTypeCode: CrmDataTypes.PicklistMultiple;
}

export type CrmFieldResponse<T = string> = T | null;

export type FilterValue = CrmFieldResponse<{ value: string | null }>;

export interface ResponseFieldModel {
  attributeTypeCode: number;
  attributeValue: any;
}

export interface VehicleOfferEventDescription {
  uds_fahrzeugbestellungeneventdescriptionid: string;
  uds_hierarchie: number;
  uds_name: string;
  uds_statusfahrer: number;
  uds_statusfahzeugbestellfreigeber: number;
  uds_statusfuhrparkleiter: number;
  uds_statusfahrerkonfigurationen: number;
  webroles: EventDescriptionWebRoles[];
  eventsCount?: number;
}

export interface EventDescriptionWebRoles {
  uds_app_status_text: string;
  uds_app_status_text_when_done: string;
  uds_apppageid: string;
  uds_apppageid_id: string;
  uds_b2c_webrollid: string;
  uds_b2c_webrollid_id: string;
  uds_name: string;
  uds_vehicleordereventwebrollsid: string;
  uds_show_in_appcode_value: ShowInAppValues;
}

export enum ShowInAppValues {
  Always = 100000000, // immer
  Never = 100000001, // nie
  OnlyIfDone = 100000002 // nur wenn erledigt
}

export interface VehicleOfferEvent {
  id: string;
  label: string;
}

export interface FileModel {
  id: string;
  created: string;
  description: string;
  path: string;
  size: number;
  name: string;
}

export interface DataWithIdAndName {
  id: string;
  logicalName: string;
  name: string;
}

export type ValueOf<T> = T[keyof T];
export type PickValue<T, TKey extends keyof T> = ValueOf<Pick<T, TKey>>;

export interface IconProps {
  width?: string | number;
  height?: string | number;
  strokeWidth?: string | number;
  className?: string;
  color?: string;
  fill?: string;
  onClick?: (event: MouseEvent) => void;
  id?: string;
  hovered?: boolean;
}

type PrependNextNum<A extends Array<unknown>> = A['length'] extends infer T
  ? ((t: T, ...a: A) => void) extends (...x: infer X) => void
    ? X
    : never
  : never;

type EnumerateInternal<A extends Array<unknown>, N extends number> = {
  0: A;
  1: EnumerateInternal<PrependNextNum<A>, N>;
}[N extends A['length'] ? 0 : 1];

export type Enumerate<N extends number> = EnumerateInternal<[], N> extends (infer E)[] ? E : never;

export type Range<FROM extends number, TO extends number> = Exclude<Enumerate<TO>, Enumerate<FROM>>;

export interface FormFieldModel<TName = string> {
  name: TName;
  type: FormFieldTypes;
  label?: ReactNode;
  labelStyle?: TextProps;
  columnWidth?: string;
  disabled?: boolean;
  direction?: string;
  hidden?: boolean;
  multiple?: boolean;
  defaultValue?: string | null | boolean | number[] | TimeType | number | Moment;
  marks?: RangeFieldMark[];
  toggleLabels?: ToggleLabels;
  min?: number;
  max?: number;
  minDate?: Moment;
  maxDate?: Moment;
  step?: number;
  options?: FormFieldOption[];
  source?: string;
  validation: RegisterOptions<Record<string, string | undefined>>;
  alwaysShowAsRequiredField?: boolean;
  componentAboveOptions?: FunctionComponentElement<RenderComponentAboveOptionsProps>;
  emptyOptionsPlaceholder?: string;
  width?: string;
  placeholder?: string;
  rows?: number;
  tooltipText?: string;
  tip?: string;
  dropzoneOptions?: DropzoneOptions;
  className?: string;
  containerClassName?: string;
  formFieldContainerClassName?: string;
  prefix?: string;
  textAreaProps?: ITextAreaField;
  infoHint?: InfoHintProps;
  onInputChange?: (e: any, value: string) => void;
  optimizationForServer?: boolean;
  shouldDisableDate?: (day: any) => boolean;
  crmFieldType?: CrmDataTypes;
  calendarViews?: CalendarPickerView[];
  inputFormat?: string;
  keepDataFromField?: boolean;
  helperText?: string;
  renderDay?: (day: unknown, selectedDays: unknown[], pickersDayProps: PickersDayProps<unknown>) => JSX.Element;
  isLoading?: boolean;
  infoNote?: string;
  id?: string;
  mask?: string;
  hideError?: boolean;
  addNewItemLabel?: string;
  isCanBeAddedNewItem?: boolean;
  addNewItemCallback?: () => void;
  maxEditionFormLength?: number;
  radioButtonsInRow?: boolean;
  radioButtonsRowDistance?: string;
  radioButtonsOnChange?: (fieldName: string, value: string) => void;
  createNewItemFormField?: Omit<FormFieldModel<TName>, 'createNewItemFormField'>;
  showCreateNewItemFormCount?: number;
  height?: string;
  disablePast?: boolean;
  endAdormentIcon?: () => JSX.Element;
  marginLeft?: string;
  rightLabel?: ReactNode;
  decimalScale?: number;
  borderless?: boolean;
  limitTags?: number;
  valueMaxWidth?: string;
  dropDownAlwaysOnTop?: boolean;
}

export interface FormFieldOption {
  label: string;
  value: string | number;
  description?: string;
  disabled?: boolean;
  bottomComponent?: (params: { isChecked: boolean }) => ReactNode;
  isChecked?: boolean;
  hidden?: boolean;
  italic?: boolean;
  showEndAdornment?: boolean;
}

export type ToggleLabels = [ToggleLabelTrue, ToggleLabelFalse];

export interface ToggleLabelTrue {
  label: string;
  value: true;
}

export interface ToggleLabelFalse {
  label: string;
  value: false;
}

export interface RangeFieldMark {
  label: string;
  value: number;
}

export interface WindowSize {
  windowWidth: number;
  windowHeight: number;
  isMobile: boolean;
}

export interface BasketResponse {
  createdon: string;
  opportunityid: string;
}

export interface RequestDataModel {
  entityName: string;
  id: string | null;
  attributes: RequestDataModelAttributes;
  setNull?: boolean;
}

export interface RequestDataModelAttributes {
  [name: string]: {
    attributeTypeCode: number;
    attributeValue: AnyObject | null;
  };
}

export interface FormModel {
  name: string;
  crmFieldType: number;
}

export interface RenderComponentAboveOptionsProps {
  field?: FormFieldModel;
  value?: any;
  action?: () => void;
}

export type ResponseDataItemWithId = Array<DataWithIdAndName>;

export type ArgumentTypes<F extends Function> = F extends (...args: infer A) => any ? A : never;

export interface ISharePointItem {
  id: string;
  name: string;
  description: string;
  createdOn: string;
}

export type ISharePointRequest = {
  [key in SharePointTitle]?: ISharePointItem[];
};

export interface AppPage {
  uds_appageid: string;
  uds_name: string;
  uds_page_url: string;
}

export interface HistoryLocationState {
  carId?: string;
  carNumber?: string;
}

export interface IReactHookForm {
  register?: UseFormRegister<FieldValues>;
  watch?: UseFormWatch<FieldValues>;
  clearErrors?: UseFormClearErrors<FieldValues>;
  resetField?: UseFormResetField<FieldValues>;
  setValue?: UseFormSetValue<FieldValues>;
  setError?: UseFormSetError<FieldValues>;
  setFocus?: UseFormSetFocus<FieldValues>;
  reset?: UseFormReset<FieldValues>;
  handleSubmit?: UseFormHandleSubmit<FieldValues>;
  getValues?: UseFormGetValues<FieldValues>;
  formState?: any;
  control?: Control<FieldValues, AnyObject>;
}

interface IUrlTree {
  [key: string]: IUrlTreeNode;
}

export interface IUrlTreeNode {
  url: string;
  breadcrumbTitle: string;
  title: string | (() => string) | React.ComponentType;
  children?: IUrlTree;
  infoChip?: string;
}

export interface Route {
  path: PAGES;
  component: ReactElement;
  accessTo: UserRole[];
  redirectTo: PAGES;
}

export interface UserRoleResponse {
  uds_b2c_webroleid: UserRoleId;
  uds_name: string;
}

export interface RoutesAccessRights {
  [key: string]: UserRoleResponse[];
}

type PushParamsConfig<TState> = {
  pathname: PAGES;
  search?: string;
  state?: TState;
};

export type PushParamsType<TState = AnyObject> = PAGES | PushParamsConfig<TState> | string;

export interface PrefilterFormLocationState<TFormValues = AnyObject> {
  formValues: Partial<TFormValues>;
}

export interface UserPoolCarClassResponse {
  id: string;
  isChecked: boolean;
}

export type OpenPageProps<TCrmName extends string> = Record<TCrmName, string> & {
  page: PAGES;
  search?: string;
  filterForServer?: AnyObject;
  filterForClient?: AnyObject;
};
