import { AxiosRequestConfig } from 'axios';
import { CompanyRequestProps, GetChildCompaniesData, UpdateCompanyData } from './company.types';

class CompanyRequestConfig {
  getDriverReport = ({ pagination, searchParam, sortParam }: CompanyRequestProps): AxiosRequestConfig => ({
    method: 'POST',
    url: 'company/driverReport',
    responseType: 'blob',
    data: {
      pagination,
      searchParam,
      sortParam
    }
  });

  updateCompany = (data: UpdateCompanyData): AxiosRequestConfig => ({
    method: 'PUT',
    url: 'company/update',
    data
  });

  getChildCompanies = (data: GetChildCompaniesData): AxiosRequestConfig => ({
    method: 'post',
    url: 'company/GetChildCompanies',
    data
  });

  getAllCompany = (accountId: string): AxiosRequestConfig => ({
    method: 'POST',
    url: 'company/getAllCompany',
    data: {
      accountId
    }
  });

  getParentCompanyContactList = (): AxiosRequestConfig => ({
    method: 'POST',
    url: 'company/getParentCompanyContactList',
    data: {}
  });

  costCenterList: AxiosRequestConfig = {
    method: 'get',
    url: 'company/kostenstellelist'
  };
}

export const companyRequestConfig = new CompanyRequestConfig();
