import React from 'react';
import classes from './info-hint.module.scss';
import classNames from 'classnames';
import { Icon } from './components/icon';
import { InfoHintProps, InfoHintType } from './info-hint.props';

export default function InfoHint({
  children,
  className,
  type = InfoHintType.Warning,
  iconSize,
  iconColor
}: InfoHintProps) {
  return (
    <div
      className={classNames(
        classes.offer,
        className ? `${classes[`offer_${type}`]} ${className}` : classes[`offer_${type}`]
      )}
    >
      <span className={classNames('info-hint-icon', classes.icon)}>
        <Icon type={type} iconSize={iconSize} iconColor={iconColor} />
      </span>

      <div>
        <p>{children}</p>
      </div>
    </div>
  );
}
