import React, { useEffect, useState } from 'react';
import { Direction, Flex, Rotate, Text } from 'common/common-components.styled';
import { CalendarViewContainer, DayContainer, StyledSortButton } from './calendar-view-styled';
import { useTheme } from 'styled-components';
import { LeftArrowIcon } from 'components/icons/left-arrow-icon';
import moment, { Moment } from 'moment';
import CarRow from './car-row';
import BookingDetailsTooltip from './booking-details-tooltip';
import { useTypedSelector } from 'hooks/use-typed-selector';
import { isEmpty } from 'lodash';
import { RecordStatuses } from './bookings-page';
import { setPoolCalendarEventTooltip } from 'actions/app_action';
import { useDispatch } from 'react-redux';
import { ArrowIcon } from 'components/icons-new/arrow';
import { PoolCarWithBookings } from './hooks/react-query/use-pool-cars-with-bookings/pool-cars-with-bookings.types';
import usePoolCarSettingsData from 'components/unternehmen/pool-car-booking-settings/hooks/use-pool-car-settings-data';
import { User } from 'common/interfaces';
import { PoolCarSettingsFieldNames } from 'components/unternehmen/pool-car-booking-settings/pool-car-booking-settings.types';
import { objectUtility } from 'utils/object/object';
import { TIME_GAP_OPTIONS } from 'components/unternehmen/pool-car-booking-settings/form-fields/general-settings.form-fields';
moment.locale('de');

interface Props {
  records: PoolCarWithBookings[];
  refetch: () => void;
  startDate: Moment;
}

export type Event = {
  requester: string | undefined;
  requester_id: string | undefined;
  startDate: Moment;
  endDate: Moment;
  uds_status: string;
  uds_status_code: RecordStatuses;
  uds_poolkalenderid: string;
  overlap: boolean;
  nextEventOverlap: boolean;
  prevEventOverlap: boolean;
  left: number;
  width: number;
  top: number;
  height: number;
  uds_name: string;
  new_fuhrparkid: string;
  new_standortid?: string;
  new_standortid_id?: string;
  uds_kilometervoraussichtlich: number;
  car_name: string;
  car_model: string;
  isOwnBooking: boolean;
  isShowBooking: boolean;
  uds_comment: string;
};

type Sort = 'asc' | 'desc';

export type Car = {
  id: string;
  name: string | null;
  model: string | null;
  days: Moment[];
  events: Event[];
  isShowPlusButton: boolean | null;
};

const CalendarView = ({ records, refetch, startDate }: Props) => {
  const user = useTypedSelector<User>(state => state['app'].user);
  const theme = useTheme();
  const [days, setDays] = useState<Moment[]>([]);
  const [sort, setSort] = useState<Sort>('asc');
  const [carsList, setCarsList] = useState<Car[]>([]);
  const poolCalendarEventTooltip = useTypedSelector(state => state['app'].poolCalendarEventTooltip);
  const dispatch = useDispatch();

  const { poolCarSettingsData } = usePoolCarSettingsData(user.companyId);
  const timeGapBetweenBookingsInMinutes = poolCarSettingsData?.[PoolCarSettingsFieldNames.TimeGap]?.value
    ? objectUtility
        .entries(TIME_GAP_OPTIONS)
        .find(([key, value]) => value.value === poolCarSettingsData[PoolCarSettingsFieldNames.TimeGap]?.value)?.[0] ||
      60
    : 60;

  const getNext7Days = (day: Moment) => {
    const days: Moment[] = [] as Moment[];
    for (let i = 0; i < 7; i++) {
      if (i > 0) {
        days.push(moment(day).add(i, 'day'));
      } else {
        days.push(moment(day));
      }
    }
    setDays(days);
  };

  useEffect(() => {
    getNext7Days(startDate);
  }, [startDate]);

  const switchWeek = (next: boolean) => {
    dispatch(setPoolCalendarEventTooltip(null));
    getNext7Days(next ? moment(days[0]).add(1, 'week') : moment(days[0]).subtract(1, 'week'));
  };

  const getCars = () => {
    const cars = records.map(record => {
      const { new_fuhrparkid, new_model, new_hersteller, new_name, new_standortid, isShowPlusButton } = record.car;
      return {
        id: new_fuhrparkid,
        name: new_name,
        model: `${new_hersteller || ''} ${new_model || ''}`,
        events: record.bookings.map(booking => ({
          requester: booking.uds_requester?.name,
          requester_id: booking.uds_requester?.id,
          startDate: booking.uds_abholdatum ? moment(booking.uds_abholdatum) : null,
          endDate: booking.uds_ruckgabedatum ? moment(booking.uds_ruckgabedatum) : null,
          uds_status: booking.uds_status?.label,
          uds_status_code: booking.uds_status?.value,
          uds_poolkalenderid: booking.uds_poolkalenderid,
          uds_name: booking.uds_name,
          new_fuhrparkid: booking.uds_fuhrpark?.id,
          new_standortid: new_standortid?.name,
          new_standortid_id: new_standortid?.id,
          uds_kilometervoraussichtlich: booking.uds_kilometervoraussichtlich,
          car_name: new_name,
          car_model: new_model,
          isShowBooking: booking.isShowBooking,
          isOwnBooking: booking.isOwnBooking,
          uds_comment: booking.uds_comment
        })) as unknown as Event[],
        days,
        isShowPlusButton
      };
    });

    const sortedCars = cars.sort((a, b) =>
      sort === 'asc' ? (a.name || '').localeCompare(b.name || '') : (b.name || '').localeCompare(a.name || '')
    );

    setCarsList(sortedCars);
  };

  useEffect(() => {
    getCars();
  }, [days, records, sort]);

  const sortHandler = () => {
    setSort(prevState => (prevState === 'asc' ? 'desc' : 'asc'));
  };

  return (
    <CalendarViewContainer>
      <Flex width='100%' height='70px'>
        <Flex direction='column' width='17%' right='10' justify='center'>
          <Flex justify='flex-end' align='center'>
            <Flex padding='15px 5px 15px 15px' pointer onClick={() => switchWeek(false)}>
              <LeftArrowIcon width='10' height='20' color={theme.colors.darkBlue} />
            </Flex>
          </Flex>
        </Flex>

        <Flex width='80%' justify='space-between'>
          {days.map((day: Moment, index) => (
            <DayContainer key={index} isCalendarTool>
              <Text size='16'>{moment(day).format('ddd')}</Text>
              <Text size='16'>{moment(day).format('DD MMM YYYY')}</Text>
            </DayContainer>
          ))}
        </Flex>
        <Flex justify='flex-start' align='center' width='2%'>
          <Flex padding='15px' pointer onClick={() => switchWeek(true)} style={{ transform: 'rotate(180deg)' }}>
            <LeftArrowIcon width='10' height='20' color={theme.colors.darkBlue} />
          </Flex>
        </Flex>
      </Flex>

      <StyledSortButton onClick={sortHandler} pointer align='center' position='absolute'>
        <Text color='grey500' right='5'>
          Fahrzeug
        </Text>
        <Rotate rotateDirection={sort === 'desc' ? Direction.Top : Direction.Bottom}>
          <ArrowIcon height={12} color={theme.colors.grey500} />
        </Rotate>
      </StyledSortButton>

      {carsList.map((car: Car, index) => (
        <CarRow
          key={car.id}
          car={car}
          isOddCarInList={index % 2 !== 0}
          refetch={refetch}
          indexOfRow={index}
          timeGap={timeGapBetweenBookingsInMinutes}
        />
      ))}

      <BookingDetailsTooltip
        showTooltip={!isEmpty(poolCalendarEventTooltip)}
        event={poolCalendarEventTooltip}
        refetch={refetch}
      />
    </CalendarViewContainer>
  );
};

export default CalendarView;
