import React, { useCallback, useMemo } from 'react';
import {
  StyledCheckout,
  StyledCheckoutHeader,
  StyledHeaderLeftContainer,
  StyledCardsContainer
} from './checkout-components.styled';
import { Flex, Text } from 'common/common-components.styled';
import CheckoutCard from 'components/checkout-card/checkout-card';
import { useHistory } from 'react-router-dom';
import { CheckoutPopupProps } from './checkout-component.props';
import { PAGES } from 'common/enums';
import cloneDeep from 'lodash/cloneDeep';

const getItemPrice = (price: number | null, discount: number | null) => {
  if (price === null && discount !== null) {
    return discount;
  } else return price;
};

const isDiscount = (price: number | null, discount: number | null) => {
  return price !== null && discount !== null;
};

const Checkout = ({ parameters }: CheckoutPopupProps) => {
  const history = useHistory();

  const handleButtonClick = useCallback(
    (packageName: string, period: string, isCreateOfferProcess?: boolean) => {
      if (packageName === 'Enterprise') {
        window.location.href = 'mailto:sarah.adrian@mobexo.de?subject=Anfrage Enterprise Fuhrparkmanagement';
        return;
      }
      if (!isCreateOfferProcess) {
        history.push(`${PAGES.PACKAGE_PAGE}?name=${packageName}&period=${period}`);
        parameters.closePopup && parameters.closePopup();
      } else {
        history.push(`${PAGES.CREATE_OFFER_PROCESS}?name=${packageName}&period=${period}`);
      }
    },
    [parameters, history]
  );

  const { extraOptionsMinHeight, packageOptionsMinHeight } = useMemo(() => {
    const packagesData = cloneDeep(parameters.packagesData);

    const longestExtraOptionsItem = packagesData.sort((a, b) => b.extraoptions.length - a.extraoptions.length)[0];
    const extraOptionsMinHeight = `${(longestExtraOptionsItem?.extraoptions.length || 0) * 55 + 40}px`;

    const longestPackageOptionsItem = packagesData.sort(
      (a, b) =>
        (b.packageoptions?.mainOptions || []).flatMap(mainOption =>
          mainOption.childOptions ? [mainOption, ...mainOption.childOptions] : mainOption
        ).length -
        (a.packageoptions?.mainOptions || []).flatMap(mainOption =>
          mainOption.childOptions ? [mainOption, ...mainOption.childOptions] : mainOption
        ).length
    )[0];
    const packageOptionsMinHeight = `${
      (longestPackageOptionsItem?.packageoptions?.mainOptions?.flatMap(mainOption =>
        mainOption.childOptions ? [mainOption, ...mainOption.childOptions] : mainOption
      ).length || 0) *
        45 +
      40
    }px`;

    return { extraOptionsMinHeight, packageOptionsMinHeight };
  }, [parameters.packagesData]);

  return (
    <StyledCheckout>
      <StyledCheckoutHeader>
        <StyledHeaderLeftContainer>
          <Flex maxWidth='350px' top='8'>
            <Text color='blue' size='14' bold>
              {parameters.description}
            </Text>
          </Flex>
        </StyledHeaderLeftContainer>
      </StyledCheckoutHeader>
      <StyledCardsContainer>
        {parameters.packagesData.map((item, index) => (
          <CheckoutCard
            key={index}
            isHeader={!!item.header}
            header={item.header}
            packageName={item.name}
            packageDescription={item.body}
            packageOptions={item.packageoptions}
            packageOptionsMinHeight={packageOptionsMinHeight}
            extraOptions={item.extraoptions}
            extraOptionsMinHeight={extraOptionsMinHeight}
            price={getItemPrice(item.price, item.pricewithdiscount)}
            isDiscount={isDiscount(item.price, item.pricewithdiscount)}
            priceWithDiscount={item.pricewithdiscount}
            pricingDetails={item.priceperiod}
            fee={item.setupfeetext}
            buttonText={parameters.isCreateOfferProcess ? 'angebot erstellen' : item.buttonlabel}
            onClick={() => handleButtonClick(item.name, item.priceperiod, parameters.isCreateOfferProcess)}
            isBenefitsExist={parameters.isBenefitsExist}
            width={100 / parameters.packagesData.length}
          />
        ))}
      </StyledCardsContainer>
    </StyledCheckout>
  );
};

export default Checkout;
