import React from 'react';
import { IconProps } from 'common/interfaces';

export const ArrowIconRight = ({ width = '26', height = '22', color = '#102F3F', className }: IconProps) => {
  return (
    // eslint-disable-next-line
    <svg width={width} height={height} viewBox='0 0 26 22' fill='none' className={className} xmlns='http://www.w3.org/2000/svg'>
      <mask id='path-1-inside-1_755_17866' fill='white'>
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          // eslint-disable-next-line
          d='M24.8303 11.3771C25.0592 11.1778 25.0592 10.8223 24.8303 10.623L13.701 0.93019C13.3774 0.648417 12.8726 0.878205 12.8726 1.30724L12.8726 5.94868L1.49779 5.94868C1.22165 5.94868 0.997793 6.17253 0.997793 6.44868L0.997792 15.5511C0.997792 15.8272 1.22165 16.0511 1.49779 16.0511L12.8726 16.0511L12.8726 20.6929C12.8726 21.1219 13.3774 21.3517 13.701 21.0699L24.8303 11.3771Z'
        />
      </mask>
      <path
        // eslint-disable-next-line
        d='M24.8303 10.623L23.8452 11.7542L23.8452 11.7542L24.8303 10.623ZM24.8303 11.3771L23.8452 10.246L23.8452 10.246L24.8303 11.3771ZM13.701 0.93019L14.6861 -0.200957L14.6861 -0.200957L13.701 0.93019ZM12.8726 5.94868L12.8726 7.44868L14.3726 7.44868L14.3726 5.94868L12.8726 5.94868ZM1.49779 5.94868L1.49779 4.44868L1.49779 5.94868ZM1.49779 16.0511L1.49779 14.5511L1.49779 16.0511ZM12.8726 16.0511L14.3726 16.0511L14.3726 14.5511L12.8726 14.5511L12.8726 16.0511ZM13.701 21.0699L14.6861 22.2011L14.6861 22.2011L13.701 21.0699ZM23.8452 11.7542C23.3875 11.3556 23.3875 10.6445 23.8452 10.246L25.8155 12.5083C26.7308 11.7111 26.7308 10.289 25.8155 9.49186L23.8452 11.7542ZM12.7158 2.06134L23.8452 11.7542L25.8155 9.49186L14.6861 -0.200957L12.7158 2.06134ZM14.3726 1.30724C14.3726 2.16531 13.3629 2.62488 12.7158 2.06134L14.6861 -0.200957C13.392 -1.32804 11.3726 -0.408902 11.3726 1.30724L14.3726 1.30724ZM14.3726 5.94868L14.3726 1.30724L11.3726 1.30724L11.3726 5.94868L14.3726 5.94868ZM1.49779 7.44868L12.8726 7.44868L12.8726 4.44868L1.49779 4.44868L1.49779 7.44868ZM2.49779 6.44868C2.49779 7.00096 2.05008 7.44868 1.49779 7.44868L1.49779 4.44868C0.393224 4.44868 -0.502207 5.34411 -0.502207 6.44868L2.49779 6.44868ZM2.49779 15.5511L2.49779 6.44868L-0.502207 6.44868L-0.502208 15.5511L2.49779 15.5511ZM1.49779 14.5511C2.05008 14.5511 2.49779 14.9988 2.49779 15.5511L-0.502208 15.5511C-0.502208 16.6556 0.393223 17.5511 1.49779 17.5511L1.49779 14.5511ZM12.8726 14.5511L1.49779 14.5511L1.49779 17.5511L12.8726 17.5511L12.8726 14.5511ZM14.3726 20.6929L14.3726 16.0511L11.3726 16.0511L11.3726 20.6929L14.3726 20.6929ZM12.7158 19.9388C13.3629 19.3752 14.3726 19.8348 14.3726 20.6929L11.3726 20.6929C11.3726 22.409 13.392 23.3282 14.6861 22.2011L12.7158 19.9388ZM23.8452 10.246L12.7158 19.9388L14.6861 22.2011L25.8155 12.5083L23.8452 10.246Z'
        fill={color}
        mask='url(#path-1-inside-1_755_17866)'
      />
    </svg>
  );
};
