import React, { useEffect, useState } from 'react';
import classes from './upload-file.module.scss';
import { useDropzone } from 'react-dropzone';
import PropTypes from 'prop-types';
import { getIcon } from '../../../download-documents-component/download-documents-component';
import { DeleteIcon } from '../../../icons/delete-icon';
import { CircleQuestionMarkIcon } from '../../../icons/circle-question-mark';
import { StyledDownload } from '../../../../pages/details/order-widget/order-widget.styled';
import { PrefixForId } from 'common/enums';
import { Flex } from '../../../../common/common-components.styled';
import { StyledText, StyledLink, StyledUploadContainer } from './upload-file.styled';
import { UploadIcon } from 'components/icons/upload_image_icon';
import { UploadedImageIcon } from 'components/icons/uploaded_image_icon';
import { useTheme } from 'styled-components';
import useIsFirstRender from '../../../../hooks/use-is-first-render';
import { removeSpaces } from '../../../../utils/remove-spaces';

export const FileComponent = ({ file, onRemove, onDownload }) => {
  const filePathArr = file.path.split('/');
  const fileName = filePathArr[filePathArr.length - 1];

  return (
    <div className={classes.file} key={file.path}>
      <div id={'UploadedFile' + removeSpaces(fileName) + 'InfoContainer'} className={classes.icon_container}>
        <div className={classes.icon}>{getIcon(fileName)}</div>
        <div className={classes.description}>
          <span id={'UploadedFile' + removeSpaces(fileName) + 'FileName'} className={classes.file_name}>
            {fileName}
          </span>
          <span id={'UploadedFile' + removeSpaces(fileName) + 'FileSize'} className={classes.file_size}>{`${(
            file.size / 1024
          ).toFixed(1)} KB`}</span>
        </div>
      </div>

      <Flex align='center'>
        {onRemove && (
          <div id={'UploadedFile' + removeSpaces(fileName) + 'RemoveButton'} onClick={onRemove}>
            <DeleteIcon className={`pointer ${classes.icon}`} color={'#102F3F'} />
          </div>
        )}

        {onDownload && (
          <div onClick={onDownload}>
            <StyledDownload />
          </div>
        )}
      </Flex>
    </div>
  );
};

FileComponent.propTypes = {
  file: PropTypes.object.isRequired,
  onRemove: PropTypes.func,
  onDownload: PropTypes.func
};

function UploadFile({
  accept,
  multiple,
  className,
  onDropAccepted,
  acceptString,
  clearAll,
  maxSizeString,
  maxSize = Infinity,
  placeholder = 'Datei hier hinziehen oder',
  required = false,
  unfallreparatur,
  defaultFiles = [],
  maxFiles = 0,
  id = '',
  description = '',
  disabled = false,
  showFiles = true,
  showDropArea = true,
  externalFiles,
  condition,
  onUpload,
  onRemove,
  shouldResetFiles = false,
  acceptFilesWithoutExtension
}) {
  const [files, setFiles] = useState(defaultFiles);
  const isFirst = useIsFirstRender();
  const [showAcceptedFormats, setShowAcceptedFormats] = React.useState(false);
  const [rejectedFiles, setRejectedFiles] = useState([]);
  const theme = useTheme();

  useEffect(() => {
    shouldResetFiles && setFiles(defaultFiles);
  }, [defaultFiles.length]);

  const onDropRejected = React.useCallback(rejected => {
    if (acceptFilesWithoutExtension && rejected.some(rejectedFile => !rejectedFile.file.type)) {
      const filesWithoutExtension = rejected
        .filter(rejectedFile => !rejectedFile.file.type)
        .map(rejectedFile => rejectedFile.file);
      setFiles(filesWithoutExtension);
    }

    setRejectedFiles(rejected);
  });

  useEffect(() => {
    if (rejectedFiles.length > 0) {
      const timer = setTimeout(() => {
        setRejectedFiles([]);
      }, 10000);
      return () => clearTimeout(timer);
    }
  }, [rejectedFiles]);

  const onDrop = React.useCallback(
    acceptedFiles => {
      let newFiles = [
        ...files,
        ...acceptedFiles.filter(
          acceptedFile => !files.some(file => file.path === acceptedFile.path && file.size === acceptedFile.size)
        )
      ];
      if (!multiple) {
        newFiles = acceptedFiles[0] ? acceptedFiles : newFiles;
      }
      const conditionMet = !condition || (condition && condition(newFiles));
      conditionMet && setFiles(newFiles);
      setRejectedFiles([]);
    },
    [files]
  );

  let { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept,
    multiple,
    onDrop,
    maxSize,
    maxFiles,
    disabled: disabled,
    onDropRejected
  });

  useEffect(() => {
    if (typeof onDropAccepted === 'function') {
      onDropAccepted(files);
    }
    if (!isFirst && onUpload) {
      onUpload(files);
    }
  }, [files]);

  useEffect(() => {
    if (clearAll) {
      setFiles([]);
    }
  }, [clearAll]);

  const handleRemoveFile = React.useCallback(filePath => {
    let newFiles = [...files];
    newFiles = newFiles.filter(file => file.path !== filePath);
    setFiles(newFiles);
    if (onRemove) {
      onRemove(newFiles);
    }
  });

  const activeStyle = {
    backgroundImage:
      // eslint-disable-next-line
      "url(\"data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='lightblue' stroke-width='3' stroke-dasharray='10%2c 6' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e\")"
  };

  return (
    <>
      {showDropArea && (
        <>
          <StyledUploadContainer
            //style={isDragActive && !disabled ? activeStyle : null}
            id={PrefixForId.FormUpload + id}
            {...getRootProps()}
            className={className}
            isDisabled={disabled}
          >
            {files.length ? (
              <UploadedImageIcon color={disabled ? theme.colors.grey200 : undefined} />
            ) : (
              <UploadIcon color={disabled ? theme.colors.grey200 : undefined} />
            )}
            <StyledText isDisabled={disabled}>{placeholder}</StyledText>
            <StyledLink isDisabled={disabled} isUnfallreparatur={unfallreparatur}>
              Datei hochladen
            </StyledLink>
            <input id='file' {...getInputProps()} />
          </StyledUploadContainer>
          <div className={classes.upload_link_container}>
            <div className={classes.hint_container}>
              {acceptString && !unfallreparatur && (
                <span
                  onMouseEnter={() => setShowAcceptedFormats(true)}
                  onMouseLeave={() => setShowAcceptedFormats(false)}
                  className={classes.accepted_formats}
                >
                  {`${required ? '*' : ''} Zulässige Formate`}
                  <div className='mr-1' />
                  <CircleQuestionMarkIcon color={'#777777'} />
                  {showAcceptedFormats && !description && (
                    <span className={classes.hint}>
                      Hier kannst du&nbsp;
                      <span className={classes.number_of_files}>{multiple ? 'zu 5 Dateien' : 'eine Datei'}</span>&nbsp;
                      mit dem Format&nbsp;<span className={classes.accept_string}>{acceptString}</span>&nbsp; mit einer
                      maximalen Dateigröße von 10 MB hochladen.
                    </span>
                  )}

                  {showAcceptedFormats && description && <span className={classes.hint}>{description}</span>}
                </span>
              )}
              {rejectedFiles.length > 0 && !!maxSizeString && !!unfallreparatur && (
                <div className={classes.error_message}>
                  <img src='/assets/images/err-icon.svg' />
                  <p>{`Die maximale Dateigröße beträgt ${maxSizeString}.`}</p>
                </div>
              )}
              {!!maxSizeString && !!maxFiles && !unfallreparatur && (
                <StyledText
                  isDisabled={disabled}
                >{`Bis zu ${maxFiles} Dokumente mit einer maximalen Dateigröße von ${maxSizeString}`}</StyledText>
              )}
              {acceptString && unfallreparatur && (
                <StyledText isDisabled={disabled}>Zulässige Formate: {acceptString}</StyledText>
              )}
              {maxSizeString && !maxFiles && <StyledText isDisabled={disabled}>{`Max. ${maxSizeString}.`}</StyledText>}
            </div>
          </div>
        </>
      )}
      {showFiles && (
        <div className={classes.files}>
          {files.map(file => (
            <FileComponent
              key={file.path}
              file={file}
              onRemove={showDropArea ? () => handleRemoveFile(file.path) : undefined}
            />
          ))}
        </div>
      )}
    </>
  );
}

UploadFile.propTypes = {
  accept: PropTypes.arrayOf(PropTypes.string).isRequired,
  acceptString: PropTypes.string,
  multiple: PropTypes.bool.isRequired,
  onDropAccepted: PropTypes.func,
  className: PropTypes.string,
  clearAll: PropTypes.number,
  required: PropTypes.bool,
  unfallreparatur: PropTypes.bool,
  maxSizeString: PropTypes.string,
  defaultFiles: PropTypes.array,
  placeholder: PropTypes.string,
  maxSize: PropTypes.number,
  maxFiles: PropTypes.number,
  id: PropTypes.string,
  disabled: PropTypes.bool,
  showFiles: PropTypes.bool,
  showDropArea: PropTypes.bool,
  externalFiles: PropTypes.array,
  condition: PropTypes.func,
  onUpload: PropTypes.func,
  onRemove: PropTypes.func,
  shouldResetFiles: PropTypes.bool,
  acceptFilesWithoutExtension: PropTypes.bool
};

export default UploadFile;
