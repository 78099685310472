import { User, UserRole } from 'common/interfaces';
import { MainMenuItem, SubMenuItem } from 'components/side-menu/side-menu-interfaces';
import { USER_ROLES } from '../../constants';

type MenuItem = Partial<Pick<MainMenuItem, 'roles' | 'links' | 'subMenuItems'> & Pick<SubMenuItem, 'external'>>;

const fillMenuItemsWithRoles = <T extends MenuItem>(menuItems: T[], user: User) => {
  menuItems.forEach(menuItem => {
    if (menuItem.roles && menuItem.links) {
      let roles: UserRole[] = [];
      menuItem.links.forEach(link => {
        if (link in user.appPages) {
          roles = [
            ...roles,
            ...user.appPages[link].map(userRoleResponse =>
              Object.values(USER_ROLES).find(userRole => userRole.id === userRoleResponse?.uds_b2c_webroleid)
            )
          ];
        }
      });

      menuItem.roles = Array.from(new Set(roles));
    }
    if (menuItem.subMenuItems) {
      menuItem.subMenuItems = fillMenuItemsWithRoles(menuItem.subMenuItems, user);
    }
  });

  return menuItems;
};

export default fillMenuItemsWithRoles;
