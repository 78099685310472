import { FormFieldTypes } from 'common/enums';
import { FormFieldModel } from 'common/interfaces';
import { insuranceTypes } from '../../../../../../../../../constants';
import { errorMessages } from 'utils/error-messages';
import { regexValue } from 'utils/regex';
import { generateFormField } from 'utils/form/generate-form-fields/generate-form-fields';

export const gebuchtFormFields: FormFieldModel[] = [
  {
    name: 'new_gebucht',
    type: FormFieldTypes.Toggle,
    defaultValue: false,
    validation: {}
  }
];

export const inkludiertFormFields: FormFieldModel[] = [
  {
    name: 'new_inkludiert_leasing',
    type: FormFieldTypes.Toggle,
    defaultValue: false,
    validation: {}
  }
];

export const insuranceTypeFormFields: FormFieldModel[] = [
  {
    label: 'Versicherungsart',
    name: 'uds_versicherungsartcode',
    type: FormFieldTypes.OptionSet,
    placeholder: 'Bitte auswählen',
    defaultValue: '',
    options: insuranceTypes,
    validation: {}
  }
];

export const dienstleisterFormFields: FormFieldModel[] = [
  {
    name: 'uds_leasingbank',
    label: 'Dienstleister',
    type: FormFieldTypes.Lookup,
    placeholder: 'Dienstleister',
    defaultValue: '',
    options: [],
    source: 'getDienstleistungen',
    validation: {
      required: errorMessages.required
    }
  }
];

export const betragFormFields: FormFieldModel[] = [
  generateFormField.money({
    name: 'new_betrag',
    placeholder: 'Betrag',
    validation: {
      required: errorMessages.required
    }
  })
];

export const zahlungsrythmusFormFields: FormFieldModel[] = [
  {
    name: 'new_zahlungsrythmus',
    type: FormFieldTypes.OptionSet,
    placeholder: 'Bitte auswählen',
    defaultValue: '',
    options: [
      { value: '100000000', label: 'monatlich' },
      { value: '100000001', label: 'einmalig' },
      { value: '100000002', label: 'jährlich' },
      { value: '100000003', label: 'vierteljährlich' },
      { value: '100000004', label: 'halbjährlich' }
    ],
    validation: {
      required: errorMessages.required
    }
  }
];

export const laufleistungFormFields: FormFieldModel[] = [
  {
    name: 'uds_laufleistung',
    type: FormFieldTypes.Number,
    placeholder: 'Laufleistung',
    defaultValue: '',
    validation: { max: 2147483647, pattern: { value: regexValue.onlyInteger, message: errorMessages.onlyInteger } }
  }
];

export const laufleistungPaFormFields: FormFieldModel[] = [
  {
    name: 'uds_laufleistungpa',
    type: FormFieldTypes.Number,
    placeholder: 'Laufleistung p. a.',
    defaultValue: '',
    validation: { max: 2147483647, pattern: { value: regexValue.onlyInteger, message: errorMessages.onlyInteger } }
  }
];

export const leasingdauerFormFields: FormFieldModel[] = [
  {
    name: 'uds_leasingdauer',
    type: FormFieldTypes.Number,
    placeholder: 'Leasingdauer',
    defaultValue: '',
    validation: { max: 100000, pattern: { value: regexValue.onlyInteger, message: errorMessages.onlyInteger } }
    //valueChangedEvent: true
  }
];

export const leasingfaktorFormFields: FormFieldModel[] = [
  {
    name: 'uds_leasingfaktor',
    type: FormFieldTypes.Number,
    placeholder: 'Leasingfaktor',
    defaultValue: '',
    validation: { max: 1000000000, pattern: { value: regexValue.onlyNumbers, message: errorMessages.number } }
  }
];

export const mehrkilometerFormFields: FormFieldModel[] = [
  {
    name: 'uds_mehrkilometer',
    type: FormFieldTypes.Number,
    placeholder: 'Mehrkilometer',
    defaultValue: '',
    validation: { max: 1000000000, pattern: { value: regexValue.onlyNumbers, message: errorMessages.number } }
  }
];

export const minderkilometerFormFields: FormFieldModel[] = [
  {
    name: 'uds_minderkilometer',
    type: FormFieldTypes.Number,
    placeholder: 'Minderkilometer',
    defaultValue: '',
    validation: { max: 1000000000, pattern: { value: regexValue.onlyNumbers, message: errorMessages.number } }
  }
];

export const toleranzgrenzeFormFields: FormFieldModel[] = [
  {
    name: 'uds_toleranzgrenze',
    type: FormFieldTypes.Number,
    placeholder: 'Toleranzgrenze +/- KM',
    defaultValue: '',
    validation: { max: 100000000000 }
  }
];

export const validFromFormFields: FormFieldModel[] = [
  {
    name: 'uds_gultigkeitvon',
    type: FormFieldTypes.Date,
    defaultValue: '',
    validation: {}
  }
];

export const validToFormFields: FormFieldModel[] = [
  {
    name: 'uds_gultigkeitbis',
    type: FormFieldTypes.Date,
    defaultValue: '',
    validation: {}
  }
];

export const vertragsNrFormFields: FormFieldModel[] = [
  {
    name: 'uds_vertragnr',
    label: 'Vertrags-Nr.',
    type: FormFieldTypes.Text,
    placeholder: 'Vertrags-Nr.',
    defaultValue: '',
    validation: { maxLength: { value: 100, message: errorMessages.getMaxTextLength(100) } }
  }
];

export const leasingstartFormFields: FormFieldModel[] = [
  {
    name: 'uds_gultigkeitvon',
    type: FormFieldTypes.Date,
    defaultValue: '',
    validation: {}
  }
];

export const leasingendFormFields: FormFieldModel[] = [
  {
    name: 'uds_gultigkeitbis',
    type: FormFieldTypes.Date,
    defaultValue: '',
    validation: {}
  }
];

export const selbstbeteiligungTeilkaskoFormFields: FormFieldModel[] = [
  {
    name: 'uds_selbstbeteiligung_teilkasko',
    type: FormFieldTypes.Money,
    placeholder: 'Selbstbeteiligung Teilkasko',
    defaultValue: '',
    validation: { min: { value: 0, message: errorMessages.number } }
  }
];

export const selbstbeteiligungVollkaskoFormFields: FormFieldModel[] = [
  {
    name: 'uds_selbstbeteiligung_vollkasko',
    type: FormFieldTypes.Money,
    placeholder: 'Selbstbeteiligung Vollkasko',
    defaultValue: '',
    validation: { min: { value: 0, message: errorMessages.number } }
  }
];

export const leasingendExtendedFormFields: FormFieldModel[] = [
  {
    name: 'uds_leaseenddate_extended_leasingvertraege',
    type: FormFieldTypes.Date,
    defaultValue: '',
    validation: {}
  }
];

export const workshopFormFields: FormFieldModel[] = [
  {
    name: 'uds_workshop_bindingcode',
    type: FormFieldTypes.OptionSet,
    options: [
      { value: 1, label: 'Ja' },
      { value: 2, label: 'Nein' }
    ],
    placeholder: 'Bitte auswählen',
    defaultValue: '',
    validation: {}
  }
];
