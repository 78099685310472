/* eslint-disable linebreak-style */
import React from 'react';
import ProgressBar from 'react-customizable-progressbar';
import classes from './data-quality-chart.module.scss';
import { DataQualityChartProps } from './data-quality-chart.props';
import classNames from 'classnames';

const DataQualityChart = ({ children, diagramIcon, diagramSetup }: DataQualityChartProps) => {
  return (
    <div>
      <ProgressBar
        {...diagramSetup}
        progress={diagramSetup.progress ? diagramSetup.progress : 0.1}
        className={classNames(classes.progress_bar, classes.progress_bar_outer_shadow)}
      >
        <div className={classes.progress_bar_icon}>{diagramIcon}</div>
      </ProgressBar>

      {children}
    </div>
  );
};

export default DataQualityChart;
