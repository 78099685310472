import { useFetch } from 'hooks/use-fetch/use-fetch';
import { ApiResponse, ResponseModel } from 'common/interfaces';
import { useQuery } from 'react-query';
import { ReactQueryKeys } from 'common/enums';
import { RequestStatuses } from '../../../../constants';
import { vehicleRequestConfig } from 'request-config/vehicle/vehicle.request-config';
import { ReleaseData, ReleaseDataResponse } from './use-release-data.types';
import { useQuery as useQueryLocation } from 'hooks';

const modifyResponse = ({ data }: ApiResponse<ReleaseDataResponse>) => {
  if (data.level === RequestStatuses.Success) {
    const result: ReleaseData = {
      status: data.data.status,
      stepperData: data.data.stepperData,
      equipment: data.data.fahrzeugangeboteData?.ausstattungs.map(ausstattung => ({
        ...ausstattung.attributes,
        id: ausstattung.id
      })),
      fotoData: data.data.fotoData,
      email: data.data.email,
      driverData: data.data.driverData
    };
    if (data.data.fahrzeugangeboteData) {
      result.vehicleOfferData = {
        ...data.data.fahrzeugangeboteData.fahrzeugangebote.attributes,
        uds_fahrzeugangeboteid: data.data.fahrzeugangeboteData.fahrzeugangebote.id,
        uds_co2_efficiencycode_value:
          data.data.fahrzeugangeboteData.fahrzeugangebote.attributes.uds_co2_efficiencycode?.value || null
      };
      result.globalVehicleOfferData = {
        ...data.data.fahrzeugangeboteData.globalFahrzeugangebote.attributes
      };
      result.carPolicies = data.data.fahrzeugangeboteData.carPolicies.map(carPolicy => ({
        ...carPolicy.attributes,
        id: carPolicy.id
      }));
    }

    return result;
  }
  return {};
};

const useReleaseData = () => {
  const query = useQueryLocation();
  const queryId = query.id as string;
  const { fetch } = useFetch<ResponseModel<ReleaseDataResponse>>({
    loadingKey: 'getExternalSigningData',
    ...vehicleRequestConfig.getExternalSigningData(queryId || '')
  });

  const { data: releaseData, isLoading: isReleaseDataLoading } = useQuery<
    ApiResponse<ReleaseDataResponse>,
    unknown,
    ReleaseData
  >([ReactQueryKeys.GetExternalSigningData, queryId], () => fetch(), {
    enabled: !!queryId,
    select: modifyResponse
  });

  return { ...(releaseData || {}), isReleaseDataLoading };
};

export default useReleaseData;
