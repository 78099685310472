import React from 'react';
import {
  PrimaryEntityRedirect,
  PrimaryEntitySecondModalData,
  PrimaryEntitySecondModalProps
} from './primary-entity-second-modal.props';
import { Button, Flex, Text } from 'common/common-components.styled';
import { PAGES } from 'common/enums';
import { useHistory } from 'react-router-dom';
import { PrimaryEntityValues } from '../cost-centers-settings.props';
import { PushParamsType } from 'common/interfaces';
import { encodeBase64 } from 'utils/decode-encode-base64';

const PrimaryEntitySecondModal = ({
  closeModal,
  records,
  newPrimaryEntity,
  changePrimaryEntity
}: PrimaryEntitySecondModalProps) => {
  const history = useHistory();

  const redirect = (redirectTo: PushParamsType<PrimaryEntityRedirect>) => {
    closeModal();
    history.push(redirectTo);
  };

  return (
    <div>
      <Text color='grey900' bottom='20'>
        Diese {DATA[newPrimaryEntity].entity} haben keine Kostenstellen:
      </Text>

      {records.map((record, i) => (
        <Text padding='0 0 3px 10px' key={record.name + i} color='grey900'>
          • {record.name}
        </Text>
      ))}

      <Text color='grey900' bottom='20' top='20'>
        Aufgrund dieser Faktoren werden einige Fahrzeuge keine Kostenstellen haben.
      </Text>

      <Text color='grey900' bottom='20'>
        Möchtest Du trotzdem die Zuordnung ändern? Du kannst Dir auch diese {DATA[newPrimaryEntity].entityList}{' '}
        anschauen und Kostenstellen einstellen, bevor Du führende Entität änderst.
      </Text>

      <Flex justify='space-between'>
        <Button onClick={() => redirect(DATA[newPrimaryEntity].redirectTo)} right='10' secondary>
          Zur {DATA[newPrimaryEntity].entityList}
        </Button>

        <Flex justify='flex-end'>
          <Button onClick={closeModal} right='10' secondary>
            Nein, abbrechen
          </Button>
          <Button onClick={changePrimaryEntity}>Ja, umstellen</Button>
        </Flex>
      </Flex>
    </div>
  );
};

const EMPTY_COST_CENTER_FILTER_DATA = `filterData=${encodeBase64(
  JSON.stringify({ costCenter: { id: 'empty', label: 'Keine Kostenstelle' } })
)}`;

const DATA: PrimaryEntitySecondModalData = {
  [PrimaryEntityValues.Driver]: {
    entity: 'Fahrer',
    entityList: 'Fahrerliste',
    redirectTo: {
      pathname: PAGES.MY_DRIVERS,
      search: EMPTY_COST_CENTER_FILTER_DATA
    }
  },
  [PrimaryEntityValues.Location]: {
    entity: 'Standorte',
    entityList: 'Standortliste',
    redirectTo: {
      pathname: PAGES.COMPANIES,
      state: {
        tabName: 'Unternehmen'
      },
      search: EMPTY_COST_CENTER_FILTER_DATA
    }
  },
  [PrimaryEntityValues.Vehicle]: {
    entity: 'Fahrzeug',
    entityList: 'Fahrzeugliste',
    redirectTo: PAGES.MY_VEHICLES
  }
};

export default PrimaryEntitySecondModal;
