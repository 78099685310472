import styled, { css } from 'styled-components';
import { Flex } from 'common/common-components.styled';
import {
  Autocomplete,
  Select,
  Slider,
  Switch,
  TextField,
  Radio,
  FormControlLabel,
  Checkbox,
  InputAdornment
} from '@mui/material';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import { IStyledCheckbox } from './form.props';

interface StyledFormFieldContainerProps {
  hasLabel: boolean;
}

export const StyledFormFieldContainer = styled(Flex)<StyledFormFieldContainerProps>`
  position: relative;
  min-height: ${({ hasLabel }) => (hasLabel ? '66px' : '42px')};
`;

export const StyledSlider = styled(Slider)`
  & .MuiSlider-rail {
    background-color: ${({ theme }) => theme.colors.lightBlue};
    opacity: 1;
    height: 9px;
  }
  & .MuiSlider-track {
    background-color: ${({ theme }) => theme.colors.blue};
    height: 9px;
  }
  & .MuiSlider-thumb {
    background-color: ${({ theme }) => theme.colors.white};
    height: 18px;
    width: 18px;

    &:hover,
    &.Mui-active {
      box-shadow: none;
    }
  }
  & .MuiSlider-mark {
    color: ${({ theme }) => theme.colors.blue};

    &[style='left: 0%;'],
    &[style='left: 100%;'] {
      display: none;
    }
  }
  & .MuiSlider-markLabel {
    color: ${({ theme }) => theme.colors.darkBlue};
    font-size: 14px;

    &[style='left: 0%;'] {
      transform: translateX(0);
    }
    &[style='left: 100%;'] {
      transform: translateX(-100%);
    }
  }
  & .MuiSlider-markLabelActive {
    font-weight: bold;
  }
`;

export const StyledSwitch = styled(Switch)`
  & .MuiSwitch-switchBase.Mui-checked {
    color: ${({ theme }) => theme.colors.white};
  }
  & .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track {
    background-color: ${({ theme }) => theme.colors.blue};
    opacity: 1;
  }
`;

interface StyledTextFieldProps {
  error: boolean;
  isPrefix?: boolean;
}

export const StyledTextField = styled(TextField)<StyledTextFieldProps>`
  width: 100%;
  letter-spacing: 0.02em !important;

  & input::-webkit-outer-spin-button,
  & input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  & .MuiOutlinedInput-notchedOutline {
    border: none;
  }
  & .MuiInputBase-input {
    //border: 1px solid ${({ theme, error }) => (error ? theme.colors.pink : theme.colors.grey500)};
    border-radius: 2px;
    padding: 10px 15px;
    color: ${({ theme }) => theme.colors.black};
    font-size: 14px;
    background-color: ${({ theme }) => theme.colors.white};
    ${({ isPrefix }) =>
      isPrefix &&
      css`
        padding-left: 0;
      `};
  }
  & .MuiInputBase-multiline {
    border: none;
    padding: 0 !important;
  }
`;

export const StyledSelect = styled(Select)`
  width: 100%;
  //border: 1px solid ${({ theme, error }) => (error ? theme.colors.pink : theme.colors.grey500)};
  border-radius: 3px;

  & .MuiOutlinedInput-notchedOutline {
    border: none;
  }
  & .MuiSelect-select {
    color: ${({ theme }) => theme.colors.black};
    padding: 10px 15px;
    background-color: ${({ theme }) => theme.colors.white};
  }

  & .MuiSelect-select.Mui-disabled {
    cursor: not-allowed;
  }
`;

export const StyledAutocomplete = styled(Autocomplete)<StyledTextFieldProps>`
  width: 100%;
  //border: 1px solid ${({ theme, error }) => (error ? theme.colors.pink : theme.colors.grey500)};
  border-radius: 3px;

  & .MuiOutlinedInput-notchedOutline {
    border: none;
  }
  & .MuiAutocomplete-endAdornment {
    top: calc(50% - 12px);
  }
  & .MuiAutocomplete-inputRoot {
    color: ${({ theme }) => theme.colors.black};
    padding-top: 10px !important;
    padding-bottom: 10px !important;
    padding-left: 15px !important;
    background-color: ${({ theme }) => theme.colors.white};
  }
  & .MuiAutocomplete-tag {
    margin: 0 !important;
    margin-right: 5px !important;
  }
`;

export const StyledTextFieldWithoutBorders = styled(StyledTextField)`
  & .MuiInputBase-input {
    border: none;
    padding: 0 !important;
  }
`;

export const StyledDatePicker = styled(DesktopDatePicker)<StyledTextFieldProps>`
  width: 100%;
  & .MuiOutlinedInput-root {
    padding: 10px 15px;
    flex-direction: row-reverse;
    letter-spacing: 0.1em;

    & .MuiIconButton-root {
      margin-right: 0;
      margin-left: -22px;
    }
  }

  & .MuiFormHelperText-root {
    color: ${({ theme }) => theme.colors.darkGold} !important;
    margin: 2px !important;
  }
`;

export const StyledInputAdornment = styled(InputAdornment)`
  & .MuiTypography-root {
    padding-top: 1px;
  }
`;

export const StyledPhoneNumberField = styled(TextField)<StyledTextFieldProps>`
  width: 100%;
  letter-spacing: 0.02em !important;

  & .MuiOutlinedInput-notchedOutline {
    border: 1px solid ${({ theme, error }) => (error ? theme.colors.pink : theme.colors.grey500)};
  }
  & .MuiInputBase-input {
    border-radius: 3px;
    padding: 10px 15px;
    color: ${({ theme }) => theme.colors.black};
    font-size: 14px;
    background-color: ${({ theme }) => theme.colors.white};
  }
`;

export const StyledRadioButton = styled(Radio)`
  color: ${({ theme }) => theme.colors.blue} !important;

  &.Mui-checked {
    color: ${({ theme }) => theme.colors.blue} !important;
  }

  &.Mui-disabled {
    color: ${({ theme }) => theme.colors.grey300} !important;
  }
`;

interface StyledFormControlLabelProps {
  radiobuttonsrowdistance?: string;
}

export const StyledFormControlLabel = styled(FormControlLabel)<StyledFormControlLabelProps>`
  color: ${({ theme }) => theme.colors.black};
  ${({ radiobuttonsrowdistance }) =>
    radiobuttonsrowdistance &&
    css`
      margin-right: ${radiobuttonsrowdistance} !important;
    `};
`;

export const StyledCheckbox = styled(Checkbox)<IStyledCheckbox>`
  color: ${({ theme, isError }) => (isError ? theme.colors.pink : theme.colors.blue)} !important;

  &.Mui-checked {
    color: ${({ theme }) => theme.colors.blue} !important;
  }

  &.Mui-disabled {
    color: ${({ theme }) => theme.colors.grey300} !important;
  }
`;
