import axios from 'axios';
import authService from "./auth-service";

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

export async function getUserAccount() {
  const token = await authService.acquireTokenAsync();
  return axios({
    method: 'GET',
    url: `${API_ENDPOINT}/api/account/roles`,
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
}
