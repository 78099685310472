import React from 'react';
import { CellProps, ColumnsVariant } from '../table-component.props';
import { DateCell, TextCell, ImageCell, ChipCell, MenuCell, MoneyCell, BooleanCell, ButtonCell } from './components';

export const Cell = ({ column, row, openedItemId, toggle }: CellProps) => {
  switch (column.type) {
    case ColumnsVariant.Text:
      return <TextCell column={column} row={row} openedItemId={openedItemId} />;

    case ColumnsVariant.Image:
      return <ImageCell column={column} row={row} />;

    case ColumnsVariant.Date:
      return <DateCell column={column} row={row} openedItemId={openedItemId} />;

    case ColumnsVariant.Chip:
      return <ChipCell column={column} row={row} toggle={toggle} openedItemId={openedItemId} />;

    case ColumnsVariant.Button:
      return <ButtonCell column={column} row={row} />;

    case ColumnsVariant.Menu:
      return <MenuCell column={column} row={row} />;

    case ColumnsVariant.Money:
      return <MoneyCell column={column} row={row} />;

    case ColumnsVariant.Boolean:
      return <BooleanCell column={column} row={row} />;

    default:
      return <>-</>;
  }
};

export const spinnerStyle = {
  width: '30px',
  position: 'absolute',
  left: '45px',
  top: '50%',
  transform: 'translate(-50%, -50%)'
};
