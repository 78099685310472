import React, { useEffect, useState } from 'react';
import { PrimaryEntityModalProps } from './primary-entity-modal.props';
import { Button, Flex, Text } from 'common/common-components.styled';
import ThreeStateCheckbox from 'components/three-state-checkbox';
import { useIsParentCompany } from 'hooks/react-query/use-is-parent-company';

const PrimaryEntityModal = ({ companyId, validatePrimaryEntity, closeModal }: PrimaryEntityModalProps) => {
  const [isChangeChildCompaniesPrimaryEntity, setIsChangeChildCompaniesPrimaryEntity] = useState(true);

  const { isChildCompany } = useIsParentCompany(companyId);

  useEffect(() => {
    if (isChildCompany) {
      setIsChangeChildCompaniesPrimaryEntity(false);
    }
  }, [isChildCompany]);

  const toggleCheckbox = () => {
    setIsChangeChildCompaniesPrimaryEntity(checked => !checked);
  };

  return (
    <div>
      <Text color='grey900' bottom='20'>
        Durch Deine Änderung aktualisieren sich alle Kostenstellen an allen Fahrzeugen (Dies kann einige Momente in
        Anspruch nehmen).
      </Text>
      <Text color='grey900' bottom='20'>
        Bist Du sicher, dass Du die primäre Zuordnung der Kostenstellen an Fahrzeugen jetzt umstellen möchtest?
      </Text>

      {!isChildCompany && (
        <Flex align='center' bottom='20'>
          <ThreeStateCheckbox
            checked={isChangeChildCompaniesPrimaryEntity}
            onChange={toggleCheckbox}
            indeterminate={false}
          />
          <Text pointer onClick={toggleCheckbox} left='10' color='grey900'>
            Die Zuordnung auch für alle Tochterunternehmen übernehmen
          </Text>
        </Flex>
      )}

      <Flex justify='flex-end'>
        <Button onClick={closeModal} right='10' secondary>
          Nein, abbrechen
        </Button>
        <Button onClick={() => validatePrimaryEntity(isChangeChildCompaniesPrimaryEntity)}>Ja, umstellen</Button>
      </Flex>
    </div>
  );
};

export default PrimaryEntityModal;
