import { mainTheme } from 'common/global-styles';
import { ChartCO2Labels, DiagramMessage, DiagramTooltipMessage, OpenPageProps } from './dashboard.props';
import { MonthData, ChartFuelLabels } from './dashboard.props';
import { AnyObject } from 'chart.js/dist/types/basic';
import { ChartData } from 'chart.js';
import { PAGES } from 'common/enums';
import { CRM_VALUE as VEHICLE_COST_CRM_VALUE } from 'pages/fahrzeugkosten-page/fahrzeugkosten-page';
import moment from 'moment';

export const getDiagramSetup = (percent: number) => {
  const setup = { message: '', color: '', tooltipMessage: '' };

  if (percent >= 90) {
    setup.message = DiagramMessage.Good;
    setup.tooltipMessage = DiagramTooltipMessage.Good;
    setup.color = mainTheme.colors.green;
  }
  if (percent >= 80 && percent < 90) {
    setup.message = DiagramMessage.Medium;
    setup.tooltipMessage = DiagramTooltipMessage.Medium;
    setup.color = mainTheme.colors.darkGold;
  }
  if (percent < 80) {
    setup.message = DiagramMessage.Bad;
    setup.tooltipMessage = DiagramTooltipMessage.Bad;
    setup.color = mainTheme.colors.pink;
  }

  return setup;
};

export const modifyFuelData = (
  consumptionData: MonthData[],
  prevChartData: ChartData<'line', number[], string>
): ChartData<'line', number[], string> => {
  if (consumptionData) {
    const dieselData: number[] = [];
    const petrolData: number[] = [];
    const electricityData: number[] = [];
    consumptionData
      .sort((a: MonthData, b: MonthData) => a.month - b.month)
      .forEach((monthData: MonthData) => {
        monthData.fuels.forEach(fuel => {
          switch (fuel.name) {
            case 'Diesel':
              dieselData.push(fuel.amount);
              break;
            case 'Benzin':
              petrolData.push(fuel.amount);
              break;
            case 'Strom':
              electricityData.push(fuel.amount);
              break;
            default:
              break;
          }
        });
      });

    const newDatasets = prevChartData.datasets.map((dataset: AnyObject) => {
      let data;
      switch (dataset.label) {
        case ChartFuelLabels.Diesel:
        case ChartCO2Labels.Diesel:
          data = dieselData;
          break;
        case ChartFuelLabels.Petrol:
        case ChartCO2Labels.Petrol:
          data = petrolData;
          break;
        case ChartFuelLabels.Electricity:
        case ChartCO2Labels.Electricity:
          data = electricityData;
          break;
        default:
          data = dataset.data;
          break;
      }
      return { ...dataset, data };
    });
    return { ...prevChartData, datasets: newDatasets };
  }

  return prevChartData;
};

export const openPageVehicleCostCurrentYearProps = (
  filterForServer: AnyObject = {},
  filterForClient: AnyObject = {}
): OpenPageProps => ({
  page: PAGES.VEHICLE_COSTS,
  companyCrmName: VEHICLE_COST_CRM_VALUE.company,
  filterForServer: {
    [VEHICLE_COST_CRM_VALUE.rgDatum]: {
      startdate: moment().startOf('year').format('YYYY-MM-DD'),
      enddate: moment().endOf('year').format('YYYY-MM-DD')
    },
    ...filterForServer
  },
  filterForClient: { [VEHICLE_COST_CRM_VALUE.rgDatum]: 'Aktuelles Jahr', ...filterForClient }
});

export const openPageVehicleCostLastYearProps = (
  filterForServer: AnyObject = {},
  filterForClient: AnyObject = {}
): OpenPageProps => ({
  page: PAGES.VEHICLE_COSTS,
  companyCrmName: VEHICLE_COST_CRM_VALUE.company,
  filterForServer: {
    [VEHICLE_COST_CRM_VALUE.rgDatum]: {
      startdate: moment().subtract(1, 'year').startOf('year').format('YYYY-MM-DD'),
      enddate: moment().subtract(1, 'year').endOf('year').format('YYYY-MM-DD')
    },
    ...filterForServer
  },
  filterForClient: { [VEHICLE_COST_CRM_VALUE.rgDatum]: 'Letztes Jahr', ...filterForClient }
});
