import React from 'react';
import { TabControllerData } from 'components/tab/tab.props';
import { FormInfoList } from 'components/form-hook/components/info/form-info-list';
import { useFormInfoListDataEngineAndGears } from './form-info-list-data/use-engine-and-gears';
import { useFormInfoListDataTechnicalInformation } from './form-info-list-data/use-technical-information';
import { useFormInfoListDataEnvironment } from './form-info-list-data/use-environment';
import { useFormInfoListDataFuelConsumption } from './form-info-list-data/use-fuel-consumption';
import { useFormInfoListDataVehicleEquipment } from './form-info-list-data/use-vehicle-equipment';
import { useVehicleDetailIsDisableEditMode } from 'pages/existing-vehicle-page/hooks/use-is-disable-edit-mode';
import { InspectionDateSection } from './components/inspection-date/inspection-date';
import useWindowSize from 'hooks/use-window-size';
import { useIsUserHaveRole } from 'hooks/use-is-user-have-role';
import { USER_ROLES } from '../../../../../../../../../constants';

export const useVehicleDetailsVehicleFormSectionData = () => {
  const { formInfoListDataEngineAndGears } = useFormInfoListDataEngineAndGears();
  const { formInfoListDataTechnicalInformation } = useFormInfoListDataTechnicalInformation();
  const { formInfoListDataEnvironment } = useFormInfoListDataEnvironment();
  const { formInfoListDataFuelConsumption } = useFormInfoListDataFuelConsumption();
  const { formInfoListDataVehicleEquipment } = useFormInfoListDataVehicleEquipment();

  const { isDisableEditMode } = useVehicleDetailIsDisableEditMode();
  const formListType = isDisableEditMode ? 'default' : 'editable';

  const { isMobile } = useWindowSize(500);
  const columns = isMobile ? 1 : 2;

  const testDatesTabAvailableForRoles = useIsUserHaveRole([
    USER_ROLES.administrator.id,
    USER_ROLES.fleetManager.id,
    USER_ROLES.poolManager.id
  ]);

  const vehicleFormSectionData: TabControllerData[] = [
    {
      tab: 'Motor und Getriebe',
      panel: <FormInfoList columns={columns} type={formListType} data={formInfoListDataEngineAndGears} />
    },
    {
      tab: 'Technische Information',
      panel: <FormInfoList columns={columns} type='default' data={formInfoListDataTechnicalInformation} />
    },
    {
      tab: 'Umwelt',
      panel: <FormInfoList columns={columns} type={formListType} data={formInfoListDataEnvironment} />
    },
    {
      tab: 'Kraftstoffverbrauch',
      panel: <FormInfoList columns={columns} type={formListType} data={formInfoListDataFuelConsumption} />
    },
    {
      tab: 'Fahrzeugausstattungen',
      panel: <FormInfoList columns={columns} type={formListType} data={formInfoListDataVehicleEquipment} />
    },
    {
      tab: 'Prüftermine',
      panel: <InspectionDateSection />,
      isHide: !testDatesTabAvailableForRoles
    }
  ];

  return { vehicleFormSectionData };
};
