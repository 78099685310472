import React, { memo } from 'react';
import HistoryItem from 'pages/existing-vehicle-page/components/layout/main-tabs/components/tab-panels/vehicle-history/components/sections/cost-centers-widget/history-item';
import { StyledSpinnerContainer } from 'components/global-reference-vehicles/action-switcher/action-switcher.styled';
import Spinner from 'components/spinner';
import { StyledToggleButton } from './cost-centers-widget.styled';
import { Text } from 'common/common-components.styled';
import useFilteredItems from './hooks/use-filtered-items';
import { CostCentersWidgetProps } from './cost-centers-widget.types';
import { VehicleDetailsHistoryEmptyPlaceholder } from '../../placeholder/empty/empty';

const CostCentersWidget = ({ isExpanded, isShowExpandButton = true }: CostCentersWidgetProps) => {
  const { filteredItems, showAllItems, toggleShowAllItems, isLoading, isToggleButtonAvailable } =
    useFilteredItems(isExpanded);

  if (isLoading) {
    return (
      <StyledSpinnerContainer minHeight='200px'>
        <Spinner style={spinnerStyle} />
      </StyledSpinnerContainer>
    );
  }

  if (!filteredItems?.length) {
    return <VehicleDetailsHistoryEmptyPlaceholder title='Kostenstellen' />;
  }

  return (
    <div>
      {filteredItems.map((item, index) => (
        <HistoryItem
          key={item.uds_cost_center_historyid}
          index={index}
          item={item}
          hideGradientLine={index === filteredItems.length - 1}
        />
      ))}

      {isToggleButtonAvailable && isShowExpandButton && (
        <StyledToggleButton justify='center' align='center' onClick={toggleShowAllItems}>
          <Text>{showAllItems ? 'Weniger anzeigen' : 'Mehr anzeigen'}</Text>
        </StyledToggleButton>
      )}
    </div>
  );
};

const spinnerStyle = {
  width: '25px',
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)'
};

export default memo(CostCentersWidget);
