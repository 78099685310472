import React, { cloneElement, useEffect, useRef } from 'react';
import classNames from 'classnames';
import { disablePageScroll, enablePageScroll } from 'scroll-lock';
import classes from './modal-overlay.module.scss';
import { useDispatch } from 'react-redux';
import { toggleModal } from '../actions/app_action';
import { MODALS } from '../constants';

export default function ModalOverlay(props) {
  const modalRef = useRef(null);
  const dispatch = useDispatch();

  useEffect(() => {
    const ref = modalRef.current;
    disablePageScroll(ref);
    return () => {
      dispatch(toggleModal(MODALS.general_contact_form, null));
      enablePageScroll(ref);
    };
  });

  return (
    <div
      ref={modalRef}
      id='mobexo-modal-overlay'
      className={classNames(classes.modal, classes.modal__align_top, classes.modal__bg, classes.modal__active)}
      role='dialog'
      aria-hidden='true'
    >
      <div className={classes.modal__dialog}>
        <div className={classNames(classes.modal__content, classes.modal__content__active)}>
          {cloneElement(props.children, { parameters: props.parameters, onCloserHandler: props.onCloserHandler })}
        </div>
      </div>
    </div>
  );
}
