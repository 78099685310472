import styled from 'styled-components';
import { Flex, Panel } from 'common/common-components.styled';

export const StyledConfigurationTypeTile = styled(Panel)`
  cursor: pointer;
  transition: all ease-in 0.3s;
  box-shadow: 0.5px 2px 5px ${({ theme }) => theme.colors.grey300};

  min-height: 560px;
  margin: 15px;

  &:hover {
    box-shadow: 0.5px 4px 10px ${({ theme }) => theme.colors.grey300};
  }

  @media (min-width: ${({ theme }) => theme.media.tablet}) {
    margin: 0 15px;
    max-width: 550px;
    width: 100%;
  }
`;

export const StyledTilesContainer = styled(Flex)`
  flex-direction: column;

  @media (min-width: ${({ theme }) => theme.media.tablet}) {
    flex-direction: row;
  }
`;
