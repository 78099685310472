import React, { CSSProperties, RefObject } from 'react';
import classes from './file-info.module.scss';
import ThreeStateCheckbox from 'components/three-state-checkbox';
import { FileInfoProps } from './file-info.props';
import { FileFormatIcon } from './file-format-icon';
import useModal from 'hooks/use-modal';
import { MODALS, MODAL_NAMES } from '../../constants';
import classNames from 'classnames';
import useEllipsisTooltip from 'hooks/use-ellipsis-tooltip';
import { PrefixForId } from 'common/enums';
import { TrashIcon } from 'components/icons-new/trash';
import IconHover from 'components/icon-hover';

export const FileInfo = ({
  fileName,
  fileSize,
  lastModified,
  onClick,
  onDelete,
  file,
  allFiles = [],
  hideCheckBox = false,
  hideModifyDate = false,
  withoutFileViewer = false,
  id
}: FileInfoProps) => {
  const { isShowTip, style, content, onMouseEnter, onMouseLeave } = useEllipsisTooltip(0, 0);
  const [showFileViewer, hideFileViewer] = useModal(MODALS.file_viewer, MODAL_NAMES.file_viewer, {
    file,
    allFiles
  });

  const fileFormat: string = fileName?.split('.')?.pop() || '';

  const openFileViewerHandler = () => {
    if (onClick) {
      onClick();
    }
    !withoutFileViewer && showFileViewer();
  };

  return (
    <div
      id={id}
      className={classNames(classes.container, { [classes.container_delete]: onDelete })}
      style={{ cursor: withoutFileViewer ? 'default' : 'pointer' }}
      onClick={openFileViewerHandler}
    >
      <div className={classes.file}>
        {!hideCheckBox && (
          <div style={{ visibility: 'visible' }} className={classes.file_checkbox}>
            <ThreeStateCheckbox checked={true} indeterminate={false} onChange={() => {}} />
          </div>
        )}

        <div className={classes.file_description_container} style={{ marginLeft: hideCheckBox ? 0 : '20px' }}>
          <FileFormatIcon fileFormat={fileFormat} />

          <div className={classes.file_description}>
            <p
              className={classes.file_name}
              ref={content as RefObject<HTMLDivElement>}
              onMouseEnter={() => onMouseEnter()}
              onMouseLeave={onMouseLeave}
            >
              {fileName}
            </p>

            {isShowTip ? <p style={style as CSSProperties}>{fileName}</p> : null}

            {!hideModifyDate && (
              <p className={classes.file_modify_date}>{`${
                (fileSize / 1024).toFixed(1) || '- '
              } KB • ${lastModified}`}</p>
            )}
          </div>
        </div>

        {onDelete && (
          <span id={id + 'Delete' + PrefixForId.Button} className={classes.delete_button} onClick={() => onDelete()}>
            <IconHover>
              <TrashIcon />
            </IconHover>
          </span>
        )}
      </div>
    </div>
  );
};
