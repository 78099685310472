import { getFields } from 'components/form/form-view';
import { AnyObject } from 'common/interfaces';
import { approverFormFields } from 'models/forms/order-widget-form-model';

const useDefaultApproverFields = (isBasedOnBulkOrderVehicle?: boolean) => {
  getFields(approverFormFields, (field: AnyObject) => {
    field.source = !isBasedOnBulkOrderVehicle ? 'getApprovers' : undefined;
  });

  return approverFormFields;
};

export default useDefaultApproverFields;
