import React from 'react';
import styles from './index.module.scss';
import { File } from './file-preview-block/file';

const DocumentsBlock = ({ files, isWithoutFileViewer = false, isDownloadIcon = false, noMargin = false }) => {
  return (
    <section className={noMargin ? styles.form__block__short : styles.form__block}>
      <div className={styles.form__block__row}>
        <div className={noMargin ? styles.form__block__left__no_margin : styles.form__block__left}>
          {!!files && files?.length > 0 && (
            <div className={styles.files}>
              {files?.map(file => (
                <File
                  key={file.fileId}
                  allFiles={files}
                  file={file}
                  isWithoutViewer={isWithoutFileViewer}
                  isDownloadIcon={isDownloadIcon}
                />
              ))}
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export default DocumentsBlock;
