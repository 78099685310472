import React, { useEffect } from 'react';
import { Flex, Text, Button } from 'common/common-components.styled';
import UploadFile from 'components/setup-assistant/upload-document/upload-file/upload-file-view';
import { useForm } from 'react-hook-form';
import { UDS_AUFGABENTYP_FUHRPARK } from '../../../../constants';
import { PrefixForId } from '../../../../common/enums';
import FormHook from 'components/form-hook/form-hook';
import { getConductionDateField } from './upload-report-form-fields';
import { isEmpty } from 'lodash';

interface UploadReportProps {
  taskTypeCode: number;
  onFormComplete: (files: Blob[], date?: string | null) => void;
  onSubmitReport: () => void;
}

const UploadReport = ({ taskTypeCode, onFormComplete, onSubmitReport }: UploadReportProps) => {
  const reactHookFormData = useForm();
  const {
    handleSubmit,
    watch,
    setValue,
    setError,
    clearErrors,
    formState: { errors }
  } = reactHookFormData;
  const files = watch('reportFiles');
  const conductionDate = watch('conductionDate');

  useEffect(() => {
    if (files?.length && conductionDate) {
      onFormComplete(files, conductionDate);
      clearErrors('reportFiles');
    }

    if (!files?.length) {
      setError('reportFiles', { type: 'custom', message: 'Bitte auswählen' });
    }
  }, [files, conductionDate, onFormComplete]);

  const getCalendarLabel = () => {
    switch (taskTypeCode) {
      case UDS_AUFGABENTYP_FUHRPARK.hu:
        return 'Datum an dem die Hauptuntersuchung durchgeführt wurde';
      case UDS_AUFGABENTYP_FUHRPARK.inspectionDates:
        return 'Untersuchungsdatum';
      default:
        return 'Datum an dem die Prüfung nach UVV durchgeführt wurde';
    }
  };

  const changeFiles = (files: File[]) => {
    setValue('reportFiles', files);
  };

  return (
    <Flex>
      <Flex direction='column' width='100%'>
        <Text color='black' bottom='30'>
          Bitte lade Deinen Prüfbericht hoch.
        </Text>
        <UploadFile
          onDropAccepted={changeFiles}
          accept={['application/pdf', 'image/png', 'image/jpeg', 'image/jpg']}
          acceptString={'pdf, png, jpg, jpeg'}
          unfallreparatur={true}
          multiple={true}
          className={null}
          maxSize={10485760}
          maxSizeString='10 MB'
          required
          maxFiles={5}
        />
        <Flex top='30'>
          <Text color='darkBlue'>{getCalendarLabel()}</Text>
          <Text color='pink'>*</Text>
        </Flex>

        <Flex width='50%' top='15'>
          <FormHook reactHookFormData={reactHookFormData} formFields={getConductionDateField(taskTypeCode)} />
        </Flex>
        <Flex top='30' justify='flex-end'>
          <Button
            disabled={!isEmpty(errors)}
            onClick={handleSubmit(onSubmitReport)}
            id={PrefixForId.Modal + PrefixForId.Form + PrefixForId.Button + PrefixForId.Submit}
          >
            Speichern
          </Button>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default UploadReport;
