import React, { cloneElement, useMemo } from 'react';
import PropTypes from 'prop-types';
import { CarWithEuroIcon } from '../../icons/offer-statuses/car-with-euro-icon';
import { CalculatorIcon } from '../../icons/offer-statuses/calcurator-icon';
import { CartIcon } from '../../icons/offer-statuses/cart-icon';
import { EditIcon } from '../../icons/offer-statuses/edit-icon';
import { DoneIcon } from '../../icons/offer-statuses/done-icon';
import { CloseIcon } from '../../icons/close-icon';
import { OFFER_STATUSES } from '../../../constants';
import { StyledDeclinedState, StyledOfferStatus } from './offer-status-section.styled';
import { Flex, Text } from '../../../common/common-components.styled';
import { useTheme } from 'styled-components';
import Tooltip from '@mui/material/Tooltip';

const OfferStatus = ({ status, tooltipText, id }) => {
  const theme = useTheme();

  return (
    <Tooltip title={tooltipText}>
      <StyledOfferStatus id={id} status={status}>
        {status.canceled
          ? cloneElement(status.canceledIcon, { color: theme.colors.white })
          : cloneElement(status.icon, { color: status.active ? theme.colors.white : theme.colors.lightBlue })}
      </StyledOfferStatus>
    </Tooltip>
  );
};

OfferStatus.propTypes = {
  status: PropTypes.shape({
    icon: PropTypes.any.isRequired,
    canceledIcon: PropTypes.any,
    active: PropTypes.bool.isRequired,
    canceled: PropTypes.bool
  }).isRequired,
  tooltipText: PropTypes.any.isRequired
};

const approvalRejectedTooltipText = 'Leasinganfrage wurde abgelehnt oder archiviert (siehe Ablehnungsgrund).';

const OfferStatusSection = ({ status, bottom = '30' }) => {
  const theme = useTheme();

  const offerStatuses = useMemo(
    () => [
      {
        icon: <CarWithEuroIcon />,
        active: [
          OFFER_STATUSES.offerRequested,
          OFFER_STATUSES.priceRequested,
          OFFER_STATUSES.requirementApproved,
          OFFER_STATUSES.vehicleRequested,
          OFFER_STATUSES.purchaseTriggered,
          OFFER_STATUSES.approvedByFleetManager,
          OFFER_STATUSES.orderApproved,
          OFFER_STATUSES.approvalRejected,
          OFFER_STATUSES.dealershipSelected
        ].includes(status),
        tooltipText: (
          <>
            <Text size='14' bottom='10' bold>
              Angebot anfragen
            </Text>
            <Text size='12'>
              Fahrer bzw. Fuhrparkleiter fragt das Barpreisangebot beim Händler und das Leasingangebot bei der
              Leasinggesellschaft an.
            </Text>
          </>
        )
      },
      {
        icon: <CalculatorIcon />,
        active: [
          OFFER_STATUSES.priceRequested,
          OFFER_STATUSES.requirementApproved,
          OFFER_STATUSES.vehicleRequested,
          OFFER_STATUSES.purchaseTriggered,
          OFFER_STATUSES.approvedByFleetManager,
          OFFER_STATUSES.orderApproved,
          OFFER_STATUSES.approvalRejected,
          OFFER_STATUSES.dealershipSelected
        ].includes(status),
        tooltipText: (
          <>
            <Text size='14' bottom='10' bold>
              Preise kalkulieren
            </Text>
            <Text size='12' bottom='10'>
              Der zuständige Händler kalkuliert den Barpreis und übermittelt sein Angebot.
            </Text>
            <Text size='12' bottom='10'>
              Wenn der Barpreis übermittelt wurde, wird eine Leasinganfrage generiert.
            </Text>
            <Text size='12'>Wenn auch das Leasingangebot da ist, kann das Fahrzeug angefordert werden.</Text>
          </>
        )
      },
      {
        icon: <CartIcon />,
        active: [
          OFFER_STATUSES.requirementApproved,
          OFFER_STATUSES.vehicleRequested,
          OFFER_STATUSES.purchaseTriggered,
          OFFER_STATUSES.approvedByFleetManager,
          OFFER_STATUSES.orderApproved,
          OFFER_STATUSES.approvalRejected
        ].includes(status),
        tooltipText: (
          <>
            <Text size='14' bottom='10' bold>
              Fahrzeug anfordern
            </Text>
            <Text size='12' bottom='10'>
              Fahrer bzw. Fuhrparkleiter fordert ein Fahrzeug an. Es können in der Regel mehrere Konfigurationen
              erstellt werden, aber nur eine davon kann man tatsächlich anfordern.
            </Text>
            <Text size='12'>Wurde das Fahrzeug angefordert, löst Mobexo bis zu 2 weitere Vergleichsangebote aus.</Text>
          </>
        )
      },
      {
        icon: <EditIcon />,
        canceledIcon: <CloseIcon />,
        active: [
          OFFER_STATUSES.vehicleRequested,
          OFFER_STATUSES.purchaseTriggered,
          OFFER_STATUSES.approvedByFleetManager,
          OFFER_STATUSES.orderApproved
        ].includes(status),
        canceled: [OFFER_STATUSES.approvalRejected].includes(status),
        tooltipText: (
          <>
            <Text size='14' bottom='10' bold>
              Freigaben einholen
            </Text>
            <Text size='12'>
              Wenn die Vergleichsangebote übermittelt wurden, kann sich der Fuhrparkleiter für einen Leasinggeber
              entscheiden, die Anforderung freigeben und ggfs. weitere Freigaben einleiten.
            </Text>
          </>
        )
      },
      {
        icon: <DoneIcon />,
        canceledIcon: <CloseIcon />,
        active: [OFFER_STATUSES.orderApproved].includes(status),
        canceled: [OFFER_STATUSES.approvalRejected].includes(status),
        tooltipText: (
          <>
            <Text size='14' bottom='10' bold>
              Fahrzeug bestellen
            </Text>
            <Text size='12' bottom='10'>
              Wenn alle Bestellfreigaben eingeholt wurden, gilt die Anforderung als freigegeben.
            </Text>
            <Text size='12' bottom='10'>
              Die signierte verbindliche Bestellung wird an der Konfiguration gespeichert. Im selben Schritt wird die
              Bestellung an das Autohaus und die Leasinggesellschaft weitergeleitet.
            </Text>
            <Text size='12'>
              Den weiteren Bestellfortschritt kann man auf der Seite <b>Bestellungen</b> einsehen.
            </Text>
          </>
        )
      }
    ],
    [status]
  );

  return status === OFFER_STATUSES.approvalRejected ? (
    <Tooltip title={approvalRejectedTooltipText}>
      <StyledDeclinedState bottom={bottom} align='center' padding='0 10px'>
        <CloseIcon color={theme.colors.white} />
        <Text left='10' top='2' size='12' color='white'>
          Vorgang abgebrochen
        </Text>
      </StyledDeclinedState>
    </Tooltip>
  ) : (
    <Flex bottom={bottom}>
      {offerStatuses.map((offerStatus, i) => (
        <OfferStatus id={'OfferStatus' + i} tooltipText={offerStatus.tooltipText} key={i} status={offerStatus} />
      ))}
    </Flex>
  );
};

OfferStatusSection.propTypes = {
  status: PropTypes.number.isRequired
};

export default OfferStatusSection;
