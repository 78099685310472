import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTypedSelector } from 'hooks/use-typed-selector';
import { useOverlay, useQuery } from '../../hooks';
import classes from './anfragen-component.module.scss';
import { toggleModal } from '../../actions/app_action';
import { cancelTask, restoreTask, delegateTask, postponeTask, getInfoForCreateTask } from './utils';
import { createNewTask } from 'services/crm-invoices-service';
import { getRequestFormData } from '../../utils/get-response-data';
import { MODAL_NAMES, MODALS, USER_ROLES, TASK_STATUS_CODES, RequestStatuses, STATECODE_NAMES } from '../../constants';
import { AufgabenFilters } from './components/aufgaben-filters/aufgaben-filters';
import { AufgabenItem } from './components/aufgaben-item/aufgaben-item';
import { UnfoldedTask } from './components/unfolded-task/unfolded-task';
import PanelPlaceholder from '../panel-placeholder';
import CreateNewTaskModal from './components/create-new-task/create-new-task-modal';
import { useSessionFilter } from 'hooks/use-session-filter';
import { Button, Flex } from 'common/common-components.styled';
import { format, formatISO, isAfter, isToday, parseISO } from 'date-fns';
import DelegateTask from './components/delegate-task/delegate-task';
import RemindLater from './components/remind-later/remind-later';
import CancelTask from './components/cancel-task/cancel-task';
import CarPoliciesForDriver from './components/car-policies-for-driver/car-policies-for-driver';
import { setSnakbar } from 'actions/app_action';
import { decodeBase64 } from '../../utils/decode-encode-base64';
import { AnyObject } from 'chart.js/dist/types/basic';
import { useHistory } from 'react-router-dom';
import { useSessionSorting } from 'hooks/use-session-sorting';
import { PlusLargeIcon } from 'components/icons/plus-large-icon';
import { PAGES, PrefixForId, ReactQueryKeys } from 'common/enums';
import {
  TableItemProps,
  NewTaskDataProps,
  TaskTypeId,
  SearchItemProps,
  CancelationTaskPhases,
  RestorationTaskPhases,
  LeaseExtentionPhases,
  StatusId,
  VehicleInitialReviewPhases,
  ChecklistPhases,
  VehicleReturnPhases
} from './task.prop';
import { useQueryClient } from 'react-query';
import { useTasksQuery } from 'hooks/react-query/tasks/tasks';
import { AxiosResponse } from 'axios';
import { ResponseModel, ValueOf } from 'common/interfaces';
import { useIsUserHaveRole } from 'hooks/use-is-user-have-role';
import { AlertProps } from '../alert/alert-view';
import { GoToAllTasksButton } from './components/buttons';
import Pagination from 'components/pagination';
import { allFieldsCreateTask } from './components/create-new-task/formFields';
import { setLeaseExtensionPhase } from 'components/aufgaben-component/utils';
import CancelLeaseExtensionTask from './components/cancel-lease-extension-task/cancel-lease-extension-task';
import { ButtonComponentType } from 'components/button/button.props';
import { default as DownloadButton } from 'components/button';
import { crmTaskRequestConfig } from 'request-config/crm-task/crm-task.request-config';
import { useSetFilterFromLocationState } from 'hooks/use-set-filter-from-location-state/use-set-filter-from-location-state';
import { useFileDownload } from 'hooks/useFileDownload';
import { useFetch } from 'hooks/use-fetch/use-fetch';
import { fleetLeaseEndRequestConfig } from 'request-config/freet-lease-end/fleet-lease-end.request-config';
import { mobexoEmployeeLeaseEndRequestConfig } from 'request-config/mobexo-employee-lease-end/mobexo-employee-lease-end.request-config';
import EllipsisWithTooltip from 'components/ellipsis-with-tooltip';
import { useTheme } from 'styled-components';
import { useMediaQuery } from '@mui/material';

const getStatusColor = (endDate: string, stateCode: number) => {
  const theme = useTheme();

  if (
    stateCode === TASK_STATUS_CODES.open ||
    stateCode === TASK_STATUS_CODES.postponed ||
    stateCode === TASK_STATUS_CODES.processing
  ) {
    if (isAfter(parseISO(endDate), new Date()) || isToday(parseISO(endDate)) || !endDate) {
      return theme.colors.darkGold;
    }
    return theme.colors.pink;
  }

  if (stateCode === TASK_STATUS_CODES.completed) {
    return theme.colors.green;
  }
  return theme.colors.grey600;
};

const isPostponeDisabled = (scheduledEnd: string) => {
  const today = new Date();
  const endDate = parseISO(scheduledEnd);

  return isAfter(today, endDate) || isToday(endDate);
};

const AufgabenComponent = () => {
  const user = useTypedSelector(state => state['app'].user);
  const dispatch = useDispatch();
  const isDriver = useIsUserHaveRole(USER_ROLES.driver.id);
  const query = useQuery();
  const caseId = query.id || null;
  const [tableParams, setTableParams] = useState(defaultTableParams);
  const [unfoldedTaskItem, setUnfoldedTaskItem] = useState({
    indx: -1,
    isActive: false,
    isEditing: false
  });
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isEditingMode, setIsEditingMode] = useState(false);
  const history = useHistory();

  const wrapperRef = useRef<any>();

  const isMobileWidth = useMediaQuery('(max-width:550px)');

  const presetFilters = query.filter ? JSON.parse(decodeBase64(query.filter as string)) : null;
  const isShowNewTaskBtn = useIsUserHaveRole([
    USER_ROLES.fleetManager.id,
    USER_ROLES.administrator.id,
    USER_ROLES.poolManager.id
  ]);

  const isMobexoEmployee = useIsUserHaveRole(USER_ROLES.mobexoEmployee.id);

  const filtersForServer = useMemo(() => {
    const defaultFiltersForServer: FilterProps = {
      ...emptyFilterStateServer,
      statuses: [TASK_STATUS_CODES.open, TASK_STATUS_CODES.processing]
    };

    presetFilters &&
      Object.keys(defaultFiltersForServer).forEach(key => {
        if (presetFilters[key]) {
          defaultFiltersForServer[key as keyof typeof defaultFiltersForServer] = presetFilters[key].value;
        }
      });

    if (defaultFiltersForServer['executorContact']) {
      defaultFiltersForServer['executorContact'] = {
        id: presetFilters['executorContact'].value,
        logicalName: 'contact',
        name: presetFilters['executorContact'].label
      };
    }
    return defaultFiltersForServer;
  }, [presetFilters]);

  const filtersForClient = useMemo(() => {
    const defaultFitersForClient: FilterProps = {
      ...emptyFilterStateClient,
      statuses: [STATECODE_NAMES.open, STATECODE_NAMES.processing]
    };

    presetFilters &&
      Object.keys(defaultFitersForClient).forEach(key => {
        if (presetFilters[key]) {
          defaultFitersForClient[key as keyof typeof defaultFitersForClient] = presetFilters[key].label;
        }
      });

    return defaultFitersForClient;
  }, [presetFilters]);

  const { sessionSorting, setSessionSorting } = useSessionSorting(PAGES.TASKS, defaultSorting);
  const { sessionFilter, setSessionFilter } = useSessionFilter(PAGES.TASKS, filtersForServer, filtersForClient);
  const { table, filter, tableRefetch, filterRefetch } = useTasksQuery(
    tableParams,
    sessionFilter,
    sessionSorting,
    caseId
  );

  const queryClient = useQueryClient();

  const [showOverlay, hideOverlay] = useOverlay();

  useSetFilterFromLocationState(setSessionFilter, emptyFilterStateServer, emptyFilterStateServer);

  const downloadFile = useFileDownload({
    requestConfig: crmTaskRequestConfig.getRecordsExcel({
      tableParams,
      sessionSorting,
      search: isMobileWidth || !!caseId ? emptyFilterStateServer : sessionFilter?.filterForServer,
      caseId
    }),
    fileName: 'Aufgaben.xlsx',
    encodeConfig: {
      path: ['data'],
      encodeWay: 'b64Data',
      contentType: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    }
  });

  useEffect(() => {
    if (presetFilters) {
      history.replace(PAGES.TASKS);
    }
  }, []);

  useEffect(() => {
    if (caseId) {
      setUnfoldedTaskItem({
        indx: 0,
        isActive: true,
        isEditing: false
      });
    }
  }, [caseId]);

  const pageChangeHandler = (page: number) => {
    closeUnfoldedTaskHandler();

    setTableParams(tableParams => ({
      ...tableParams,
      pagination: {
        ...tableParams.pagination,
        start: page
      }
    }));
  };

  const headersReverseSort = (headerIndx: number, fieldName: string) => {
    closeUnfoldedTaskHandler();

    setSessionSorting({
      predicate: fieldName,
      reverse: fieldName === sessionSorting.predicate ? !sessionSorting.reverse : false
    });

    goToFirstPage();
  };

  const closeAlert = useCallback(() => {
    dispatch(toggleModal(MODALS.alert, null));
    setIsMenuOpen(false);
  }, [dispatch]);

  const createTaskHandler = async (data: AnyObject) => {
    showOverlay();
    closeAlert();

    const newTaskData: NewTaskDataProps = {};
    newTaskData.createTask = {};
    newTaskData.createTask.entityName = 'task';
    newTaskData.createTask.attributes = getRequestFormData(data, allFieldsCreateTask);

    if (newTaskData.createTask.attributes.regardingobjectid.attributeValue) {
      newTaskData.createTask.attributes.regardingobjectid.attributeValue.logicalName = 'new_fuhrpark';
    } else {
      newTaskData.createTask.attributes.regardingobjectid = null;
    }

    const response: AxiosResponse<ResponseModel> = await getInfoForCreateTask();
    if (
      response.data?.level === RequestStatuses.Success &&
      newTaskData.createTask.attributes.uds_executor_contactid.attributeValue.id
    ) {
      const executor = response.data.data.contacts.filter(
        (contact: { id: string; logicalName: string; name: string }) =>
          data?.uds_executor_contactid.includes(contact.id)
      );

      if (data.uds_aufgabentyp_fuhrpark === TaskTypeId.Allgemein && executor.length > 1) {
        newTaskData.Executors = executor;
      } else if (executor.length === 1) {
        newTaskData.executorContactOrFirm = executor[0];
      }
      newTaskData.TaskType = data.uds_aufgabentyp_fuhrpark;

      delete newTaskData.createTask.attributes.uds_executor_contactid;

      try {
        await createNewTask(newTaskData);

        queryClient.resetQueries([PAGES.TASKS]);
        queryClient.resetQueries([ReactQueryKeys.GetVehiclesForCreateTask]);

        dispatch(
          setSnakbar({
            isOpen: true,
            message: 'Aufgabe wurde erstellt',
            type: 'success'
          })
        );
        setIsSubmitted(!isSubmitted);
        hideOverlay();
      } catch (err) {
        console.log(err);
        hideOverlay();

        dispatch(
          setSnakbar({
            isOpen: true,
            message: 'Aufgabe wurde nicht erstellt',
            type: 'error'
          })
        );
      }
    }
  };

  const openCreateNewTaskModal = () => {
    closeUnfoldedTaskHandler();

    const alertData: ValueOf<Pick<AlertProps, 'parameters'>> = {
      title: 'Neue Aufgabe',
      children: <CreateNewTaskModal onSubmit={createTaskHandler} onClose={closeAlert} />,
      buttons: [
        {
          type: 'cancel',
          title: 'Abbrechen',
          action: closeAlert
        }
      ],
      allButtonsHidden: true
    };
    dispatch(toggleModal(MODALS.alert, MODAL_NAMES.alert, alertData));
  };

  const handleDelegateTask = useCallback(
    (selectedUser: any, taskId: any) => {
      showOverlay();
      closeAlert();
      delegateTask({
        taskId: taskId,
        executorId: selectedUser.id,
        executorLogicalName: selectedUser.logicalName
      }).then(response => {
        filterRefetch();
        queryClient.resetQueries([PAGES.TASKS]);
        dispatch(
          setSnakbar({
            isOpen: true,
            message: response.data.isSuccess
              ? `Die Aufgabe wurde erfolgreich an ${selectedUser.name} delegiert`
              : response.data.messages[0].message,
            type: response.data.level.toLowerCase()
          })
        );
      });
    },
    [showOverlay, closeAlert, filterRefetch, queryClient, dispatch]
  );

  const openPoliciesForDriverModal = useCallback(
    (driver: SearchItemProps, taskId: string) => {
      setIsMenuOpen(true);
      const alertData = {
        title: 'Policies für den Fahrer',
        children: (
          <CarPoliciesForDriver
            driver={driver}
            closeAlert={closeAlert}
            taskId={taskId}
            onSubmitDelegation={handleDelegateTask}
            handleGoBack={() => {}}
          />
        ),
        buttons: [
          {
            type: 'cancel',
            title: 'Abbrechen',
            action: closeAlert,
            hide: true
          }
        ],
        allButtonsHidden: true
      };
      dispatch(toggleModal(MODALS.alert, MODAL_NAMES.alert, alertData));
    },
    [closeAlert, dispatch]
  );

  const openDelegateModal = useCallback(
    (taskId: string, taskTypeValue: number) => {
      closeAlert();
      setIsMenuOpen(true);
      const alertData = {
        title: 'Aufgabe delegieren',
        children: (
          <DelegateTask
            taskId={taskId}
            onSubmitDelegation={handleDelegateTask}
            onCloseModal={closeAlert}
            taskTypeValue={taskTypeValue}
          />
        ),
        buttons: [
          {
            type: 'cancel',
            title: 'Abbrechen',
            action: closeAlert,
            hide: true
          }
        ],
        allButtonsHidden: true
      };
      dispatch(toggleModal(MODALS.alert, MODAL_NAMES.alert, alertData));
    },
    [closeAlert, handleDelegateTask, openPoliciesForDriverModal, dispatch]
  );

  const handleRemindLater = useCallback(
    (taskId: any, postponeDate: any) => {
      const formattedDateForServer = formatISO(postponeDate?.toDate(), { representation: 'date' });
      const formattedDateForSnackbar = format(postponeDate?.toDate(), 'dd.MM.yyyy');

      showOverlay();
      closeAlert();
      postponeTask({
        TaskId: taskId,
        ScheduledDate: formattedDateForServer
      }).then(response => {
        queryClient.resetQueries([PAGES.TASKS]);
        queryClient.resetQueries([ReactQueryKeys.Filter, PAGES.TASKS]);

        dispatch(
          setSnakbar({
            isOpen: true,
            message: response.data.isSuccess
              ? `Wir erinnern dich an die Aufgabe am ${formattedDateForSnackbar} wieder`
              : response.data.messages[0].message,
            type: response.data.level.toLowerCase()
          })
        );
      });
    },
    [showOverlay, closeAlert, filterRefetch, tableRefetch, dispatch]
  );

  const openRemindModal = useCallback(
    (scheduledend: string, taskId: string) => {
      setIsMenuOpen(true);
      const alertData = {
        title: 'Später an die Aufgabe erinnern',
        children: (
          <RemindLater
            scheduleend={scheduledend}
            taskId={taskId}
            onSubmitPostpone={handleRemindLater}
            onCloseModal={closeAlert}
          />
        ),
        buttons: [
          {
            type: 'cancel',
            title: 'Abbrechen',
            action: closeAlert,
            hide: true
          }
        ],
        allButtonsHidden: true
      };
      dispatch(toggleModal(MODALS.alert, MODAL_NAMES.alert, alertData));
    },
    [closeAlert, dispatch, handleRemindLater]
  );

  const changeLeaseExtentionPhase = (
    taskId: string,
    vehicleId: string,
    currentPhase: number,
    nextPhase: number,
    lvaId: string,
    successMessage: string,
    comment?: string,
    isChecked?: boolean
  ) => {
    closeAlert();
    showOverlay();
    setLeaseExtensionPhase({
      data: {
        TaskId: taskId,
        FahrzeugId: vehicleId,
        LvaId: lvaId,
        PrevPhaseNo: currentPhase,
        NewPhaseNo: nextPhase,
        Comment: comment,
        NoAskCancelStatus: isChecked
      }
    }).then(response => {
      hideOverlay();
      filterRefetch();
      tableRefetch();
      closeUnfoldedTaskHandler();
      dispatch(
        setSnakbar({
          isOpen: true,
          message: response.data.isSuccess ? successMessage : response.data.messages[0].message,
          type: response.data.level.toLowerCase()
        })
      );
    });
  };

  const handleCancelForLeaseExtension = (changePhase: (comment: string) => void) => {
    setIsMenuOpen(true);
    const alertData = {
      title: 'Später an die Aufgabe erinnern',
      children: <CancelLeaseExtensionTask onCancel={closeAlert} onSubmit={(comment: string) => changePhase(comment)} />,
      buttons: [
        {
          type: 'cancel',
          title: 'Abbrechen',
          action: closeAlert,
          hide: true
        }
      ],
      allButtonsHidden: true
    };
    dispatch(toggleModal(MODALS.alert, MODAL_NAMES.alert, alertData));
  };

  const { fetch: setVirActionFetch } = useFetch({
    loadingKey: 'setting phase',
    isShowSuccessSnackbar: true,
    successMessage: 'Die Daten wurden erfolgreich übermittelt',
    successCallback: tableRefetch
  });

  const cancelAppraiseTask = async (taskId: string, vehicleId: string) => {
    dispatch(toggleModal(MODALS.alert, null));

    await setVirActionFetch({
      requestConfig: fleetLeaseEndRequestConfig.setVirAction({
        actionName: 'Cancel',
        fuhrparkId: vehicleId,
        taskId
      })
    });
  };

  const cancelCurrentTask = useCallback(
    (
      taskId: string,
      isChecked: boolean,
      isTypeLeaseExtention: boolean,
      vehicleId: string,
      currentPhase: number,
      nextPhase: number,
      lvaId?: string,
      isTypeAppraise?: boolean
    ) => {
      const successMessage = 'Die Aufgabe wurde erfolgreich storniert';
      closeAlert();

      if (isTypeLeaseExtention && lvaId) {
        if (isMobexoEmployee) {
          changeLeaseExtentionPhase(taskId, vehicleId, currentPhase, nextPhase, lvaId, successMessage);
          return;
        }

        handleCancelForLeaseExtension(comment =>
          changeLeaseExtentionPhase(
            taskId,
            vehicleId,
            currentPhase,
            nextPhase,
            lvaId,
            successMessage,
            comment,
            table.noAskStatuses || isChecked
          )
        );

        return;
      }

      if (isTypeAppraise) {
        cancelAppraiseTask(taskId, vehicleId);

        return;
      }

      showOverlay();
      cancelTask({
        Id: taskId,
        noAskStatus: isChecked
      }).then(response => {
        hideOverlay();
        filterRefetch();
        tableRefetch();
        closeUnfoldedTaskHandler();
        dispatch(
          setSnakbar({
            isOpen: true,
            message: response.data.isSuccess ? successMessage : response.data.messages[0].message,
            type: response.data.level.toLowerCase()
          })
        );
      });
    },
    [closeAlert, dispatch, filterRefetch, hideOverlay, showOverlay, tableRefetch]
  );

  const handleCancelTask = useCallback(
    (
      title: string,
      taskId: string,
      isTypeLeaseExtension: boolean,
      vehicleId: string,
      currentPhase: number,
      nextPhase: number,
      isTypeAppraise: boolean,
      lvaId?: string
    ) => {
      const alertData = {
        title: 'Aufgabe stornieren',
        children: (
          <CancelTask
            onCancelTask={(isChecked: boolean) =>
              cancelCurrentTask(
                taskId,
                isChecked,
                isTypeLeaseExtension,
                vehicleId,
                currentPhase,
                nextPhase,
                lvaId,
                isTypeAppraise
              )
            }
            onCloseAlert={closeAlert}
            title={title}
            isTypeAppraise={isTypeAppraise}
          />
        ),
        buttons: [
          {
            type: 'cancel',
            title: 'Abbrechen',
            action: closeAlert,
            hide: true
          }
        ],
        allButtonsHidden: true
      };

      table.noAskStatuses
        ? cancelCurrentTask(taskId, true, isTypeLeaseExtension, vehicleId, currentPhase, nextPhase, lvaId)
        : dispatch(toggleModal(MODALS.alert, MODAL_NAMES.alert, alertData)) && setIsMenuOpen(true);
    },
    [cancelCurrentTask, table, dispatch, closeAlert]
  );

  const handleRestoreTask = useCallback(
    (
      taskId: string,
      isTypeLeaseExtention: boolean,
      vehicleId: string,
      currentPhase: number,
      nextPhase: number,
      lvaId?: string
    ) => {
      const successMessage = 'Die Aufgabe wurde erfolgreich wiederhergestellt';
      showOverlay();

      if (isTypeLeaseExtention && lvaId) {
        changeLeaseExtentionPhase(taskId, vehicleId, currentPhase, nextPhase, lvaId, successMessage);

        return;
      }
      restoreTask({
        Id: taskId
      }).then(response => {
        closeAlert();
        tableRefetch();
        dispatch(
          setSnakbar({
            isOpen: true,
            message: response.data.isSuccess ? successMessage : response.data.messages[0].message,
            type: response.data.level.toLowerCase()
          })
        );
      });
    },
    [closeAlert, dispatch, tableRefetch, showOverlay]
  );

  const { fetch: setLVATaskStatusFetch } = useFetch({
    loadingKey: 'setting status',
    isShowSuccessSnackbar: true,
    successMessage: 'Die Daten wurden erfolgreich übermittelt',
    successCallback: tableRefetch
  });

  const handleUpdateStatus = async (taskId: string, statusCode: StatusId) => {
    await setLVATaskStatusFetch({
      requestConfig: mobexoEmployeeLeaseEndRequestConfig.setLVATaskStatus({
        taskId,
        statusCode
      })
    });
  };

  const openUnfoldedTaskHandler = (indx: number, isEditing?: boolean) => {
    if (!isEditingMode || (isEditingMode && unfoldedTaskItem.indx === indx)) {
      setUnfoldedTaskItem(() => ({ indx, isActive: true, isEditing: !!isEditing }));
      setIsEditingMode(false);
    }
  };

  const closeUnfoldedTaskHandler = () => {
    setUnfoldedTaskItem(() => ({
      indx: -1,
      isActive: false,
      isEditing: false
    }));
    setIsEditingMode(false);
  };

  const unfoldedTaskSubmittedHandler = () => {
    setIsSubmitted(!isSubmitted);
    closeUnfoldedTaskHandler();
    tableRefetch();
  };

  const goToFirstPage = () => {
    setTableParams(prev => ({
      ...prev,
      pagination: {
        start: 1,
        number: 20
      }
    }));
  };

  useEffect(() => {
    tableRefetch();
    filterRefetch();
  }, [isSubmitted]);

  useEffect(() => {
    const checkIfCalendarIconClicked = (target: HTMLElement | null) => {
      if (target) {
        return [target.id, target.parentElement?.id].includes(`filterDatePickerPopper${PrefixForId.Open}Icon`);
      }
      return false;
    };
    const handleClickOutside = (e: MouseEvent) => {
      const scrollContainer = document.querySelector('.scroll-container');
      const isScrollPresent = scrollContainer && scrollContainer.scrollHeight > scrollContainer.clientHeight;
      const isClickOnScrollbar = e.clientX + 16 >= window.innerWidth && isScrollPresent;
      const isClickForClosingTask =
        wrapperRef.current &&
        !wrapperRef.current.contains(e.target) &&
        !checkIfCalendarIconClicked(e.target as HTMLElement | null) &&
        !isMenuOpen &&
        !isEditingMode &&
        !isClickOnScrollbar;

      if (isClickForClosingTask) {
        closeUnfoldedTaskHandler();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isEditingMode, isMenuOpen, wrapperRef]);

  const isCancelHidden = (item: TableItemProps) => {
    if (item.uds_aufgabentyp_fuhrpark.attributeValue?.value === TaskTypeId.LeaseExtention) {
      const availablePhases = isMobexoEmployee
        ? [
            LeaseExtentionPhases.Phase3,
            LeaseExtentionPhases.Phase4,
            LeaseExtentionPhases.Phase14,
            LeaseExtentionPhases.Phase15
          ]
        : [LeaseExtentionPhases.Phase2, LeaseExtentionPhases.Phase7];
      return !availablePhases.includes(item.lva_state);
    }

    if (item.uds_aufgabentyp_fuhrpark.attributeValue?.value === TaskTypeId.Appraise) {
      const availablePhases = [VehicleInitialReviewPhases.Phase1, VehicleInitialReviewPhases.Phase6];

      return !availablePhases.includes(item.vir_state);
    }

    if (item.uds_aufgabentyp_fuhrpark.attributeValue?.value === TaskTypeId.Checklist) {
      const availablePhases = [ChecklistPhases.Phase1, ChecklistPhases.Phase2, ChecklistPhases.Phase3];

      return !availablePhases.includes(item.chkl_state);
    }

    if (item.uds_aufgabentyp_fuhrpark.attributeValue?.value === TaskTypeId.VehicleReturn) {
      const availablePhases = [VehicleReturnPhases.Phase2];

      return !availablePhases.includes(item.vr_state);
    }

    return item.uds_b2c_portal_status.attributeValue?.value === TASK_STATUS_CODES.canceled || isDriver;
  };

  const isRestoreHidden = (item: TableItemProps) => {
    if (item.uds_aufgabentyp_fuhrpark.attributeValue?.value === TaskTypeId.LeaseExtention) {
      const availablePhases = isMobexoEmployee
        ? [
            LeaseExtentionPhases.Phase12,
            LeaseExtentionPhases.Phase13,
            LeaseExtentionPhases.Phase18,
            LeaseExtentionPhases.Phase19
          ]
        : [LeaseExtentionPhases.Phase11, LeaseExtentionPhases.Phase17];

      return !availablePhases.includes(item.lva_state);
    }

    return (
      item.uds_b2c_portal_status.attributeValue?.value !== TASK_STATUS_CODES.canceled ||
      isDriver ||
      item.uds_aufgabentyp_fuhrpark.attributeValue?.value !== TaskTypeId.Allgemein
    );
  };

  const showChangeStatusItem = (item: TableItemProps, statusId: StatusId) => {
    if (item.uds_aufgabentyp_fuhrpark.attributeValue?.value === TaskTypeId.LeaseExtention) {
      const availablePhases = [LeaseExtentionPhases.Phase3, LeaseExtentionPhases.Phase4];
      return isMobexoEmployee
        ? !availablePhases.includes(item.lva_state) || item.uds_b2c_portal_status?.attributeValue?.value === statusId
        : true;
    }

    return true;
  };

  const getAufrageMenuItems = useCallback(
    (item: TableItemProps, index: number) => {
      const isTypeLeaseExtention = item.uds_aufgabentyp_fuhrpark.attributeValue?.value === TaskTypeId.LeaseExtention;
      const isTypeAppraise = item.uds_aufgabentyp_fuhrpark.attributeValue?.value === TaskTypeId.Appraise;

      return [
        {
          title: 'Delegieren...',
          onItemClick: () =>
            openDelegateModal(
              item.activityid.attributeValue || '',
              item.uds_aufgabentyp_fuhrpark.attributeValue?.value || 0
            ),
          hidden:
            isMobexoEmployee ||
            item.uds_b2c_portal_status.attributeValue?.value === TASK_STATUS_CODES.canceled ||
            item.uds_b2c_portal_status.attributeValue?.value === TASK_STATUS_CODES.completed ||
            isDriver,
          id: 'Delegate'
        },
        {
          title: 'Bearbeiten',
          onItemClick: () => {
            openUnfoldedTaskHandler(index, true);
            setIsEditingMode(true);
          },
          hidden: item.uds_aufgabentyp_fuhrpark.attributeValue?.value !== TaskTypeId.Allgemein || isMobexoEmployee,
          disabled: [TASK_STATUS_CODES.completed, TASK_STATUS_CODES.canceled].includes(
            item.uds_b2c_portal_status.attributeValue?.value || 0
          ),
          id: 'Edit'
        },
        {
          title: 'Später erinnern...',
          onItemClick: () =>
            openRemindModal(item.scheduledend.attributeValue || '', item.activityid?.attributeValue || ''),
          hidden:
            item.uds_b2c_portal_status.attributeValue?.value === TASK_STATUS_CODES.canceled ||
            item.uds_b2c_portal_status.attributeValue?.value === TASK_STATUS_CODES.completed ||
            isMobexoEmployee,
          disabled: isPostponeDisabled(item.scheduledend.attributeValue || ''),
          id: 'RememberLater'
        },
        {
          title: 'Stornieren',
          onItemClick: () =>
            handleCancelTask(
              item.subject.attributeValue || '',
              item.activityid.attributeValue || '',
              isTypeLeaseExtention,
              item.regardingobjectid.attributeValue?.id || '',
              item.lva_state,
              CancelationTaskPhases[item.lva_state],
              isTypeAppraise,
              item.lva_phaseinfo?.lvaId
            ),
          hidden: isCancelHidden(item),
          id: 'Cancel'
        },
        {
          title: 'Wiederherstellen',
          onItemClick: () =>
            handleRestoreTask(
              item.activityid.attributeValue || '',
              isTypeLeaseExtention,
              item.regardingobjectid.attributeValue?.id || '',
              item.lva_state,
              RestorationTaskPhases[item.lva_state],
              item.lva_phaseinfo?.lvaId
            ),
          hidden: isRestoreHidden(item),
          id: 'Restore'
        },
        {
          title: 'Status: In Bearbeitung',
          onItemClick: () => handleUpdateStatus(item.activityid.attributeValue as string, StatusId.InProgress),
          hidden: showChangeStatusItem(item, StatusId.InProgress),
          id: 'In Bearbeitung'
        },
        {
          title: 'Status: Offen ',
          onItemClick: () => handleUpdateStatus(item.activityid.attributeValue as string, StatusId.Open),
          hidden: showChangeStatusItem(item, StatusId.Open),
          id: 'Offen '
        }
      ];
    },
    [
      isDriver,
      handleCancelTask,
      handleRestoreTask,
      openRemindModal,
      openDelegateModal,
      unfoldedTaskItem,
      isCancelHidden,
      isRestoreHidden,
      isMobexoEmployee
    ]
  );

  return (
    <div className={classes.aufgaben}>
      <div className={classes.aufgaben_wrapper}>
        <div className={classes.tasks_container}>
          <div className={classes.page_header}>
            {caseId && <GoToAllTasksButton />}

            <div className={classes.filters_container}>
              <div className={classes.filters_wrapper_left}>
                {!caseId && (
                  <AufgabenFilters
                    // @ts-ignore
                    filterData={filter}
                    user={user}
                    closeUnfoldedTaskHandler={closeUnfoldedTaskHandler}
                    sessionFilter={sessionFilter}
                    // @ts-ignore
                    setSessionFilter={setSessionFilter}
                    goToFirstPage={goToFirstPage}
                  />
                )}
              </div>
              {!isMobileWidth && (
                <>
                  <DownloadButton
                    component={ButtonComponentType.DownloadExcelIcon}
                    onClick={downloadFile}
                    disabled={!table?.tasks?.length}
                    iconWidth={32}
                    tooltip='Download File Excel'
                  />
                  <div className={classes.new_aufgaben}>
                    {isShowNewTaskBtn ? (
                      <Button
                        onClick={openCreateNewTaskModal}
                        id={PrefixForId.Modal + PrefixForId.Button + PrefixForId.Open}
                      >
                        <Flex align='center' width='max-content'>
                          <PlusLargeIcon className={classes.add_new_icon} />
                          Neue Aufgabe
                        </Flex>
                      </Button>
                    ) : null}
                  </div>
                </>
              )}
            </div>
          </div>
          {table?.tasks?.length ? (
            <section className={classes.container} ref={wrapperRef} id={PrefixForId.Table + PrefixForId.Container}>
              <div className={classes.headers_wrapper}>
                <div className={classes.headers_container}>
                  {!caseId &&
                    headers?.map((header, indx) => (
                      <EllipsisWithTooltip tooltip={header.title}>
                        <span
                          key={header.title}
                          onClick={() => headersReverseSort(indx, header.fieldName)}
                          className={classes.headers_title}
                          id={PrefixForId.Table + PrefixForId.Sorting + header.title}
                        >
                          {sessionSorting.predicate === header.fieldName ? (
                            <img
                              className={
                                !sessionSorting.reverse
                                  ? `${classes.sort_arrow} ${classes.reverse}`
                                  : classes.sort_arrow
                              }
                              src='/assets/images/down_arrow.svg'
                              alt='down_arrow'
                            />
                          ) : null}
                          {header.title}
                        </span>
                      </EllipsisWithTooltip>
                    ))}
                </div>
              </div>

              {table?.tasks?.map((item: TableItemProps, indx: number) => (
                <div key={indx}>
                  {unfoldedTaskItem.isActive && unfoldedTaskItem.indx === indx ? (
                    <UnfoldedTask
                      aufgeklappteAufgabeInfo={item}
                      closeAufgeklappteAufgabe={closeUnfoldedTaskHandler}
                      unfoldedTaskSubmittedHandler={unfoldedTaskSubmittedHandler}
                      statusColor={getStatusColor(
                        item.scheduledend.attributeValue || '',
                        item.uds_b2c_portal_status.attributeValue?.value || 0
                      )}
                      menuItems={getAufrageMenuItems(item, indx)}
                      isDriver={isDriver}
                      setIsMenuOpen={(isMenuOpen: boolean) => setIsMenuOpen(isMenuOpen)}
                      index={indx}
                      isEditing={unfoldedTaskItem.isEditing}
                      onCancelTaskEdit={() => openUnfoldedTaskHandler(indx, false)}
                    />
                  ) : (
                    <AufgabenItem
                      aufgabenInfo={item}
                      key={item.activityid.attributeTypeCode}
                      openUnfoldedTaskHandler={() => openUnfoldedTaskHandler(indx)}
                      statusColor={getStatusColor(
                        item.scheduledend.attributeValue || '',
                        item.uds_b2c_portal_status.attributeValue?.value || 0
                      )}
                      menuItems={getAufrageMenuItems(item, indx)}
                      index={indx}
                    />
                  )}
                </div>
              ))}
            </section>
          ) : (
            <PanelPlaceholder title='Nichts gefunden' description='Diese Seite enthält keine Ergebnisse.' />
          )}
        </div>
      </div>

      {table?.tasks?.length && table.totalCount > tableParams.pagination.number ? (
        <Pagination
          activePage={tableParams.pagination.start}
          itemsCountPerPage={tableParams.pagination.number}
          totalItemsCount={table.totalCount}
          onPageChange={pageChangeHandler}
        />
      ) : null}
    </div>
  );
};

const headers = [
  { title: 'Titel', fieldName: 'subject' },
  { title: 'Kennzeichen', fieldName: 'regardingobjectid' },
  { title: 'Mitarbeiter', fieldName: 'uds_executor_contactid' },
  { title: 'Unternehmen', fieldName: 'uds_executor_accountid' },
  { title: 'Fälligkeit', fieldName: 'scheduledend' },
  { title: 'Status', fieldName: 'statuscode' }
];

interface FilterProps {
  [key: string]: number[] | string[] | string | null | object;
}

export const CRM_VALUE_TASK_PAGE = {
  company: 'executorFirmId',
  scheduledendStatus: 'scheduledendStatus',
  scheduledendStart: 'StartScheduledDate',
  scheduledendEnd: 'EndScheduledDate',
  scheduledendDateOnClientSide: 'scheduledendDate',
  status: 'statuses'
};

export const emptyFilterStateServer: FilterProps = {
  executorFirmId: null,
  isWithoutCar: null,
  regardingObjectId: null,
  scheduledendStatus: null,
  statuses: null,
  aufgabenTyp: null,
  executorContact: null,
  StartScheduledDate: null,
  EndScheduledDate: null
};

export const emptyFilterStateClient: FilterProps = {
  executorFirmId: null,
  isWithoutCar: null,
  regardingObjectId: null,
  scheduledendStatus: null,
  statuses: null,
  aufgabenTyp: null,
  executorContact: null,
  scheduledendDate: null
};

export const defaultTableData = {
  tasks: [],
  totalCount: 0,
  noAskStatuses: false
};

export const defaultFilterData = {
  executorContacts: [],
  executorFirms: [],
  regardingObjects: [],
  scheduledendStatuses: [],
  statuses: [],
  taskTypes: []
};

const defaultTableParams = {
  pagination: {
    start: 1,
    number: 20
  },
  search: {}
};

const defaultSorting = { predicate: 'uds_executor_accountid', reverse: false };

export default AufgabenComponent;
