import React from 'react';
import PropTypes from 'prop-types';

export const EditIcon = ({ color = '#fff', width = '20', height = '20', ...props }) => {
  return (
    <svg width={width} height={height} {...props} viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M12.9286 2L2 12.9286L2.07143 17.9286L7.07143 18L18 7.07143L12.9286 2ZM16 7.07143L15.5 7.57143L12.5 4.57143L13 4.07143L16 7.07143ZM6.5 16.5714H3.5V13.5714L11.5 5.5L12.5 6.5L4.71429 14.2857L5.71429 15.2857L13.5 7.5L14.5 8.5L6.5 16.5714Z'
        fill={color}
      />
    </svg>
  );
};

EditIcon.propTypes = {
  color: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};
