import React from 'react';
import { SpinnerContainerProps } from './spinner-container.types';
import Spinner from 'components/spinner';
import { StyledSpinnerContainer } from 'components/global-reference-vehicles/action-switcher/action-switcher.styled';

const SpinnerContainer = ({ minHeight = '300px' }: SpinnerContainerProps) => {
  return (
    <StyledSpinnerContainer minHeight={minHeight}>
      <Spinner style={spinnerStyle} />
    </StyledSpinnerContainer>
  );
};

const spinnerStyle = {
  width: '40px',
  position: 'absolute',
  left: '50%',
  top: '50%',
  transform: 'translate(-50%, -50%)'
};

export default SpinnerContainer;
