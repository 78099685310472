import React from 'react';
import PropTypes from 'prop-types';

import Panel from '../panel/panel';
import classes from './panel-placeholder.module.scss';
import classNames from 'classnames';
import { PrefixForId } from '../../common/enums';

const PanelPlaceholder = ({
  title,
  description,
  children,
  img = '/assets/images/role-less-image.png',
  icon,
  className = null,
  maxWidth = '1170px',
  descriptionBg = false
}) => {
  return (
    <Panel
      id={PrefixForId.Panel + PrefixForId.Placeholder}
      style={{ maxWidth: maxWidth }}
      className={className ? classNames(className, classes.panel) : classes.panel}
    >
      {icon ? icon : <img width='200px' src={img} alt='new_config_icon' />}
      <h6 id={PrefixForId.Panel + PrefixForId.Placeholder + PrefixForId.Title} className={classes.title}>
        {title}
      </h6>
      <div
        id={PrefixForId.Panel + PrefixForId.Placeholder + PrefixForId.Description}
        className={classNames(classes.description, descriptionBg && classes.descriptionBg)}
      >
        {description}
      </div>
      {children}
    </Panel>
  );
};

PanelPlaceholder.propTypes = {
  title: PropTypes.string,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.any]),
  img: PropTypes.string,
  children: PropTypes.any,
  className: PropTypes.string,
  descriptionBg: PropTypes.bool,
  icon: PropTypes.any
};

PanelPlaceholder.defaultProps = {
  icon: null
};

export default PanelPlaceholder;
