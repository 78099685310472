import React from 'react';
import { PanelItemFrameProps } from './panel-item-frame.props';

export const PanelItemFrame = ({ children, isActive }: PanelItemFrameProps) => {
  if (!isActive) {
    return null;
  }

  return <div>{children}</div>;
};
