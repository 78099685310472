export enum MenuTitles {
  BusinessDetails = 'Geschäftsdetails',
  Contact = 'Kontakt',
  Company = 'Unternehmen',
  Payment = 'Zahlung',
  LedgerAccountMapping = 'Sachkonto Mapping',
  Notification = 'Benachrichtigung',
  Leasing = 'Leasing',
  CostCenters = 'Kostenstellen',
  PoolCalendar = 'Poolkalender',
  InspectionDates = 'Prüftermine',
  ConsumptionSurcharge = 'Verbrauchsaufschlag'
}

export interface SettingsMenuItems {
  title: MenuTitles;
  active: boolean;
  beta?: boolean;
}
