import React, { Component, Fragment, useCallback, useEffect, useRef, useState } from 'react';

import classes from './comparison-form.module.scss';
import ComparisonCard from '../comparison-panel/comparison-card/comparison-card-view';
import { CONFIGURATION_TYPES, Transmission } from '../../constants';
import { PriceItem, requestOfferDetailsAsync } from '../../services/price-calculator-service';
import PriceOptionsContainer from '../price-calculator/price-options';
import classnames from 'classnames';
import Select from '../select';
import cloneDeep from 'lodash/cloneDeep';
import ListItem from '../price-calculator/flatrate-section/list-item';
import toMoney from '../../utils/toMoney';
import { sum } from '../../utils/summ';
import { debounce } from 'lodash';
import classesNames from 'classnames';
import { getLeasingangebotesForGRV } from '../barpreisanfragen-component/utils';

class ComparisonForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      left: '0',
      cars: []
    };
  }

  deleteCar = id => {
    const newCars = this.state.cars.filter(car => car.uds_fahrzeugangeboteid !== id);
    this.setState({ cars: newCars });
    this.props.deleteFromComparison(id);
  };

  nextCar = () => {
    switch (this.state.left) {
      case '0':
        this.setState({ left: '-50%' });
        break;
      case '-50%':
        this.setState({ left: '-100%' });
        break;
      default:
        break;
    }
  };
  prevCar = () => {
    switch (this.state.left) {
      case '-100%':
        this.setState({ left: '-50%' });
        break;
      case '-50%':
        this.setState({ left: '0' });
        break;
      default:
        break;
    }
  };

  mileageChangeHandler = (e, id) => {
    const newCars = cloneDeep(this.state.cars);
    const changedCar = newCars.find(car => car.uds_fahrzeugangeboteid === id);
    const period = changedCar.priceItem.period;
    const mileage = +e.target.value;

    let priceItem = PriceItem.find(changedCar.priceItems, mileage, period);

    priceItem = this.setPrevStateCheckboxes(priceItem, changedCar);

    changedCar.priceItem = priceItem;
    this.setState({ cars: newCars });
  };

  setPrevStateCheckboxes = (priceItem, changedCar) => {
    for (let key in priceItem) {
      if (
        priceItem.hasOwnProperty(key) &&
        key.includes('_checkbox') &&
        !key.includes('_checkbox_disabled') &&
        key !== 'wkrleasingenthalten_checkbox'
      ) {
        priceItem[key] = changedCar.priceItem[key];
      }
    }
    priceItem.price = sum(priceItem);

    return priceItem;
  };

  periodChangeHandler = (e, id) => {
    const period = +e.target.value;
    const newCars = cloneDeep(this.state.cars);
    const changedCar = newCars.find(car => car.uds_fahrzeugangeboteid === id);
    let priceItem = PriceItem.getPriceItemByPeriod(changedCar.priceItems, changedCar.priceItem, period);
    const mileages = PriceItem.getMileagesForPeriod(changedCar.priceItems, period);

    priceItem = this.setPrevStateCheckboxes(priceItem, changedCar);

    changedCar.mileages = mileages;
    changedCar.priceItem = priceItem;
    this.setState({ cars: newCars });
  };

  getDefaultPriceOptions = car => {
    const priceItem = PriceItem.fromFahr(car);
    return [priceItem];
  };

  loadOfferDetails = async () => {
    try {
      let cars = [...this.props.comparisonCars];
      let carsOfferDetails = await Promise.all(cars.map(car => requestOfferDetailsAsync(car.uds_fahrzeugangeboteid)));
      let isGlobalReferenceVehicle =
        cars[0]?.uds_configurationtypecode_value === CONFIGURATION_TYPES.globalReferenceVehicle.value;
      let grvLeasingOffersData;
      if (isGlobalReferenceVehicle) {
        const response = await Promise.all(cars.map(car => getLeasingangebotesForGRV(car.uds_fahrzeugangeboteid)));
        grvLeasingOffersData = response?.map(item => item.data?.data[0]) || [];
      }

      let newCars = [];
      carsOfferDetails.forEach(carDetails => {
        let { data: priceItems, config } = carDetails;
        let car = cars.find(car => car.uds_fahrzeugangeboteid === JSON.parse(config.data).uds_bezugid);
        priceItems = priceItems.length ? priceItems : this.getDefaultPriceOptions(car);

        let priceItem;
        if (isGlobalReferenceVehicle) {
          const grvLeasingOfferData = grvLeasingOffersData.find(
            item => item.uds_bezugid.attributeValue?.id === car.uds_fahrzeugangeboteid
          );
          priceItem =
            priceItems.find(
              item => item.finanzrate === grvLeasingOfferData?.new_finanzratemobexo.attributeValue?.value
            ) || priceItems[0];
        } else {
          priceItem = priceItems[0];
        }
        const mileages = PriceItem.getMileagesForPeriod(priceItems, priceItem.period);
        const periods = PriceItem.getPeriods(priceItems);

        for (let key in priceItem) {
          // checkboxes checked by default
          if (
            priceItem.hasOwnProperty(key) &&
            key.includes('_checkbox') &&
            !key.includes('_checkbox_disabled') &&
            key !== 'wkrleasingenthalten_checkbox'
          ) {
            priceItem[key] = true;
          }
        }
        priceItem.price = sum(priceItem) || 0;

        newCars.push({ ...car, priceItems, priceItem, mileages, periods });
      });
      this.setState({ cars: newCars });
    } catch (err) {
      console.log(err);
    }
  };

  flatrateChangeHandler = (name, checked, id) => {
    const newCars = cloneDeep(this.state.cars);
    const changedCar = newCars.find(car => car.uds_fahrzeugangeboteid === id);

    const flatrate = cloneDeep(changedCar.priceItem);
    flatrate[name] = checked;
    flatrate.price = sum(flatrate);
    changedCar.priceItem = flatrate;
    this.setState({ cars: newCars });
  };

  async componentDidMount() {
    await this.loadOfferDetails();
  }

  render() {
    if (!this.props.comparisonCars.length) {
      this.props.closeModal();
    }
    const cars = [...this.state.cars];
    while (cars.length < 4) {
      cars.push(null);
    }

    return (
      <div className={classes.body}>
        <img
          src='/assets/images/close-icon-configurator.png'
          className={classes.close_icon}
          alt='close'
          onClick={this.props.closeModal}
        />
        <h4 className={classes.title}>Ihre Fahrzeuge im Vergleich</h4>
        <div className={classes.header}>
          <div style={{ left: this.state.left }} className={classes.cars_container}>
            {cars.map((car, i) => (
              <ComparisonCard key={i} car={car} deleteCar={this.deleteCar} />
            ))}
          </div>
          <div className={classes.arrows}>
            <span onClick={this.prevCar}>
              <img src='/assets/images/left-arrow.png' alt='left_arrow' />
            </span>
            <span onClick={this.nextCar}>
              <img src='/assets/images/right-arrow.png' alt='right_arrow' />
            </span>
          </div>
        </div>

        <h5 className={classes.section_title}>Fahrzeugdaten</h5>
        <ComparisonValue cars={cars} left={this.state.left} title={'Marke'} value={'uds_marke'} />
        <ComparisonValue cars={cars} left={this.state.left} title={'Modell'} value={'uds_modell'} />

        <p className={classes.value_title}>Treibstoff</p>
        <div style={{ left: this.state.left }} className={[classes.cars_container, classes.value_container].join(' ')}>
          {cars.map((car, i) => (car ? <p key={i}>{car.engineType.name || '-'}</p> : <p key={i}>-</p>))}
        </div>

        <ComparisonValue cars={cars} left={this.state.left} title={'Leistung, kW'} value={'uds_leistungkw'} />

        <p className={classes.value_title}>Getriebe</p>
        <div style={{ left: this.state.left }} className={[classes.cars_container, classes.value_container].join(' ')}>
          {cars.map((car, i) =>
            car ? <p key={i}>{Transmission[car.uds_getriebe_value] || '-'}</p> : <p key={i}>-</p>
          )}
        </div>

        <ComparisonValue cars={cars} left={this.state.left} title={'Farbe'} value={'uds_color'} />
        <ComparisonValue cars={cars} left={this.state.left} title={'Innenfarbe'} value={'uds_interiorcolor'} />

        <h5 className={classes.section_title}>Leasingdaten</h5>

        <p className={classes.value_title}>Bruttolistenpreis</p>
        <div style={{ left: this.state.left }} className={[classes.cars_container, classes.value_container].join(' ')}>
          {cars.map((car, i) =>
            car ? (
              <p key={i}>{car.uds_listenpreis_value ? toMoney(car.uds_listenpreis_value) : '-'}</p>
            ) : (
              <p key={i}>-</p>
            )
          )}
        </div>

        <p className={classes.value_title}>Nettolistenpreis</p>
        <div style={{ left: this.state.left }} className={[classes.cars_container, classes.value_container].join(' ')}>
          {cars.map((car, i) =>
            car ? (
              <p key={i}>{car.uds_listenpreis_netto_value ? toMoney(car.uds_listenpreis_netto_value) : '-'}</p>
            ) : (
              <p key={i}>-</p>
            )
          )}
        </div>

        <p className={classes.value_title}>Leasingfaktor</p>
        <div style={{ left: this.state.left }} className={[classes.cars_container, classes.value_container].join(' ')}>
          {cars.map((car, i) =>
            car ? (
              <p key={i}>
                {car.uds_listenpreis_netto_value && car.priceItem.finanzrate
                  ? ((car.priceItem.finanzrate * 100) / car.uds_listenpreis_netto_value).toFixed(2) || '-'
                  : '-'}
              </p>
            ) : (
              <p key={i}>-</p>
            )
          )}
        </div>

        <p className={classes.value_title}>Umweltbonus</p>
        <div style={{ left: this.state.left }} className={[classes.cars_container, classes.value_container].join(' ')}>
          {cars.map((car, i) =>
            car ? (
              <p key={i}>{car.uds_bafa === true ? 'Ja' : car.uds_bafa === false ? 'Nein' : '-'}</p>
            ) : (
              <p key={i}>-</p>
            )
          )}
        </div>

        <p className={classes.value_title}>Finanzrate</p>
        <div style={{ overflow: 'hidden' }}>
          <div
            style={{ left: this.state.left }}
            className={[classes.cars_container, classes.value_container].join(' ')}
          >
            {cars.map((car, i) =>
              car ? (
                <div key={i} className={classes.finanzrate_container}>
                  {/*<div className={classnames('select filter-select', classes.option_set_container)}>*/}
                  {/*  <Select*/}
                  {/*    options={PriceOptionsContainer.toPeriodOptions(car.periods)}*/}
                  {/*    selectProps={{*/}
                  {/*      className: 'form-control btn btn-default btn-block select-manufacturer',*/}
                  {/*      style: { borderColor: 'rgba(0, 0, 0, 0.75)' },*/}
                  {/*      name: 'period',*/}
                  {/*      onChange: e => this.periodChangeHandler(e, car.uds_fahrzeugangeboteid),*/}
                  {/*      value: car.priceItem.period*/}
                  {/*    }}*/}
                  {/*  />*/}
                  {/*</div>*/}
                  {/*<div className={classnames('select filter-select', classes.option_set_container)}>*/}
                  {/*  <Select*/}
                  {/*    options={PriceOptionsContainer.toMileageOptions(car.mileages)}*/}
                  {/*    selectProps={{*/}
                  {/*      className: 'form-control btn btn-default btn-block select-manufacturer',*/}
                  {/*      style: { borderColor: 'rgba(0, 0, 0, 0.75)' },*/}
                  {/*      name: 'mileage',*/}
                  {/*      onChange: e => this.mileageChangeHandler(e, car.uds_fahrzeugangeboteid),*/}
                  {/*      value: car.priceItem.mileagePerYear*/}
                  {/*    }}*/}
                  {/*  />*/}
                  {/*</div>*/}
                  <p key={i}>
                    {(
                      <Fragment>
                        <span>{toMoney(car.priceItem.finanzrate)}</span>
                        <span style={{ fontSize: '15px' }}>/ Monat netto</span>
                      </Fragment>
                    ) || '-'}
                  </p>
                </div>
              ) : (
                <p key={i}>-</p>
              )
            )}
          </div>
        </div>

        <h5 className={classes.section_title}>Rundum-Sorglos-Paket</h5>
        <div style={{ overflow: 'hidden' }}>
          <div
            style={{ left: this.state.left }}
            className={[classes.cars_container, classes.value_container].join(' ')}
          >
            {cars.map((car, i) =>
              car ? (
                <div key={i} className={classes.finanzrate_container}>
                  <ul className={classes.flatrate_list}>
                    <ListItem
                      checked={car.priceItem.insurance_checkbox}
                      name={'insurance_checkbox'}
                      icon={<img src='/assets/images/guarantee-32.png' alt='Vollkasko-Versicherung' />}
                      value={toMoney(car.priceItem.insurance)}
                      label={'Vollkasko-Versicherung'}
                      disabled={!car.priceItem.insurance_checkbox_disabled}
                      onChange={(name, checked) =>
                        this.flatrateChangeHandler(name, checked, car.uds_fahrzeugangeboteid)
                      }
                    />
                    <ListItem
                      checked={car.priceItem.tax_checkbox}
                      name={'tax_checkbox'}
                      icon={<img src='/assets/images/steuer-32.png' alt='Kfz-Steuer' />}
                      value={toMoney(car.priceItem.tax)}
                      label={'Kfz-Steuer'}
                      disabled={!car.priceItem.tax_checkbox_disabled}
                      onChange={(name, checked) =>
                        this.flatrateChangeHandler(name, checked, car.uds_fahrzeugangeboteid)
                      }
                    />
                    <ListItem
                      checked={car.priceItem.gez_checkbox}
                      name={'gez_checkbox'}
                      icon={<img src='/assets/images/zoll-32.png' alt='GEZ Gebühr' />}
                      value={toMoney(car.priceItem.gez)}
                      label={'GEZ Gebühr'}
                      disabled={!car.priceItem.gez_checkbox_disabled}
                      onChange={(name, checked) =>
                        this.flatrateChangeHandler(name, checked, car.uds_fahrzeugangeboteid)
                      }
                    />
                    {!car.priceItem.wkrleasingenthalten_checkbox ? (
                      <ListItem
                        checked={car.priceItem.winterWheelsStorage_checkbox}
                        name={'winterWheelsStorage_checkbox'}
                        icon={<img src='/assets/images/winter.png' alt='Winterräder' />}
                        value={toMoney(car.priceItem.winterWheelsStorage)}
                        label={'Winterräder'}
                        disabled={!car.priceItem.winterWheelsStorage_checkbox_disabled}
                        onChange={(name, checked) =>
                          this.flatrateChangeHandler(name, checked, car.uds_fahrzeugangeboteid)
                        }
                      />
                    ) : (
                      <ListItem
                        icon={<img src='/assets/images/winter.png' alt='Winterräder' />}
                        label={'Winterräder'}
                        disabled={true}
                      />
                    )}
                    <ListItem
                      checked={car.priceItem.wheelStorageReplacement_checkbox}
                      name={'wheelStorageReplacement_checkbox'}
                      icon={<img src='/assets/images/wheel.png' alt='Rädereinlagerung & -wechsel' />}
                      value={toMoney(car.priceItem.wheelStorageReplacement)}
                      label={'Rädereinlagerung & -wechsel'}
                      disabled={!car.priceItem.wheelStorageReplacement_checkbox_disabled}
                      onChange={(name, checked) =>
                        this.flatrateChangeHandler(name, checked, car.uds_fahrzeugangeboteid)
                      }
                    />
                    <ListItem
                      checked={car.priceItem.tireReplacement_checkbox}
                      name={'tireReplacement_checkbox'}
                      icon={<img src='/assets/images/rad-32.png' alt='Reifenersatz' />}
                      value={toMoney(car.priceItem.tireReplacement)}
                      label={'Reifenersatz'}
                      disabled={!car.priceItem.tireReplacement_checkbox_disabled}
                      onChange={(name, checked) =>
                        this.flatrateChangeHandler(name, checked, car.uds_fahrzeugangeboteid)
                      }
                    />
                    <ListItem
                      checked={car.priceItem.maintenance_checkbox}
                      name={'maintenance_checkbox'}
                      icon={<img src='/assets/images/inspektion-32.png' alt='Wartung/Inspektion' />}
                      value={toMoney(car.priceItem.maintenance)}
                      label={'Wartung/Inspektion'}
                      disabled={!car.priceItem.maintenance_checkbox_disabled}
                      onChange={(name, checked) =>
                        this.flatrateChangeHandler(name, checked, car.uds_fahrzeugangeboteid)
                      }
                    />
                  </ul>
                </div>
              ) : (
                <p key={i}>-</p>
              )
            )}
          </div>
        </div>

        <p className={classes.value_title}>Full-Service Preis plus Kraftstoffkosten</p>
        <div style={{ left: this.state.left }} className={[classes.cars_container, classes.value_container].join(' ')}>
          {cars.map((car, i) =>
            car ? (
              <p key={i}>
                <Fragment>
                  <span style={{ fontSize: '22px', color: '#335566' }}>
                    {toMoney(
                      (car.uds_configurationtypecode_value === CONFIGURATION_TYPES.globalReferenceVehicle.value
                        ? car.uds_gesamtkosten_monatlich_vertragsabschluss_value || 0
                        : car.uds_mobexo_gesamtrate_value || 0) + (car.kraftstoffcosten || 0)
                    )}
                  </span>
                  <span style={{ fontSize: '15px', color: '#335566' }}>/ Monat netto</span>
                </Fragment>
              </p>
            ) : (
              <p key={i}>-</p>
            )
          )}
        </div>

        <VerbrauchSectionExpand cars={cars} left={this.state.left} />
      </div>
    );
  }
}

function ComparisonValue(props) {
  return (
    <Fragment>
      <p className={classes.value_title}>{props.title}</p>
      <div style={{ left: props.left }} className={[classes.cars_container, classes.value_container].join(' ')}>
        {props.cars.map((car, i) => (car ? <p key={i}>{car[props.value] || '-'}</p> : <p key={i}>-</p>))}
      </div>
    </Fragment>
  );
}

function VerbrauchSectionExpand(props) {
  const [setHeight, setHeightState] = useState('0px');
  const [setActive, setActiveState] = useState(false);
  const content = useRef(null);

  function toggleAccordion() {
    setActiveState(!setActive);
    setHeightState(setActive ? '0px' : `${content.current.scrollHeight + 1}px`);
  }

  const resizeHandler = useCallback(
    debounce(() => {
      if (!setActive) return;
      const height = content.current.scrollHeight + 1;
      setHeightState(`${height}px`);
    }, 50),
    [setActive, setHeightState]
  );

  useEffect(() => {
    window.addEventListener('resize', resizeHandler);
    return () => {
      window.removeEventListener('resize', resizeHandler);
    };
  });

  return (
    <Fragment>
      <h5 style={{ cursor: 'pointer' }} onClick={toggleAccordion} className={classes.section_title}>
        Umwelt & Verbrauch{' '}
        <img
          className={classesNames(classes.subitem_arrow, setActive ? classes.subitem_arrow_expand : null)}
          src='/assets/images/expand.png'
          alt='expand'
        />
      </h5>
      <div ref={content} style={{ maxHeight: `${setHeight}`, overflow: 'hidden', transition: 'all .6s ease' }}>
        <ComparisonValue
          cars={props.cars}
          left={props.left}
          title={'Kraftstoffverbrauch (kombiniert)'}
          value={'uds_uds_fuelconsumption_combined'}
        />
        <ComparisonValue
          cars={props.cars}
          left={props.left}
          title={'Kraftstoffverbrauch (innerorts)'}
          value={'uds_fuelconsumption_urban'}
        />
        <ComparisonValue
          cars={props.cars}
          left={props.left}
          title={'Kraftstoffverbrauch (außerorts)'}
          value={'uds_fuelconsumption_out_of_city'}
        />
        <ComparisonValue
          cars={props.cars}
          left={props.left}
          title={'Stromverbrauch (kombiniert)'}
          value={'new_stromverbrauch'}
        />
        <ComparisonValue
          cars={props.cars}
          left={props.left}
          title={'CO2-Emissionen (kombiniert)'}
          value={'uds_co2_emission_combined'}
        />
      </div>
    </Fragment>
  );
}

export default ComparisonForm;
