import { AxiosRequestConfig } from 'axios';

class VehicleOfferRequestConfig {
  deactivate = (fahrzeugangeboteId: string): AxiosRequestConfig => ({
    method: 'POST',
    url: 'fahrzeugangebote/deactivate',
    data: {
      fahrzeugangeboteId
    }
  });

  getDriversPersonalConfigurations = (userId: string): AxiosRequestConfig => ({
    method: 'POST',
    url: 'fahrzeugangebote/getByUserId',
    data: {
      userId
    }
  });
}

export const vehicleOfferRequestConfig = new VehicleOfferRequestConfig();
