import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { toggleModal, setSnakbar } from '../../../../actions/app_action';
import { useOverlay } from 'hooks';
import { REPLACEMENT_VEHICLE_PHASES, MODALS, MODAL_NAMES, USER_ROLES } from '../../../../constants';
import { noNeedReplacementVehicle } from 'components/aufgaben-component/utils';
import NoReplacementVehicle from 'components/aufgaben-component/components/no-need-replacement/no-need-replacement';
import { encodeBase64 } from 'utils/decode-encode-base64';
import { useIsUserHaveRole } from 'hooks/use-is-user-have-role';
import OfferStatusSection from 'components/product/offer-status-section/offer-status-section';
import { format, parseISO } from 'date-fns';
import { Flex, Text, Button } from 'common/common-components.styled';
import { PAGES } from 'common/enums';
import { StyledButtonsContainer, StyledButton } from './leasing-end-generic.card.styled';

interface RVOPhaseProps {
  progressInfo: {
    id: string;
    name: string;
    progressCode: number;
  }[];
  redirect: (configId: string) => void;
  startTaskDate?: string;
  configurationId?: {
    id: string;
    keyAttributes: [];
    logicalName: string;
    name: string;
  };
  orderedVehicle?: {
    id: string;
    keyAttributes: [];
    logicalName: string;
    name: string;
  };
}

const ConfigurationStatusRender = ({ progressInfo, redirect }: RVOPhaseProps) => {
  return (
    <Flex direction='column' top='5'>
      {progressInfo.map(item => {
        return (
          <Flex key={item.id} bottom='10' align='center'>
            <Text
              color='grey500'
              right='10'
              pointer
              onClick={() => redirect(item.id)}
            >{`Konfiguration ${item.name}`}</Text>
            <OfferStatusSection status={item.progressCode} bottom='0' />
          </Flex>
        );
      })}
    </Flex>
  );
};

interface RVOProps {
  rvo_phaseinfo: RVOPhaseProps;
  rvo_state: number;
}

interface LeaseEndWidgetProps {
  rvo: RVOProps;
  carId: string;
  driver: {
    attributeTypeCode: number;
    attributeValue: {
      id: string;
      logicalName: string;
      name: string;
    };
  };
  plateNumber: string;
  setRefreshKey: any;
}

export const RVOButtons = ({ rvo, carId, driver, plateNumber, setRefreshKey }: LeaseEndWidgetProps) => {
  const history = useHistory();
  const isFleetManager = useIsUserHaveRole(USER_ROLES.fleetManager.id);
  const dispatch = useDispatch();
  const [showOverlay, hideOverlay] = useOverlay();

  const redirectToOrders = useCallback(() => {
    isFleetManager
      ? rvo.rvo_phaseinfo.configurationId &&
        history.push(`${PAGES.ORDER_DETAILS}?id=${encodeBase64(rvo.rvo_phaseinfo.configurationId.id)}`)
      : rvo.rvo_phaseinfo.configurationId &&
        history.push(`${PAGES.CONFIGURATION_DETAILS}?id=${encodeBase64(rvo.rvo_phaseinfo.configurationId.id)}`);
  }, [history, isFleetManager, rvo]);

  const redirectToNewVehicle = () => {
    rvo.rvo_phaseinfo.orderedVehicle &&
      history.push(`${PAGES.MY_VEHICLES_DETAILS}/?id=${rvo.rvo_phaseinfo.orderedVehicle.id}`);
  };

  const closeAlert = useCallback(() => {
    dispatch(toggleModal(MODALS.alert, null));
  }, [dispatch]);

  const handleNoNeedReplacement = useCallback(() => {
    closeAlert();
    showOverlay();
    noNeedReplacementVehicle(null, carId).then(response => {
      setRefreshKey((oldKey: number) => oldKey + 1);
      dispatch(
        setSnakbar({
          isOpen: true,
          message: response.data.isSuccess
            ? 'Deine Entscheidung wurde erfolgreich gespeichert'
            : response.data.messages[0].message,
          type: response.data.level.toLowerCase()
        })
      );
      hideOverlay();
    });
  }, [closeAlert, dispatch, showOverlay, hideOverlay, carId, setRefreshKey]);

  const openNoNeedReplacementModal = useCallback(() => {
    const alertData = {
      title: 'Bestätigung',
      children: (
        <NoReplacementVehicle onSubmit={handleNoNeedReplacement} onCancel={closeAlert} plateNumber={plateNumber} />
      ),
      buttons: [
        {
          type: 'cancel',
          title: 'Abbrechen',
          action: closeAlert,
          hide: true
        }
      ],
      allButtonsHidden: true
    };
    dispatch(toggleModal(MODALS.alert, MODAL_NAMES.alert, alertData));
  }, [closeAlert, dispatch, handleNoNeedReplacement, plateNumber]);

  const redirectToConfigureVehicle = useCallback(() => {
    if (isFleetManager) {
      if (driver.attributeValue) {
        history.push(
          `${PAGES.NEW_REFERENCE_CONFIGURATIONS}?driverId=${encodeBase64(driver.attributeValue.id)}&redirect=${true}`
        );
      } else {
        history.push(`${PAGES.EMPLOYEE_CONFIGURATIONS}?newConfig=${true}`);
      }
    } else {
      history.push(`${PAGES.NEW_CONFIGURATIONS}?redirect=${true}`);
    }
  }, [history, isFleetManager, driver]);

  switch (rvo.rvo_state) {
    case REPLACEMENT_VEHICLE_PHASES.created:
      return <Button onClick={redirectToConfigureVehicle}>Jetzt konfigurieren</Button>;
    case REPLACEMENT_VEHICLE_PHASES.open:
      return (
        <StyledButtonsContainer>
          {isFleetManager && (
            <StyledButton secondary onClick={openNoNeedReplacementModal} right='10'>
              Keine Ersatzbeschaffung
            </StyledButton>
          )}
          <StyledButton onClick={redirectToConfigureVehicle}>Konfigurieren</StyledButton>
        </StyledButtonsContainer>
      );
    case REPLACEMENT_VEHICLE_PHASES.closedWithoutVehicle:
      return <Button onClick={redirectToConfigureVehicle}>Trotzdem konfigurieren</Button>;
    case REPLACEMENT_VEHICLE_PHASES.processing:
      return <Button onClick={redirectToConfigureVehicle}>Weitere Konfiguration starten</Button>;
    case REPLACEMENT_VEHICLE_PHASES.closedVehicleOrdered:
      return <Button onClick={redirectToOrders}>Zur Bestellung →</Button>;
    case REPLACEMENT_VEHICLE_PHASES.closedVehicleDelivered:
      return <Button onClick={redirectToNewVehicle}>Zum Fahrzeug →</Button>;
  }
};

const ReplacementVehicleOrderingCard = (rvo: RVOProps) => {
  const history = useHistory();
  const isFleetManager = useIsUserHaveRole(USER_ROLES.fleetManager.id);

  const redirectToConfiguration = useCallback(
    (itemId: string) => {
      isFleetManager
        ? history.push(`${PAGES.EMPLOYEE_CONFIGURATION_DETAILS}?id=${encodeBase64(itemId)}`)
        : history.push(`${PAGES.CONFIGURATION_DETAILS}?id=${encodeBase64(itemId)}`);
    },
    [history, isFleetManager]
  );

  const getPhasesDescriptions = useCallback(
    (phase: number) => {
      switch (phase) {
        case REPLACEMENT_VEHICLE_PHASES.created:
          return rvo.rvo_phaseinfo.startTaskDate && format(parseISO(rvo.rvo_phaseinfo?.startTaskDate), 'dd.MM.yyyy');
        case REPLACEMENT_VEHICLE_PHASES.open:
          return 'Ein Ersatzfahrzeug wurde noch nicht konfiguriert';
        case REPLACEMENT_VEHICLE_PHASES.closedWithoutVehicle:
          return 'Ein Ersatzfahrzeug wurde als nicht benötigt gekennzeichnet';
        case REPLACEMENT_VEHICLE_PHASES.processing:
          return (
            <ConfigurationStatusRender
              progressInfo={rvo.rvo_phaseinfo?.progressInfo}
              redirect={redirectToConfiguration}
            />
          );
        case REPLACEMENT_VEHICLE_PHASES.closedVehicleOrdered:
          return 'Das Ersatzfahrzeug wurde bestellt';
        case REPLACEMENT_VEHICLE_PHASES.closedVehicleDelivered:
          return `Ersatzfahrzeug mit Kennzeichen ${rvo.rvo_phaseinfo.orderedVehicle?.name} ist in Betrieb`;
      }
    },
    [redirectToConfiguration, rvo]
  );

  return (
    <Flex direction='column'>
      <Flex width={rvo.rvo_state === REPLACEMENT_VEHICLE_PHASES.processing ? '100%' : '225px'}>
        <Text color='grey500' size='12'>
          {getPhasesDescriptions(rvo.rvo_state)}
        </Text>
      </Flex>
    </Flex>
  );
};

export default ReplacementVehicleOrderingCard;
