import React, { useCallback, useState } from 'react';
import classes from './my-vehicles-filters.module.scss';
import { Filter, FilterButton, FilterContainer, FilterInput, PickersContainer } from '../../../components/filter-items';
import { CRM_VALUE, emtyFilterState, emtyFilterStateForServer } from '../my-vehicles';
import { MyVehiclesFiltersProps } from '../my-vehicles.props';
import { AnyObject, LookupResponse, PicklistResponse } from 'common/interfaces';
import { convertDateToServer } from 'utils/convert-date-to-server';
import { useGetFleetFilterData } from 'hooks/react-query/fleet/use-get-fleet-filter-data/use-get-fleet-filter-data';
import { debounce } from 'lodash';
import { FilterDataParams } from 'request-config/fleet/fleet.interface';
import { useMultiplyFilter } from 'hooks/use-multiply-filter/use-multiply-filter';
import { useOnCloseFilter } from './hooks/use-on-close-filter';
import { FilterExpandContainer } from 'components/filter-items/filter-expand-container/filter-expand-container';
import { useMyVehicleIsFilterExpanded } from './hooks/use-is-filter-expand';
import { PAGES } from 'common/enums';
import { useHistory } from 'react-router-dom';
import { FilterExpandClearButton } from 'components/filter-items/filter-expand-container/components/filter-expand-clear-button';
import { CONSUMPTION_BUTTONS, VEHICLE_STATUS_BUTTONS, VEHICLE_STATUS_PROCESSING_BUTTONS } from './utils';
import useWindowSize from 'hooks/use-window-size';

export const MyVehiclesFilters = ({ sessionFilter, setSessionFilter, goToFirstPage }: MyVehiclesFiltersProps) => {
  const [isOpen, setOpen] = useState('');
  const [filterDataParams, setFilterDataParams] = useState<FilterDataParams>({ searchValue: null, columnName: null });

  const { filterData, isFetching } = useGetFleetFilterData(filterDataParams);

  const { windowWidth } = useWindowSize(500);

  const history = useHistory();

  const changeHandlerDebounce = useCallback(
    debounce((params: FilterDataParams) => setFilterDataParams(params), 500),
    []
  );

  useOnCloseFilter(!!isOpen, setFilterDataParams);
  const { isFilterExpended } = useMyVehicleIsFilterExpanded(sessionFilter);
  const {
    label: stateCodeLabel,
    allLabels: stateCodeAllLabels,
    submitFilter: stateCodeSubmitFilter
  } = useMultiplyFilter(sessionFilter, CRM_VALUE.stateCode);

  const {
    label: statusLabel,
    allLabels: statusAllLabels,
    submitFilter: statusSubmitFilter
  } = useMultiplyFilter(sessionFilter, CRM_VALUE.status);

  const carNumberLabel = sessionFilter?.filterForClient[CRM_VALUE.carNumber];
  const carLabel = sessionFilter?.filterForClient[CRM_VALUE.car];
  const manufacturerLabel = sessionFilter?.filterForClient[CRM_VALUE.manufacturer];
  const modelLabel = sessionFilter?.filterForClient[CRM_VALUE.model];
  const leaseReturnLabel = sessionFilter?.filterForClient[CRM_VALUE.leaseReturn];
  const firstRegistrationLabel = sessionFilter?.filterForClient[CRM_VALUE.firstRegistration];
  const companyLabel = sessionFilter?.filterForClient[CRM_VALUE.company];
  const driverLabel = sessionFilter?.filterForClient[CRM_VALUE.driver];
  const costCenterLabel = sessionFilter?.filterForClient[CRM_VALUE.costCenter];
  const locationLabel = sessionFilter?.filterForClient[CRM_VALUE.location];
  const mileageRecommendationLabel = sessionFilter?.filterForClient[CRM_VALUE.mileageRecommendation];
  const consumptionLabel = sessionFilter?.filterForClient[CRM_VALUE.consumption];
  const leasingContractNumberLabel = sessionFilter?.filterForClient[CRM_VALUE.leasingContractNumber];
  const poolCarClassLabel = sessionFilter?.filterForClient[CRM_VALUE.poolCarClass];

  const toggleFilterIsOpen = (title = '') => {
    setOpen(title);
  };

  const isDateFilter = (title: string) => {
    return (
      convertHeaderToCrmName(title) === CRM_VALUE.leaseReturn ||
      convertHeaderToCrmName(title) === CRM_VALUE.firstRegistration
    );
  };

  const convertHeaderToCrmName = (title = '') => {
    switch (title.toLowerCase()) {
      case 'unternehmen':
        return CRM_VALUE.company;
      case 'mobexo car-id':
        return CRM_VALUE.carNumber;
      case 'kennzeichen':
        return CRM_VALUE.car;
      case 'erstzulassung':
        return CRM_VALUE.firstRegistration;
      case 'hersteller':
        return CRM_VALUE.manufacturer;
      case 'modell':
        return CRM_VALUE.model;
      case 'leasingrückgabe':
        return CRM_VALUE.leaseReturn;
      case 'status':
        return CRM_VALUE.stateCode;
      case 'status bearbeitung':
        return CRM_VALUE.status;
      case 'fahrer':
        return CRM_VALUE.driver;
      case 'kostenstelle':
        return CRM_VALUE.costCenter;
      case 'standort':
        return CRM_VALUE.location;
      case 'laufleistungempfehlung':
        return CRM_VALUE.mileageRecommendation;
      case 'verbrauch':
        return CRM_VALUE.consumption;
      case 'leasingvertragsnummer':
        return CRM_VALUE.leasingContractNumber;
      case 'pool-car klasse':
        return CRM_VALUE.poolCarClass;

      default:
        return '';
    }
  };

  const saveFilter = (
    title: string,
    serverValue: string | number | null | AnyObject,
    clientValue: string | number | null | AnyObject
  ) => {
    setSessionFilter(
      { [convertHeaderToCrmName(title)]: serverValue },
      { [convertHeaderToCrmName(title)]: clientValue }
    );
  };

  const clearFilter = (title?: string) => {
    setOpen('');
    goToFirstPage();
    setOpen('');

    history.replace({ pathname: PAGES.MY_VEHICLES, state: {} });

    if (!title) {
      setSessionFilter(emtyFilterStateForServer, emtyFilterState);
      return;
    }

    if (convertHeaderToCrmName(title) === CRM_VALUE.status) {
      saveFilter(title, { [CRM_VALUE.status]: [] }, { [CRM_VALUE.status]: [] });
    }

    if (isDateFilter(title)) {
      return saveFilter(title, emtyFilterStateForServer[convertHeaderToCrmName(title)], null);
    }

    saveFilter(title, null, null);
  };

  const handleInput = (title: string, value: string | Omit<LookupResponse, 'logicalName'> | PicklistResponse) => {
    const filtersThatMustBeOpenAfterSubmit = [CRM_VALUE.stateCode, CRM_VALUE.status];

    !filtersThatMustBeOpenAfterSubmit.includes(convertHeaderToCrmName(title)) && CRM_VALUE.status && setOpen('');
    goToFirstPage();

    history.replace({ pathname: PAGES.MY_VEHICLES, state: {} });

    if (typeof value === 'object') {
      if ('id' in value) {
        if (convertHeaderToCrmName(title) === CRM_VALUE.stateCode) {
          const { filterFromServer, filterFromClient } = stateCodeSubmitFilter(value.id, value.name);
          return saveFilter(title, filterFromServer, filterFromClient);
        }

        if (convertHeaderToCrmName(title) === CRM_VALUE.status) {
          const { filterFromServer, filterFromClient } = statusSubmitFilter(value.id, value.name);
          return saveFilter(title, filterFromServer, filterFromClient);
        }

        return saveFilter(title, value.id, value.name);
      }
      if ('value' in value) {
        return saveFilter(title, value.value, value.label);
      }
    }

    if (isDateFilter(title)) {
      return saveFilter(title, convertDateToServer(value), value);
    }

    saveFilter(title, value, value);
  };

  const isSearchValueEntered = filterDataParams.searchValue?.length;

  return (
    <section className={classes.filters}>
      <FilterExpandContainer
        isHideExpandedButton={windowWidth <= 550}
        isFilterExpended={isFilterExpended}
        abbreviatedLength={7}
        minWindowWidthForCompressClearButton={1600}
        rightComponent={props => (
          <FilterExpandClearButton
            {...props}
            filterState={sessionFilter?.filterForClient || {}}
            clearFilter={clearFilter}
          />
        )}
      >
        <Filter
          title={'Status'}
          className={classes.status_filter}
          isOpen={isOpen}
          value={stateCodeLabel}
          toggleFilterIsOpen={toggleFilterIsOpen}
          clearFilter={clearFilter}
        >
          <FilterContainer header='Status' onClose={() => setOpen('')}>
            {VEHICLE_STATUS_BUTTONS?.map(item => {
              return (
                <FilterButton
                  key={item.id}
                  title='Status'
                  handleInput={handleInput}
                  label={item.name}
                  value={item}
                  idEnding={item.name}
                  active={stateCodeAllLabels.some(filter => item.name === filter)}
                />
              );
            })}
          </FilterContainer>
        </Filter>

        <Filter
          title={'Status Bearbeitung'}
          className={classes.remove_for_mobile}
          isOpen={isOpen}
          value={statusLabel}
          toggleFilterIsOpen={toggleFilterIsOpen}
          clearFilter={clearFilter}
        >
          <FilterContainer header='Status Bearbeitung' onClose={() => setOpen('')}>
            {VEHICLE_STATUS_PROCESSING_BUTTONS?.map(item => {
              return (
                <FilterButton
                  key={item.id}
                  title='Status Bearbeitung'
                  handleInput={handleInput}
                  label={item.name}
                  value={item}
                  idEnding={item.name}
                  active={statusAllLabels.some(filter => item.name === filter)}
                />
              );
            })}
          </FilterContainer>
        </Filter>

        <Filter
          value={carLabel}
          title='Kennzeichen'
          isOpen={isOpen}
          toggleFilterIsOpen={setOpen}
          clearFilter={clearFilter}
        >
          <FilterContainer header='Kennzeichen' onClose={() => setOpen('')}>
            {!isSearchValueEntered &&
              filterData?.new_name?.map((item, index) => {
                if (index <= 2) {
                  return (
                    <FilterButton key={item} title='kennzeichen' handleInput={handleInput} label={item} value={item} />
                  );
                }
              })}
            <FilterInput
              isLoading={isFetching}
              title='kennzeichen'
              handleInput={handleInput}
              dropListData={filterData?.new_name || []}
              onChange={value => changeHandlerDebounce({ searchValue: value, columnName: CRM_VALUE.car })}
            />
          </FilterContainer>
        </Filter>

        <Filter
          value={driverLabel}
          title='Fahrer'
          className={classes.remove_for_mobile}
          isOpen={isOpen}
          toggleFilterIsOpen={setOpen}
          clearFilter={clearFilter}
        >
          <FilterContainer header='Fahrer' onClose={() => setOpen('')}>
            <FilterInput
              isLoading={isFetching}
              title='Fahrer'
              handleInput={handleInput}
              dropListData={filterData.new_fahrerid || []}
              dropListDataWithId
              onChange={value => changeHandlerDebounce({ searchValue: value, columnName: CRM_VALUE.driver })}
              withoutSearchButton
            />
          </FilterContainer>
        </Filter>

        <Filter
          title='Unternehmen'
          isOpen={isOpen}
          className={classes.remove_for_mobile}
          value={companyLabel}
          toggleFilterIsOpen={setOpen}
          clearFilter={clearFilter}
        >
          <FilterContainer header='Unternehmen' onClose={setOpen}>
            {!isSearchValueEntered &&
              filterData?.new_sparteid?.map((company, indx) => {
                if (indx < 2) {
                  return (
                    <FilterButton
                      handleInput={handleInput}
                      title='unternehmen'
                      label={company.name}
                      value={company}
                      key={indx}
                    />
                  );
                }
              })}
            <FilterInput
              isLoading={isFetching}
              title='Unternehmen'
              handleInput={handleInput}
              dropListData={filterData?.new_sparteid || []}
              dropListDataWithId
              onChange={value => changeHandlerDebounce({ searchValue: value, columnName: CRM_VALUE.company })}
              withoutSearchButton
            />
          </FilterContainer>
        </Filter>
        <Filter
          value={manufacturerLabel}
          title='Hersteller'
          className={classes.manufacturer_filter}
          isOpen={isOpen}
          toggleFilterIsOpen={setOpen}
          clearFilter={clearFilter}
        >
          <FilterContainer header='Hersteller' onClose={() => setOpen('')}>
            {!isSearchValueEntered &&
              filterData.new_hersteller?.map((item, index) => {
                if (index <= 2) {
                  return (
                    <FilterButton key={item} title='hersteller' handleInput={handleInput} label={item} value={item} />
                  );
                }
              })}
            <FilterInput
              isLoading={isFetching}
              title='hersteller'
              handleInput={handleInput}
              dropListData={filterData.new_hersteller || []}
              onChange={value => changeHandlerDebounce({ searchValue: value, columnName: CRM_VALUE.manufacturer })}
            />
          </FilterContainer>
        </Filter>
        <Filter
          value={leaseReturnLabel}
          title='Leasingrückgabe'
          className={classes.remove_for_mobile}
          isOpen={isOpen}
          toggleFilterIsOpen={setOpen}
          clearFilter={clearFilter}
        >
          <FilterContainer header='Leasingrückgabe' onClose={() => setOpen('')}>
            <div className={classes.filter_buttons_wrapper}>
              {!isSearchValueEntered &&
                leaseReturnFilterButtons.map(buttonText => {
                  return (
                    <FilterButton
                      key={buttonText}
                      title='Leasingrückgabe'
                      label={buttonText}
                      value={buttonText}
                      handleInput={handleInput}
                    />
                  );
                })}
            </div>
            <PickersContainer title='Leasingrückgabe' value={leaseReturnLabel} handleInput={handleInput} />
          </FilterContainer>
        </Filter>
        <Filter
          value={modelLabel}
          title='Modell'
          className={classes.remove_for_mobile}
          isOpen={isOpen}
          toggleFilterIsOpen={setOpen}
          clearFilter={clearFilter}
        >
          <FilterContainer header='Modell' onClose={() => setOpen('')}>
            {!isSearchValueEntered &&
              filterData?.new_model?.map((item, index) => {
                if (index <= 2) {
                  return <FilterButton key={item} title='modell' handleInput={handleInput} label={item} value={item} />;
                }
              })}
            <FilterInput
              isLoading={isFetching}
              title='modell'
              handleInput={handleInput}
              dropListData={filterData?.new_model || []}
              onChange={value => changeHandlerDebounce({ searchValue: value, columnName: CRM_VALUE.model })}
            />
          </FilterContainer>
        </Filter>
        <Filter
          value={costCenterLabel}
          title='Kostenstelle'
          className={classes.remove_for_mobile}
          isOpen={isOpen}
          toggleFilterIsOpen={setOpen}
          clearFilter={clearFilter}
        >
          <FilterContainer header='Kostenstelle' onClose={() => setOpen('')}>
            <FilterInput
              isLoading={isFetching}
              title='Kostenstelle'
              handleInput={handleInput}
              dropListData={filterData?.new_kostenstelleid || []}
              dropListDataWithId
              onChange={value => changeHandlerDebounce({ searchValue: value, columnName: CRM_VALUE.costCenter })}
              withoutSearchButton
            />
          </FilterContainer>
        </Filter>
        <Filter
          value={firstRegistrationLabel}
          title='Erstzulassung'
          className={classes.remove_for_mobile}
          isOpen={isOpen}
          toggleFilterIsOpen={setOpen}
          clearFilter={clearFilter}
        >
          <FilterContainer header='Erstzulassung' onClose={() => setOpen('')}>
            <div className={classes.filter_buttons_wrapper}>
              <FilterButton title='Erstzulassung' label='Letztes Jahr' value='Letztes Jahr' handleInput={handleInput} />
              <FilterButton
                title='Erstzulassung'
                label='Aktuelles Jahr'
                value='Aktuelles Jahr'
                handleInput={handleInput}
              />
            </div>
            <PickersContainer title='Erstzulassung' value={firstRegistrationLabel} handleInput={handleInput} />
          </FilterContainer>
        </Filter>
        <Filter
          title={'Mobexo Car-ID'}
          className={classes.remove_for_mobile}
          isOpen={isOpen}
          value={carNumberLabel}
          toggleFilterIsOpen={setOpen}
          clearFilter={clearFilter}
        >
          <FilterContainer header='Mobexo Car-ID' onClose={() => setOpen('')}>
            <FilterInput
              isLoading={isFetching}
              title='Mobexo Car-ID'
              handleInput={handleInput}
              dropListData={filterData?.uds_modexocarinternalid || []}
              onChange={value => changeHandlerDebounce({ searchValue: value, columnName: CRM_VALUE.carNumber })}
            />
          </FilterContainer>
        </Filter>
        <Filter
          title='Standort'
          className={classes.remove_for_mobile}
          isOpen={isOpen}
          value={locationLabel}
          toggleFilterIsOpen={setOpen}
          clearFilter={clearFilter}
        >
          <FilterContainer header='Standort' onClose={setOpen}>
            {!isSearchValueEntered &&
              filterData?.new_standortid?.map((location, indx) => {
                if (indx < 2) {
                  return (
                    <FilterButton
                      handleInput={handleInput}
                      title='Standort'
                      label={location.name}
                      value={location}
                      key={indx}
                    />
                  );
                }
              })}
            <FilterInput
              isLoading={isFetching}
              title='Standort'
              handleInput={handleInput}
              dropListData={filterData?.new_standortid || []}
              dropListDataWithId
              onChange={value => changeHandlerDebounce({ searchValue: value, columnName: CRM_VALUE.location })}
              withoutSearchButton
            />
          </FilterContainer>
        </Filter>
        <Filter
          title={'Laufleistungempfehlung'}
          className={classes.remove_for_mobile}
          isOpen={isOpen}
          value={mileageRecommendationLabel}
          toggleFilterIsOpen={toggleFilterIsOpen}
          clearFilter={clearFilter}
        >
          <FilterContainer
            header='Laufleistungempfehlung'
            onClose={() => setOpen('')}
            isNothingFound={!filterData?.uds_mileagerecommendation?.length}
          >
            {!isSearchValueEntered &&
              filterData?.uds_mileagerecommendation?.map(item => {
                return (
                  <FilterButton
                    key={item.id}
                    title='Laufleistungempfehlung'
                    handleInput={handleInput}
                    label={item.name}
                    value={item}
                  />
                );
              })}
          </FilterContainer>
        </Filter>

        <Filter
          value={leasingContractNumberLabel}
          title='Leasingvertragsnummer'
          className={classes.remove_for_mobile}
          isOpen={isOpen}
          toggleFilterIsOpen={setOpen}
          clearFilter={clearFilter}
        >
          <FilterContainer header='Leasingvertragsnummer' onClose={() => setOpen('')}>
            <div className={classes.filter_buttons_wrapper}>
              {!isSearchValueEntered &&
                filterData.new_lvn?.map((buttonText, index) => {
                  if (index <= 2) {
                    return (
                      <FilterButton
                        key={buttonText}
                        title='Leasingvertragsnummer'
                        label={buttonText}
                        value={buttonText}
                        handleInput={handleInput}
                      />
                    );
                  }
                })}
            </div>
            <FilterInput
              isLoading={isFetching}
              title='Leasingvertragsnummer'
              handleInput={handleInput}
              onChange={value =>
                changeHandlerDebounce({ searchValue: value, columnName: CRM_VALUE.leasingContractNumber })
              }
              dropListData={filterData.new_lvn || []}
            />
          </FilterContainer>
        </Filter>

        <Filter
          title={'Verbrauch'}
          className={classes.remove_for_mobile}
          isOpen={isOpen}
          value={consumptionLabel}
          toggleFilterIsOpen={toggleFilterIsOpen}
          clearFilter={clearFilter}
        >
          <FilterContainer header='Verbrauch' onClose={() => setOpen('')}>
            {CONSUMPTION_BUTTONS.map(item => {
              return (
                <FilterButton key={item.value} title='Verbrauch' handleInput={handleInput} {...item} value={item} />
              );
            })}
          </FilterContainer>
        </Filter>

        <Filter
          title='Pool-Car Klasse'
          className={classes.remove_for_mobile}
          isOpen={isOpen}
          value={poolCarClassLabel}
          toggleFilterIsOpen={toggleFilterIsOpen}
          clearFilter={clearFilter}
        >
          <FilterContainer header='Pool-Car Klasse' onClose={() => setOpen('')}>
            <FilterInput
              isLoading={isFetching}
              title='Pool-Car Klasse'
              handleInput={handleInput}
              dropListData={filterData?.uds_poolcar_classid || []}
              dropListDataWithId
              onChange={value => changeHandlerDebounce({ searchValue: value, columnName: CRM_VALUE.poolCarClass })}
              withoutSearchButton
            />
          </FilterContainer>
        </Filter>
      </FilterExpandContainer>
    </section>
  );
};

const leaseReturnFilterButtons = ['Letztes Jahr', 'Aktuelles Jahr', 'Nächstes Jahr'];
