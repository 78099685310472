import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { toggleModal } from '../actions/app_action';

const useModal = (modal, modalName, data) => {
  const dispatch = useDispatch();

  const show = useCallback(() => {
    dispatch(toggleModal(modal, modalName, data));
  }, [dispatch]);

  const hide = useCallback(() => {
    dispatch(toggleModal(modal, null));
  }, [dispatch]);

  return [show, hide];
};

export default useModal;
