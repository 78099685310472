import React from 'react';
import PropTypes from 'prop-types';

export const UserCardIcon = ({ width = '20', height = '21', color = '#102F3F', ...props }) => (
  <svg width={width} height={height} viewBox='0 0 20 21' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M1.42871 6.52149C1.42871 5.41692 2.32414 4.52148 3.42871 4.52148H16.5716C17.6761 4.52148 18.5716 5.41691 18.5716 6.52148V14.6643C18.5716 15.7689 17.6761 16.6643 16.5716 16.6643H3.42871C2.32414 16.6643 1.42871 15.7689 1.42871 14.6643V6.52149ZM8.80966 11.3072C8.27436 10.6529 7.44192 10.2364 6.54987 10.2358C7.56073 10.2347 8.33346 9.46125 8.33346 8.45005C8.33346 7.43815 7.55965 6.66434 6.54775 6.66434C5.53584 6.66434 4.76204 7.43815 4.76204 8.45005C4.76204 9.46124 5.53475 10.2347 6.54559 10.2358C4.87996 10.2369 3.57157 11.546 3.57157 13.2119C3.57157 13.3905 3.63109 13.5096 3.75014 13.6286C3.86919 13.7477 3.98823 13.8072 4.16681 13.8072H8.33347H8.9287C9.28585 13.8072 9.52394 13.5691 9.52394 13.2119C9.52394 12.4977 9.22632 11.8429 8.80966 11.3072ZM6.54559 10.2358C6.54631 10.2358 6.54703 10.2358 6.54775 10.2358C6.54846 10.2358 6.54916 10.2358 6.54987 10.2358C6.54916 10.2358 6.54845 10.2358 6.54775 10.2358C6.54703 10.2358 6.54631 10.2358 6.54559 10.2358ZM10.2144 8.8072C10.2144 8.53106 10.4383 8.3072 10.7144 8.3072H13.5716C13.8477 8.3072 14.0716 8.53106 14.0716 8.8072C14.0716 9.08334 13.8477 9.3072 13.5716 9.3072H10.7144C10.4383 9.3072 10.2144 9.08334 10.2144 8.8072ZM10.2144 13.0929C10.2144 12.8168 10.4383 12.5929 10.7144 12.5929H16.4287C16.7049 12.5929 16.9287 12.8168 16.9287 13.0929C16.9287 13.3691 16.7049 13.5929 16.4287 13.5929H10.7144C10.4383 13.5929 10.2144 13.3691 10.2144 13.0929ZM10.2144 10.9501C10.2144 10.6739 10.4383 10.4501 10.7144 10.4501H16.4287C16.7049 10.4501 16.9287 10.6739 16.9287 10.9501C16.9287 11.2262 16.7049 11.4501 16.4287 11.4501H10.7144C10.4383 11.4501 10.2144 11.2262 10.2144 10.9501Z'
      fill={color}
    />
  </svg>
);

UserCardIcon.propTypes = {
  color: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
};
