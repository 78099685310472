import React, { useMemo, useState, useEffect } from 'react';
import TableComponent from 'components/table-component';
import { useHistory } from 'react-router-dom';
import classes from './pool-car-booking.module.scss';
import BookingMoreMenu from './booking-more-menu';
import NumberFormat from 'react-number-format';
import { usePoolCalendarQuery } from 'hooks/react-query/pool-calendar';
import PanelPlaceholder from 'components/panel-placeholder';
import { ColumnsVariant, RootTagName } from '../table-component/table-component.props';
import { TABLE_MENU_WIDTH } from 'components/table-component/table-component';
import { Button, Flex, Text } from 'common/common-components.styled';
import CalendarView from './calendar-view';
import moment from 'moment';
import CustomFilters from './filters/custom-filters';
import { useSessionFilter } from 'hooks/use-session-filter';
import { ReactQueryKeys } from 'common/enums';
import { useTheme } from 'styled-components';
import BookingFilters from './filters/booking-filters';
import Pagination from 'components/pagination/pagination';
import { AnyObject } from 'common/interfaces';
import { default as DownloadButton } from 'components/button';
import { ButtonComponentType } from 'components/button/button.props';
import { poolCalendarRequestConfig } from 'request-config/pool-calendar/pool-calendar.request-config';
import { useFileDownload } from 'hooks/useFileDownload';
import { useQuery } from 'hooks';
import { decodeBase64 } from 'utils/decode-encode-base64';
import usePoolCarsWithBookings from 'components/pool-car-booking/hooks/react-query/use-pool-cars-with-bookings';
import { useQueryClient } from 'react-query';
import useNavigationUrl from './hooks/use-navigation-url';
import { getDeviceType } from 'utils/get-device-type';
import { DeviceTypes } from '../../constants';
import MobileBookingItem from './mobile-booking-item';

const deviceType = getDeviceType();
const isMobile = deviceType === DeviceTypes.Mobile;

export enum RecordStatuses {
  APPROVED_RECORD = 752560001,
  OPENED_RECORD = 752560000,
  CANCELED_RECORD = 752560003,
  REJECTED_RECORD = 752560002
}
interface DriverStatusProps {
  row: AnyObject;
}

interface VehicleProps {
  row: AnyObject;
  navigationURL: string;
}

interface KilometerValueProps {
  row: AnyObject;
  isPlannedKilometer: boolean;
}

interface DateCell {
  row: AnyObject;
  field: string;
}

const DriverStatus = ({ row }: DriverStatusProps) => {
  const theme = useTheme();
  const getStatusColor = (statusCode: number) => {
    switch (statusCode) {
      case RecordStatuses.OPENED_RECORD:
        return theme.colors.darkGold;
      case RecordStatuses.REJECTED_RECORD:
        return theme.colors.pink;
    }
  };

  return <span style={{ color: getStatusColor(row.uds_status_code) }}>{row.uds_status}</span>;
};

const Vehicle = ({ row, navigationURL }: VehicleProps) => {
  const history = useHistory();
  const DETAILS_PAGE_LINK = `/${navigationURL}/fahrzeuge/?id=${row.new_fuhrparkid}`;

  const handleClick = () => {
    history.push(DETAILS_PAGE_LINK);
  };

  return (
    <span style={{ cursor: 'pointer' }} onClick={handleClick}>
      {row.new_name || '-'}
    </span>
  );
};

const KilometerValue = ({ row, isPlannedKilometer }: KilometerValueProps) => {
  const kmValue = isPlannedKilometer ? row.uds_kilometervoraussichtlich : row.kmstand;
  return (
    <NumberFormat
      value={kmValue || '-'}
      displayType={'text'}
      thousandSeparator='.'
      decimalSeparator=','
      fixedDecimalScale={true}
      suffix=' km'
    />
  );
};

const DateCell = ({ row, field }: DateCell) => {
  return <span>{row[field] ? moment(row[field]).format('DD.MM.YYYY HH:mm') : '-'}</span>;
};

export enum FilterNames {
  StartDate = 'from',
  EndDate = 'to',
  Status = 'status',
  Booking = 'name',
  Mark = 'kennzeichen',
  Location = 'standort',
  Driver = 'fahrer'
}

export const emptyFilterState = {
  date: null,
  [FilterNames.Status]: null,
  [FilterNames.Booking]: null,
  [FilterNames.Mark]: null,
  [FilterNames.Location]: null,
  [FilterNames.Driver]: null
};

const initialFilterState = {
  ...emptyFilterState,
  [FilterNames.Status]: [{ value: RecordStatuses.APPROVED_RECORD }, { value: RecordStatuses.OPENED_RECORD }]
};

export type TableParams = {
  pagination?: {
    start?: number;
    number?: number;
  };
  sort: { predicate: string; reverse: boolean };
  records: AnyObject[];
  searchParams: AnyObject;
  totalCount: number;
};

interface BookingPageQueryData {
  bookingName: string;
}

export const defaultTableParams = {
  pagination: {
    start: 1,
    number: 20
  },
  sort: { predicate: 'uds_name', reverse: true },
  records: [],
  searchParams: {},
  totalCount: 0
} as TableParams;

const BookingPage = () => {
  const query = useQuery();
  const queryClient = useQueryClient();
  const history = useHistory();
  const navigationURL = useNavigationUrl();
  const [tableParams, setTableParams] = useState(defaultTableParams);
  const { sessionFilter, setSessionFilter } = useSessionFilter(navigationURL, initialFilterState, initialFilterState);
  const [calendarView, setCalendarView] = useState(!isMobile);
  const { table, poolCalendarListFilterData } = usePoolCalendarQuery(
    navigationURL,
    tableParams,
    sessionFilter,
    !calendarView
  );
  const { poolCarsWithBookings, poolCarsWithBookingsFilterData } = usePoolCarsWithBookings(
    navigationURL,
    { ...tableParams.sort, predicate: 'new_name' },
    {
      driver: sessionFilter.filterForServer[FilterNames.Driver]?.id || null,
      car: sessionFilter.filterForServer[FilterNames.Mark]?.id || null,
      standort: sessionFilter.filterForServer[FilterNames.Location]?.id || null,
      booking: sessionFilter.filterForServer[FilterNames.Booking],
      status: sessionFilter.filterForServer[FilterNames.Status] || []
    },
    calendarView
  );

  const filterData = calendarView ? poolCarsWithBookingsFilterData : poolCalendarListFilterData;
  const isEmptyList = !table.tableRows.length && !poolCarsWithBookings;

  const [filtersWereCleared, setFiltersWereCleared] = useState(false);
  const [startDate, setStartDate] = useState(moment().startOf('week'));
  const downloadFile = useFileDownload({
    requestConfig: poolCalendarRequestConfig.getPoolCalendarReport(tableParams, sessionFilter || {}),
    fileName: `Pool-Car_Buchungen_Report_${moment().format('DD-MM-YYYY')}.xlsx`,
    encodeConfig: {
      path: [],
      encodeWay: 'newBlob'
    }
  });

  const queryData: BookingPageQueryData | null = useMemo(
    () => (query.data ? JSON.parse(decodeBase64(query.data as string)) : null),
    [query.data]
  );

  useEffect(() => {
    if (queryData) {
      setCalendarView(prev => !prev);

      setSessionFilter(
        { ...initialFilterState, [FilterNames.Booking]: queryData.bookingName },
        { ...initialFilterState, [FilterNames.Booking]: queryData.bookingName }
      );
      history.replace(navigationURL);
    }
  }, []);

  const refetchData = () => {
    queryClient.resetQueries([ReactQueryKeys.GetPoolCalendarList]);
    queryClient.resetQueries([ReactQueryKeys.GetPoolCarsWithBookings]);
  };

  const colums = useMemo(() => {
    return [
      {
        name: 'Buchung',
        type: ColumnsVariant.Text,
        propName: 'uds_name',
        sort: true,
        sortName: 'uds_name',
        width: '9%'
      },

      {
        name: 'Fahrzeug',
        type: ColumnsVariant.Text,
        propName: 'new_model',
        sort: false,
        sortName: 'new_model',
        width: '12%'
      },

      {
        name: 'Kennzeichen',
        type: ColumnsVariant.Text,
        component: <Vehicle row={{}} navigationURL={navigationURL} />,
        propName: 'new_name',
        sort: true,
        sortName: 'new_name',
        width: '10%'
      },

      {
        name: 'Standort',
        type: ColumnsVariant.Text,
        propName: 'new_standortid',
        sort: false,
        sortName: 'new_standortid',
        width: '10%'
      },

      // {
      //   name: 'Aktueller Kilometerstand',
      //   type: ColumnsVariant.Text,
      //   component: <KilometerValue row={{}} isPlannedKilometer={false} />,
      //   propName: 'kmstand',
      //   sort: false,
      //   sortName: 'kmstand',
      //   width: '11%'
      // },

      {
        name: 'Geplante Kilometer',
        type: ColumnsVariant.Text,
        component: <KilometerValue row={{}} isPlannedKilometer={true} />,
        propName: 'uds_kilometervoraussichtlich',
        sort: true,
        sortName: 'uds_kilometervoraussichtlich',
        width: '10%'
      },

      {
        name: 'Abholung',
        type: ColumnsVariant.Text,
        component: <DateCell row={{}} field='uds_abholdatum' />,
        propName: 'uds_abholdatum',
        sort: true,
        sortName: 'uds_abholdatum',
        width: '10%'
      },

      {
        name: 'Rückgabe',
        type: ColumnsVariant.Text,
        component: <DateCell row={{}} field='uds_ruckgabedatum' />,
        propName: 'uds_ruckgabedatum',
        sort: true,
        sortName: 'uds_ruckgabedatum',
        width: '10%'
      },

      {
        name: 'Mitarbeiter',
        type: ColumnsVariant.Text,
        propName: 'uds_requester',
        sort: true,
        sortName: 'uds_requester',
        width: '12%'
      },

      {
        name: 'Status',
        type: ColumnsVariant.Text,
        component: <DriverStatus row={{}} />,
        propName: 'uds_status',
        sort: true,
        sortName: 'uds_status',
        width: '9%'
      },
      {
        name: '',
        type: ColumnsVariant.Menu,
        sort: false,
        component: <BookingMoreMenu row={{}} refetch={refetchData} />,
        width: TABLE_MENU_WIDTH
      }
    ];
  }, [filterData]);

  const changePageHandler = (page: number) => {
    setTableParams({ ...tableParams, pagination: { ...tableParams.pagination, start: page } });
  };

  const goToFirstPage = () => {
    if (!calendarView) {
      setTableParams({
        ...tableParams,
        pagination: {
          ...tableParams.pagination,
          start: 1
        }
      });
    }
  };

  const sortHandler = (fieldName: string) => {
    if (fieldName === 'new_name') {
      setTableParams({
        ...tableParams,
        sort: {
          predicate: 'uds_fuhrpark',
          reverse: tableParams?.sort?.predicate === 'uds_fuhrpark' ? !tableParams?.sort?.reverse : false
        }
      });

      return;
    }

    setTableParams({
      ...tableParams,
      sort: {
        predicate: fieldName,
        reverse: fieldName === tableParams?.sort?.predicate ? !tableParams?.sort?.reverse : false
      }
    });
  };

  const handleChangeView = () => {
    setSessionFilter(
      { ...sessionFilter.filterForServer, date: null },
      { ...sessionFilter.filterForClient, date: null }
    );
    setCalendarView(prev => !prev);
  };

  return (
    <div className={classes.table_wrapper}>
      <div className={classes.table_container}>
        <div>
          <div className={isEmptyList ? classes.placeholder_cutom_filters_container : null}>
            <CustomFilters
              setSessionFilter={setSessionFilter}
              sessionFilter={sessionFilter}
              goToFirstPage={goToFirstPage}
              filtersWereCleared={filtersWereCleared}
              setFiltersWereCleared={setFiltersWereCleared}
              calendarView={calendarView}
              setStartDate={setStartDate}
            />
          </div>
        </div>
        <div
          className={`${classes.new_booking_button_container} ${
            isEmptyList && classes.placeholder_new_booking_button_container
          }`}
        >
          <BookingFilters
            filters={filterData}
            setSessionFilter={setSessionFilter}
            sessionFilter={sessionFilter}
            goToFirstPage={goToFirstPage}
            setFiltersWereCleared={setFiltersWereCleared}
          />
          <div className={classes.buttons_wrapper}>
            {!isMobile && (
              <div className={classes.calendar_view_container} onClick={handleChangeView}>
                <img src={`/assets/images/${calendarView ? 'table' : 'calendar'}_view_icon.svg`} alt='view-icon' />
                <Text left='6'>{calendarView ? 'Listenansicht' : 'Kalenderansicht'}</Text>
              </div>
            )}
            <Flex gap='20px'>
              <DownloadButton
                component={ButtonComponentType.DownloadExcelIcon}
                onClick={downloadFile}
                disabled={isEmptyList}
                iconWidth={32}
                tooltip='Download File Excel'
              />
              <Button onClick={() => history.push(`${navigationURL}/neue-buchung`)}>Neue Buchung</Button>
            </Flex>
          </div>
        </div>

        {!!table.tableRows.length &&
          !calendarView &&
          (isMobile ? (
            table.tableRows.map(row => (
              <MobileBookingItem key={row.uds_poolkalenderid} row={row} refetchData={refetchData} />
            ))
          ) : (
            <TableComponent
              columns={colums}
              rows={table.tableRows}
              rootTag={{
                name: RootTagName.Div,
                propName: 'id'
              }}
              sort={sortHandler}
              sortParams={tableParams.sort}
            />
          ))}

        {calendarView && <CalendarView records={poolCarsWithBookings} refetch={refetchData} startDate={startDate} />}

        {isEmptyList && (
          <PanelPlaceholder title='Nichts gefunden' description='Diese Seite enthält keine Ergebnisse.' />
        )}
      </div>

      {table.pagination?.number && table.totalCount > table.pagination.number && !calendarView && (
        <Pagination
          activePage={table.pagination.start || 1}
          itemsCountPerPage={table.pagination.number}
          totalItemsCount={table.totalCount}
          onPageChange={changePageHandler}
        />
      )}
    </div>
  );
};

export default BookingPage;
