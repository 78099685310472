import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getFields } from '../components/form/form-view';
import { getUserCompanyCarPolicies } from '../services/car-policy-service';
import {
  getCompaniesByType,
  getCompaniesByTypes,
  getKostenstellen,
  getRahmenvertragen,
  getSparteCompanies,
  getDriversWithCar,
  getCompanyContactList,
  getParentCompanyContactList
} from '../services/company-service';
import { getUserCompanyDriverLevels } from '../services/driver-level-service';
import {
  getFileDescriptions,
  getUnusedUserCompanyFuhrparks,
  getUserCompanyFuhrparks
} from '../services/fuhrpark-service';
import { getEntityByFieldQuery } from '../services/crm-entity-service';
import { crmDataTypes, USER_ROLES } from '../constants';
import { getResponseFormData } from '../utils/get-response-data';
import { getContactsByCriteria, getLocations } from '../services/user-service';
import { getVehicleForCreateTask } from '../components/aufgaben-component/utils';
import { CarPolicyTypes, ConditionOperator } from '../common/enums';
import { getPoolCarClasses } from '../services/pool-car-booking-servises';

export function withFetchData(WrappedComponent) {
  class Wrapper extends Component {
    state = {
      formFields: null
    };

    source = {
      getUserCompanyCarPolicies: () => getUserCompanyCarPolicies(this.props.user.id),
      getUserCompanyPoolcarCarPolicies: () => getUserCompanyCarPolicies(this.props.user.id),
      getUserCompanyDrivers: () => getDriversWithCar(this.props.user.companyId),
      getUserCompanyActiveDrivers: () => getDriversWithCar(this.props.user.companyId, true),
      getApprovers: () =>
        getContactsByCriteria({
          activeOnly: true,
          roleIds: [USER_ROLES.approver.id],
          accountId: this.props.user.companyId,
          includeChildAccounts: true,
          includeParentAccount: true,
          columns: ['contactid', 'fullname']
        }),
      getFleetManagersAndPoolManagers: () =>
        getContactsByCriteria({
          activeOnly: true,
          roleIds: [USER_ROLES.fleetManager.id, USER_ROLES.poolManager.id],
          accountId: this.props.user.companyId,
          includeChildAccounts: true,
          includeParentAccount: true,
          columns: ['contactid', 'fullname']
        }),
      getUserCompanyDriverLevels: () => getUserCompanyDriverLevels(this.props.user.id),
      getUnusedUserCompanyFuhrparks: () => getUnusedUserCompanyFuhrparks(this.props.user.id),
      getUserCompanyFuhrparks: () => getUserCompanyFuhrparks(),
      getKostenstellen: () => getKostenstellen(this.props.user.id),
      getRahmenvertragen: () => getRahmenvertragen(this.props.user.id),
      getSparteCompanies: () => getSparteCompanies(this.props.user.id),
      getLeasingBanks: () => getCompaniesByType('100000000'),
      getInsuranceCompanies: () => getCompaniesByType('14'),
      getFileDescriptions: () => getFileDescriptions(),
      getDienstleistungen: () =>
        getCompaniesByTypes({
          CustomerTypeCodes: [100000000, 100000005, 14, 2, 13, 1, 11, 10],
          Attributes: ['accountid', 'name']
        }),
      getContractPartnerCompanies: () =>
        getCompaniesByTypes({
          CustomerTypeCodes: [100000005, 11, 10, 100000000],
          Attributes: ['accountid', 'name']
        }),
      getStandorts: () =>
        getEntityByFieldQuery({
          entityName: 'new_standort',
          columns: ['new_name', 'new_standortid'],
          conditions: [
            {
              attributeTypeCode: crmDataTypes.Lookup,
              attributeName: 'new_firma',
              conditionOperator: ConditionOperator.Equal,
              value: [
                {
                  id: this.props.user.companyId,
                  logicalName: '',
                  name: ''
                }
              ]
            },
            {
              attributeTypeCode: crmDataTypes.State,
              attributeName: 'statecode',
              conditionOperator: ConditionOperator.Equal,
              value: [{ value: '0' }]
            }
          ],
          order: { fieldName: 'new_name', reverse: false }
        }),
      getStandortsByCompany: companyId =>
        getEntityByFieldQuery({
          entityName: 'new_standort',
          columns: ['new_name', 'new_standortid'],
          conditions: [
            {
              attributeTypeCode: crmDataTypes.Lookup,
              attributeName: 'new_firma',
              conditionOperator: ConditionOperator.Equal,
              value: [
                {
                  id: companyId,
                  logicalName: '',
                  name: ''
                }
              ]
            },
            {
              attributeTypeCode: crmDataTypes.State,
              attributeName: 'statecode',
              conditionOperator: ConditionOperator.Equal,
              value: [{ value: '0' }]
            }
          ],
          order: { fieldName: 'new_name', reverse: false }
        }),
      getAllCurrencies: () =>
        getEntityByFieldQuery({
          entityName: 'transactioncurrency',
          columns: ['currencyname', 'transactioncurrencyid'],
          conditions: []
        }),
      getIBAN: () =>
        getEntityByFieldQuery({
          entityName: 'uds_iban',
          columns: ['uds_ibanid', 'uds_name'],
          conditions: [
            {
              attributeTypeCode: crmDataTypes.Lookup,
              attributeName: 'uds_accountid',
              conditionOperator: ConditionOperator.Equal,
              value: [
                {
                  id: this.props.companyId || this.props.user.companyId,
                  logicalName: '',
                  name: ''
                }
              ]
            }
          ]
        }),
      getCompanyActiveCarsForPoolManager: () =>
        getEntityByFieldQuery({
          entityName: 'new_fuhrpark',
          columns: ['new_name', 'new_fuhrparkid', 'statecode', 'new_kstverantw'],
          conditions: [
            {
              attributeTypeCode: 6,
              attributeName: 'new_sparteid',
              conditionOperator: ConditionOperator.In,
              value: this.props.user.childCompanies.concat([
                {
                  id: this.props.user.companyId,
                  name: this.props.user.companyName
                }
              ])
            },
            {
              attributeTypeCode: 11,
              attributeName: 'statecode',
              conditionOperator: ConditionOperator.Equal,
              value: [{ value: '0' }]
            },
            {
              attributeTypeCode: 6,
              attributeName: 'new_kstverantw',
              conditionOperator: ConditionOperator.Equal,
              value: [{ id: this.props.user.id }]
            }
          ]
        }),
      getCompanyActiveCars: () =>
        getEntityByFieldQuery({
          entityName: 'new_fuhrpark',
          columns: ['new_name', 'new_fuhrparkid', 'statecode'],
          conditions: [
            {
              attributeTypeCode: 6,
              attributeName: 'new_sparteid',
              conditionOperator: ConditionOperator.In,
              value: this.props.user.childCompanies.concat([
                {
                  id: this.props.user.companyId,
                  name: this.props.user.companyName
                }
              ])
            },
            {
              attributeTypeCode: 11,
              attributeName: 'statecode',
              conditionOperator: ConditionOperator.Equal,
              value: [{ value: '0' }]
            }
          ]
        }),
      getAllActiveDrivers: () =>
        getEntityByFieldQuery({
          entityName: 'contact',
          columns: ['fullname', 'contactid', 'statecode'],
          conditions: [
            {
              attributeTypeCode: 6,
              attributeName: 'parentcustomerid',
              conditionOperator: ConditionOperator.In,
              value: this.props.user.childCompanies.concat([
                {
                  id: this.props.user.companyId,
                  name: this.props.user.companyName
                }
              ])
            },
            {
              attributeTypeCode: 11,
              attributeName: 'statecode',
              conditionOperator: ConditionOperator.Equal,
              value: [{ value: '0' }]
            }
          ]
        }),
      getCompanies: () => {
        return this.props?.user?.childCompanies.concat([
          { id: this.props?.user?.companyId, name: this.props?.user?.companyName }
        ]);
      },
      getCarBrands: () =>
        getEntityByFieldQuery({
          entityName: 'uds_carbrand',
          columns: ['uds_carbrandid', 'uds_name'],
          conditions: [
            {
              attributeTypeCode: crmDataTypes.State,
              attributeName: 'statecode',
              conditionOperator: ConditionOperator.Equal,
              value: [{ value: '0' }]
            }
          ]
        }),
      getCarModels: () => {
        let carBrandId = null;
        getFields(this.props.formFields, field => {
          if (field.name === 'uds_carmodelid') {
            carBrandId = field.sourceParameter;
          }
        });
        return getEntityByFieldQuery({
          entityName: 'uds_carmodel',
          columns: ['uds_carmodelid', 'uds_name'],
          conditions: [
            {
              attributeTypeCode: crmDataTypes.Lookup,
              attributeName: 'uds_carbrandid',
              conditionOperator: ConditionOperator.Equal,
              value: [
                {
                  id: carBrandId,
                  logicalName: '',
                  name: ''
                }
              ]
            },
            {
              attributeTypeCode: crmDataTypes.State,
              attributeName: 'statecode',
              conditionOperator: ConditionOperator.Equal,
              value: [{ value: '0' }]
            }
          ]
        });
      },
      getContactsForCreateTask: async () => {
        const { data } = await this.props.fetchGetInfoForCreateTask();
        return data.data;
      },
      getLocations: () => getLocations(),
      getVehicleForCreateTask: () =>
        getVehicleForCreateTask([
          this.props.user.companyId,
          ...this.props.user.childCompanies.map(childCompany => childCompany.id)
        ]),
      getPoolCarClasses: () => getPoolCarClasses(this.props.user.companyId),
      getCompanyContacts: () => getCompanyContactList(),
      getParentCompanyContactList: () => getParentCompanyContactList()
    };

    updateFormFields = () => {
      const formFields = cloneDeep(this.props.formFields);
      const fieldsForDataFetching = [];
      getFields(formFields, field => {
        if (['lookup', 'optionSet', 'multipleCheckbox'].includes(field.type) && field.source && !field.hidden) {
          fieldsForDataFetching.push(field);
        }
      });

      if (fieldsForDataFetching.length) {
        (async () => {
          for (let i = 0; i < fieldsForDataFetching.length; i++) {
            if (fieldsForDataFetching[i].showLoader) {
              this.props.showOverlay();
            }
            let response;
            switch (fieldsForDataFetching[i].source) {
              case 'getUserCompanyCarPolicies':
                response = await this.source[fieldsForDataFetching[i].source]();
                response.data.items = response.data.items.map(item => ({
                  id: item.uds_carpolicyid,
                  label: item.uds_name
                }));
                break;
              case 'getUserCompanyPoolcarCarPolicies':
                response = await this.source[fieldsForDataFetching[i].source]();
                response.data.items = response.data.items
                  .filter(item => String(item.uds_type) === CarPolicyTypes.PoolCar)
                  .map(item => ({
                    id: item.uds_carpolicyid,
                    label: item.uds_name
                  }));
                break;
              case 'getUserCompanyDriverLevels':
                response = await this.source[fieldsForDataFetching[i].source]();
                response.data = response.data.items.map(item => ({
                  id: item.uds_car_policy_nutzerid,
                  label: item.uds_name
                }));
                break;
              case 'getUserCompanyFuhrparks':
                response = await this.source[fieldsForDataFetching[i].source]();
                response.data = response.data.items.map(item => ({
                  id: item.new_fuhrparkid,
                  label: item.new_name
                }));
                break;
              case 'getLeasingBanks':
              case 'getInsuranceCompanies':
              case 'getDienstleistungen':
              case 'getContractPartnerCompanies':
              case 'getSparteCompanies':
                response = await this.source[fieldsForDataFetching[i].source]();
                response.data = response.data.map(item => ({
                  id: item.accountid,
                  label: item.name
                }));
                break;
              case 'getKostenstellen':
                response = await this.source[fieldsForDataFetching[i].source]();
                response.data = response?.data?.data?.kostenstelles
                  .map(item => ({
                    id: item?.new_kostenstelleid?.attributeValue || '-',
                    label: item?.new_name?.attributeValue || '-',
                    description: item?.new_firmaid?.attributeValue?.name || '-'
                  }))
                  .sort((a, b) => {
                    if (a.label.toLowerCase() < b.label.toLowerCase()) {
                      return -1;
                    }
                    if (a.label.toLowerCase() > b.label.toLowerCase()) {
                      return 1;
                    }
                    return 0;
                  });
                break;
              case 'getRahmenvertragen':
                response = await this.source[fieldsForDataFetching[i].source]();
                response.data = response.data.map(item => ({
                  id: item.uds_rahmenvertrgesid,
                  label: item.uds_name
                }));
                break;
              case 'getUnusedUserCompanyFuhrparks':
                response = await this.source[fieldsForDataFetching[i].source]();
                response.data = response.data.map(item => ({
                  id: item.new_fuhrparkid,
                  label:
                    item.new_hersteller || item.new_model
                      ? `${item.new_name} (${item.new_hersteller || ''} ${item.new_model || ''})`
                      : item.new_name
                }));
                break;
              case 'getFileDescriptions':
                response = await this.source[fieldsForDataFetching[i].source]();
                response.data = response.data.data.map((item, i) => ({
                  id: item,
                  label: item
                }));
                break;
              case 'getUserCompanyDrivers':
              case 'getUserCompanyActiveDrivers':
                response = await this.source[fieldsForDataFetching[i].source]();
                response.data.items = response.data.data.map((item, i) => ({
                  id: item.id,
                  label: item.name
                }));
                break;
              case 'getStandorts':
                response = await this.source[fieldsForDataFetching[i].source]();
                response.data.items = getResponseFormData(response.data, [{ name: 'entityes', type: 'array' }]).map(
                  item => ({
                    id: item.new_standortid,
                    label: item.new_name
                  })
                );
                break;
              case 'getStandortsByCompany':
                response = await this.source[fieldsForDataFetching[i].source](fieldsForDataFetching[i].sourceParameter);
                response.data.items = getResponseFormData(response.data, [{ name: 'entityes', type: 'array' }]).map(
                  item => ({
                    id: item.new_standortid,
                    label: item.new_name
                  })
                );
                break;
              case 'getCompanyContacts':
                response = await this.source[fieldsForDataFetching[i].source]();
                response.data.items = response.data.data.map(item => ({
                  id: item.id,
                  label: item.name
                }));
                break;
              case 'getParentCompanyContactList':
                response = await this.source[fieldsForDataFetching[i].source]();
                response.data.items = response.data.data.map(item => ({
                  id: item.id,
                  label: item.attributes.fullname,
                  description: item.attributes.parentcustomerid.name || '-'
                }));
                break;
              case 'getApprovers':
              case 'getFleetManagersAndPoolManagers':
                response = await this.source[fieldsForDataFetching[i].source]();
                response.data.items = response.data.data.map(item => ({
                  id: item.contactid.attributeValue,
                  label: item.fullname.attributeValue
                }));
                break;
              case 'getAllCurrencies':
                response = await this.source[fieldsForDataFetching[i].source]();
                response.data.items = getResponseFormData(response.data, [{ name: 'entityes', type: 'array' }]).map(
                  item => ({
                    id: item.transactioncurrencyid,
                    label: item.currencyname
                  })
                );
                break;
              case 'getIBAN':
                response = await this.source[fieldsForDataFetching[i].source]();
                response.data.items = getResponseFormData(response.data, [{ name: 'entityes', type: 'array' }]).map(
                  item => ({
                    id: item.uds_ibanid,
                    label: item.uds_name
                  })
                );
                break;

              case 'getCompanyActiveCarsForPoolManager':
              case 'getCompanyActiveCars':
                response = await this.source[fieldsForDataFetching[i].source]();
                response.data.items = getResponseFormData(response.data, [{ name: 'entityes', type: 'array' }]).map(
                  item => ({
                    id: item.new_fuhrparkid,
                    label: item.new_name
                  })
                );
                break;

              case 'getAllActiveDrivers':
                response = await this.source[fieldsForDataFetching[i].source]();
                response.data.items = getResponseFormData(response.data, [{ name: 'entityes', type: 'array' }]).map(
                  item => ({
                    id: item.contactid,
                    label: item.fullname
                  })
                );
                break;

              case 'getCarBrands':
                response = await this.source[fieldsForDataFetching[i].source]();
                response.data.items = getResponseFormData(response.data, [{ name: 'entityes', type: 'array' }]).map(
                  item => ({
                    id: item.uds_carbrandid,
                    label: item.uds_name
                  })
                );
                break;

              case 'getCarModels':
                response = await this.source[fieldsForDataFetching[i].source]();
                response.data.items = getResponseFormData(response.data, [{ name: 'entityes', type: 'array' }]).map(
                  item => ({
                    id: item.uds_carmodelid,
                    label: item.uds_name
                  })
                );
                break;

              case 'getCompanies':
                response = await this.source[fieldsForDataFetching[i].source]();
                response.data = response.map(company => ({
                  id: company.id,
                  label: company.name
                }));
                break;

              case 'getContactsForCreateTask':
                response = await this.source[fieldsForDataFetching[i].source]();
                response.data = response.contacts.map(contact => ({
                  id: contact.id,
                  label: contact.name
                }));
                break;

              case 'getLocations':
                response = await this.source[fieldsForDataFetching[i].source]();
                response.data = response.data?.data?.map(contact => ({
                  id: contact.attributes.new_standortid,
                  label: contact.attributes.new_name,
                  description: contact.attributes.new_firma?.name || '-'
                }));
                break;

              case 'getVehicleForCreateTask':
                response = await this.source[fieldsForDataFetching[i].source]();

                response.data = response.data.data.map(dataItem => ({
                  id: dataItem.new_fuhrparkid.attributeValue,
                  label: dataItem.new_name.attributeValue
                }));
                break;

              case 'getPoolCarClasses':
                response = await this.source[fieldsForDataFetching[i].source]();

                response.data = response.data.data.items.map(dataItem => ({
                  id: dataItem.uds_poolcar_classid.attributeValue,
                  label: dataItem.uds_name.attributeValue
                }));
                break;

              default:
                response = await this.source[fieldsForDataFetching[i].source]();
                break;
            }
            if (fieldsForDataFetching[i].type === 'lookup') {
              fieldsForDataFetching[i].data = Array.isArray(response.data) ? response.data : response.data.items;
            }
            if (fieldsForDataFetching[i].type === 'optionSet') {
              fieldsForDataFetching[i].options = Array.isArray(response.data) ? response.data : response.data.items;
            }
            if (fieldsForDataFetching[i].type === 'multipleCheckbox') {
              fieldsForDataFetching[i].checkboxNames = Array.isArray(response.data)
                ? response.data
                : response.data.items;
            }

            if (fieldsForDataFetching[i].showLoader) {
              this.props.hideOverlay();
            }
          }

          this.setState({ formFields });
        })();
      }
    };

    updateLookupData = fieldName => {
      const formFields = cloneDeep(this.props.formFields);
      getFields(formFields, field => {
        if (field.name === fieldName) {
          (async () => {
            let response;
            this.props.showOverlay();
            switch (field.source) {
              case 'getKostenstellen':
                response = await this.source[field.source]();
                response.data = response.data?.data?.kostenstelles
                  .map(item => ({
                    id: item.new_kostenstelleid?.attributeValue,
                    label: item.new_name?.attributeValue,
                    description: item.new_firmaid?.attributeValue?.name
                  }))
                  .sort((a, b) => {
                    if (a.label.toLowerCase() < b.label.toLowerCase()) {
                      return -1;
                    }
                    if (a.label.toLowerCase() > b.label.toLowerCase()) {
                      return 1;
                    }
                    return 0;
                  });
                break;
              case 'getIBAN':
                response = await this.source[field.source]();
                response.data = getResponseFormData(response.data, [{ name: 'entityes', type: 'array' }]).map(item => ({
                  id: item.uds_ibanid,
                  label: item.uds_name
                }));
                break;
              default:
                response = await this.source[field.source]();
                break;
            }
            this.props.hideOverlay();
            field.data = response.data;

            this.setState({ formFields });
          })();
        }
      });
    };

    componentDidMount() {
      this.updateFormFields();
    }

    componentDidUpdate(prevProps) {
      if (!isEqual(this.props.formFields, prevProps.formFields)) {
        this.updateFormFields();
      }
    }

    render() {
      const props = {
        ...this.props,
        updateLookupData: this.updateLookupData,
        formFields: this.state.formFields || this.props.formFields
      };
      return <WrappedComponent {...props} />;
    }
  }

  const mapStateToProps = state => ({
    user: state.app.user
  });

  return connect(mapStateToProps, null)(Wrapper);
}
