export const SET_USER = 'SET_USER';
export const SET_INDICATOR = 'SET_INDICATOR';
export const RESET_STATE = 'RESET_STATE';
export const SET_LIST_ID = 'SET_LIST_ID';
export const SET_ITEM = 'SET_ITEM';
export const SET_PRICE_ITEMS = 'SET_PRICE_ITEMS';
export const TOGGLE_MODAL = 'TOGGLE_MODAL';
export const CHANGE_PAGE = 'CHANGE_PAGE';
export const CHANGE_SORT = 'CHANGE_SORT';
export const CHANGE_SEARCH = 'CHANGE_SEARCH';
export const TOGGLE_MENU = 'TOGGLE_MENU';
export const SET_VEHICLE_MODELS = 'SET_VEHICLE_MODELS';
export const TOGGLE_SIDEBAR = 'TOGGLE_SIDEBAR';
export const RESET_FILTERS = 'RESET_FILTERS';
export const PREVIOUS_LOCATION_CHANGE = 'PREVIOUS_LOCATION_CHANGE';
export const SET_FORM = 'SET_FORM';
export const SAVE_FORM_VALUES = 'SAVE_FORM_VALUES';
export const IS_FORM_VALID = 'IS_FORM_VALID';
export const IS_SET_FORM_SAVED_VALUES = 'IS_SET_FORM_SAVED_VALUES';
export const DELETE_FORM = 'DELETE_FORM';
export const SET_FAHRZEUGKOSTEN = 'SET_FAHRZEUGKOSTEN';
export const SET_CAR_POLICIES = 'SET_CAR_POLICIES';
export const SET_OPTIONS = 'SET_OPTIONS';
export const SET_MENU_ITEMS_VIEW = 'SET_MENU_ITEMS_VIEW';
export const SET_CUSTOM_URL_TREE = 'SET_CUSTOM_URL_TREE';
export const SET_UPLOAD_DOCUMENTS = 'SET_UPLOAD_DOCUMENTS';
export const SET_SIDE_MENU_OPEN = 'SET_SIDE_MENU_OPEN';
export const SET_CONTACT_SETUP = 'SET_CONTACT_SETUP';
export const DRIVER_LICENSE_TRIGGER = 'DRIVER_LICENSE_TRIGGER';
export const LICENSE_PLATE = 'LICENSE_PLATE';
export const REFRESH_KEY = 'REFRESH_KEY';
export const IS_SITE_AVAILABLE = 'IS_SITE_AVAILABLE';
export const SNACKBAR = 'SNACKBAR';
export const DATE_IMPORT_FILES = 'DATE_IMPORT_FILES';
export const SET_SUCCESS = 'SET_SUCCESS';
export const SHOW_DESCRIPTION_TRIGGER = 'SHOW_DESCRIPTION_TRIGGER';
export const VEHILE_LIST_QUERY_KEY = 'VEHILE_LIST_QUERY_KEY';
export const TOGGLE_CHATBOT = 'TOGGLE_CHATBOT';
export const TOGGLE_GREETING_SIDEBAR = 'TOGGLE_GREETING_SIDEBAR';
export const SET_OPEN_VIDEO_TUTORIALS = 'SET_OPEN_VIDEO_TUTORIALS';
export const SET_SNACKBAR_COMPONENT = 'SET_SNACKBAR_COMPONENT';
export const TOGGLE_SIDEBAR_TYPE = 'TOGGLE_SIDEBAR_TYPE';
export const SET_DASHBOARD_TOUR_IS_STARTED = 'SET_DASHBOARD_TOUR_IS_STARTED';
export const TOGGLE_AFTER_TOUR_POP_UP = 'TOGGLE_AFTER_TOUR_POP_UP';
export const SET_SHOW_GUIDED_TOUR_FIRST = 'SET_SHOW_GUIDED_TOUR_FIRST';
export const SET_IS_PACKAGE_EXIST = 'SET_IS_PACKAGE_EXIST';
export const SET_IS_PACKAGES_FETCHED = 'SET_IS_PACKAGES_FETCHED';
export const SET_LOGIN_COUNTER = 'SET_LOGIN_COUNTER';
export const SET_START_LOADING = 'SET_START_LOADING';
export const SET_END_LOADING = 'SET_END_LOADING';
export const SET_POOL_CALENDAR_EVENT_TOOLTIP = 'SET_POOL_CALENDAR_EVENT_TOOLTIP';
export const SET_SHOW_EXPIRED_TRIAL_MODAL = 'SET_SHOW_EXPIRED_TRIAL_MODAL';
export const SET_SIDE_MENU = 'SET_SIDE_MENU';
export const SET_UNSAVED_MODAL_TYPE = 'SET_UNSAVED_MODAL_TYPE';
export const SET_UNSAVED_CHANGES_STATE = 'SET_UNSAVED_CHANGES_STATE';
export const SET_FORM_INFO = 'SET_FORM_INFO';
export const SET_HIGHLIGHTED_FORM = 'SET_HIGHLIGHTED_FORM';

export const setFormInfo = formInfo => ({
  type: SET_FORM_INFO,
  payload: formInfo
});

export const setDateImportsFiles = files => ({
  type: DATE_IMPORT_FILES,
  payload: files
});

export const setSnakbar = setup => ({
  type: SNACKBAR,
  payload: setup
});

export const setPageRefreshKey = refreshKey => ({
  type: REFRESH_KEY,
  payload: refreshKey
});

export const resetFiltersAction = () => ({
  type: RESET_FILTERS
});

export const toggleSidebar = flag => ({
  type: TOGGLE_SIDEBAR,
  payload: flag
});

export const setVehicleModels = models => ({
  type: SET_VEHICLE_MODELS,
  payload: models
});

export const changeSearch = search => ({
  type: CHANGE_SEARCH,
  payload: search
});

export const toggleModal = (name, modalType, params) => ({
  type: TOGGLE_MODAL,
  payload: {
    name,
    modalType,
    params
  }
});

export const toggleMenu = (params, component) => ({
  type: TOGGLE_MENU,
  payload: {
    params,
    component
  }
});

export const changePage = pagination => ({
  type: CHANGE_PAGE,
  payload: pagination
});

export const changeSort = sort => ({
  type: CHANGE_SORT,
  payload: sort
});

export const resetState = () => ({
  type: RESET_STATE
});

export const setUser = user => ({
  type: SET_USER,
  payload: user
});

export const setIndicator = indicator => ({
  type: SET_INDICATOR,
  payload: indicator
});

export const setListId = list => ({
  type: SET_LIST_ID,
  payload: list
});

export const setFilterOptions = options => {
  return {
    type: SET_OPTIONS,
    payload: options
  };
};

export const setItem = item => ({
  type: SET_ITEM,
  payload: item
});

export const setPriceItems = priceItems => ({
  type: SET_PRICE_ITEMS,
  payload: priceItems
});

export const setForm = form => ({
  type: SET_FORM,
  payload: form
});

export const setSaveFormValues = saveFormValues => ({
  type: SAVE_FORM_VALUES,
  payload: saveFormValues
});

export const setIsFormValid = isValidForm => ({
  type: IS_FORM_VALID,
  payload: isValidForm
});

export const setIsSetFormFromSavedValues = isSetFormSavedValues => ({
  type: IS_SET_FORM_SAVED_VALUES,
  payload: isSetFormSavedValues
});

export const deleteForm = formName => ({
  type: DELETE_FORM,
  payload: formName
});

export const previousLocationChange = location => ({
  type: PREVIOUS_LOCATION_CHANGE,
  payload: location
});

export const setFahrzeugkosten = fahrzeugkosten => ({
  type: SET_FAHRZEUGKOSTEN,
  payload: fahrzeugkosten
});

export const setCarPolicies = carPolicies => ({
  type: SET_CAR_POLICIES,
  payload: carPolicies
});

export const setCustomUrlTree = urlTree => ({
  type: SET_CUSTOM_URL_TREE,
  payload: urlTree
});

export const setUploadDocuments = documentList => ({
  type: SET_UPLOAD_DOCUMENTS,
  payload: documentList
});

export const setSideMenuOpen = flag => ({
  type: SET_SIDE_MENU_OPEN,
  payload: flag
});

export const setContactSetup = setup => ({
  type: SET_CONTACT_SETUP,
  payload: setup
});

export const setDriverLicenseTrigger = trigger => ({
  type: DRIVER_LICENSE_TRIGGER,
  payload: trigger
});

export const setLicensePlate = licensePlate => ({
  type: LICENSE_PLATE,
  payload: licensePlate
});

export const setIsSiteAvailable = isSiteAvailable => ({
  type: IS_SITE_AVAILABLE,
  payload: isSiteAvailable
});

export const setVehicleListQueryKey = key => ({
  type: VEHILE_LIST_QUERY_KEY,
  payload: key
});

export const setSuccess = success => ({
  type: SET_SUCCESS,
  payload: success
});

export const setShowDescriptionTrigger = showDescription => ({
  type: SHOW_DESCRIPTION_TRIGGER,
  payload: showDescription
});

export const toggleChatbot = isOpenChatbot => ({
  type: TOGGLE_CHATBOT,
  payload: isOpenChatbot
});

export const toggleGreetingSideBar = isOpenHelpWindow => ({
  type: TOGGLE_GREETING_SIDEBAR,
  payload: isOpenHelpWindow
});

export const setOpenVideoTutorials = flag => ({
  type: SET_OPEN_VIDEO_TUTORIALS,
  payload: flag
});

export const setSnackbarComponent = payload => ({
  type: SET_SNACKBAR_COMPONENT,
  payload: payload
});

export const toggleSidebarType = payload => ({
  type: TOGGLE_SIDEBAR_TYPE,
  payload: payload
});

export const setDashboardTourIsStartted = payload => ({
  type: SET_DASHBOARD_TOUR_IS_STARTED,
  payload: payload
});

export const toggleAfterTourPopUp = payload => ({
  type: TOGGLE_AFTER_TOUR_POP_UP,
  payload: payload
});

export const setShowGuidedTourFirst = payload => ({
  type: SET_SHOW_GUIDED_TOUR_FIRST,
  payload: payload
});

export const setIsPackageExist = payload => ({
  type: SET_IS_PACKAGE_EXIST,
  payload: payload
});

export const setIspackagesFetched = payload => ({
  type: SET_IS_PACKAGES_FETCHED,
  payload: payload
});

export const setLoginCounter = payload => ({
  type: SET_LOGIN_COUNTER,
  payload: payload
});

export const setStartLoading = (loadingKey = 'default', isExtendedHeight = false) => ({
  type: SET_START_LOADING,
  payload: { loadingKey, isExtendedHeight }
});

export const setEndLoading = (loadingKey = 'default') => ({
  type: SET_END_LOADING,
  payload: loadingKey
});

export const setPoolCalendarEventTooltip = payload => ({
  type: SET_POOL_CALENDAR_EVENT_TOOLTIP,
  payload: payload
});

export const setShowExpiredTrialModal = payload => ({
  type: SET_SHOW_EXPIRED_TRIAL_MODAL,
  payload: payload
});

export const setSideMenu = sideMenu => ({
  type: SET_SIDE_MENU,
  payload: sideMenu
});

export const setUnsavedModalType = payload => ({
  type: SET_UNSAVED_MODAL_TYPE,
  payload: payload
});

export const setUnsavedChangesState = payload => ({
  type: SET_UNSAVED_CHANGES_STATE,
  payload: payload
});

export const setHighlightedForm = payload => ({
  type: SET_HIGHLIGHTED_FORM,
  payload: payload
});
