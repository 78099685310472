class ErrorMessages {
  getMaxTextLength = (maxLength: number) => `Das Feld darf nur max. ${maxLength} Zeichen enthalten.`;
  getMaxNumberLength = (maxLength: number) => `Das Feld kann max. ${maxLength} Ziffern enthalten.`;
  getMinNumberValue = (minNumber: number) => `Dieses Feld muss einen Wert größer als ${minNumber} haben.`;
  getMaxNumberValue = (maxNumber: number) => `Dieses Feld muss einen Wert kleiner als ${maxNumber} haben.`;
  selectedDateNotEarlierThanFieldDate = (fieldName: string) => `Das Datum muss nach dem ${fieldName} Datum liegen.`;
  number = 'Bitte verwende nur Zahlen, keine Sonderzeichen.';
  onlyInteger = 'Bitte verwende nur ganze Zahlen, keine Sonderzeichen.';
  chassisNumber =
    'Die Fahrgestellnummer ist nicht korrekt. Sie darf maximal 17 Zeichen lang sein und keine Buchstaben O, I oder Q enthalten.';
  licencePlate = 'Bitte gib das Kennzeichen im richtigen Format ein (Beispiel: BO-CC 4500H).';
  required = 'Das ist ein Pflichtfeld, bitte fülle es aus.';
  requiredOptionSet = 'Das ist ein Pflichtfeld, bitte wähle eine Option aus.';
  email = 'Bitte gib eine gültige E-Mail-Adresse ein.';
}

export const errorMessages = new ErrorMessages();
