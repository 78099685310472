import authService from 'services/auth-service';
import axios, { AxiosResponse } from 'axios';
import { API_ENDPOINT } from '../constants';
import { CheckedItemsResult } from 'components/global-reference-vehicles/global-reference-vehicles.props';
import { AnyObject, ResponseModel } from 'common/interfaces';
import { GlobalReferenceVehiclesCount } from 'components/global-reference-vehicles/action-switcher/leasing-price-dialog/confirmation-component/confirmation-component.props';
import { ServerAndClientFilter } from 'hooks/use-session-filter';
import { GlobalReferenceVehiclesFilterData } from 'components/global-reference-vehicles/global-reference-vehicles-filters/global-reference-vehicles-filters.props';
import { IsAllValid } from 'components/global-reference-vehicles/action-switcher/leasing-price-dialog/leasing-price-dialog.props';
import { ISorting } from 'hooks/use-session-sorting';
import { IPagination } from 'hooks/use-table/use-table.props';
import { ApprovalsFilterData } from 'pages/approvals-page/approvals-component/approvals-interfaces';

export const getGlobalReferenceVehicleBrands = async (
  accountId: string,
  filterForServer: ServerAndClientFilter,
  targetDate: string
) => {
  const token = await authService.acquireTokenAsync();
  return axios({
    method: 'POST',
    url: `${API_ENDPOINT}/api/fahrzeugangebote/getReferenceVehicleBrands`,
    headers: {
      Authorization: `Bearer ${token}`
    },
    data: {
      accountId,
      targetDate,
      search: filterForServer
    }
  });
};

export const getGlobalReferenceVehicleModels = async (
  accountId: string,
  brandId: string,
  filterForServer: ServerAndClientFilter,
  targetDate: string
) => {
  const token = await authService.acquireTokenAsync();
  return axios({
    method: 'POST',
    url: `${API_ENDPOINT}/api/fahrzeugangebote/getReferenceVehicleModels`,
    headers: {
      Authorization: `Bearer ${token}`
    },
    data: {
      accountId,
      brandId,
      targetDate,
      search: filterForServer
    }
  });
};

export const getGlobalReferenceVehicles = async (
  modelId: string,
  filterForServer: ServerAndClientFilter,
  targetDate: string
) => {
  const token = await authService.acquireTokenAsync();
  return axios({
    method: 'POST',
    url: `${API_ENDPOINT}/api/fahrzeugangebote/GetReferenceVehicles`,
    headers: {
      Authorization: `Bearer ${token}`
    },
    data: {
      modelId,
      targetDate,
      search: filterForServer
    }
  });
};

export const calculateGlobalReferenceVehiclesCount = async (
  data: CheckedItemsResult,
  filterForServer: ServerAndClientFilter,
  targetDate: string
): Promise<AxiosResponse<ResponseModel<GlobalReferenceVehiclesCount[]>>> => {
  const token = await authService.acquireTokenAsync();
  return axios({
    method: 'POST',
    url: `${API_ENDPOINT}/api/fahrzeugangebote/calculateGlobalReferenceVehiclesCount`,
    headers: {
      Authorization: `Bearer ${token}`
    },
    data: {
      ...data,
      targetDate,
      search: filterForServer
    }
  });
};

export const getReferenceVehicleDetails = async (
  vehicleId: string,
  targetDate: string
): Promise<AxiosResponse<ResponseModel>> => {
  const token = await authService.acquireTokenAsync();
  return axios({
    method: 'POST',
    url: `${API_ENDPOINT}/api/fahrzeugangebote/getReferenceVehicleDetails`,
    headers: {
      Authorization: `Bearer ${token}`
    },
    data: {
      vehicleId,
      targetDate
    }
  });
};

export const getReferenceVehicleModelDetails = async (
  modelId: string,
  targetDate: string,
  search: ServerAndClientFilter
): Promise<AxiosResponse<ResponseModel>> => {
  const token = await authService.acquireTokenAsync();
  return axios({
    method: 'POST',
    url: `${API_ENDPOINT}/api/fahrzeugangebote/GetReferenceVehicleModelDetails`,
    headers: {
      Authorization: `Bearer ${token}`
    },
    data: {
      modelId,
      targetDate,
      search
    }
  });
};

export const getReferenceVehicleBrandDetails = async (
  brandId: string,
  accountId: string,
  targetDate: string,
  search: ServerAndClientFilter
): Promise<AxiosResponse<ResponseModel>> => {
  const token = await authService.acquireTokenAsync();
  return axios({
    method: 'POST',
    url: `${API_ENDPOINT}/api/fahrzeugangebote/GetReferenceVehicleBrandDetails`,
    headers: {
      Authorization: `Bearer ${token}`
    },
    data: {
      brandId,
      accountId,
      targetDate,
      search
    }
  });
};

export const setReferenceVehicleVisibility = async (
  data: CheckedItemsResult,
  visible: boolean,
  accountId: string,
  filterForServer: ServerAndClientFilter
): Promise<AxiosResponse<ResponseModel>> => {
  const token = await authService.acquireTokenAsync();
  return axios({
    method: 'POST',
    url: `${API_ENDPOINT}/api/fahrzeugangebote/setReferenceVehicleVisibility`,
    headers: {
      Authorization: `Bearer ${token}`
    },
    data: {
      ...data,
      visible,
      accountId,
      search: filterForServer
    }
  });
};

export const setReferenceVehicleCarPolicies = async (
  data: CheckedItemsResult,
  carPolicies: string[] | null,
  accountId: string,
  filterForServer: ServerAndClientFilter
): Promise<AxiosResponse<ResponseModel>> => {
  const token = await authService.acquireTokenAsync();
  return axios({
    method: 'POST',
    url: `${API_ENDPOINT}/api/fahrzeugangebote/setReferenceVehicleCarPolicies`,
    headers: {
      Authorization: `Bearer ${token}`
    },
    data: {
      ...data,
      carPolicies,
      accountId,
      search: filterForServer
    }
  });
};

export const getReferenceVehicleFilterData = async (): Promise<
  AxiosResponse<ResponseModel<GlobalReferenceVehiclesFilterData>>
> => {
  const token = await authService.acquireTokenAsync();
  return axios({
    method: 'POST',
    url: `${API_ENDPOINT}/api/fahrzeugangebote/getReferenceVehicleFilterData`,
    headers: {
      Authorization: `Bearer ${token}`
    },
    data: {}
  });
};

export const getVehiclesHaveValidContract = async (
  data: CheckedItemsResult,
  filterForServer: ServerAndClientFilter
): Promise<AxiosResponse<ResponseModel<IsAllValid>>> => {
  const token = await authService.acquireTokenAsync();
  return axios({
    method: 'POST',
    url: `${API_ENDPOINT}/api/fahrzeugangebote/GetVehiclesHaveValidRahmenvertrag`,
    headers: {
      Authorization: `Bearer ${token}`
    },
    data: {
      ...data,
      search: filterForServer
    }
  });
};

export const getGlobalReferenceCarPolicies = async (
  data: CheckedItemsResult,
  filterForServer: ServerAndClientFilter
): Promise<AxiosResponse<ResponseModel<AnyObject[]>>> => {
  const token = await authService.acquireTokenAsync();
  return axios({
    method: 'POST',
    url: `${API_ENDPOINT}/api/fahrzeugangebote/GetGlobalReferenceCarPolicies`,
    headers: {
      Authorization: `Bearer ${token}`
    },
    data: {
      ...data,
      search: filterForServer
    }
  });
};

export const getForApprover = async (
  filterForServer: ServerAndClientFilter,
  sort: Partial<ISorting>,
  pagination: IPagination
): Promise<AxiosResponse<ResponseModel>> => {
  const token = await authService.acquireTokenAsync();
  return axios({
    method: 'POST',
    url: `${API_ENDPOINT}/api/fahrzeugangebote/GetForFreigaben`,
    headers: {
      Authorization: `Bearer ${token}`
    },
    data: {
      sort,
      pagination,
      search: filterForServer
    }
  });
};

export const getForApproverFilterData = async (): Promise<AxiosResponse<ResponseModel<ApprovalsFilterData>>> => {
  const token = await authService.acquireTokenAsync();
  return axios({
    method: 'POST',
    url: `${API_ENDPOINT}/api/fahrzeugangebote/GetForFreigabenFilterData`,
    headers: {
      Authorization: `Bearer ${token}`
    },
    data: {}
  });
};

export const getVehicleOfferDetailsForConfigurator = async (
  vehicleOfferId: string
): Promise<AxiosResponse<ResponseModel>> => {
  const token = await authService.acquireTokenAsync();
  return axios({
    method: 'POST',
    url: `${API_ENDPOINT}/api/fahrzeugangebote/GetVehicleOfferDetailsForConfigurator`,
    headers: {
      Authorization: `Bearer ${token}`
    },
    data: { vehicleOfferId }
  });
};
