import React from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { toggleModal, setSnakbar } from 'actions/app_action';
import { Flex, Text, Button } from 'common/common-components.styled';
import { VEHICLE_RETURN_PHASES, MODALS, MODAL_NAMES } from '../../../../constants';
import { StyledButtonsContainer, StyledButton } from './leasing-end-generic.card.styled';
import { PAGES } from 'common/enums';
import { UploadProtocol } from 'components/aufgaben-component/components/upload-protocol/upload-protocol';
import { uploadProtocol } from 'components/aufgaben-component/utils';
import { useOverlay } from 'hooks';

interface VehicleReturnCardProps {
  phase: number;
}

interface VehicleReturnButtonsProps extends VehicleReturnCardProps {
  vehicleId: string;
  orderId: string;
  setRefreshKey: any;
}

const getDescription = (phase: number) => {
  switch (phase) {
    case VEHICLE_RETURN_PHASES.open:
    case VEHICLE_RETURN_PHASES.filledAndCanceledByEmployee:
      return 'Bitte erstelle den Auftrag für die Leasingrückgabe';
    case VEHICLE_RETURN_PHASES.filledByClient:
    case VEHICLE_RETURN_PHASES.filledByEmployee:
      return 'Bitte lade das Protokoll der Übergabe hoch';
    case VEHICLE_RETURN_PHASES.protocolUploadedByClient:
    case VEHICLE_RETURN_PHASES.protocolUploadedByEmployee:
      return 'Das Übergabeprotokoll wurde erfolgreich hochgeladen';
    case VEHICLE_RETURN_PHASES.returnProtocolWasCanceled:
      return `Der vorherige Übergabeprotokoll wurde annulliert. 
        Bitte lade das Protokoll der Übergabe hoch.`;
    case VEHICLE_RETURN_PHASES.mobexoTagThatProtocolIsNotNeeded:
      return 'Das Fahrzeug wurde erfolgreich übergeben ';
    default:
      return null;
  }
};

export const VehicleReturnButtons = ({ phase, vehicleId, orderId, setRefreshKey }: VehicleReturnButtonsProps) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [showOverlay, hideOverlay] = useOverlay();

  const redirectToOrder = () => {
    history.push(`${PAGES.ASSIGNMENTS_LEASE_RETURN_PRO}?id=${vehicleId}`);
  };

  const closeAlert = () => {
    dispatch(toggleModal(MODALS.alert, null));
  };

  const handleSubmit = (isWithoutProtocol: boolean, returnDate: string, files: File[]) => {
    closeAlert();
    showOverlay();
    uploadProtocol(vehicleId, orderId, files, isWithoutProtocol, returnDate).then(response => {
      setRefreshKey((oldKey: number) => oldKey + 1);
      dispatch(
        setSnakbar({
          isOpen: true,
          message: response.data.isSuccess
            ? 'Deine Entscheidung wurde erfolgreich gespeichert'
            : response.data.messages[0].message,
          type: response.data.level.toLowerCase()
        })
      );
      hideOverlay();
    });
  };

  const openUploadProtocolModal = () => {
    const alertData = {
      title: 'Übergabeprotokoll hochladen',
      children: <UploadProtocol onSubmit={handleSubmit} />,
      buttons: [
        {
          type: 'cancel',
          title: 'Abbrechen',
          action: closeAlert,
          hide: true
        }
      ],
      allButtonsHidden: true
    };
    dispatch(toggleModal(MODALS.alert, MODAL_NAMES.alert, alertData));
  };

  switch (phase) {
    case VEHICLE_RETURN_PHASES.open:
    case VEHICLE_RETURN_PHASES.filledAndCanceledByEmployee:
      return (
        <Button onClick={redirectToOrder} id={'createOrder'}>
          Auftrag erstellen
        </Button>
      );
    case VEHICLE_RETURN_PHASES.filledByClient:
    case VEHICLE_RETURN_PHASES.returnProtocolWasCanceled:
      return (
        <StyledButtonsContainer>
          <StyledButton secondary onClick={redirectToOrder}>
            Auftrag ansehen
          </StyledButton>
          <StyledButton onClick={openUploadProtocolModal}>Übergabeprotokoll hochladen</StyledButton>
        </StyledButtonsContainer>
      );
    default:
      return null;
  }
};

export const VehicleReturnCard = ({ phase }: VehicleReturnCardProps) => {
  return (
    <Flex direction='column'>
      <Flex maxWidth='260px'>
        <Text size='12' color='grey500'>
          {getDescription(phase)}
        </Text>
      </Flex>
    </Flex>
  );
};
