import React from 'react';
import { ConfigurationTypeTileProps, TilesData } from './configuration-type-tile.props';
import { StyledConfigurationTypeTile } from './configuration-type-tile.styled';
import { ConfigurationTypes } from 'common/enums';
import { Flex, Text } from 'common/common-components.styled';
import CheckIcon from 'components/icons/check';
import { useTheme } from 'styled-components';

const ConfigurationTypeTile = ({ type, setConfigurationsType }: ConfigurationTypeTileProps) => {
  const theme = useTheme();

  const data = TILES_DATA[type];

  return (
    <StyledConfigurationTypeTile onClick={() => setConfigurationsType(type)}>
      <Flex direction='column' align='center' justify='center' height='100%'>
        <img src={data.img} alt='tile img' />
        <Text margin='20px 0' color='blue' size='20' bold>
          {data.title}
        </Text>
        <Text textAlign='center' bottom='20' color='grey900' size='16'>
          {data.description}
        </Text>

        <div>
          {data.features.map(feature => (
            <Flex bottom='5' align='center' key={feature}>
              <CheckIcon color={theme.colors.black} />
              <Text left='10' color='black'>
                {feature}
              </Text>
            </Flex>
          ))}
        </div>
      </Flex>
    </StyledConfigurationTypeTile>
  );
};

const TILES_DATA: TilesData = {
  [ConfigurationTypes.Global]: {
    img: '/assets/images/configuration-type-tile-global-vehicle.svg',
    title: 'Erweiterte Referenzfahrzeuge',
    description: (
      <Text>
        Konfiguriere Dein neues Fahrzeug auf Grundlage von{' '}
        <Text as='span' bold>
          erweiterten
        </Text>{' '}
        Referenzfahrzeugen.
      </Text>
    ),
    features: ['Größere Auswahl', 'Tagesaktuelle Daten']
  },
  [ConfigurationTypes.Reference]: {
    img: '/assets/images/configuration-type-tile-reference-vehicle.svg',
    title: 'Referenzfahrzeuge',
    description: <Text>Konfiguriere Dein neues Fahrzeug auf Grundlage von Referenzfahrzeugen.</Text>,
    features: []
  },
  [ConfigurationTypes.BulkOrders]: {
    img: '/assets/images/configuration-type-tile-reference-vehicle.svg',
    title: 'Sammelbestellung',
    description: <Text>Wähle dein neues Fahrzeug aus fertig konfigurierten Fahrzeugen.</Text>,
    features: ['Schneller Verfügbar', 'Ohne Zuzahlung']
  }
};

export default ConfigurationTypeTile;
