import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import classes from './download-documents-component.module.scss';
import classNames from 'classnames';
import ThreeStateCheckbox from '../three-state-checkbox';
import Download from '../icons/download';
import useExpand from '../../hooks/use-expand';
import cloneDeep from 'lodash/cloneDeep';
import ExcelFileIcon from '../icons/excel-file-icon';
import PictureFileIcon from '../icons/picture-file-icon';
import PdfFileIcon from '../icons/pdf-file-icon';
import TextFileIcon from '../icons/text-file-icon';
import sharepointService from '../../services/sharepoint-service';
import { useOverlay } from '../../hooks';
import { b64toBlob, getFileForDownload, getFormat } from '../vertrage/vertrag-download/vertrag-download';
import { FileInfo } from './components/file-info/file-info';
import { formatDate } from 'helpers';
import { DownArrowIcon } from 'components/icons/down-arrow-icon';
import { FileFormatTypes } from 'common/enums';
import { Button } from 'common/common-components.styled';
import LetterModal from 'pages/existing-vehicle-page/letter-modal/letter-modal';
import { useLetterModal } from 'pages/existing-vehicle-page/letter-modal/hooks/use-letter-modal';
import EmlFileIcon from 'components/icons/eml-file-icon';
import MsgFilelIcon from 'components/icons/msg-file-icon';

const getFile = (groupedDocuments, callback) => {
  for (let key in groupedDocuments) {
    if (groupedDocuments.hasOwnProperty(key)) {
      groupedDocuments[key].forEach(file => {
        callback(file);
      });
    }
  }
};

export const getIcon = fileName => {
  const extensionArr = fileName.split('.');
  const extension = extensionArr[extensionArr.length - 1];
  switch (extension.toLocaleLowerCase()) {
    case FileFormatTypes.XLSX:
    case FileFormatTypes.XLS:
    case FileFormatTypes.CSV:
      return <ExcelFileIcon />;
    case FileFormatTypes.JPEG:
    case FileFormatTypes.JPG:
    case FileFormatTypes.PNG:
      return <PictureFileIcon />;
    case FileFormatTypes.PDF:
      return <PdfFileIcon />;
    case FileFormatTypes.EML:
      return <EmlFileIcon />;
    case FileFormatTypes.MSG:
      return <MsgFilelIcon />;
    default:
      return <TextFileIcon />;
  }
};

export const GroupeItem = ({
  title,
  files,
  groupCheckboxChanged,
  showCheckboxes,
  allFiles,
  archiveName,
  fileCheckboxChanged,
  costingsInfo,
  showTitle,
  downloadingArray,
  showCheckboxInHideTitle = false,
  showDownloadButtonInFileInfo = false,
  showLetterModal
}) => {
  const { height, content, toggleAccordion, expand } = useExpand(showCheckboxes);
  const [checkboxState, setCheckboxState] = useState({ checked: false, indeterminate: false });
  const [showOverlay, hideOverlay] = useOverlay();

  useEffect(() => {
    const filesCheckboxStates = [];
    const newCheckboxState = { checked: false, indeterminate: true };
    files.forEach(file => {
      filesCheckboxStates.push(file.checked);
    });
    if (filesCheckboxStates.every(state => state)) {
      newCheckboxState.checked = true;
      newCheckboxState.indeterminate = false;
    }
    if (filesCheckboxStates.every(state => !state)) {
      newCheckboxState.checked = false;
      newCheckboxState.indeterminate = false;
    }

    setCheckboxState(newCheckboxState);
  }, [files]);

  const toggle = () => {
    toggleAccordion();
  };

  const downloadArchive = async () => {
    const checkedFiles = [];
    getFile(downloadingArray, file => {
      if (file.checked) {
        checkedFiles.push(file);
      }
    });
    const filePaths =
      checkedFiles.length === 0 ? files.map(file => file.filePath) : checkedFiles.map(file => file.filePath);

    if (filePaths.length === 1) {
      downloadFileHandler(filePaths[0]);
    } else {
      showOverlay();
      const response = await sharepointService.getZipFilesByPath(filePaths, archiveName);
      hideOverlay();
      const a = document.createElement('a');
      a.href = URL.createObjectURL(new Blob([response.data]));
      a.download = `${archiveName}.zip`;
      a.click();
    }
  };

  const downloadFileHandler = filePaths => {
    showOverlay();
    getFileForDownload(filePaths)
      .then(({ data }) => {
        const filePathArr = filePaths.split('/');
        const fileName = filePathArr[filePathArr.length - 1];
        const blob = new Blob([data], { type: 'application/octet-stream' });
        const a = document.createElement('a');
        a.href = URL.createObjectURL(blob);
        a.download = fileName;
        a.click();
      })
      .catch(err => console.log(err))
      .finally(hideOverlay);
  };

  return (
    <div>
      {showTitle && <hr className={classes.hr_widget_small} />}
      <div onClick={toggle} className={classes.groupe_item}>
        <div className='d-flex a-i-center'>
          {showTitle && (
            <div
              style={{
                visibility: (!!checkboxState.checked || !!checkboxState.indeterminate || showCheckboxes) && 'visible'
              }}
              className={classes.groupe_item_checkbox}
            >
              <ThreeStateCheckbox
                checked={checkboxState.checked}
                indeterminate={checkboxState.indeterminate}
                onChange={data => groupCheckboxChanged(title, data)}
              />
            </div>
          )}
          {!showTitle && (
            <div onClick={downloadArchive}>
              {showCheckboxInHideTitle ? (
                <ThreeStateCheckbox
                  checked={checkboxState.checked}
                  indeterminate={checkboxState.indeterminate}
                  onChange={data => groupCheckboxChanged(title, data)}
                />
              ) : (
                <Download
                  className={`${classes.download_icon} ${classes.pointer}`}
                  width='12'
                  height='18'
                  fill={'#C6CACC'}
                />
              )}
            </div>
          )}
          {title === 'Angebot an Kunde' || title === 'Angebot an Kunden - Folierung' ? (
            <div className={classes.kosten_info}>
              <span>
                {title === 'Angebot an Kunde' ? 'Kostenvoranschlag für Reparatur' : 'Kostenvoranschlag für Folierung'}
              </span>
              {costingsInfo?.auftragStatus &&
                costingsInfo?.auftragStatus.length &&
                costingsInfo?.angebotSections.length && (
                  <p>{`${
                    title === 'Angebot an Kunde'
                      ? costingsInfo.angebotSections[0]?.angebot[0]?.uds_kostenvoranschlag_freigegeben_option
                          ?.attributeValue?.value === 100000000
                        ? 'Freigegeben von:'
                        : 'Abgelehnt von:'
                      : costingsInfo.angebotSections[1]?.angebot[0]?.uds_kostenvoranschlag_freigegeben_option
                          ?.attributeValue?.value === 100000000
                      ? 'Freigegeben von:'
                      : 'Abgelehnt von:'
                  } ${
                    costingsInfo.auftragStatus[0]?.uds_createdby_portaluser?.attributeValue?.name
                      ? costingsInfo.auftragStatus[0].uds_createdby_portaluser.attributeValue.name
                      : ''
                  } • am: ${
                    costingsInfo.auftragStatus[0]?.modifiedon?.attributeValue
                      ? formatDate(costingsInfo.auftragStatus[0].modifiedon.attributeValue)
                      : ''
                  }`}</p>
                )}
            </div>
          ) : (
            <span>{title}</span>
          )}
        </div>
        <DownArrowIcon className={expand && classes.rotate} />
      </div>
      <div ref={content} style={{ height: height, overflow: 'hidden' }}>
        {files.map(file => (
          <FileInfo
            key={file.fileId}
            showCheckboxes={showCheckboxes}
            allFiles={allFiles}
            fileCheckboxChanged={fileCheckboxChanged}
            file={file}
            title={title}
            onDownload={showDownloadButtonInFileInfo && downloadFileHandler}
            showLetterModal={showLetterModal}
          />
        ))}
      </div>
    </div>
  );
};

GroupeItem.propTypes = {
  title: PropTypes.string.isRequired,
  files: PropTypes.array.isRequired,
  showCheckboxes: PropTypes.bool.isRequired,
  showDownloadButtonInFileInfo: PropTypes.bool,
  groupCheckboxChanged: PropTypes.func.isRequired,
  fileCheckboxChanged: PropTypes.func.isRequired,
  showCheckboxInHideTitle: PropTypes.bool,
  allFiles: PropTypes.array.isRequired,
  entityName: PropTypes.string,
  entityValue: PropTypes.string,
  costingsInfo: PropTypes.any
};

const DownloadDocumentsComponent = ({
  documentsGroupedByDescription,
  archiveName,
  entityName = '',
  entityValue = '',
  costingsInfo = {},
  showTitle = true,
  showCheckboxInHideTitle = false,
  showBigDownloadButton = false,
  compressedDocumentLength = 3,
  showDownloadButtonInFileInfo = false,
  className
}) => {
  const [showOverlay, hideOverlay] = useOverlay();
  const [documents, setDocuments] = useState(documentsGroupedByDescription);
  const [checkboxState, setCheckboxState] = useState({ checked: false, indeterminate: false });
  const [expanded, setExpanded] = useState(false);
  const [archiveTitle, setArchiveTitle] = useState(archiveName);

  const documentsArray = Object.entries(documents);
  const allFiles = useMemo(() => documentsArray.flatMap(document => document[1]), [documents]);
  const downloadButtonActive = checkboxState.checked || checkboxState.indeterminate;

  const {
    isLetterModalOpened,
    selectedLetter,
    allLetters,
    selectedLetterIndex,
    showLetterModal,
    handleClose,
    handleSwitchLetter
  } = useLetterModal(allFiles);

  useEffect(() => {
    setDocuments(documentsGroupedByDescription);
  }, [documentsGroupedByDescription]);

  useEffect(() => {
    const filesCheckboxStates = [];
    const newCheckboxState = { checked: false, indeterminate: true };
    getFile(documents, file => {
      filesCheckboxStates.push(file.checked);
    });
    let newArchiveTitle = `${filesCheckboxStates.reduce(
      (accumulator, currentValue) => (currentValue ? ++accumulator : accumulator),
      0
    )} ausgewählt`;
    if (filesCheckboxStates.every(state => state)) {
      newCheckboxState.checked = true;
      newCheckboxState.indeterminate = false;
      newArchiveTitle = 'Alle Dateien';
    }
    if (filesCheckboxStates.every(state => !state)) {
      newCheckboxState.checked = false;
      newCheckboxState.indeterminate = false;
      newArchiveTitle = archiveName;
    }
    setArchiveTitle(newArchiveTitle);
    setExpanded(newCheckboxState.checked || newCheckboxState.indeterminate);
    setCheckboxState(newCheckboxState);
  }, [documents, archiveName]);

  const mainCheckboxChanged = data => {
    const newDocuments = cloneDeep(documents);
    getFile(newDocuments, file => {
      file.checked = !data.indeterminate && !data.checked;
    });
    setDocuments(newDocuments);
  };

  const groupCheckboxChanged = (groupTitle, data) => {
    const newDocuments = cloneDeep(documents);
    for (let key in newDocuments) {
      if (newDocuments.hasOwnProperty(key)) {
        if (key === groupTitle) {
          newDocuments[key].forEach(file => {
            file.checked = !data.indeterminate && !data.checked;
          });
        }
      }
    }
    setDocuments(newDocuments);
  };

  const fileCheckboxChanged = (fileId, data) => {
    const newDocuments = cloneDeep(documents);
    getFile(newDocuments, file => {
      if (file.fileId === fileId) {
        file.checked = !data.checked;
      }
    });
    setDocuments(newDocuments);
  };

  const downloadArchive = async () => {
    if (downloadButtonActive) {
      const checkedFiles = [];
      getFile(documents, file => {
        if (file.checked) {
          checkedFiles.push(file);
        }
      });
      const filePaths = checkedFiles.map(file => file.filePath);
      showOverlay();
      if (filePaths.length === 1) {
        getFileForDownload(filePaths[0])
          .then(({ data }) => {
            const filePathArr = filePaths[0].split('/');
            const fileName = filePathArr[filePathArr.length - 1];
            const blob = new Blob([data], { type: 'application/octet-stream' });
            const a = document.createElement('a');
            a.href = URL.createObjectURL(blob);
            a.download = fileName;
            a.click();
          })
          .catch(err => console.log(err))
          .finally(hideOverlay);
      } else {
        const response = await sharepointService.getZipFilesByPath(filePaths, archiveName);
        hideOverlay();
        const a = document.createElement('a');
        a.href = URL.createObjectURL(new Blob([response.data]));
        a.download = `${archiveName}.zip`;
        a.click();
      }
    }
  };

  return (
    <div className={classNames(classes.wrapper, className)}>
      {showTitle && documentsArray.length > 0 && (
        <div className={classes.download_archive}>
          <div className='d-flex a-i-center' style={{ display: 'flex', width: '100%' }}>
            <ThreeStateCheckbox
              checked={checkboxState.checked}
              disabled={!documentsArray.length}
              indeterminate={checkboxState.indeterminate}
              onChange={mainCheckboxChanged}
            />
            <span style={{ color: documentsArray.length ? '#102F3F' : '#C6CACC', marginLeft: '15px' }}>
              {archiveTitle}
            </span>
          </div>
          <div onClick={downloadArchive}>
            <Download
              className={downloadButtonActive ? `${classes.download_icon} ${classes.pointer}` : classes.download_icon}
              fill={downloadButtonActive ? '#102F3F' : '#C6CACC'}
            />
          </div>
        </div>
      )}
      {documentsArray.slice(0, expanded ? documentsArray.length : compressedDocumentLength).map(([key, value]) => (
        <GroupeItem
          key={key}
          title={key}
          showCheckboxes={checkboxState.checked || checkboxState.indeterminate}
          allFiles={allFiles}
          groupCheckboxChanged={groupCheckboxChanged}
          fileCheckboxChanged={fileCheckboxChanged}
          files={value}
          archiveName={archiveName}
          costingsInfo={costingsInfo}
          entityName={entityName}
          entityValue={entityValue}
          showTitle={showTitle}
          downloadingArray={documents}
          showCheckboxInHideTitle={showCheckboxInHideTitle}
          showDownloadButtonInFileInfo={showDownloadButtonInFileInfo}
          showLetterModal={showLetterModal}
        />
      ))}
      {showBigDownloadButton && downloadButtonActive && (
        <Button className={classes.download_button} onClick={downloadArchive}>
          Herunterladen
        </Button>
      )}
      {documentsArray.length > compressedDocumentLength ? (
        <>
          <hr className={classes.hr_widget} />
          <div onClick={() => setExpanded(!expanded)} className={classes.mehr_anzeigen_btn}>
            {expanded ? 'Weniger anzeigen' : 'Mehr anzeigen'}
          </div>
        </>
      ) : null}
      {!documentsArray.length && (
        <div className={classes.empty_list}>
          <h5>Der Ordner ist leer.</h5>
          <p className={classes.empty_message}>Es sind noch keine Dokumente vorhanden.</p>
        </div>
      )}

      <LetterModal
        isOpen={isLetterModalOpened}
        selectedLetter={selectedLetter}
        allLetters={allLetters}
        selectedLetterIndex={selectedLetterIndex}
        onClose={handleClose}
        handleSwitchLetter={handleSwitchLetter}
      />
    </div>
  );
};

DownloadDocumentsComponent.propTypes = {
  documentsGroupedByDescription: PropTypes.object.isRequired,
  archiveName: PropTypes.string.isRequired,
  entityName: PropTypes.string,
  entityValue: PropTypes.string,
  costingsInfo: PropTypes.any,
  showCheckboxInHideTitle: PropTypes.bool,
  showDownloadButtonInFileInfo: PropTypes.bool,
  compressedDocumentLength: PropTypes.number,
  className: PropTypes.string,
  showLetterModal: PropTypes.func
};

export default DownloadDocumentsComponent;
